import { Pipe, PipeTransform } from '@angular/core';
import { tranformDateToTimeComponents } from '../utils/date-transforms/time-component';

@Pipe({
  name: 'dateToTimeComponent',
})
export class DateToTimeComponentPipe implements PipeTransform {
  transform(date: Date | null): string {
    return tranformDateToTimeComponents(date);
  }
}
