import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { map, Observable, of, Subscription } from 'rxjs';
import { isWithinFourHours } from 'src/app/core/utils/events';
import { DateToHoursMinutesAgoPipe } from 'src/app/shared/pipes/date-to-hours-minutes-ago.pipe';
import { CommentService } from 'src/app/shared/utils/comments/comment.service';
import {
  CommentsUser,
  IComment,
} from 'src/app/shared/view-models/comments.view.model';
import { SharedModule } from '../../shared.module';
import { CommentFormComponent } from '../comment-form/comment-form.component';

@Component({
  selector: 'app-comment-section',
  templateUrl: './comment-section.component.html',
  styleUrls: ['./comment-section.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CommentFormComponent,
    DateToHoursMinutesAgoPipe,
    SharedModule,
  ],
})
export class CommentSectionComponent implements OnDestroy {
  @Input() comments: IComment[] = [];
  @Input() currentUserId: string = '';
  @Input() currentUserFullName: string = '';
  @Input() usersToMention: CommentsUser[] = [];
  @Output() addComment = new EventEmitter<{
    content: string;
    mentionedIds: string[];
  }>();
  @Output() editComment = new EventEmitter<{ id: string; content: string }>();
  @Output() deleteComment = new EventEmitter<string>();

  commentForm: FormGroup;
  editingCommentId: string | null = null;
  private commentSubscription: Subscription | null = null;
  private colorAssignments: Map<string, string> = new Map();
  private colorIndex: number = 0;
  private avatarColors: string[] = [
    '#337810',
    '#486969',
    '#367cbf',
    '#0f3770',
    '#8a46ff',
    '#6d407e',
    '#d71328',
    '#ef7e42',
    '#06052f',
    '#ff9f08',
    '#625b71',
    '#245d96',
    '#61a42b',
    '#5c9fd4',
    '#ffc21b',
    '#9b6fa9',
    '#f6a971',
    '#708f8f',
    '#ab6eff',
    '#eb516c',
  ];

  constructor(
    private fb: FormBuilder,
    private commentService: CommentService,
    private sanitizer: DomSanitizer
  ) {
    this.commentForm = this.fb.group({
      comment: ['', Validators.required],
    });
  }
  ngOnDestroy(): void {
    if (this.commentSubscription) {
      this.commentSubscription.unsubscribe();
    }
  }

  onCommentSubmit(content: string): void {
    this.commentSubscription = this.commentService
      .parseMentions(content, this.usersToMention || [])
      .subscribe(({ parsedContent, mentionedIds }) => {
        this.addComment.emit({ content: parsedContent, mentionedIds });
        this.commentForm.reset();
      });
  }

  onCommentEdit(commentId: string): void {
    this.editingCommentId = commentId;
  }

  onCommentEditSubmit(commentId: string, newContent: string): void {
    this.editComment.emit({ id: commentId, content: newContent });
    this.editingCommentId = null;
  }

  onCommentDelete(commentId: string): void {
    this.deleteComment.emit(commentId);
  }

  onCommentCancel(): void {
    this.commentForm.reset();
  }

  getAvatarColor(authorName: string): string {
    if (authorName === this.currentUserFullName) {
      return 'var(--text-500)';
    }

    if (!this.colorAssignments.has(authorName)) {
      const color = this.avatarColors[this.colorIndex];
      this.colorAssignments.set(authorName, color);
      this.colorIndex = (this.colorIndex + 1) % this.avatarColors.length;
    }

    return this.colorAssignments.get(authorName)!;
  }

  isCreatedWithinFourHours(time: Date | string): boolean {
    return isWithinFourHours(time);
  }

  parseCommentContent(content: string): Observable<SafeHtml> {
    return of(this.usersToMention).pipe(
      map(users => {
        const parsedContent = content.replace(/@(\w+\s\w+)/g, (match, name) => {
          const user = users?.find(
            u => `${u.firstName} ${u.lastName}` === name
          );
          if (user) {
            return `<span style="font-size: 15px;
                                 font-weight: 400;
                                 line-height: 15px;
                                 color: var(--text-500);
                                 background-color: #DCE1FF;
                                 border-radius: 20px;
                                 padding: 4px;
                    ">@${name}</span>`;
          }
          return match;
        });
        return this.sanitizer.bypassSecurityTrustHtml(parsedContent);
      })
    );
  }

  adjustTextareaHeight(element: HTMLTextAreaElement): void {
    element.style.height = 'auto';
    element.style.height = `${element.scrollHeight}px`;

    const maxHeight = 200;
    if (element.scrollHeight > maxHeight) {
      element.style.height = `${maxHeight}px`;
      element.style.overflowY = 'auto';
    } else {
      element.style.overflowY = 'hidden';
    }
  }

  cancelEdit(): void {
    this.editingCommentId = null;
  }
}
