import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { Store } from '@ngrx/store';
import { CoreState } from 'src/app/core/store/state/core.state';
import { OverviewSelector } from 'src/app/core/store/types';
import { NewBadgeComponent } from '../../new-badge/new-badge.component';

@Component({
  selector: 'app-title',
  standalone: true,
  imports: [CommonModule, NewBadgeComponent, MatBadgeModule],
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss'],
})
export class TitleComponent {
  unreadInsightsCount$ = this.store.select(
    OverviewSelector.selectUnreadInsightsCount
  );

  constructor(private store: Store<CoreState>) {}
}
