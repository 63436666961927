import {
  dayVideoMap,
  StreamVideo,
  thermalVideoMap,
  viewsStreamMap,
  viewsVideoMap,
} from '../view-models/event.view.model';

export function buildVideos<T extends string>(
  videoMap: Record<string, T>,
  videoUrls: string[],
  token: string
): Record<T, string | null> {
  const sortedKeys = Object.keys(videoMap).sort(
    (key1, key2) => key2.length - key1.length
  );

  return videoUrls
    .map(videoUrl => {
      const key = sortedKeys.find(key => videoUrl.indexOf(key) > -1);
      return key ? { [videoMap[key]]: `${videoUrl}?token=${token}` } : null;
    })
    .reduce(
      (prev, elem) => ({ ...prev, ...elem }),
      {} as Record<T, string | null>
    );
}

export function buildViewsVideos(videoUrls: string[], token: string) {
  return buildVideos(viewsVideoMap, videoUrls, token!);
}

export function buildDayVideos(videoUrls: string[], token: string) {
  return buildVideos(dayVideoMap, videoUrls, token!);
}

export function buildThermalVideos(videoUrls: string[], token: string) {
  return buildVideos(thermalVideoMap, videoUrls, token!);
}

export function buildViewsStreams(cameraIds: string[]): StreamVideo[] {
  const result = Object.entries(viewsStreamMap)
    .filter(key => cameraIds.includes(key[0]))
    .map(([key, value]) => {
      return { cameraId: key, cameraName: value } as StreamVideo;
    });
  return result;
}

export function buildDayStreams(cameraIds: string[]): StreamVideo[] {
  const result = Object.entries(dayVideoMap)
    .filter(key => cameraIds.includes(key[0]))
    .map(([key, value]) => {
      return { cameraId: key, cameraName: value } as StreamVideo;
    });
  return result;
}

export function buildThermalStreams(cameraIds: string[]): StreamVideo[] {
  const result = Object.entries(thermalVideoMap)
    .filter(key => cameraIds.includes(key[0]))
    .map(([key, value]) => {
      return { cameraId: key, cameraName: value } as StreamVideo;
    });
  return result;
}
