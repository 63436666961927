import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
} from '@angular/core';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { BehaviorSubject, debounceTime, takeUntil } from 'rxjs';
import { combineTree, Node } from '../../../utils/combine-tree';
import { DestroyRef } from '../../services/destroy-ref.service';
import { ValueAccessorProxy } from '../../services/value-accessor-proxy.service';
import { TreeOptionComponent } from './tree-option/tree-option.component';

export type SelectionState = boolean | null;

interface FlatNode {
  expandable: boolean;
  item: TreeOptionComponent;
  level: number;
  children: Node<TreeOptionComponent>[];
}

type Transformer = (node: Node<TreeOptionComponent>, level: number) => FlatNode;

@Component({
  selector: 'orc-tree-select',
  templateUrl: './tree-select.component.html',
  styleUrls: ['./tree-select.component.scss'],
  providers: [DestroyRef, ValueAccessorProxy],
})
export class TreeSelectComponent implements OnInit, AfterContentInit {
  constructor(
    private dr: DestroyRef,
    protected vap: ValueAccessorProxy<string[]>
  ) {
    this.selection.changed
      .pipe(takeUntil(this.dr.destroy$), debounceTime(0))
      .subscribe(val => {
        this.selectionChanged.emit(val.source.selected.map(s => s.item.key));
      });

    vap.value$.subscribe(val => {
      if (val instanceof Array) {
        this._setValue(val);
      }
    });
  }

  private _options$ = new BehaviorSubject<TreeOptionComponent[]>([]);
  get options$() {
    return this._options$.asObservable();
  }

  @Output()
  selectionChanged = new EventEmitter<string[]>();

  @Input()
  set value(val: string[]) {
    this._setValue(val);
  }

  private nodes$ = this.options$.pipe(combineTree(opt => opt.options$));

  protected selection = new SelectionModel<FlatNode>(true);

  private _getFlatNodesMapping(): Record<string, FlatNode> {
    const nodes = this.treeControl.dataNodes ?? [];
    const pairs = nodes.map(fn => [fn.item.key, fn] as const);
    const map = Object.fromEntries(pairs);
    return map;
  }

  private async _setValue(val: string[]) {
    const map = this._getFlatNodesMapping();
    const selected = val ? val.map(key => map[key]).filter(fn => !!fn) : [];
    this.selection.setSelection(...selected);
  }

  toggleNode(node: FlatNode) {
    const newState = this.getSelectionState(node) === true ? false : true;

    if (newState === true) {
      this.selection.select(node);
    } else {
      this.selection.deselect(node);
    }

    const descentdants = this.treeControl.getDescendants(node);
    if (newState === true) {
      this.selection.select(...descentdants);
    } else {
      this.selection.deselect(...descentdants);
    }

    descentdants.forEach(c => this.selection.isSelected(c));
    this.checkAllParentsSelection(node);
    const selected = this.selection.selected.map(fn => fn.item.key);
    this.vap.valueChanged(selected);
    this.vap.touch();
  }

  getSelectionState(value: FlatNode): SelectionState {
    if (this.selection.isSelected(value)) return true;

    const descendants = this.treeControl.getDescendants(value);

    if (descendants.length === 0) return this.selection.isSelected(value);

    const selectedCount = descendants.reduce(
      (acc, item) => (this.selection.isSelected(item) ? acc + 1 : acc),
      0
    );
    if (selectedCount === 0) return false;
    if (selectedCount === descendants.length) return true;
    return null;
  }

  checkAllParentsSelection(node: FlatNode): void {
    let parent: FlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  checkRootNodeSelection(node: FlatNode): void {
    const nodeSelected = this.selection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every(child => {
        return this.selection.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      this.selection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.selection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: FlatNode): FlatNode | null {
    const currentLevel = node.level;

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (currentNode.level < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  @ContentChildren(TreeOptionComponent)
  options!: QueryList<TreeOptionComponent>;

  transformer: Transformer = (node, level) => ({
    expandable: node.children.length > 0,
    level: level,
    item: node.item,
    children: node.children,
  });

  treeFlattener = new MatTreeFlattener<
    Node<TreeOptionComponent>,
    FlatNode,
    string
  >(
    this.transformer,
    n => n.level,
    n => n.expandable,
    n => n.children
  );

  treeControl = new FlatTreeControl<FlatNode, string>(
    n => n.level,
    n => n.expandable,
    {
      trackBy: n => n.item.key,
    }
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  hasChild = (_: number, node: FlatNode) => node.expandable;

  ngAfterContentInit(): void {
    this._options$.next([...this.options]);
    this.options.changes.pipe(takeUntil(this.dr.destroy$)).subscribe(val => {
      this._options$.next(val);
    });

    this.nodes$.subscribe(val => {
      this.dataSource.data = val;
    });
  }

  ngOnInit(): void {}
}
