import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-save-preset-dialog',
  templateUrl: './save-preset-dialog.component.html',
  styleUrls: ['./save-preset-dialog.component.scss'],
})
export class SavePresetDialogComponent {
  label = '';

  presetName = new FormControl<string>('', [Validators.required]);
  constructor(
    public dialogRef: MatDialogRef<SavePresetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.label = `Filter number ${data.numOfPresets + 1}`;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
