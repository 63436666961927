<div class="container">
  <div class="content-wrapper">
    <div
      class="content"
      *ngFor="let stringScore of scoreDetail"
      [ngStyle]="{
        width: (stringScore.indecies.length * 100) / scores.length + '%'
      }">
      <div
        class="string-score {{ stringScore.title | lowercase }} {{
          stringScore.indecies.includes(score) ? 'selected' : ''
        }}">
        <div
          class="text {{
            stringScore.indecies.includes(score) ? 'caption-bold' : 'caption'
          }}">
          {{ stringScore.title }}
        </div>
        <div class="bar-wrapper">
          <div
            class="bar"
            [ngStyle]="{
              'background-color': stringScore.indecies.includes(score)
                ? stringScore.selectedBarColor
                : stringScore.barColor
            }"></div>
        </div>
        <div class="indicators">
          <div
            *ngFor="let index of stringScore.indecies"
            class="{{
              isSelected(index)
                ? 'caption-bold selected-index'
                : 'caption index'
            }}">
            {{ index }}
            <div *ngIf="isSelected(index)" class="triangle"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
