import { TemplateRef } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { CellTemplateContext } from '../models/cell-template-context.model';
import { ColumnDefinition } from '../models/column-definition.model';
import { HeaderTemplateContext } from '../models/header-template-context.model';
import { RowKey } from '../models/row-key.model';
import { TrackBySelector } from '../models/track-by-selector.model';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const INIT_STATE: TableState = {
  data: [],
  columns: [],
  trackBy: t => t,
  cellTemplates: {},
  headerTemplates: {},
  sortColumn: '',
  sortDirection: '',
  selectedItemId: null,
};

export interface TableState {
  readonly data: any[];
  readonly columns: ColumnDefinition<any>[];
  readonly trackBy: TrackBySelector<any>;
  readonly defaultCellTemplate?: TemplateRef<CellTemplateContext>;
  readonly cellTemplates: Record<string, TemplateRef<CellTemplateContext>>;
  readonly defaultHeaderTemplate?: TemplateRef<HeaderTemplateContext>;
  readonly headerTemplates: Record<string, TemplateRef<HeaderTemplateContext>>;
  readonly sortColumn: string;
  readonly sortDirection: SortDirection;
  readonly selectedItemId: RowKey | null;
}

export type InitTableState = Required<TableState>;
