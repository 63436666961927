import { KeyValue } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { VideosNames } from '../view-models/event.view.model';

@Pipe({
  name: 'sortVideos',
})
export class VideoSortPipe implements PipeTransform {
  private sortOrder: VideosNames[] = ['Port', 'Bow', 'Starboard'];

  transform(
    videos: KeyValue<string, string | null>[] | null
  ): KeyValue<string, string | null>[] {
    if (!videos) {
      return [];
    }
    return videos.sort((a, b) => {
      return (
        this.sortOrder.indexOf(a.key as VideosNames) -
        this.sortOrder.indexOf(b.key as VideosNames)
      );
    });
  }
}
