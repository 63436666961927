<div class="container">
  <div class="text-container">
    <div class="logo-container">
      <img class="logo" src="./assets/icons/logo_auth.svg" />
      <h2>ORCA AI</h2>
    </div>
    <div class="account-created-container">
      <h2>Your account was created</h2>
      <div class="sub-title">
        <span class="subtitle1">We have sent you an email with your</span>
        <span class="subtitle1">login instructions</span>
      </div>
    </div>
    <div class="help-container">
      <span class="caption"
        >If you don't receive instructions shortly, please check your email's
        spam or junk folders.
      </span>
      <span class="caption">
        If this doesn't work, please contact us at
        <a
          href="mailto:fleetview@orca-ai.io?subject=Orca FleetView email verification issue"
          >FleetView@orca-ai.io</a
        ></span
      >
    </div>
  </div>
</div>
