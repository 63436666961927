import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { firstValueFrom, Observable } from 'rxjs';
import { CoreState } from 'src/app/core/store/state/core.state';
import { EventsActions, EventsSelectors } from 'src/app/core/store/types';
import { AppliedEventFilters } from 'src/app/core/view-models/filter.view.model';
import { CustomSliderOptions } from 'src/app/shared/view-models/slider.options.model';
@Component({
  selector: 'app-high-rot-filter',
  templateUrl: './high-rot-filter.component.html',
  styleUrls: ['./high-rot-filter.component.scss'],
})
export class HighRotFilterComponent implements OnInit {
  sliderHighRot$!: Observable<CustomSliderOptions>;
  appliedRotFilter$!: Observable<Partial<AppliedEventFilters>>;
  constructor(private store: Store<CoreState>) {}

  ngOnInit(): void {
    this.sliderHighRot$ = this.store.select(
      EventsSelectors.selectSliderHighRot
    );
  }

  async onEventFiltersChange(value: any, key: string): Promise<void> {
    const appliedFilters = await firstValueFrom(
      this.store.select(EventsSelectors.selectAppliedEventFilters)
    );
    const updatedAppliedFilters: AppliedEventFilters = {
      ...appliedFilters,
      [key]: value,
    };
    this.store.dispatch(
      EventsActions.onEventFilterChange({ updatedAppliedFilters })
    );
  }
}
