import {
  CommentsUser,
  IComment,
} from 'src/app/shared/view-models/comments.view.model';
import {
  defaultEventFilters,
  EventFilters,
} from '../../models/events-filters.model';
import {
  defaultEventsData,
  defaultSelectedShipEventsData,
  Event,
  EventsData,
} from '../../models/events.model';
import { Events } from '../../view-models/event.view.model';
import {
  AppliedEventFilters,
  defaultAppliedEventFilters,
  PresetFilter,
} from '../../view-models/filter.view.model';

export interface EventsState {
  readonly overviewEvents: Event[];
  readonly eventsData: EventsData;
  readonly selectedEvent: Events | null;
  readonly eventFilters: EventFilters;
  readonly appliedEventFilters: AppliedEventFilters;
  readonly presetEventFilters: PresetFilter[];
  readonly selectedPresetFilterInEvents: PresetFilter | undefined;
  readonly selectedPresetFilterInOverview: PresetFilter | undefined;
  readonly selectedPresetFilterInShip: PresetFilter | undefined;
  readonly selectedShipEventsData: EventsData;
  readonly comments: IComment[];
  readonly commentsUsers: CommentsUser[];
}

export const initialEventsState: EventsState = {
  eventsData: defaultEventsData,
  eventFilters: defaultEventFilters,
  overviewEvents: [],
  appliedEventFilters: { ...defaultAppliedEventFilters },
  selectedEvent: null,
  presetEventFilters: [],
  selectedPresetFilterInEvents: undefined,
  selectedPresetFilterInOverview: undefined,
  selectedPresetFilterInShip: undefined,
  selectedShipEventsData: defaultSelectedShipEventsData,
  comments: [],
  commentsUsers: [],
};
