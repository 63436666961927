import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[appDynamicSquare]',
})
export class DynamicSquareDirective implements OnInit {
  private width!: number;
  private height!: number;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.setSquareDimentions();
  }

  @Input()
  toSquare!: HTMLDivElement;
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.setSquareDimentions();
  }

  private setSquareDimentions(): void {
    this.width =
      this.el.nativeElement.offsetWidth === 0
        ? 160
        : this.el.nativeElement.offsetWidth;
    this.height =
      this.el.nativeElement.offsetHeight === 0
        ? 160
        : this.el.nativeElement.offsetHeight;
    const squareSize = Math.min(this.width, this.height);

    this.renderer.setStyle(this.toSquare, 'height', `${squareSize}px`);
    this.renderer.setStyle(this.toSquare, 'width', `${squareSize}px`);
  }
}
