<div
  class="container"
  [ngClass]="{ 'blur-effect': (showFlag$ | async) === false }">
  <div class="event-data-container">
    <div class="nav-back">
      <img src="./assets/icons/arrow_left.svg" />
      <span class="back-button caption" (click)="navigateBack()">Back</span>
    </div>
    <div class="separator"></div>
    <div *ngIf="rtEvent as realTimeEvent" class="event-data">
      <div class="header">
        <div
          class="rt-event-box"
          [ngClass]="{ live: !isRtEventOver, ended: isRtEventOver }">
          <div class="rt-event-status">
            <img
              [src]="
                isRtEventOver
                  ? './assets/icons/red_live.svg'
                  : './assets/icons/blue_live.svg'
              " />
            <span [ngClass]="{ 'red-span-text': isRtEventOver }">
              {{ isRtEventOver ? 'LIVE : ENDED' : 'LIVE' }}
            </span>
          </div>
          <span class="rt-event-time" *ngIf="!isRtEventOver">
            {{ getTimeDiff(realTimeEvent) }}
          </span>
        </div>
        <app-ship-event-header
          [event]="realTimeEvent"
          [eventPathImg]="getEventPath()">
          <ng-template #subtitleTemplate let-event>
            {{ event.type }}
            <span *ngIf="event | typeGuard: isRTComplianceEvent">Event</span>
            <span *ngIf="event | typeGuard: isRTNoGoZoneEvent">
              - {{ event.areaOfInterest }}
            </span>
          </ng-template>
        </app-ship-event-header>
      </div>

      <app-info-row>
        <app-info-column
          title="Location"
          [value]="realTimeEvent.location"></app-info-column>
        <app-info-column
          title="Received"
          [value]="
            realTimeEvent.timestamp | dateToHoursMinutesAgo
          "></app-info-column>
        <app-info-column
          *ngIf="realTimeEvent | typeGuard: isRTComplianceEvent"
          title="AOI"
          [value]="realTimeEvent.areaOfInterest">
        </app-info-column>
      </app-info-row>

      <app-info-row
        *ngIf="
          (realTimeEvent | typeGuard: isRTComplianceEvent) &&
          realTimeEvent.aoiComplianceLimitations
        ">
        <app-info-column
          *ngIf="realTimeEvent.aoiComplianceLimitations.sog.maxAllowed"
          title="SOG Limitation"
          [value]="
            'Max allowed: ' +
            realTimeEvent.aoiComplianceLimitations.sog.maxAllowed +
            ' Knts'
          ">
        </app-info-column>
        <app-info-column
          *ngIf="realTimeEvent.aoiComplianceLimitations.cog.minAllowed"
          title="COG Limitation"
          [value]="
            'Not within: ' +
            (realTimeEvent.aoiComplianceLimitations.cog.minAllowed
              | number: '1.0-2') +
            '°'
          ">
        </app-info-column>
      </app-info-row>

      <div class="separator"></div>
      <app-event-info title="Event Info">
        <app-event-data
          title="SOG"
          [value]="realTimeEvent.sog | number: '1.0-2'"
          unit="Knts"
          [violatedValue]="
            (realTimeEvent | typeGuard: isRTComplianceEvent) &&
            realTimeEvent.aoiComplianceLimitations &&
            (realTimeEvent.sog
              | isViolatedLimitation
                : realTimeEvent.aoiComplianceLimitations!.sog)
          "></app-event-data>
        <app-event-data
          *ngIf="
            (realTimeEvent | typeGuard: isRTNoGoZoneEvent) ||
            (realTimeEvent | typeGuard: isRTComplianceEvent)
          "
          title="COG"
          [value]="(realTimeEvent.cog | number: '1.0-2') + '°'"
          [violatedValue]="
            (realTimeEvent | typeGuard: isRTComplianceEvent) &&
            realTimeEvent.cog &&
            realTimeEvent.aoiComplianceLimitations &&
            (realTimeEvent.cog
              | isViolatedLimitation
                : realTimeEvent.aoiComplianceLimitations!.cog)
          "></app-event-data>
        <app-event-data
          *ngIf="
            (realTimeEvent | typeGuard: isRTHighPitchRollEvent) ||
            (realTimeEvent | typeGuard: isRTComplianceEvent)
          "
          title="Roll"
          [value]="
            (realTimeEvent.roll | number: '1.0-2') + '°'
          "></app-event-data>
        <app-event-data
          *ngIf="
            (realTimeEvent | typeGuard: isRTHighPitchRollEvent) ||
            (realTimeEvent | typeGuard: isRTComplianceEvent)
          "
          title="Pitch"
          [value]="
            (realTimeEvent.pitch | number: '1.0-2') + '°'
          "></app-event-data>
      </app-event-info>

      <mat-tab-group
        mat-stretch-tabs
        class="custom-tabs"
        *ngIf="
          (realTimeEvent | typeGuard: isRTHighPitchRollEvent) ||
          (((realTimeEvent | typeGuard: isRTComplianceEvent) ||
            (realTimeEvent | typeGuard: isRTNoGoZoneEvent)) &&
            isRtEventOver === false)
        ">
        <mat-tab label="Live Video">
          <app-live-stream
            [fleetId]="realTimeEvent.fleetId"
            [shipId]="realTimeEvent.shipId"></app-live-stream>
        </mat-tab>
        <mat-tab
          label="Latest Screenshot"
          [disabled]="screenshotsDisabled$ | async">
          <div class="tab">
            <img
              class="single-item"
              *ngFor="let screenshot of screenshots$ | async"
              [src]="screenshot.url"
              (click)="openImageDialog(screenshot)" />
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>

  <div class="graph-map-container">
    <div *ngIf="!graphDisabled" class="graph">
      <mat-button-toggle-group [(ngModel)]="this.activeGraph">
        <mat-button-toggle [value]="this.graphSegmentsChoices.PITCH_AND_ROLL">
          Pitch and Roll
        </mat-button-toggle>
        <mat-button-toggle [value]="this.graphSegmentsChoices.SOG">
          SOG
        </mat-button-toggle>
      </mat-button-toggle-group>

      <app-imu-samples-graph
        *ngIf="this.activeGraph === graphSegmentsChoices.PITCH_AND_ROLL"
        class="main-graph"
        [allGraphDataView]="pitchRollGraphDataView"
        [colorScheme]="pitchRollColorScheme"
        [tooltipViewMapping]="graphPitchRollValuesMap"
        [yLabel]="formatPitchRollYLabel">
      </app-imu-samples-graph>
      <app-imu-samples-graph
        *ngIf="this.activeGraph === graphSegmentsChoices.SOG"
        class="main-graph"
        [allGraphDataView]="sogGraphDataView"
        [tooltipViewMapping]="graphSogValuesMap"
        [colorScheme]="sogColorScheme"
        [yLabel]="formatSogYLabel">
      </app-imu-samples-graph>
    </div>
    <div class="map" [ngClass]="{ 'graph-disabled-map': graphDisabled }">
      <app-map
        [position]="eventPosition"
        [events]="rtMapEvent$ | async"
        [polygons]="polygons"
        [shipLastLocations]="shipLastLocations">
        <ng-template #layerMenus>
          <app-weather-menu></app-weather-menu>
        </ng-template>
      </app-map>
    </div>
  </div>
</div>
