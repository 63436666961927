import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Model } from 'common';
import cryptoRandomString from 'crypto-random-string';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  filter,
  firstValueFrom,
  from,
  map,
  multicast,
  Observable,
  refCount,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { Fleet, Ship } from 'src/app/core/models/fleet.model';
import { AdminService } from 'src/app/core/services/admin.service';
import { FleetService } from 'src/app/core/services/fleet.service';
import { CoreState } from 'src/app/core/store/state/core.state';
import { AuthenticationSelectors } from 'src/app/core/store/types';
import { groupBy, UsersGroupedByCompany } from 'src/app/core/utils/users';
import { rolesDropdownOptions } from 'src/app/core/view-models/roles.view.model';
import { limitNumOfUsers } from 'src/app/shared/utils/form-validators/num.of-users.validator';
import { isShipIdRequired } from 'src/app/shared/utils/form-validators/ship.req.when.captian';
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit, OnDestroy {
  rolesDropdownOptions = rolesDropdownOptions;

  groupedUsers!: UsersGroupedByCompany;
  userCreationForm = this.fb.group(
    {
      subFleetShipsId: [null],
      shipId: [null],
      fleetId: [null, [Validators.required]],
      role: ['fleet-manager', [Validators.required, Validators.minLength(8)]],
      numOfUsers: [1, [Validators.required, limitNumOfUsers]],
    },
    {
      validators: [isShipIdRequired()],
    }
  );
  editUsernameForm = new FormControl<string>('', [Validators.required]);

  editingUserId: string | null = null;

  fleets$!: Observable<Fleet[]>;
  ships$!: Observable<Ship[]>;
  shipIdToNameMap!: Map<number, string>;

  isShipsDisabled$!: Observable<boolean>;
  isSubFleetManagerRoleSelected$!: Observable<boolean>;

  searchBoxSubShipsControl = new FormControl<string>('');
  searchBox$!: Observable<string | null>;

  constructor(
    private store: Store<CoreState>,
    private fb: FormBuilder,
    private fleetsService: FleetService,
    private adminService: AdminService,
    private route: ActivatedRoute
  ) {}

  ngOnDestroy(): void {
    this.adminService.unRegisterToUsersDb();
  }

  ngOnInit(): void {
    this.shipIdToNameMap = this.route.snapshot.data['shipIdToNameMap'];
    this.fleets$ = this.store.select(AuthenticationSelectors.selectFleets).pipe(
      filter(fleets => fleets !== null),
      // eslint-disable-next-line @ngrx/avoid-mapping-selectors
      map(fleets => fleets!.slice(1))
    );
    const shipsByFleet$ = this.userCreationForm
      .get('fleetId')!
      .valueChanges.pipe(
        filter(fleetId => fleetId !== null),
        switchMap(fleetId => from(this.fleetsService.getShips$(fleetId!))),
        tap(_ => {
          this.userCreationForm.get('shipId')?.reset(null);
          this.userCreationForm.get('subFleetShipsId')?.reset(null);
        })
      );
    this.searchBox$ = this.searchBoxSubShipsControl.valueChanges.pipe(
      startWith(''),
      debounceTime(500),
      map(v => v!.toLowerCase())
    );
    this.ships$ = combineLatest([shipsByFleet$, this.searchBox$]).pipe(
      map(([ships, shipNameToSearch]) => {
        if (shipNameToSearch === '') {
          return [...ships];
        } else {
          return ships.filter(ship =>
            ship.shipName.toLowerCase().includes(shipNameToSearch!)
          );
        }
      })
    );
    this.isSubFleetManagerRoleSelected$ = this.userCreationForm
      .get('role')!
      .valueChanges.pipe(map(role => role === 'sub-fleet-manager'));

    this.isShipsDisabled$ = this.userCreationForm
      .get('role')!
      .valueChanges.pipe(
        multicast(() => new BehaviorSubject(this.userCreationForm.value.role)),
        refCount(),
        map(role => role !== 'ship-captain')
      );

    this.adminService.registerToUsersDb(users => {
      this.groupedUsers = groupBy(users);
    });
  }

  async createUsers(): Promise<void> {
    const fleets = await firstValueFrom(this.fleets$);
    const companyName = fleets.filter(
      fleet => fleet.fleetId === this.userCreationForm.get('fleetId')!.value
    )[0].companyName;
    let users: Model.PreliminaryUser[] = new Array();
    for (let i = 0; i < this.userCreationForm.get('numOfUsers')!.value!; i++) {
      const user: Model.PreliminaryUser = {
        companyName: companyName,
        fleetId: this.userCreationForm.value.fleetId!,
        id: cryptoRandomString({ length: 15, type: 'alphanumeric' }),
        password: cryptoRandomString({ length: 15, type: 'base64' }),
        username: cryptoRandomString({ length: 15, type: 'base64' }),
        role: this.userCreationForm.value.role! as Model.Role,
        shipId:
          (this.userCreationForm.value.role as Model.Role) === 'ship-captain'
            ? this.userCreationForm.value.shipId!
            : null,
        subFleetShipsId:
          (this.userCreationForm.value.role as Model.Role) ===
          'sub-fleet-manager'
            ? [...this.userCreationForm.value.subFleetShipsId!]
            : null,
      };
      users.push(user);
    }
    await this.adminService.createUsers(users);
  }

  startEditing(user: Model.PreliminaryUser): void {
    this.editingUserId = user.id;
    this.editUsernameForm.setValue(user.username);
  }

  async saveUsername(): Promise<void> {
    if (this.editUsernameForm.valid && this.editingUserId) {
      const newUsername = this.editUsernameForm.value;
      await this.adminService
        .updateUser({ id: this.editingUserId, username: newUsername! })
        .then(() => this.resetEditState());
    }
  }

  cancelEdit(): void {
    this.resetEditState();
  }

  private resetEditState(): void {
    this.editingUserId = null;
    this.editUsernameForm.reset();
  }
  getShipName(shipId: number): string {
    return this.shipIdToNameMap.get(shipId) || 'Unknown Ship';
  }
}
