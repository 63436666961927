import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-gallery-image-dialog',
  templateUrl: './gallery-image-dialog.component.html',
  styleUrls: ['./gallery-image-dialog.component.scss'],
})
export class GalleryImageDialogComponent {
  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<GalleryImageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
  openLivestream(shipId: number): void {
    this.dialogRef.close();
    this.router.navigate(['/private/live/ship', shipId], {
      queryParams: { isLiveStreamOpen: true },
    });
  }
}
