import { defaultRange } from 'src/app/shared/view-models/range.view.model';
import { DropdownViewModel } from '../../view-models/dropdown.options.view.model';
import { CustomSliderOptions } from '../../view-models/slider.options.model';

export function buildCustomSliderOptions(
  step: number,
  min: number,
  max: number,
  lowRangeValue = min,
  highRangeNumber = max
): CustomSliderOptions {
  if (
    min !== null &&
    max !== null &&
    lowRangeValue !== null &&
    highRangeNumber !== null
  ) {
    return {
      highRangeValue:
        Math.ceil(highRangeNumber / step) * step + Math.random() / 1000,
      lowRangeValue:
        Math.floor(lowRangeValue / step) * step - Math.random() / 1000,
      ngxSliderOptions: {
        hideLimitLabels: true,
        floor: parseFloat((Math.floor(min / step) * step).toFixed(2)),
        ceil: parseFloat((Math.ceil(max / step) * step).toFixed(2)),
        step: step,
      },
    };
  }

  return {
    highRangeValue: defaultRange.max,
    lowRangeValue: defaultRange.min,
    ngxSliderOptions: {
      hideLimitLabels: true,
      floor: defaultRange.min,
      ceil: defaultRange.max,
      step: step,
    },
  };
}

export function buildDropdownOptions(
  options: Array<any>,
  transformFunc: (value: any) => string,
  firstOption?: DropdownViewModel
): DropdownViewModel[] {
  const dropdownOptions = options
    .map(value => {
      return { value, viewValue: transformFunc(value) };
    })
    .filter(value => value.viewValue != null && value.viewValue != undefined);
  return firstOption ? [firstOption, ...dropdownOptions] : [...dropdownOptions];
}
