<app-no-data [dataExists]="dataExists$ | async">
  <div class="container">
    <app-widget-title
      class="header"
      [widgetTitleType]="'trendWithIconTitle'"
      [title]="'Avg. Fleet Safety Score'"
      [avgScore$]="fleetAverageSafetyScore$"
      [secendAvgScore$]="orcaAverageSafetyScore$"
      [showEventsDayFilterTitle$]="showEventsDayFilterTitle$"
      [selectedDateRange$]="selectedDateRange$"
      [startDateAvgSeverityScore$]="startDateAvgSeverityScore$"
      [endDateAvgSeverityScore$]="endDateAvgSeverityScore$">
    </app-widget-title>
    <div class="graph-container">
      <ngx-charts-line-chart
        class="primary-line-chart"
        [results]="safetyScoreByDateView$ | async"
        [xAxis]="true"
        [yAxis]="true"
        [yScaleMin]="yScaleMin"
        [yScaleMax]="yScaleMax"
        [curve]="linearCurve"
        [tooltipDisabled]="false"
        [xAxisTickFormatting]="formatXLabel"
        [xAxisTicks]="(xAxisTicks$ | async)!">
        <ng-template
          #seriesTooltipTemplate
          #tooltipTemplate
          let-safetyScore="model">
          <div class="tooltip-container">
            <div class="score">
              <h2 class="regular">
                {{
                  safetyScore.value || safetyScore[0].value | number: '1.0-0'
                }}
              </h2>
              <span class="subtitle1">/10</span>
            </div>
            <caption>
              {{
                safetyScore.name || safetyScore[0].date | dateToDayMonthTime
              }}
            </caption>
          </div>
        </ng-template>
      </ngx-charts-line-chart>
    </div>
  </div>
</app-no-data>
