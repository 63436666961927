import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'orca-on-off-button',
  templateUrl: './on-off-button.component.html',
  styleUrls: ['./on-off-button.component.scss']
})
export class OnOffButtonComponent implements OnInit {
  @Input()
  value: boolean | null = false;

  @Output()
  toggled = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  onClick() {
    this.toggled.emit(!this.value);
  }

}
