import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  NavigationStart,
  Resolve,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { concatMap, EMPTY, filter, firstValueFrom, Observable } from 'rxjs';
import { Ship } from '../models/ship.model';
import { EventsService } from '../services/events.service';
import { fetchShipDetails } from '../store/actions/ships.actions';
import { CoreState } from '../store/state/core.state';
import {
  AuthenticationSelectors,
  EventsActions,
  EventsSelectors,
  ShipsActions,
  ShipsSelectors,
} from '../store/types';
import { defaultAppliedEventFilters } from '../view-models/filter.view.model';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class ShipResolver
  implements Resolve<Promise<Ship | Observable<never>>>
{
  private isNavigationBackward: boolean = false;
  constructor(
    private store: Store<CoreState>,
    private eventsService: EventsService,
    private router: Router
  ) {
    this.subscribeToRouterEvents();
  }

  private subscribeToRouterEvents(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe(event => {
        this.isNavigationBackward =
          (event as NavigationStart).navigationTrigger === 'popstate';
      });
  }

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<Ship | Observable<never>> {
    const shipId = parseInt(route.params['shipId']);

    if (!isNaN(shipId)) {
      this.store.dispatch(fetchShipDetails({ shipId: shipId }));
      const ships = await firstValueFrom(
        this.store.select(AuthenticationSelectors.selectAuthenticatedUser).pipe(
          filter(authUser => authUser?.token != ''),
          concatMap(_ =>
            this.store
              .select(ShipsSelectors.selectShips)
              .pipe(filter(ships => ships.length > 0))
          )
        )
      );
      const foundShip = ships.find(ship => ship.shipId === shipId);

      if (foundShip) {
        // eslint-disable-next-line @ngrx/avoid-dispatching-multiple-actions-sequentially
        if (!this.isNavigationBackward)
          this.store.dispatch(EventsActions.resetShipSettings());
        const datesFilter = await firstValueFrom(
          this.store.select(EventsSelectors.selectEventsDateFilter)
        );

        const eventsData = await firstValueFrom(
          this.eventsService.getEventsAndCount$(
            {
              ...defaultAppliedEventFilters,
              shipName: { value: foundShip.shipName },
            },
            'severity',
            datesFilter.dates,
            1
          )
        );
        // eslint-disable-next-line @ngrx/avoid-dispatching-multiple-actions-sequentially
        this.store.dispatch(
          EventsActions.saveShipEventsOnPresetChange({ eventsData })
        );
        // eslint-disable-next-line @ngrx/avoid-dispatching-multiple-actions-sequentially
        this.store.dispatch(
          ShipsActions.setSelectedShipIdOnViewShipProfile({
            shipId: foundShip.shipId,
          })
        );

        return foundShip;
      } else {
        return EMPTY;
      }
    }

    return EMPTY;
  }
}
