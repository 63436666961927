import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  collection,
  deleteDoc,
  doc,
  Firestore,
  onSnapshot,
  orderBy,
  query,
  setDoc,
} from '@angular/fire/firestore';
import { Model } from 'common';
import cryptoRandomString from 'crypto-random-string';
import {
  catchError,
  combineLatest,
  firstValueFrom,
  map,
  Observable,
  of,
} from 'rxjs';
import { Dates } from 'src/app/shared/view-models/dates.view.model';
import { defaultRange } from 'src/app/shared/view-models/range.view.model';
import { environment } from 'src/environments/environment';
import {
  AlertNamesToEventTypeMap,
  alertNamesToEventTypes,
  defaultEventFilters,
  DynamicFiltersDto,
  EventFilters,
  EventsSortBy,
} from '../models/events-filters.model';
import {
  Event,
  EventDto,
  EventLocation,
  EventLocationDto,
  EventsCountDto,
  EventsData,
  RtEventDto,
} from '../models/events.model';
import { mapEventDtoToEvent, mapRtEventDtoToRtEvent } from '../utils/events';
import { mapLocationsDtoToLocations } from '../utils/filters';
import {
  ActiveRTEvent,
  ActiveRTEventDto,
  RtEvent,
} from '../view-models/event.view.model';
import {
  AppliedEventFilters,
  defaultAppliedEventFilters,
  MultipleChoiceEventFilter,
  PresetFilter,
  PresetFilterDto,
} from '../view-models/filter.view.model';
import { IsBusyService } from './is-busy.service';
import { NotificationsService } from './notifications.service';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  constructor(
    private http: HttpClient,
    private isBusy: IsBusyService,
    private firestore: Firestore,
    private notificationService: NotificationsService
  ) {}

  getEventsFilterValues$(dates: Dates): Observable<EventFilters> {
    return this.http
      .get<DynamicFiltersDto>(environment.api.eventsFilters, {
        params: {
          start_date: dates.startDate.toISOString(),
          end_date: dates.endDate.toISOString(),
        },
      })
      .pipe(
        map((filterDto): EventFilters => {
          return {
            generalFilters: {
              congestionLevels: [...(filterDto.congestion_levels ?? [])],
              eventTypes: [...(filterDto.alert_names ?? [])],
              locations: mapLocationsDtoToLocations(filterDto.locations),
              severityLevels: [...(filterDto.severity_levels ?? [])],
              shipNames: [...(filterDto.ship_names ?? [])],
              ownSog: { min: filterDto.min_sog, max: filterDto.max_sog },
            },
            closeEncounterFilter: {
              colregClassifications: [
                ...(filterDto.colreg_classifications ?? []),
              ],
              minDistance: {
                min: filterDto.min_target_distance_min_dis,
                max: filterDto.max_target_distance_min_dis,
              },
              targetSog: {
                min: filterDto.min_target_sog_start,
                max: filterDto.max_target_sog_start,
              },
              targetType: [...(filterDto.target_types ?? [])],
            },
            highPitchRoll: {
              pitch: { min: filterDto.min_pitch, max: filterDto.max_pitch },
              roll: { min: filterDto.min_roll, max: filterDto.max_roll },
            },
            highRot: {
              rot: { min: filterDto.min_rot, max: filterDto.max_rot },
            },
            speedDrop: {
              sogDiff: {
                min: filterDto.min_sog_diff,
                max: filterDto.max_sog_diff,
              },
            },
            ukcFilter: {
              minDepth: { min: filterDto.min_depth, max: filterDto.max_depth },
            },
          };
        }),
        catchError(err => of(defaultEventFilters))
      );
  }

  setAppliedFiltersToDefault(eventFilters: EventFilters): AppliedEventFilters {
    return {
      ...defaultAppliedEventFilters,
      shipName: { value: 'all' },
      ownSog: { ...eventFilters.generalFilters.ownSog },
      congestionLevel: {
        value: 'all',
      },
      eventType: { value: 'all' },
      locations: [
        ...eventFilters.generalFilters.locations
          .map(location => location.subLocations)
          .flat(),
      ],
      inPort: false,
      showOnlyRt: false,
      minDistance: { ...eventFilters.closeEncounterFilter.minDistance },
      targetSog: { ...eventFilters.closeEncounterFilter.targetSog },
      colregClassifications: {
        value: 'all',
      },
      targetType: { value: 'all' },
      pitch: { ...eventFilters.highPitchRoll.pitch },
      roll: { ...eventFilters.highPitchRoll.roll },
      rot: { ...eventFilters.highRot.rot },
      sogDiff: { ...eventFilters.speedDrop.sogDiff },
      minDepth: { ...eventFilters.ukcFilter.minDepth },
    };
  }

  private getAppliedEventMultipleFilterValue(
    filters: AppliedEventFilters,
    filterName: MultipleChoiceEventFilter
  ) {
    let filterValue = filters[filterName].value;
    return Array.isArray(filterValue) ? filterValue.join(',') : filterValue;
  }

  private buildEventFiltersQueryParam(filters: AppliedEventFilters) {
    const params = {
      ship_names: this.getAppliedEventMultipleFilterValue(filters, 'shipName'),
      congestion_levels: this.getAppliedEventMultipleFilterValue(
        filters,
        'congestionLevel'
      ),
      min_sog: filters.ownSog.min,
      max_sog: filters.ownSog.max,
      alert_names: this.getAppliedEventMultipleFilterValue(
        filters,
        'eventType'
      ),
      colreg_classifications: this.getAppliedEventMultipleFilterValue(
        filters,
        'colregClassifications'
      ),
      min_target_distance_min_dis: filters.minDistance.min,
      max_target_distance_min_dis: filters.minDistance.max,
      min_target_sog_start: filters.targetSog.min,
      max_target_sog_start: filters.targetSog.max,
      target_types: this.getAppliedEventMultipleFilterValue(
        filters,
        'targetType'
      ),
      min_depth: filters.minDepth.min,
      max_depth: filters.minDepth.max,
      min_roll: filters.roll.min,
      max_roll: filters.roll.max,
      min_pitch: filters.pitch.min,
      max_pitch: filters.pitch.max,
      min_sog_diff: filters.sogDiff.min,
      max_sog_diff: filters.sogDiff.max,
      min_rot: filters.rot.min,
      max_rot: filters.rot.max,
      severity_levels: filters.severityLevels.join(','),
      sub_locations: filters.locations.join(','),
      inside_port: filters.inPort,
      is_rt_event: filters.showOnlyRt,
    };

    const queryParams = Object.entries(params).filter(
      entry =>
        entry[1] !== 'all' &&
        entry[1] !== defaultRange.min &&
        entry[1] !== defaultRange.max &&
        entry[1] !== null
    );

    return queryParams.reduce(
      (obj, item) => Object.assign(obj, { [item[0]]: item[1] }),
      {}
    );
  }

  public getEventsCount$(
    filters: AppliedEventFilters,
    datesFilter: Dates
  ): Observable<number> {
    const queryParams = this.buildEventFiltersQueryParam(filters);
    return this.http
      .get<EventsCountDto>(environment.api.eventsCount, {
        params: {
          ...queryParams,
          start_date: datesFilter.startDate.toISOString(),
          end_date: datesFilter.endDate.toISOString(),
        },
      })
      .pipe(
        map(res => res.total_count),
        catchError(_ => of(0))
      );
  }

  public getEvents$(
    filters: AppliedEventFilters,
    sortBy: EventsSortBy,
    datesFilter: Dates,
    pageNumber = 1,
    size = 100
  ): Promise<Event[]> {
    const queryParams = this.buildEventFiltersQueryParam(filters);
    return this.isBusy.add(() =>
      firstValueFrom(
        this.http
          .get<EventDto[]>(environment.api.events, {
            params: {
              ...queryParams,
              sort_by: sortBy,
              page_number: pageNumber,
              size: size,
              start_date: datesFilter.startDate.toISOString(),
              end_date: datesFilter.endDate.toISOString(),
            },
          })
          .pipe(
            map((eventsDto): Event[] =>
              eventsDto.map((dto): Event => mapEventDtoToEvent(dto))
            ),
            catchError(_ => of([]))
          )
      )
    );
  }

  public getEventsLocation$(
    filters: AppliedEventFilters,
    eventsDateFilter: Dates,
    sortBy: EventsSortBy = 'default'
  ): Observable<EventLocation[]> {
    const queryParams = this.buildEventFiltersQueryParam(filters);
    return this.http
      .get<EventLocationDto[]>(environment.api.eventsLocation, {
        params: {
          ...queryParams,
          start_date: eventsDateFilter.startDate.toISOString(),
          end_date: eventsDateFilter.endDate.toISOString(),
          sort_by: sortBy,
        },
      })
      .pipe(
        map(res =>
          res.map(
            (eventLocationDto): EventLocation => ({
              id: eventLocationDto.event_id,
              lat: eventLocationDto.own_lat_start,
              long: eventLocationDto.own_long_start,
              severityLevel: eventLocationDto.severity_level,
            })
          )
        ),
        catchError(_ => of([]))
      );
  }

  getEventsData$(
    filters: AppliedEventFilters,
    sortBy: EventsSortBy,
    datesFilter: Dates,
    pageNumber = 1
  ): Promise<Partial<EventsData>> {
    return this.isBusy.add(() =>
      firstValueFrom(
        combineLatest({
          totalCount: this.getEventsCount$(filters, datesFilter),
          events: this.getEvents$(filters, sortBy, datesFilter, pageNumber),
          eventsLocation: this.getEventsLocation$(filters, datesFilter),
        })
      )
    );
  }

  getEventsAndCount$(
    filters: AppliedEventFilters,
    sortBy: EventsSortBy,
    datesFilter: Dates,
    pageNumber = 1,
    size = 100
  ): Observable<Partial<EventsData>> {
    return combineLatest({
      totalCount: this.getEventsCount$(filters, datesFilter),
      events: this.getEvents$(filters, sortBy, datesFilter, pageNumber, size),
    });
  }

  getEventById(eventId: string): Promise<Event | null> {
    return this.isBusy.add(() => {
      return firstValueFrom(
        this.http.get<EventDto>(`${environment.api.events}/${eventId}`).pipe(
          map((dto): Event => mapEventDtoToEvent(dto)),
          catchError(_ => of(null))
        )
      );
    });
  }

  getRtEventById(eventId: string): Promise<RtEvent | null> {
    return this.isBusy.add(() => {
      return firstValueFrom(
        this.http.get<RtEventDto>(`${environment.api.rtEvent}/${eventId}`).pipe(
          map((dto): RtEvent => mapRtEventDtoToRtEvent(dto)),
          catchError(_ => of(null))
        )
      );
    });
  }

  downloadKepler(url: string): Promise<Blob> {
    return this.isBusy.add(() =>
      firstValueFrom(
        this.http
          .get(url, { responseType: 'blob' })
          .pipe(catchError(_ => of(null)))
      )
    );
  }

  savePresetFilters(
    user: Model.User,
    filters: AppliedEventFilters,
    presetName: string
  ): Promise<PresetFilter | undefined> {
    const presetFilter: PresetFilter = {
      presetId: cryptoRandomString({ length: 15, type: 'alphanumeric' }),
      presetName,
      filters: { ...filters },
      createdAt: new Date(),
    };
    return this.isBusy.add(async () => {
      try {
        await setDoc(
          doc(
            this.firestore,
            'preset-filters',
            user.id,
            'presets',
            presetFilter.presetId as string
          ),
          {
            presetName: presetFilter.presetName,
            filters: presetFilter.filters,
            createdAt: presetFilter.createdAt,
          }
        );
        return presetFilter;
      } catch {
        this.notificationService.error('Could not save preset filter');
        return undefined;
      }
    }, 'Preset filter was saved successfully');
  }

  saveSelectedPreset(
    user: Model.User,
    selectedPreset: PresetFilter | undefined
  ): Promise<PresetFilter | undefined> {
    return this.isBusy.add(async () => {
      try {
        if (selectedPreset) {
          await setDoc(
            doc(
              this.firestore,
              'preset-filters',
              user.id,
              'selected-presets',
              'selected-preset-in-overview'
            ),
            {
              id: selectedPreset.presetId,
              presetName: selectedPreset.presetName,
              filters: selectedPreset.filters,
              createdAt: selectedPreset.createdAt,
            }
          );
        } else {
          await deleteDoc(
            doc(
              this.firestore,
              'preset-filters',
              user.id,
              'selected-presets',
              'selected-preset-in-overview'
            )
          );
        }
        return selectedPreset;
      } catch {
        return undefined;
      }
    });
  }

  async registerToPresetsInPresetsDb(
    user: Model.User,
    func: (appliedFilters: PresetFilter[]) => void
  ): Promise<void> {
    const q = query(
      collection(this.firestore, 'preset-filters', `${user.id}`, 'presets'),
      orderBy('createdAt', 'desc')
    );
    onSnapshot(q, querySnapshot => {
      const presets = querySnapshot.docs.map((doc): PresetFilter => {
        const data = doc.data() as PresetFilterDto;
        return {
          ...data,
          createdAt: data.createdAt.toDate(),
          presetId: doc.id,
        };
      });
      func(presets);
    });
  }

  async registerToSelectedPresetInPresetsDb(
    user: Model.User,
    func: (presetFilter: PresetFilter | undefined) => void
  ): Promise<void> {
    const selectedPresetCollectionRef = collection(
      this.firestore,
      'preset-filters',
      `${user.id}`,
      'selected-presets'
    );
    onSnapshot(selectedPresetCollectionRef, async querySnapshot => {
      if (!querySnapshot.empty) {
        const presetFilterDoc = querySnapshot.docs[0];
        if (presetFilterDoc.exists()) {
          const data = presetFilterDoc.data() as PresetFilter;
          func({ ...data, presetId: data.presetId });
        }
      } else {
        func(undefined);
      }
    });
  }

  getActiveRTEvents(): Observable<{ [shipId: number]: ActiveRTEvent }> {
    return this.http
      .get<ActiveRTEventDto[]>(`${environment.api.rtEvent}/active_rt_events`)
      .pipe(
        map(activeRTEventDtos => {
          const activeRTEvents: { [shipId: string]: ActiveRTEvent } = {};
          activeRTEventDtos.forEach((dto: ActiveRTEventDto) => {
            activeRTEvents[dto.ship_id] = {
              areaOfInterest: dto.area_of_interest,
              aoiComplianceLimitations: dto.aoi_compliance_limitations,
              polygonId: dto.polygon_uuid,
              alertName:
                alertNamesToEventTypes[
                  dto.event_type as keyof AlertNamesToEventTypeMap
                ],
              timestampStart: new Date(dto.timestamp_start),
            };
          });
          return activeRTEvents;
        }),
        catchError(error => {
          console.error('Error fetching active RT events', error);
          return of({});
        })
      );
  }
}
