<app-menu-button
  [iconSrc]="'./assets/icons/weather.svg'"
  [isEnabledFeature]="isEnabledFeature"
  [menuOpen]="isEnabled"
  (openMenu)="onOpenMenu()">
  <ng-template #menuTitle>
    <div class="title">
      <h4><img src="./assets/icons/weather.svg" alt="weather" />Weather</h4>
      <mat-slide-toggle
        color="primary"
        [(ngModel)]="isEnabled"
        (change)="
          updateSettings(this.selectedLayer, $event.checked)
        "></mat-slide-toggle>
    </div>
    <span class="subtitle"
      >Last update: {{ roundedTimeToNearest3Hours() }} UTC</span
    >
  </ng-template>
  <ng-template #menuContent>
    <mat-radio-group
      class="layers-content"
      [(ngModel)]="selectedLayer"
      [disabled]="!isEnabled">
      <div
        class="layer-item"
        *ngFor="let weatherLayerOption of weatherLayersOptions">
        <mat-radio-button
          color="primary"
          [value]="weatherLayerOption.key"
          (change)="updateSettings($event.value, true)">
          {{ weatherLayerOption.label }}
        </mat-radio-button>
      </div>
    </mat-radio-group>
  </ng-template>
</app-menu-button>
