import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { distinctUntilChanged, filter, take, timer } from 'rxjs';
import {
  DayNamesDto,
  ThermalNamesDto,
  ViewStreamNamesDto,
} from 'src/app/core/view-models/event.view.model';
import videojs, { VideoJsPlayer } from 'video.js';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements AfterViewInit, OnDestroy {
  @ViewChild('videoPlayer', { read: ElementRef, static: false })
  private videoPlayerElementRef!: ElementRef;

  @Input()
  streamUrl!: string;

  @Input()
  cameraControl!: FormControl<
    DayNamesDto | ThermalNamesDto | ViewStreamNamesDto | null
  >;

  private player!: VideoJsPlayer;
  constructor(videoPlayerElementRef: ElementRef) {}

  ngAfterViewInit(): void {
    this.initializePlayer();
    this.cameraControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        filter(cameraId => cameraId !== null)
      )
      .subscribe(_ => {
        this.visuallyReloadPlayer();
      });
  }

  ngOnDestroy() {
    this.disposePlayer();
  }

  private initializePlayer(): void {
    this.player = videojs(this.videoPlayerElementRef.nativeElement, {
      autoplay: true,
      muted: true,
      controlBar: {
        fullscreenToggle: true,
      },
      sources: [
        {
          src: this.streamUrl,
          type: 'application/x-mpegURL',
        },
      ],
    });

    this.videoPlayerElementRef.nativeElement.setAttribute('controls', '');

    this.player.on('ended', () => {
      this.player.src({ src: this.streamUrl, type: 'application/x-mpegURL' });
      this.player.play();
    });

    this.player.on('error', () => {
      this.player.src(this.player.currentSrc());
    });
  }

  private disposePlayer(): void {
    if (this.player) {
      this.player.dispose();
    }
  }

  private visuallyReloadPlayer(): void {
    this.player.pause();
    this.player.src({ src: '', type: 'application/x-mpegURL' });
    timer(5000) // Wait 5 seconds
      .pipe(take(1))
      .subscribe(() => {
        this.player.src({
          src: this.streamUrl,
          type: 'application/x-mpegURL',
        });
        this.player.play();
      });
  }
}
