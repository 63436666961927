import { Directive, TemplateRef } from '@angular/core';
import { CellTemplateContext } from '../models/cell-template-context.model';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[orcTableCell]',
})
export class TableCellDirective {
  constructor(public template: TemplateRef<CellTemplateContext>) {}
}
