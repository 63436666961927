import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'underscoreToDisplayedString',
})
export class UnderscoreToDisplayedStringPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    }

    const words = value.split('_');

    const capitalizedWords = words.map(
      word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );

    // Join the words back into a sentence with spaces
    return capitalizedWords.join(' ');
  }
}
