import { dateFilters } from 'src/app/shared/view-models/dates.view.model';
import { EventSeverityKeys } from 'src/app/shared/view-models/general.event.view.model';

import { EventType, RTEventLimitations } from '../view-models/event.view.model';
import {
  AlertNames,
  ColregClassificationNames,
  CongestionLevelsNames,
  EventDayFilter,
  EventsSortBy,
  TargetTypeNames,
} from './events-filters.model';

export type EventSeverity = 'high' | 'medium' | 'low';
export type CrewShifts =
  | '08-12'
  | '12-16'
  | '16-20'
  | '20-00'
  | '00-04'
  | '04-08';

export type RtEventType =
  | 'Dangerous motion'
  | 'Dangerous Speed Drop'
  | 'Dangerous ROT'
  | 'Grounding risk'
  | 'Collision risk'
  | 'No Go Zone'
  | 'Compliance'
  | 'Sog Compliance'
  | 'Cog Compliance';
export type RtEventTypeToRtEventNameMap = { [key in RtEventType]: EventType };

export const rtEventTypeToRtEventName: RtEventTypeToRtEventNameMap = {
  'Dangerous motion': 'High Pitch/Roll',
  'Dangerous Speed Drop': 'Speed Drop',
  'Dangerous ROT': 'High ROT',
  'Grounding risk': 'UKC',
  'Collision risk': 'Close Encounter',
  'No Go Zone': 'No Go Zone',
  Compliance: 'Compliance',
  'Sog Compliance': 'Compliance',
  'Cog Compliance': 'Compliance',
};

export interface EventDto {
  readonly event_id: string;
  readonly alert_name: AlertNames;
  readonly congestion_level: CongestionLevelsNames;
  readonly fleet_id: number;
  readonly geo_group: string;
  readonly geo_tag_including_client_requests: string;
  readonly kepler_url: string;
  readonly videos_url_list: string[];
  readonly own_lat_start: number;
  readonly own_long_start: number;
  readonly own_ship_id: number;
  readonly own_sog_start: number;
  readonly severity_level: EventSeverityKeys;
  readonly ship_name: string;
  readonly timestamp_start: Date;
  readonly ais_target_distance_min_dis?: number;
  readonly ais_target_id?: string;
  readonly ais_target_sog_start?: number;
  readonly ais_target_type?: TargetTypeNames;
  readonly colreg_classification?: ColregClassificationNames;
  readonly max_delta_roll?: number;
  readonly max_delta_pitch?: number;
  readonly max_delta_sog?: number;
  readonly max_rot?: number;
  readonly min_depth?: number;
  readonly cost_of_extra_distance_sailed_dollar?: number;
  readonly extra_fuel_consumed_mt?: number;
  readonly is_rt_event?: boolean;
  readonly cog_start?: number;
  readonly area_of_interest?: string;
  readonly timestamp_end?: Date;
  readonly aoi_compliance_limitations?: RTEventLimitationsDto;
}

export interface Event {
  readonly id: string;
  readonly severityLevel: EventSeverityKeys;
  readonly eventType: AlertNames;
  readonly fleetId: number;
  readonly location: string;
  readonly subLocation: string;
  readonly keplerUrl: string;
  readonly videosUrl: string[];
  readonly congestionLevel: CongestionLevelsNames;
  readonly lat: number;
  readonly long: number;
  readonly shipName: string;
  readonly shipId: number;
  readonly timestamp: Date;
  readonly sog: number;
  readonly cog?: number;
  readonly aisTargetMinDis?: number;
  readonly aisTargetId?: string;
  readonly aisTargetSog?: number;
  readonly aisTargetType?: TargetTypeNames;
  readonly colregClassification?: ColregClassificationNames;
  readonly roll?: number;
  readonly pitch?: number;
  readonly sogDiff?: number;
  readonly rot?: number;
  readonly depth?: number;
  readonly costOfExtraDistanceSailedDollar?: number;
  readonly extraFuelConsumedMt?: number;
  readonly isRtEvent?: boolean;
  readonly aoiComplianceLimitations?: RTEventLimitations | null;
  readonly timestampEnd?: Date;
  readonly areaOfInterest?: string;
}

export interface DownloadedEvent {
  readonly id: string;
  readonly severityLevel: EventSeverityKeys;
  readonly eventType: AlertNames;
  readonly location: string;
  readonly subLocation: string;
  readonly congestionLevel: CongestionLevelsNames;
  readonly lat: number;
  readonly long: number;
  readonly shipName: string;
  readonly timestamp: Date;
  readonly sog: number;
  readonly aisTargetMinDis?: number;
  readonly aisTargetId?: string;
  readonly aisTargetSog?: number;
  readonly aisTargetType?: TargetTypeNames;
  readonly colregClassification?: ColregClassificationNames;
  readonly roll?: number;
  readonly pitch?: number;
  readonly sogDiff?: number;
  readonly rot?: number;
  readonly depth?: number;
}

export interface EventLocationDto {
  readonly event_id: string;
  readonly severity_level: EventSeverityKeys;
  readonly own_lat_start: number;
  readonly own_long_start: number;
}

export interface EventLocation {
  readonly id: string;
  readonly severityLevel: EventSeverityKeys;
  readonly lat: number;
  readonly long: number;
}

export interface EventsCountDto {
  readonly total_count: number;
}

export interface EventsData {
  readonly totalCount: number;
  readonly eventsLocation: EventLocation[];
  readonly events: Event[];
  readonly sortby: EventsSortBy;
  readonly pageNumber: number;
  readonly scrollToTop: boolean;
  readonly eventsDateFilter: EventDayFilter;
}

export const defaultEventsData: EventsData = {
  events: [],
  eventsLocation: [],
  totalCount: 0,
  sortby: 'default',
  pageNumber: 1,
  scrollToTop: true,
  eventsDateFilter: {
    dates: { ...dateFilters['Last 30 days']! },
    safetyScore: null,
  },
};

export const defaultSelectedShipEventsData: EventsData = {
  ...defaultEventsData,
  sortby: 'severity',
};

export interface RtEventDto {
  readonly event_id: string;
  readonly fleet_id: number;
  readonly ship_name: string;
  readonly ship_id: number;
  readonly event_type: AlertNames;
  readonly severity_level: EventSeverityKeys;
  readonly timestamp_start: Date;
  readonly timestamp_end: Date | null;
  readonly lat_start: number;
  readonly long_start: number;
  readonly sog_start: number;
  readonly cog_start: number;
  readonly pitch_start: number;
  readonly roll_start: number;
  readonly polygon_uuid: string;
  readonly area_of_interest: string;
  readonly textual_location: string;
  readonly screenshots: RtEventScreenshotDto[];
  readonly imu_samples: RtEventImuSampleDto[];
  readonly aoi_compliance_limitations: RTEventLimitationsDto;
}

export interface RtEventScreenshotDto {
  cdn_url: string;
  captured_at: string;
}

export interface RtEventImuSampleDto {
  readonly roll: number;
  readonly pitch: number;
  readonly sog: number;
  readonly sampled_at: Date;
}

export interface RTEventLimitationsDto {
  readonly min_allowed_sog: number;
  readonly max_allowed_sog: number;
  readonly sog_not_in_range: {
    min_value: number;
    max_value: number;
  };
  readonly min_allowed_cog: number;
  readonly max_allowed_cog: number;
  readonly cog_not_in_range: {
    min_value: number;
    max_value: number;
  };
}

export interface EventCountDto {
  readonly total_count: number;
  readonly group_result: EventCountByGroup[];
}

export interface EventCountByGroup {
  readonly group_title: EventSeverity | CrewShifts | AlertNames;
  readonly count: number;
}
