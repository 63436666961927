<div class="container">
  <div class="form-container">
    <div class="sign-up-logo-container">
      <img class="logo" src="./assets/icons/logo_auth.svg" />
      <h2>ORCA AI</h2>
    </div>

    <form class="form" [formGroup]="signUpForm" autocomplete="off">
      <h2 class="title">Create Account</h2>
      <div class="fullname-container">
        <mat-form-field appearance="outline" class="primary-outline">
          <mat-label>First name</mat-label>
          <input matInput formControlName="firstname" type="text" />
          <mat-error *ngIf="signUpForm.get('firstname')!.hasError('pattern')">
            Must contain letters only
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="primary-outline">
          <mat-label>Last name</mat-label>
          <input matInput formControlName="lastname" type="text" />
          <mat-error *ngIf="signUpForm.get('lastname')!.hasError('pattern')">
            Must contain letters only
          </mat-error>
        </mat-form-field>
      </div>

      <mat-form-field appearance="outline" class="primary-outline">
        <mat-label>Email</mat-label>
        <input matInput type="email" formControlName="email" />
      </mat-form-field>

      <mat-form-field appearance="outline" class="primary-outline">
        <mat-label>Company</mat-label>
        <input matInput type="text" formControlName="companyName" readonly />
      </mat-form-field>

      <mat-form-field appearance="outline" class="primary-outline">
        <mat-label>Enter your password</mat-label>
        <input matInput formControlName="password" #password type="password" />
        <mat-icon matSuffix appTogglePasswordVisibility [inputRef]="password"
          >visibility_off</mat-icon
        >
        <mat-error *ngIf="signUpForm.get('password')!.hasError('minlength')">
          Password is too short. It must contain at least 8 characters
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="primary-outline">
        <mat-label>Confirm Password</mat-label>
        <input
          matInput
          type="password"
          formControlName="confirmPassword"
          #confirmPassword
          [errorStateMatcher]="formErrorMatcher" />
        <mat-icon
          matSuffix
          appTogglePasswordVisibility
          [inputRef]="confirmPassword"
          >visibility_off</mat-icon
        >
        <mat-error *ngIf="signUpForm.hasError('passwordsNotSame')">{{
          'Passwords does not match'
        }}</mat-error>
      </mat-form-field>
      <mat-checkbox
        class="primary-outline"
        color="primary"
        formControlName="readLegalDoc"
        >I have read and agreed to the
        <a
          href="https://www.orca-ai.io/terms-of-use"
          target="_blank"
          rel="noreferrer noopener"
          >Terms of use</a
        >
        and the
        <a
          href="https://www.orca-ai.io/privacy-policy"
          target="_blank"
          rel="noreferrer noopener"
          >privacy policy</a
        >
      </mat-checkbox>
      <div class="btn-container">
        <button
          data-test="signup-btn"
          class="stay-primary-on-disabled signup-btn"
          mat-flat-button
          color="primary"
          (click)="signup()"
          [disabled]="!signUpForm.valid">
          Sign up
        </button>
      </div>
    </form>
  </div>
</div>
