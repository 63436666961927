import { Pipe, PipeTransform } from '@angular/core';
import { InsightDataType } from '../view-models/insight.view.model';

@Pipe({
  name: 'insightTypeCheck',
  standalone: true,
})
export class InsightTypeCheckPipe implements PipeTransform {
  transform(content: InsightDataType, typeToCheck: any): boolean {
    return content instanceof typeToCheck;
  }
}
