import { Injectable } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { ZoomOption } from '../models/map.view.models';
import { defaultMaxBounds, Position } from '../models/position.model';

const NO_BOUNDS = undefined;

@Injectable({
  providedIn: 'root',
})
export class CameraService {
  private map!: mapboxgl.Map;

  init(map: mapboxgl.Map): void {
    this.map = map;
  }

  setMinZoomToFitWorldBounds(): void {
    const maxBoundsZoom = this.map.setMaxBounds(defaultMaxBounds).getZoom();
    this.map.setMinZoom(maxBoundsZoom).setMaxBounds(NO_BOUNDS);
  }
  enableZoom(isEnabled: boolean): void {
    if (isEnabled) {
      this.map.scrollZoom.enable();
    } else {
      this.map.scrollZoom.disable();
    }
  }

  enableTilt(isEnabled: boolean): void {
    if (isEnabled) {
      this.map.dragRotate.enable();
      this.map.touchZoomRotate.enable();
    } else {
      this.map.dragRotate.disable();
      this.map.touchZoomRotate.disable();
    }
  }

  setMaxZoomHeight(maxZoom: number): void {
    this.map.setMaxZoom(maxZoom);
  }

  setZoom(zoomValue: ZoomOption): void {
    if (zoomValue == 'fit') zoomValue = this.map.getMinZoom();
    this.map!.setZoom(zoomValue);
  }

  zoomIn(zoomIncrement: number): void {
    this.map!.zoomTo(zoomIncrement);
  }

  getCurrentZoomHeight(): number {
    return this.map!.getZoom();
  }

  getOffsetPosition(offsetPositionDestination: number): number {
    return offsetPositionDestination / Math.pow(2, this.getCurrentZoomHeight());
  }

  flyTo(
    position: Position,
    offsetLongPositionDestination: number,
    offsetLatPositionDestination: number,
    duration?: number
  ): void {
    const targetLongitude =
      position.long - this.getOffsetPosition(offsetLongPositionDestination);
    const targetLatitude =
      position.lat + this.getOffsetPosition(offsetLatPositionDestination);

    const center = new mapboxgl.LngLat(targetLongitude, targetLatitude).wrap();
    this.map!.flyTo({
      center: center,
      zoom: this.getCurrentZoomHeight(),
      bearing: 0,
      pitch: 0,
      duration: duration ? duration * 1000 : 2000,
    });
  }
}
