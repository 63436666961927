import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Model } from 'common';
import { Observable } from 'rxjs';
import { RoutingService } from '../../services/routing.service';
import { CoreState } from '../../store/state/core.state';
import { AuthenticationSelectors } from '../../store/types';

@Component({
  selector: 'app-private',
  templateUrl: './private.component.html',
  styleUrls: ['./private.component.scss'],
})
export class PrivateComponent implements OnInit {
  user$!: Observable<Model.User | null>;
  isContainerSmall$!: Observable<boolean>;
  hideContainer$!: Observable<boolean>;

  constructor(
    private store: Store<CoreState>,
    private routingService: RoutingService
  ) {}

  ngOnInit(): void {
    this.hideContainer$ = this.routingService.isLiveNewTab$();
    this.isContainerSmall$ = this.routingService.isOverviewPage();

    this.user$ = this.store.select(
      AuthenticationSelectors.selectAuthenticatedUser
    );
  }
}
