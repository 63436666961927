import { PlatformLocation } from '@angular/common';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, map, mergeMap, switchMap } from 'rxjs';
import { CommentsService } from '../../services/comments.service';
import { CommentsActions } from '../types';

@Injectable()
export class CommentsEffects {
  constructor(
    private actions$: Actions,
    private commentsService: CommentsService,
    private platformLocation: PlatformLocation
  ) {}

  getThreadDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommentsActions.getThreadDetailsRequested),
      // eslint-disable-next-line @ngrx/no-multiple-actions-in-effects
      mergeMap(({ threadId }) => [
        CommentsActions.getUsersOfCurrentThread({ threadId }),
        CommentsActions.getCommentsOfCurrentThread({ threadId }),
      ])
    );
  });

  getUsersOfCurrentThread$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommentsActions.getCommentsOfCurrentThread),
      switchMap(action =>
        from(this.commentsService.getUsersByThreadId(action.threadId)).pipe(
          map(users =>
            CommentsActions.setUsersOfCurrentThreadInStore({
              users: users,
            })
          )
        )
      )
    );
  });

  getCommentsOfCurrentThread$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommentsActions.getCommentsOfCurrentThread),
      switchMap(action =>
        from(this.commentsService.getCommentsByThreadId(action.threadId)).pipe(
          map(comments =>
            CommentsActions.setCommentsOfCurrentThreadInStore({
              comments: comments,
            })
          )
        )
      )
    );
  });

  addCommentToThread$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommentsActions.addCommentToThread),
      switchMap(action =>
        this.commentsService
          .addComment(action.threadId, {
            content: action.content,
            linkUrl: this.platformLocation.href,
            mentions: action.mentions,
          })
          .pipe(
            map(_ => {
              return CommentsActions.getCommentsOfCurrentThread({
                threadId: action.threadId,
              });
            })
          )
      )
    );
  });

  editCommentToThread$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommentsActions.editCommentOnThread),
      switchMap(action =>
        from(
          this.commentsService.updateComment(
            action.threadId,
            action.commentId,
            {
              content: action.content,
              linkUrl: this.platformLocation.href,
            }
          )
        ).pipe(
          map(_ =>
            CommentsActions.getCommentsOfCurrentThread({
              threadId: action.threadId,
            })
          )
        )
      )
    );
  });

  deleteCommentToThread$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommentsActions.deleteCommentOnThread),
      switchMap(action =>
        from(
          this.commentsService.deleteComment(action.threadId, action.commentId)
        ).pipe(
          map(_ =>
            CommentsActions.getCommentsOfCurrentThread({
              threadId: action.threadId,
            })
          )
        )
      )
    );
  });
}
