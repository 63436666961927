import { CommonModule } from '@angular/common';
import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { SharedModule } from '../../../shared/shared.module';
import { Events, RtEvent } from '../../view-models/event.view.model';

@Component({
  selector: 'app-ship-event-header',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './ship-event-header.component.html',
  styleUrls: ['./ship-event-header.component.scss'],
})
export class ShipEventHeaderComponent {
  @Input()
  event!: RtEvent | Events;
  @Input()
  eventPathImg!: string;
  @ContentChild('subtitleTemplate', { static: false })
  subtitleTemplate!: TemplateRef<any>;

  constructor(private router: Router) {}

  navigateToShipProfile(): void {
    this.router.navigateByUrl(`/private/fleet/ship/${this.event.shipId}`);
  }
}
