import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { defaultFleet, Fleet } from '../../models/fleet.model';
import { CoreState } from '../../store/state/core.state';
import {
  AuthenticationActions,
  AuthenticationSelectors,
} from '../../store/types';

@Component({
  selector: 'app-fleet-dropdown',
  templateUrl: './fleet-dropdown.component.html',
  styleUrls: ['./fleet-dropdown.component.scss'],
})
export class FleetDropdownComponent implements OnInit {
  isUserOrcaAdmin$!: Observable<boolean | null>;

  fleets$!: Observable<Fleet[] | null>;

  fleetIdControl = new FormControl<number>(defaultFleet.fleetId);

  defualtFleet: Fleet = defaultFleet;

  constructor(private store: Store<CoreState>) {}

  ngOnInit(): void {
    this.isUserOrcaAdmin$ = this.store.select(
      AuthenticationSelectors.selectIsUserOrcaAdmin
    );

    this.fleets$ = this.store.select(AuthenticationSelectors.selectFleets);
  }

  fleetIdSelected(fleetId: number | null): void {
    const updatedFleetId = fleetId === -1 ? null : fleetId;
    this.store.dispatch(
      AuthenticationActions.changeFleetId({ fleetId: updatedFleetId })
    );
  }
}
