import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CoreState } from 'src/app/core/store/state/core.state';
import { EventsSelectors, FiltersSelectors } from 'src/app/core/store/types';
import {
  ColorScheme,
  GraphItemDataView,
  GraphTooltipMapping,
} from 'src/app/shared/models/graph-data-model';
import { DateFilterKeys } from 'src/app/shared/view-models/dates.view.model';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
})
export class PieChartComponent implements OnInit {
  @Input()
  dataExists: boolean = false;

  @Input()
  title: string = '';

  @Input()
  graphData: GraphItemDataView[] = [];

  @Input()
  totalCount: number = 0;

  @Input()
  countTitle: string = '';

  @Input()
  colorScheme: ColorScheme[] = [];

  @Input()
  legendClass = '';

  @Output()
  labelClick: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  sliceClick: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  centerTitleClick: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  tooltipMapping: GraphTooltipMapping = {};

  @Input()
  countByGroup: GraphItemDataView[] = [];
  selectedDateRange$!: Observable<DateFilterKeys>;
  showEventsDayFilterTitle$!: Observable<boolean>;

  constructor(public store: Store<CoreState>) {}
  ngOnInit(): void {
    this.showEventsDayFilterTitle$ = this.store.select(
      EventsSelectors.selectIsEventsFilterByDay
    );

    this.selectedDateRange$ = this.store.select(
      FiltersSelectors.selectFilterdDatesKey
    );
  }
}
