<div [ngStyle]="{ width: '60vw' }" class="container">
  <div mat-dialog-title class="title">
    <h4>Live Stream</h4>
    <button class="close" mat-button (click)="onNoClick()">
      <img class="icon" src="./assets/icons/exit.svg" />
    </button>
  </div>
  <div class="separator"></div>
  <app-live-stream [isDialogChild]="true"></app-live-stream>
</div>
