<div class="container">
  <p class="title">{{ insight.title }}</p>
  <div class="content">
    <div class="text" [innerHTML]="getInsightText()"></div>
    <p class="date">
      {{ insight.dateUpdated | date: 'dd MMM yyyy HH:mm' }}
    </p>
  </div>
  <div class="footer">
    <div class="link" (click)="clickLinkInsight()">
      {{ insight.link.text }}
    </div>
    <app-like-unlike
      [isLikeActive]="insight.likeVote"
      (statusLikeChanged)="updateStatusLike($event)"
      likeText="Helpful"
      unlikeText="Unhelpful"></app-like-unlike>
  </div>
</div>
