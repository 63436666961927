import { Injectable } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { allEntityImagePath } from '../map-data/entitty-images';

@Injectable({
  providedIn: 'root',
})
export class ImageEntitiesService {
  private map!: mapboxgl.Map;

  init(map: mapboxgl.Map): void {
    this.map = map;
  }

  loadImageEntity(): void {
    allEntityImagePath.forEach((imageKey: string) => {
      this.map.loadImage(imageKey, (error, image) => {
        if (error) console.error(`Error loading image ${image}:`, error);
        else if (image && !this.map.hasImage(imageKey))
          this.map.addImage(imageKey, image);
      });
    });
  }
}
