import { Component, Input } from '@angular/core';
import { SortDirection } from '@angular/material/sort';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'orc-sort-arrow',
  templateUrl: './sort-arrow.component.html',
  styleUrls: ['./sort-arrow.component.scss'],
})
export class SortArrowComponent {
  @Input()
  direction: SortDirection = '';

  constructor() {}
}
