import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DestroyRef } from 'projects/orca-lib-main/projects/orca-lib/src/lib/services/destroy-ref.service';

@Component({
  selector: 'app-live-stream-dialog',
  templateUrl: './live-stream-dialog.component.html',
  styleUrls: ['./live-stream-dialog.component.scss'],
  providers: [DestroyRef],
})
export class LiveStreamDialogComponent {
  constructor(public dialogRef: MatDialogRef<LiveStreamDialogComponent>) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
