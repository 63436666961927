<div class="container">
  <h4>Save a new preset</h4>
  <div class="separator"></div>
  <div mat-dialog-content class="dialog-body">
    <mat-form-field
      appearance="outline"
      class="primary-outline filter-form-field"
      aria-autocomplete="none">
      <mat-label>{{ label }}</mat-label>
      <input matInput [formControl]="presetName" autocomplete="none" />
      <mat-error class="field-error" *ngIf="presetName.hasError('required')">
        Filter name needs to contain at least one character
      </mat-error>
    </mat-form-field>
  </div>
  <div mat-dialog-actions class="action-btns">
    <button mat-button (click)="onNoClick()">Close</button>
    <button
      mat-stroked-button
      color="primary"
      class="primary-border"
      [disabled]="presetName.invalid"
      [mat-dialog-close]="presetName.value">
      Save a new preset
    </button>
  </div>
</div>
