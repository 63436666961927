<app-menu-button [iconSrc]="'./assets/icons/layers.svg'">
  <ng-template #menuTitle>
    <div class="title">
      <img src="./assets/icons/layers.svg" alt="layers" />
      <h4>Map Layers</h4>
    </div>
  </ng-template>
  <ng-template #menuContent>
    <div class="layer-content">
      <div class="layer-title">Events</div>
      <div
        class="layer-item"
        *ngFor="let eventLayerOption of eventLayersOptions">
        <mat-checkbox
          [checked]="initialEnabledLayers.includes(eventLayerOption.key)"
          color="primary"
          (change)="updateSettings($event, eventLayerOption.key)"
          >{{ eventLayerOption.label }}</mat-checkbox
        >
      </div>
    </div>
    <div class="layer-content">
      <div class="layer-title">Areas of Interest</div>
      <div class="layer-item" *ngFor="let areaLayerOption of areaLayersOptions">
        <mat-checkbox
          [checked]="initialEnabledLayers.includes(areaLayerOption.key)"
          color="primary"
          (change)="updateSettings($event, areaLayerOption.key)"
          >{{ areaLayerOption.label }}</mat-checkbox
        >
      </div>
    </div>
  </ng-template>
</app-menu-button>
