/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Model } from 'common';
import { environment } from 'src/environments/environment';
import {
  addUserIdentity,
  initMixpanel,
  sendMetricEvent,
} from 'src/environments/mix-panel/mix-panel';
import { AuthService } from './auth.service';
import { FeatureFlagService } from './feature-toggle.service';
import { IsBusyService } from './is-busy.service';

@Injectable({
  providedIn: 'root',
})
export class InitCoreService {
  constructor(
    private auth: AuthService,
    private isBusy: IsBusyService,
    private featureFlag: FeatureFlagService
  ) {}

  async init() {
    this.isBusy.init();
    const user = await this.auth.init();
    this.initUserTracking(user);
    await this.featureFlag.init(user);
  }

  private initUserTracking(user: Model.User | null): void {
    const MIXPANEL_CUSTOM_LIB_URL = `${environment.mixpanel.proxyUrl}/lib.min.js`;
    initMixpanel();

    if (user) {
      addUserIdentity(user);
    }

    sendMetricEvent('SessionStart');
    window.addEventListener('beforeunload', e => {
      sendMetricEvent('SessionEnd');
    });
  }
}
