<div class="container">
  <div class="data-container">
    <div class="graph-container">
      <ngx-charts-line-chart
        class="rt-samples"
        [results]="allGraphDataView"
        [xAxis]="true"
        [yAxis]="true"
        [legend]="true"
        [legendPosition]="legendPosition"
        [legendTitle]="''"
        [curve]="curveBumpX"
        [tooltipDisabled]="false"
        [xAxisTickFormatting]="formatXLabel"
        [yAxisTickFormatting]="yLabel"
        [scheme]="colorScheme">
        <ng-template #tooltipTemplate let-sample="model">
          <div class="tooltip-container">
            <div
              appGraphTooltipItem
              [class]="sampleClass"
              [colorClass]="tooltipViewMapping[sample.series].divClass"
              [name]="sample.series + ':'"
              [value]="yLabel(sample.value)"></div>
            <caption>
              Time:
              {{
                sample.name | dateToTimeComponent
              }}
            </caption>
          </div>
        </ng-template>
        <ng-template #seriesTooltipTemplate let-samples="model">
          <div class="tooltip-container">
            <div class="samples-container" *ngFor="let sample of samples">
              <div
                *ngIf="sample.series as s"
                appGraphTooltipItem
                [class]="sampleClass"
                [colorClass]="tooltipViewMapping[sample.series].divClass"
                [name]="sample.series + ':'"
                [value]="yLabel(sample.value)"></div>
            </div>
            <caption>
              Time:
              {{
                samples[0].name | dateToTimeComponent
              }}
            </caption>
          </div>
        </ng-template>
      </ngx-charts-line-chart>
    </div>
  </div>
</div>
