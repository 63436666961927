import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';
import { CoreState } from '../store/state/core.state';
import { AuthenticationSelectors } from '../store/types';

export const protectedApi = new InjectionToken<string[]>('Protected Urls');
export const orcaAdminApi = new InjectionToken<string[]>('Orca Admin Api');

@Injectable()
export class AppHttpInterceptorService implements HttpInterceptor {
  constructor(
    private store: Store<CoreState>,
    @Inject(protectedApi) private protectedApi: string[],
    @Inject(orcaAdminApi) private orcaAdmindApi: string[]
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      !this.isProtectedApi(req.url) &&
      !this.orcaAdmindApi.includes(req.url)
    ) {
      return this.getNextHandle(req, next);
    } else {
      return this.store
        .select(AuthenticationSelectors.selectAuthenticatedUser)
        .pipe(
          first(),
          switchMap(auth => {
            req = req.clone({
              headers: req.headers.set(
                'Authorization',
                `Bearer ${auth?.token}`
              ),
            });
            return this.getNextHandle(req, next);
          })
        );
    }
  }

  private getNextHandle(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  private isProtectedApi(givenUrl: string): boolean {
    const url = new URL(givenUrl);
    const domain = url.origin;

    return this.protectedApi.includes(domain);
  }
}
