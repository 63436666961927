import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import {
  concatMap,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  InefficientEventResponse,
  SailFuelExpenditureGraphData,
  ShipsEmissionResponse,
} from '../../models/efficiency.models';
import { EfficiencyService } from '../../services/efficiency.service';
import { CoreState } from '../state/core.state';
import {
  AuthenticationActions,
  AuthenticationSelectors,
  EffeciencySelectors,
  EfficiencyAction,
  FiltersActions,
  FiltersSelectors,
} from '../types';

@Injectable()
export class ShipsEmissionEffects {
  constructor(
    private actions$: Actions,
    private store: Store<CoreState>,
    private efficiencyService: EfficiencyService
  ) {}

  onChangeDateFilterGetShipEfficiency$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        FiltersActions.saveSelectedDatesFilter,
        FiltersActions.resetDateFilterToDefault
      ),
      withLatestFrom(
        this.store.select(EffeciencySelectors.selectIsEfficiencyPageActive)
      ),
      filter(([_, isActive]) => isActive),
      map(_ => EfficiencyAction.getShipsEfficiencyData())
    );
  });

  onUpdateUserOnAdminSetClaimsGetShipEfficiency$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthenticationActions.updateUserOnAdminSetClaims),
      withLatestFrom(
        this.store.select(EffeciencySelectors.selectIsEfficiencyPageActive)
      ),
      filter(([_, isActive]) => isActive),
      map(_ => EfficiencyAction.getShipsEfficiencyData())
    );
  });

  onGetShipEfficiency$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EfficiencyAction.getShipsEfficiencyData),
      switchMap(action => {
        return of(action).pipe(
          withLatestFrom(
            this.store
              .select(AuthenticationSelectors.selectAuthenticatedUser)
              .pipe(
                filter(authUser => authUser?.token != ''),
                concatMap(_ =>
                  this.store.select(FiltersSelectors.selectFilteredDates)
                )
              )
          )
        );
      }),
      switchMap(([_, dates]) =>
        from(this.efficiencyService.getShipsEmission$(dates)).pipe(
          map((data: ShipsEmissionResponse) =>
            EfficiencyAction.saveShipsEfficiencyData({
              kpisData: data.kpisData,
              shipsEfficiency: data.shipsEfficiency,
            })
          )
        )
      )
    );
  });

  onSaveShipsEfficiencyData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EfficiencyAction.saveShipsEfficiencyData),
      switchMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(FiltersSelectors.selectFilteredDates)
          )
        )
      ),
      switchMap(([_, dates]) =>
        from(this.efficiencyService.getIneficientEvent$(dates)).pipe(
          map((data: InefficientEventResponse) =>
            EfficiencyAction.saveInefficientEventData({
              inefficientEventPerShip: data.inefficientEventPerShip,
            })
          )
        )
      )
    );
  });

  onChangeSailFuelExpendituresTimeFilter$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EfficiencyAction.changeSailFuelExpendituresTimeFilter),
      map(_ => EfficiencyAction.getFuelCostData())
    );
  });

  onGetFuelCostData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EfficiencyAction.getFuelCostData),
      withLatestFrom(
        this.store.select(
          EffeciencySelectors.selectSailFuelExpendituresTimeFilter
        )
      ),
      switchMap(([_, timeFilter]) =>
        from(this.efficiencyService.getShipsCost$(timeFilter)).pipe(
          map((data: SailFuelExpenditureGraphData[]) =>
            EfficiencyAction.saveFuelCostData({
              sailFuelExpenditures: data,
            })
          )
        )
      )
    );
  });

  onUpdateUserOnAdminSetClaimsGetFuelCostData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthenticationActions.updateUserOnAdminSetClaims),
      withLatestFrom(
        this.store.select(EffeciencySelectors.selectIsEfficiencyPageActive)
      ),
      filter(([_, isActive]) => isActive),
      map(_ => EfficiencyAction.getFuelCostData())
    );
  });
}
