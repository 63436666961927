import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateToToday',
})
export class DateToTodayPipe implements PipeTransform {
  transform(date: Date | null | string): string | Date | null {
    if (date) {
      const d = new Date(date).getTime();
      const today = new Date().setUTCHours(0, 0, 0, 0);
      return d === today ? 'Today' : date;
    }
    return null;
  }
}
