<div class="container">
  <div class="text-container">
    <div class="logo-container">
      <img class="logo" src="./assets/icons/logo_auth.svg" />
      <h2>ORCA AI</h2>
    </div>
    <div class="account-created-container">
      <img src="./assets/icons/message.svg" />
      <h2>Check your mail</h2>
      <div class="sub-title">
        <span class="subtitle1">We have sent you a password recovery</span>
        <span class="subtitle1">email</span>
      </div>
    </div>
    <div class="help-container">
      <span class="caption">
        The email didn't arrive?
        <a routerLink="/reset-password">Try again</a></span
      >
    </div>
  </div>
</div>
