import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { concatMap, EMPTY, filter, firstValueFrom } from 'rxjs';
import { ScoringFactorsStats } from '../models/ship.model';
import { ShipsService } from '../services/ships.service';
import { CoreState } from '../store/state/core.state';
import { AuthenticationSelectors, ShipsActions } from '../store/types';

@Injectable({
  providedIn: 'root',
})
export class ShipScoringFactorsResolver {
  constructor(
    private store: Store<CoreState>,
    private shipService: ShipsService,
    private router: Router
  ) {}
  async resolve(
    route: ActivatedRouteSnapshot
  ): Promise<ScoringFactorsStats | never> {
    const shipId = parseInt(route.params['shipId']);

    if (!isNaN(shipId)) {
      const shipScoringFactors: ScoringFactorsStats = await firstValueFrom(
        this.store.select(AuthenticationSelectors.selectAuthenticatedUser).pipe(
          filter(authUser => authUser?.token != ''),
          concatMap(_ => this.shipService.getShipsScoreStatistics(shipId))
        )
      );

      this.store.dispatch(
        ShipsActions.updateShipsScoringFactorsStatistics({
          shipsScoreStats: shipScoringFactors,
        })
      );

      return shipScoringFactors;
    } else {
      this.router.navigateByUrl('/private/overview');
      return firstValueFrom(EMPTY);
    }
  }
}
