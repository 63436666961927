<div class="container">
  <div class="form-container">
    <div class="logo-container">
      <img class="logo" src="./assets/icons/logo_auth.svg" />
      <h2>ORCA AI</h2>
    </div>
    <form class="form" [formGroup]="resetPasswordForm" autocomplete="off">
      <h2>Reset Password</h2>
      <span> Please enter your email address to reset the password </span>
      <mat-form-field appearance="outline" class="primary-outline">
        <mat-label>Email</mat-label>
        <input matInput type="email" formControlName="email" />
      </mat-form-field>

      <div class="btn-container">
        <button
          data-test="reset-password-btn"
          class="stay-primary-on-disabled btn"
          mat-flat-button
          color="primary"
          (click)="resetPassword()"
          [disabled]="!resetPasswordForm.valid">
          Reset Password
        </button>
      </div>
    </form>
  </div>
</div>
