import { createSelector } from '@ngrx/store';
import _ from 'lodash';
import { MapEntity } from 'src/app/map/models/map.entity.model';
import { defaultPosition, Position } from 'src/app/map/models/position.model';
import {
  buildCustomSliderOptions,
  buildDropdownOptions,
} from 'src/app/shared/utils/filters/general';
import { CustomSliderOptions } from 'src/app/shared/view-models/slider.options.model';

import {
  AlertNames,
  alertNamesToEventTypes,
  colregClassificationMap,
  ColregClassificationNames,
  congestionLevelsMap,
  CongestionLevelsNames,
  TargetTypeNames,
  targetTypeNamesMap,
} from '../../models/events-filters.model';
import { Event } from '../../models/events.model';
import {
  appliedFiltersComparar,
  mapEventToEventsType,
} from '../../utils/events';
import { Events } from '../../view-models/event.view.model';
import {
  defaultAppliedEventFilters,
  LocationsViewModel,
  SingleLocation,
} from '../../view-models/filter.view.model';
import { CoreState } from '../state/core.state';
import { selectFilteredDates } from './filters.selectore';

const eventsState = (state: CoreState) => state.events;

export const selectEventsData = createSelector(
  eventsState,
  state => state.eventsData
);

export const selectEvents = createSelector(selectEventsData, state => {
  return state.events.map(
    (event: Event): Events => mapEventToEventsType(event)
  );
});

export const selectEventsCount = createSelector(
  selectEventsData,
  state => state.totalCount
);

export const selectEventsLocation = createSelector(
  selectEventsData,
  state => state.eventsLocation
);
export const selectOverviewEvents = createSelector(
  eventsState,
  state => state.overviewEvents
);

export const selectLastestOverviewFiveEvents = createSelector(
  selectOverviewEvents,
  overviewEvents =>
    overviewEvents.map((event: Event): Events => mapEventToEventsType(event))
);

export const selectShipEventsData = createSelector(
  eventsState,
  state => state.selectedShipEventsData
);

export const selectEventsForShip = createSelector(
  selectShipEventsData,
  shipEvents => {
    return shipEvents.events;
  }
);

export const selectShipEvents = createSelector(selectShipEventsData, state => {
  return state.events.map(
    (event: Event): Events => mapEventToEventsType(event)
  );
});

export const selectShipEventsCount = createSelector(
  selectShipEventsData,
  state => state.totalCount
);

export const selectShipEventsSortBy = createSelector(
  selectShipEventsData,
  state => state.sortby
);

export const selectEventsFilters = createSelector(
  eventsState,
  state => state.eventFilters
);

export const selectGeneralFilters = createSelector(
  selectEventsFilters,
  state => state.generalFilters
);
export const selectCloseEncounterFilter = createSelector(
  selectEventsFilters,
  state => state.closeEncounterFilter
);

export const selectHighPitchRollFilter = createSelector(
  selectEventsFilters,
  state => state.highPitchRoll
);

export const selectUkcFilter = createSelector(
  selectEventsFilters,
  state => state.ukcFilter
);

export const selectSpeedDropFilter = createSelector(
  selectEventsFilters,
  state => state.speedDrop
);

export const selectHighRotFilter = createSelector(
  selectEventsFilters,
  state => state.highRot
);

export const selectAppliedEventFilters = createSelector(
  eventsState,
  state => state.appliedEventFilters
);

export const selectAppliedShipNameFilter = createSelector(
  selectAppliedEventFilters,
  state => state.shipName
);

export const selectAppliedEventTypeFilter = createSelector(
  selectAppliedEventFilters,
  state => state.eventType
);

export const selectAppliedLocationsFilter = createSelector(
  selectAppliedEventFilters,
  state => state.locations
);

export const selectAppliedCongestionLevel = createSelector(
  selectAppliedEventFilters,
  state => state.congestionLevel
);

export const selectAppliedOwnSog = createSelector(
  selectAppliedEventFilters,
  state => state.ownSog
);

export const selectShowEventsInPort = createSelector(
  selectAppliedEventFilters,
  state => (state.inPort === null ? true : false)
);

export const selectAppliedShowOnlyRt = createSelector(
  selectAppliedEventFilters,
  state => (state.showOnlyRt ? true : false)
);

export const selectAppliedColregClassification = createSelector(
  selectAppliedEventFilters,
  state => state.colregClassifications
);

export const selectAppliedTargetType = createSelector(
  selectAppliedEventFilters,
  state => state.targetType
);
export const selectAppliedMinTargetDistance = createSelector(
  selectAppliedEventFilters,
  state => state.minDistance
);

export const selectAppliedTargetSog = createSelector(
  selectAppliedEventFilters,
  state => state.targetSog
);

export const selectAppliedMinDepth = createSelector(
  selectAppliedEventFilters,
  state => state.minDepth
);

export const selectAppliedPitch = createSelector(
  selectAppliedEventFilters,
  state => state.pitch
);

export const selectAppliedRoll = createSelector(
  selectAppliedEventFilters,
  state => state.roll
);

export const selectAppliedSogDiff = createSelector(
  selectAppliedEventFilters,
  state => state.sogDiff
);

export const selectAppliedRot = createSelector(
  selectAppliedEventFilters,
  state => state.rot
);

export const selectSliderTargetSog = createSelector(
  selectCloseEncounterFilter,
  selectAppliedTargetSog,
  (state, appliedTargetSog): CustomSliderOptions => {
    return buildCustomSliderOptions(
      1,
      state.targetSog.min,
      state.targetSog.max,
      appliedTargetSog.min,
      appliedTargetSog.max
    );
  }
);

export const selectSliderTargetDistance = createSelector(
  selectCloseEncounterFilter,
  selectAppliedMinTargetDistance,
  (state, appliedTargetDis): CustomSliderOptions =>
    buildCustomSliderOptions(
      0.2,
      state.minDistance.min,
      state.minDistance.max,
      appliedTargetDis.min,
      appliedTargetDis.max
    )
);

export const selectSliderOwnSog = createSelector(
  selectGeneralFilters,
  selectAppliedOwnSog,
  (state, appliedOwnSog): CustomSliderOptions =>
    buildCustomSliderOptions(
      1,
      state.ownSog.min,
      state.ownSog.max,
      appliedOwnSog.min,
      appliedOwnSog.max
    )
);

export const selectSliderPitch = createSelector(
  selectHighPitchRollFilter,
  selectAppliedPitch,
  (state, appliedPitch): CustomSliderOptions =>
    buildCustomSliderOptions(
      0.5,
      state.pitch.min,
      state.pitch.max,
      appliedPitch.min,
      appliedPitch.max
    )
);

export const selectSliderRoll = createSelector(
  selectHighPitchRollFilter,
  selectAppliedRoll,
  (state, appliedRoll): CustomSliderOptions =>
    buildCustomSliderOptions(
      0.5,
      state.roll.min,
      state.roll.max,
      appliedRoll.min,
      appliedRoll.max
    )
);

export const selectSliderSogDiff = createSelector(
  selectSpeedDropFilter,
  selectAppliedSogDiff,
  (state, appliedSogDiff): CustomSliderOptions =>
    buildCustomSliderOptions(
      1,
      state.sogDiff.min,
      state.sogDiff.max,
      appliedSogDiff.min,
      appliedSogDiff.max
    )
);

export const selectSliderDepth = createSelector(
  selectUkcFilter,
  selectAppliedMinDepth,
  (state, appliedMinDepth): CustomSliderOptions =>
    buildCustomSliderOptions(
      1,
      state.minDepth.min,
      state.minDepth.max,
      appliedMinDepth.min,
      appliedMinDepth.max
    )
);

export const selectSliderHighRot = createSelector(
  selectHighRotFilter,
  selectAppliedRot,
  (state, appliedRot): CustomSliderOptions =>
    buildCustomSliderOptions(
      0.5,
      state.rot.min,
      state.rot.max,
      appliedRot.min,
      appliedRot.max
    )
);

export const selectTargetTypeDropdown = createSelector(
  selectCloseEncounterFilter,
  state =>
    buildDropdownOptions(
      state.targetType,
      (targetTypeName: TargetTypeNames) => targetTypeNamesMap[targetTypeName]
    )
);

export const selectShipNamesDropdown = createSelector(
  selectGeneralFilters,
  state => {
    return buildDropdownOptions(state.shipNames, (shipName: string) =>
      shipName.toUpperCase()
    );
  }
);

export const selectCongestionLevelsDropdown = createSelector(
  selectGeneralFilters,
  state => {
    return buildDropdownOptions(
      state.congestionLevels,
      (congestionLevelName: CongestionLevelsNames) =>
        congestionLevelsMap[congestionLevelName]
    );
  }
);

export const selectEventTypeDropdown = createSelector(
  selectGeneralFilters,
  state => {
    return buildDropdownOptions(
      state.eventTypes,
      (alertName: AlertNames) => alertNamesToEventTypes[alertName]
    );
  }
);

export const selectLocations = createSelector(selectGeneralFilters, state => {
  return state.locations.map(
    (location): LocationsViewModel => ({
      parentLocation: {
        id: location.parentLocation,
        location: location.parentLocation,
      },
      subLocations: location.subLocations.map(
        (subLocation): SingleLocation => ({
          id: subLocation,
          location: subLocation,
        })
      ),
    })
  );
});

export const selectColregClassificationDropdown = createSelector(
  selectCloseEncounterFilter,
  state => {
    return buildDropdownOptions(
      state.colregClassifications,
      (colregClassificationName: ColregClassificationNames) =>
        colregClassificationMap[colregClassificationName]
    );
  }
);

export const selectSeverityLevels = createSelector(
  selectAppliedEventFilters,
  state => state.severityLevels
);

export const selectIsHighSeverityLevel = createSelector(
  selectAppliedEventFilters,
  state => state.severityLevels.includes('high')
);

export const selectIsMediumSeverityLevel = createSelector(
  selectAppliedEventFilters,
  state => state.severityLevels.includes('medium')
);

export const selectIsLowSeverityLevel = createSelector(
  selectAppliedEventFilters,
  state => state.severityLevels.includes('low')
);

export const selectIsAllEvents = createSelector(
  selectAppliedEventFilters,
  state => state.eventType.value === 'all'
);

export const selectIsCloseEncounterEvent = createSelector(
  selectGeneralFilters,
  selectAppliedEventFilters,
  selectIsAllEvents,
  (generalFiltersState, appliedGeneralFiltersState, isAllEvents) =>
    generalFiltersState.eventTypes.includes('Collision risk') &&
    (appliedGeneralFiltersState.eventType.value.includes('Collision risk') ||
      isAllEvents)
);

export const selectIsUkcEvent = createSelector(
  selectGeneralFilters,
  selectAppliedEventFilters,
  selectIsAllEvents,
  (generalFiltersState, appliedGeneralFiltersState, isAllEvents) =>
    generalFiltersState.eventTypes.includes('Grounding risk') &&
    (appliedGeneralFiltersState.eventType.value.includes('Grounding risk') ||
      isAllEvents)
);

export const selectIsSpeedDropEvent = createSelector(
  selectGeneralFilters,
  selectAppliedEventFilters,
  selectIsAllEvents,
  (generalFiltersState, appliedGeneralFiltersState, isAllEvents) =>
    generalFiltersState.eventTypes.includes('Dangerous Speed Drop') &&
    (appliedGeneralFiltersState.eventType.value.includes(
      'Dangerous Speed Drop'
    ) ||
      isAllEvents)
);

export const selectIsHighRotEvent = createSelector(
  selectGeneralFilters,
  selectAppliedEventFilters,
  selectIsAllEvents,
  (generalFiltersState, appliedGeneralFiltersState, isAllEvents) =>
    generalFiltersState.eventTypes.includes('Dangerous ROT') &&
    (appliedGeneralFiltersState.eventType.value.includes('Dangerous ROT') ||
      isAllEvents)
);

export const selectIsHighPitchRollEvent = createSelector(
  selectGeneralFilters,
  selectAppliedEventFilters,
  selectIsAllEvents,
  (generalFiltersState, appliedGeneralFiltersState, isAllEvents) =>
    generalFiltersState.eventTypes.includes('Dangerous motion') &&
    (appliedGeneralFiltersState.eventType.value.includes('Dangerous motion') ||
      isAllEvents)
);

export const selectSortBy = createSelector(
  selectEventsData,
  eventsData => eventsData.sortby
);

export const selectPageNumber = createSelector(
  selectEventsData,
  eventsData => eventsData.pageNumber
);

export const selectScrollToTop = createSelector(
  selectEventsData,
  eventsData => eventsData.scrollToTop
);

export const selectEventsDateFilter = createSelector(
  selectEventsData,
  eventsData => eventsData.eventsDateFilter
);

export const selectEventsDateFilterStartDate = createSelector(
  selectEventsDateFilter,
  eventDayFilter => eventDayFilter.dates.startDate
);

export const selectSelectedEvent = createSelector(
  eventsState,
  state => state.selectedEvent
);

export const selectMapOverviewEvents = createSelector(
  selectLastestOverviewFiveEvents,
  selectSelectedEvent,
  (overviewEvents, selectedEvent) =>
    overviewEvents.map((event): MapEntity => {
      const mapEvent: MapEntity = {
        id: String(event.eventId),
        image: _.snakeCase(`/assets/map/events/${event.severity}.png`),
        long: event.long,
        lat: event.lat,
        eyeOffset: [0.0, 0.0, 0.0],
      };

      if (selectedEvent?.eventId === event.eventId) {
        return {
          ...mapEvent,
          image: _.snakeCase(
            `/assets/map/events/${selectedEvent.type}/${event.severity}.png`
          ),
          eyeOffset: [0.0, 0.0, -10000.0],
        };
      }
      return mapEvent;
    })
);

export const selectSelectedEventPosition = createSelector(
  selectSelectedEvent,
  (event): Position => {
    if (event) {
      return {
        long: event.long,
        lat: event.lat,
      };
    }
    return defaultPosition;
  }
);

export const selectSelectedEventPositionOverview = createSelector(
  selectSelectedEvent,
  (event): Position => {
    if (event) {
      return {
        long: event.long,
        lat: event.lat,
      };
    }
    return defaultPosition;
  }
);

export const selectIsEventsFilterByDay = createSelector(
  selectEventsDateFilter,
  selectFilteredDates,
  (eventsDateFilter, generalDateFilter) => {
    return (
      eventsDateFilter.dates.startDate.getTime() !==
        generalDateFilter!.startDate.getTime() ||
      eventsDateFilter.dates.endDate.getTime() !==
        generalDateFilter?.endDate.getTime()
    );
  }
);

export const selectEventDaySafetyScore = createSelector(
  selectEventsDateFilter,
  eventDayFilter => eventDayFilter.safetyScore
);

export const selectMapEvents = createSelector(
  selectEventsLocation,
  selectSelectedEvent,
  (eventsLocation, selectedEvent) =>
    eventsLocation.map((event): MapEntity => {
      const mapEvent: MapEntity = {
        id: String(event.id),
        image: _.snakeCase(`/assets/map/events/${event.severityLevel}.png`),
        long: event.long,
        lat: event.lat,
        eyeOffset: [0.0, 0.0, 0.0],
      };
      if (selectedEvent?.eventId === event.id) {
        return {
          ...mapEvent,
          image: _.snakeCase(
            `/assets/map/events/${selectedEvent.type}/${event.severityLevel}.png`
          ),
          eyeOffset: [0.0, 0.0, -10000.0],
        };
      }
      return mapEvent;
    })
);

export const selectMapShipEvents = createSelector(
  selectEventsForShip,
  selectSelectedEvent,
  (eventsLocation, selectedEvent) => {
    return eventsLocation.map((event): MapEntity => {
      const mapEvent: MapEntity = {
        id: String(event.id),
        image: _.snakeCase(`/assets/map/events/${event.severityLevel}.png`),
        long: event.long,
        lat: event.lat,
        eyeOffset: [0.0, 0.0, 0.0],
      };
      if (selectedEvent?.eventId === event.id) {
        return {
          ...mapEvent,
          image: _.snakeCase(
            `/assets/map/events/${selectedEvent.type}/${event.severityLevel}.png`
          ),
          eyeOffset: [0.0, 0.0, -10000.0],
        };
      }
      return mapEvent;
    });
  }
);

export const selectPresetFilters = createSelector(
  eventsState,
  state => state.presetEventFilters
);

export const selectSelectedShipEventsDataFilter = createSelector(
  eventsState,
  state =>
    state.selectedPresetFilterInShip
      ? state.selectedPresetFilterInShip.filters
      : defaultAppliedEventFilters
);

export const selectShipPreset = createSelector(
  eventsState,
  state => state.selectedPresetFilterInShip
);

export const selectAppliedPresetInEvents = createSelector(
  eventsState,
  state => state.selectedPresetFilterInEvents
);

export const selectAppliedPresetInOverview = createSelector(
  eventsState,
  state => state.selectedPresetFilterInOverview
);

export const selectAppliedPresetInShip = createSelector(
  eventsState,
  state => state.selectedPresetFilterInShip
);

export const selectIsPresetFiltersEmpty = createSelector(
  selectPresetFilters,
  presets => presets.length === 0
);

export const selectIsPresetValuesExist = createSelector(
  selectPresetFilters,
  selectAppliedEventFilters,
  (presetsFilters, appliedFilters) =>
    presetsFilters.find(preset =>
      appliedFiltersComparar(preset.filters, appliedFilters)
    )
      ? true
      : false
);
