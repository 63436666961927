import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'orc-tree-content',
  templateUrl: './tree-content.component.html',
  styleUrls: ['./tree-content.component.scss']
})
export class TreeContentComponent implements OnInit {

  constructor() { }

  @ViewChild(TemplateRef)
  template: TemplateRef<any> | null = null;

  ngOnInit(): void {
  }

}
