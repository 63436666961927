import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { catchError, firstValueFrom, map, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  InsightDataDto,
  InsightReadDto,
  InsightVoteDto,
} from '../models/insights.models';
import { IsBusyService } from './is-busy.service';

@Injectable({
  providedIn: 'root',
})
export class InsightsService {
  constructor(private http: HttpClient, private busyService: IsBusyService) {}

  getInsights$(): Promise<InsightDataDto[]> {
    return this.busyService.add(() =>
      firstValueFrom(
        this.http.get<InsightDataDto[]>(`${environment.api.insights}`).pipe(
          map(response =>
            plainToInstance(InsightDataDto, response, {
              excludeExtraneousValues: true,
            })
          ),
          catchError(_ => of([]))
        )
      )
    );
  }

  markAsRead(
    insightId: string,
    readTime: Date
  ): Observable<InsightReadDto | null> {
    return this.http
      .patch<InsightReadDto>(
        `${environment.api.insights}/${insightId}/markAsRead`,
        { read_time: readTime }
      )
      .pipe(
        map(response =>
          plainToInstance(InsightReadDto, response, {
            excludeExtraneousValues: true,
          })
        ),
        catchError(_ => of(null))
      );
  }

  vote(
    insightId: string,
    likeVote: boolean
  ): Observable<InsightVoteDto | null> {
    return this.http
      .patch<InsightVoteDto>(`${environment.api.insights}/${insightId}/vote`, {
        like_vote: likeVote,
      })
      .pipe(
        map(response =>
          plainToInstance(InsightVoteDto, response, {
            excludeExtraneousValues: true,
          })
        ),
        catchError(_ => of(null))
      );
  }
}
