import mapboxgl from 'mapbox-gl';

export interface Position {
  readonly long: number;
  readonly lat: number;
  readonly height?: number;
}

export const defaultMapHeight = 40000000;

export const defaultPosition: Required<Position> = {
  long: -17.743711,
  lat: 34.903953,
  height: defaultMapHeight,
};

export const defaultMaxBounds: mapboxgl.LngLatBoundsLike = [
  [-180, -90],
  [180, 90],
];
