<div class="container">
  <div class="text-container">
    <div class="logo-container">
      <img class="logo" src="./assets/icons/logo_auth.svg" />
      <h2>ORCA AI</h2>
    </div>
    <div class="email-verified-container">
      <img src="./assets/icons/message_verified.svg" />
      <h2>Your email was verified</h2>
    </div>
  </div>
</div>
