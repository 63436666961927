import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, firstValueFrom } from 'rxjs';
import { CoreState } from '../store/state/core.state';
import { AuthenticationSelectors } from '../store/types';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  constructor(private store: Store<CoreState>, private router: Router) {}
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const adminUser = await firstValueFrom(
      this.store
        .select(AuthenticationSelectors.selectIsUserOrcaAdmin)
        .pipe(filter(user => user != null))
    );

    if (adminUser) {
      return true;
    }

    this.router.navigateByUrl('/private/overview');
    return false;
  }
}
