import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Model } from 'common';
import { firstValueFrom, map } from 'rxjs';
import { AuthenticationActions } from 'src/app/core/store/types';
import { FormErrorMatcher } from 'src/app/shared/utils/form-error-matchers/form-error-matcher';
import { isSamePasswordValidator } from 'src/app/shared/utils/form-validators/samePasswordValidator';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss'],
})
export class NewPasswordComponent {
  formErrorMatcher = new FormErrorMatcher();
  newPasswordForm = this.fb.group(
    {
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required]],
    },
    { validators: [isSamePasswordValidator()] }
  );
  constructor(
    private store: Store,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute
  ) {}

  async savePassword(): Promise<void> {
    const code: string = await firstValueFrom(
      this.activatedRoute.queryParams.pipe(map(params => params['oobCode']))
    );
    const newPasswordReq: Model.NewPasswordRequest = {
      code: code,
      password: this.newPasswordForm.value.password!,
    };
    this.store.dispatch(AuthenticationActions.newPassword({ newPasswordReq }));
  }
}
