export function customXTicks(datesArray: Date[]) {
  const xAxisTicks = [];
  const sortedDates = sortDatesArray(datesArray);

  if (sortedDates) {
    const range = datesArray.length;

    xAxisTicks.push(sortedDates[0]);

    if (range === 1) return xAxisTicks;

    if (range > 4) {
      const gapOfTicks = range / 3; //5

      xAxisTicks.push(sortedDates[Math.floor(gapOfTicks)]);
      xAxisTicks.push(sortedDates[Math.floor(gapOfTicks * 2)]);
    }

    xAxisTicks.push(sortedDates[sortedDates.length - 1]);
  }

  return xAxisTicks;
}

export function sortDatesArray(dates: Date[]): Date[] | null {
  if (dates.length > 0) {
    return [...dates].sort((dateA, dateB) =>
      dateA.getTime() > dateB.getTime() ? 1 : -1
    );
  }
  return null;
}
