import { CommonModule } from '@angular/common';
import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'app-menu-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './menu-button.component.html',
  styleUrls: ['./menu-button.component.scss'],
})
export class MenuButtonComponent {
  @Input() iconSrc!: string;
  @Input() isEnabledFeature: boolean | null = true;
  @Input() menuOpen: boolean | null = false;

  @Output() openMenu = new EventEmitter<void>();

  @ContentChild('menuTitle')
  menuTitle!: TemplateRef<any>;
  @ContentChild('menuContent')
  menuContent!: TemplateRef<any>;

  onToggleMenu() {
    if (this.isEnabledFeature) {
      this.menuOpen = !this.menuOpen;
      if (this.menuOpen) this.openMenu.emit();
    }
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    if (
      !this.elementRef.nativeElement.contains(event.target) &&
      this.menuOpen
    ) {
      this.menuOpen = false;
    }
  }

  constructor(private elementRef: ElementRef) {}
}
