import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as shape from 'd3-shape';
import { combineLatest, map, Observable } from 'rxjs';
import {
  ScoringFactorsStats,
  ShipSafetyScore,
} from 'src/app/core/models/ship.model';
import { CoreState } from 'src/app/core/store/state/core.state';
import { FiltersSelectors, ShipsSelectors } from 'src/app/core/store/types';
import { tranformDateToDayMonth } from 'src/app/shared/utils/date-transforms/dateToDayMonth';
import { customXTicks } from 'src/app/shared/utils/graphs/general';
import { DateFilterKeys } from 'src/app/shared/view-models/dates.view.model';

@Component({
  selector: 'app-ship-average-safety-score-graph',
  templateUrl: './ship-average-safety-score-graph.component.html',
  styleUrls: ['./ship-average-safety-score-graph.component.scss'],
})
export class ShipAverageSafetyScoreGraphComponent implements OnInit {
  shipSafetyScores$!: Observable<ShipSafetyScore[]>;
  shipSafetyScoreByDateView$: any;

  yScaleMax = 10;
  yScaleMin = 0;
  linearCurve = shape.curveLinear;
  xAxisTicks$!: Observable<Date[]>;
  shipAverageSafetyScore$!: Observable<number>;
  fleetAverageSafetyScore$!: Observable<number>;
  selectedDateRange$!: Observable<DateFilterKeys>;
  dataExists$!: Observable<boolean>;
  showEventsDayFilterTitle$!: Observable<boolean>;
  endDateAvgShipSafetyScore$!: Observable<number>;
  startDateAvgShipSafetyScore$!: Observable<number>;
  showModal: boolean = false;
  scoreExplainations$!: Observable<ScoringFactorsStats | null>;

  constructor(public store: Store<CoreState>) {}
  toggleShowModal() {
    this.showModal = !this.showModal;
  }
  ngOnInit(): void {
    this.shipSafetyScores$ = this.store.select(
      ShipsSelectors.selectSelectedShipSafetyScoresWithoutNA
    );

    this.shipSafetyScoreByDateView$ = this.shipSafetyScores$.pipe(
      map(safetyScoreByDate =>
        safetyScoreByDate.map(safetyScore => {
          const customSafetyScore = {
            ...safetyScore,
            name: safetyScore.date,
            value: safetyScore.safetyScore,
          };
          return customSafetyScore;
        })
      ),
      map(safetyScoreView => {
        return [
          {
            name: 'Safety Score By Date',
            series: safetyScoreView,
          },
        ];
      })
    );

    this.xAxisTicks$ = this.shipSafetyScores$.pipe(
      map(shipSafetyScoresByDate =>
        shipSafetyScoresByDate.map(safetyScore => safetyScore.date)
      ),
      map(dates => customXTicks(dates))
    );

    this.shipAverageSafetyScore$ = this.store.select(
      ShipsSelectors.selectSelectedShipAverageSafetyScoreWithoutNA
    );

    this.dataExists$ = combineLatest([
      this.shipSafetyScores$,
      this.shipAverageSafetyScore$,
    ]).pipe(
      map(
        ([safetyScores, averageSafetyScore]) =>
          safetyScores.length > 0 && averageSafetyScore > 0
      )
    );

    this.selectedDateRange$ = this.store.select(
      FiltersSelectors.selectFilterdDatesKey
    );
    this.endDateAvgShipSafetyScore$ = this.store.select(
      ShipsSelectors.selectEndDateAvgShipSafetyScore
    );

    this.startDateAvgShipSafetyScore$ = this.store.select(
      ShipsSelectors.selectStartDateAvgShipSafetyScore
    );

    this.fleetAverageSafetyScore$ = this.store.select(
      ShipsSelectors.selectAverageFleetSafteyScore
    );

    this.scoreExplainations$ = this.store.select(
      ShipsSelectors.selectShipsScoreExplaination
    );
  }

  formatXLabel(date: Date): string {
    return tranformDateToDayMonth(date);
  }
}
