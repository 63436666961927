<mat-form-field appearance="fill" class="custom-form-field-selection selection">
  <mat-label *ngIf="showLabelCondition">
    {{ label }}
  </mat-label>
  <mat-select
    (selectionChange)="setPresetFitler($event)"
    [disabled]="(isPresetsDiabled$ | async)!"
    [compareWith]="compareFilterValues"
    [value]="selectedPreset">
    <mat-option>{{ defaultOption }}</mat-option>
    <mat-option
      panelClass="custom-select"
      *ngFor="let preset of presetFilters$ | async"
      [value]="preset"
      >{{ preset.presetName }}</mat-option
    >
  </mat-select>
</mat-form-field>
