<app-no-data [dataExists]="dataExists$ | async">
  <div class="container">
    <div class="header">
      <div class="title">
        <div class="body1-bold">Pitch and Roll</div>
      </div>
      <div class="subtitle2 sub-title">
        Received: {{ latestReceivedDate | dateToTimeAgo }}
      </div>
    </div>
    <div class="graph-container">
      <ngx-charts-line-chart
        class="rt-samples"
        [results]="pitchRollGraphDataView$ | async"
        [xAxis]="true"
        [yAxis]="true"
        [legend]="true"
        [yScaleMin]="yScaleMin"
        [yScaleMax]="yScaleMax"
        [legendPosition]="legendPosition"
        [legendTitle]="''"
        [curve]="curveBumpX"
        [tooltipDisabled]="false"
        [xAxisTickFormatting]="formatXLabel"
        [yAxisTickFormatting]="formatPitchRollYLabel"
        [scheme]="pitchRollColorScheme"
        [xAxisTicks]="(xAxisTicks$ | async)!">
        <ng-template #tooltipTemplate let-sample="model">
          <div class="tooltip-container">
            <div
              appGraphTooltipItem
              [class]="sampleClass"
              [colorClass]="tooltipViewMapping[sample.series].divClass"
              [name]="sample.series + ':'"
              [value]="sample.value"></div>
            <div>
              Time:
              {{ sample.name | dateToTimeComponent }}
            </div>
          </div>
        </ng-template>
        <ng-template #seriesTooltipTemplate let-samples="model">
          <div class="tooltip-container">
            <div class="samples-container" *ngFor="let sample of samples">
              <div
                *ngIf="sample.series as s"
                appGraphTooltipItem
                [class]="sampleClass"
                [colorClass]="tooltipViewMapping[sample.series].divClass"
                [name]="sample.series + ':'"
                [value]="sample.value"></div>
            </div>
            <div>
              Time:
              {{ samples[0].name | dateToTimeComponent }}
            </div>
          </div>
        </ng-template>
      </ngx-charts-line-chart>
    </div>
  </div>
</app-no-data>
