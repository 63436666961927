import { Injectable, OnDestroy } from '@angular/core';
import { Model } from 'common';
import { initialize, LDClient, LDContext } from 'launchdarkly-js-client-sdk';
import { filter, from, map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FeatureKey } from '../models/feature-flag.model';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService implements OnDestroy {
  client!: LDClient;
  private featureFlags$ = new Map<FeatureKey, boolean>();

  async ngOnDestroy() {
    await this.client.close();
  }

  async init(user: Model.User | null): Promise<void> {
    let context: LDContext;
    if (user) {
      context = {
        kind: 'user',
        key: user.companyName,
      };
    } else {
      context = {
        anonymous: true,
      };
    }
    this.client = initialize(environment.launchdarkly, context);

    await this.client.waitForInitialization();

    Object.entries(this.client.allFlags()).forEach(entry => {
      this.featureFlags$.set(entry[0] as FeatureKey, entry[1]);

      this.client.on(`change:${entry[0]}`, flagValue => {
        this.featureFlags$.set(entry[0] as FeatureKey, flagValue);
      });
    });
  }

  getFeatureFlag$(flagKey: FeatureKey): Observable<boolean> {
    return from(this.client.waitForInitialization()).pipe(
      filter(() => this.featureFlags$.has(flagKey)),
      map(() => this.featureFlags$.get(flagKey) as boolean)
    );
  }
}
