<div class="container" *ngIf="isUserOrcaAdmin$ | async">
  <mat-form-field
    appearance="fill"
    class="custom-form-field-selection selection">
    <mat-select
      [formControl]="fleetIdControl"
      panelClass="custom-select"
      (selectionChange)="fleetIdSelected($event.value)">
      <mat-option *ngFor="let fleet of fleets$ | async" [value]="fleet.fleetId">
        {{ fleet.companyName }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
