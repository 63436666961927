import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { OrcaLibModule } from 'projects/orca-lib-main/projects/orca-lib/src/public-api';
import { environment } from 'src/environments/environment';
import { StoreDevModule } from 'src/environments/redux/devtools';
import { AppRoutingModule } from '../app-routing.module';
import { ShipProfileTooltipComponent } from '../map/components/tooltip/ship-profile-tooltip/ship-profile-tooltip.component';
import { MapModule } from '../map/map.module';
import { MaterialModule } from '../material/material.module';
import { CarouselComponent } from '../shared/components/carousel/carousel.component';
import { CommentSectionComponent } from '../shared/components/comment-section/comment-section.component';
import { WidgetTitleComponent } from '../shared/components/widget-title/widget-title.component';
import { SharedModule } from '../shared/shared.module';
import { DeleteCommentDialogComponent } from './components/dialogs/delete-comment-dialog/delete-comment-dialog.component';
import { GalleryImageDialogComponent } from './components/dialogs/gallery-image-dialog/gallery-image-dialog.component';
import { LiveStreamComponent } from './components/dialogs/live-stream-dialog/live-stream/live-stream.component';
import { ModuleWillComeSoonDialogComponent } from './components/dialogs/module-will-come-soon-dialog/module-will-come-soon-dialog.component';
import { SavePresetDialogComponent } from './components/dialogs/save-preset-dialog/save-preset-dialog.component';
import { ScreenshotDialogComponent } from './components/dialogs/screenshot-dialog/screenshot-dialog.component';
import { EventDataComponent } from './components/event-exploration/event-info/event-data/event-data.component';
import { EventInfoComponent } from './components/event-exploration/event-info/event-info.component';
import { InfoColumnComponent } from './components/event-exploration/info-column/info-column.component';
import { InfoRowComponent } from './components/event-exploration/info-row/info-row.component';
import { EventsListComponent } from './components/events-list/events-list.component';
import { CloseEncounterFilterComponent } from './components/filters/close-encounter-filter/close-encounter-filter.component';
import { HighPitchRollFilterComponent } from './components/filters/high-pitch-roll-filter/high-pitch-roll-filter.component';
import { HighRotFilterComponent } from './components/filters/high-rot-filter/high-rot-filter.component';
import { LocationsComponent } from './components/filters/locations/locations.component';
import { PresetsComponent } from './components/filters/presets/presets.component';
import { SeverityFilterComponent } from './components/filters/severity-filter/severity-filter.component';
import { SpeedDropFilterComponent } from './components/filters/speed-drop-filter/speed-drop-filter.component';
import { UkcFilterComponent } from './components/filters/ukc-filter/ukc-filter.component';
import { FleetDropdownComponent } from './components/fleet-dropdown/fleet-dropdown.component';
import { FleetSafetyGraphComponent } from './components/fleet-safety-graph/fleet-safety-graph.component';
import { PieChartComponent } from './components/graphs/pie-chart/pie-chart.component';
import { ShipEventsByAlertNameComponent } from './components/graphs/ship-events-by-alert-name/ship-events-by-alert-name.component';
import { ShipEventsByCrewShiftsComponent } from './components/graphs/ship-events-by-crew-shifts/ship-events-by-crew-shifts.component';
import { ShipEventsBySeverityComponent } from './components/graphs/ship-events-by-severity/ship-events-by-severity.component';
import { ShipSailDataGraphComponent } from './components/graphs/ship-sail-data/ship-sail-data-graph.component';
import { RedRTEventIndicationComponent } from './components/red-rt-event-indication/red-rt-event-indication.component';
import { ShipEventHeaderComponent } from './components/ship-event-header/ship-event-header.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { AdminComponent } from './pages/admin/admin/admin.component';
import { AccountCreatedComponent } from './pages/auth/account-created/account-created.component';
import { EmailVerifiedComponent } from './pages/auth/email-verified/email-verified.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { NewPasswordComponent } from './pages/auth/new-password/new-password.component';
import { PasswordRecoveryComponent } from './pages/auth/password-recovery/password-recovery.component';
import { ResetPassComponent } from './pages/auth/reset-pass/reset-pass.component';
import { SignUpComponent } from './pages/auth/sign-up/sign-up.component';
import { UserManagementComponent } from './pages/auth/user-management/user-management.component';
import { EventsGraphComponent } from './pages/events/events-graph/events-graph.component';
import { EventsComponent } from './pages/events/events.component';
import { FilteredEventsComponent } from './pages/events/filtered-events/filtered-events.component';
import { FiltersComponent } from './pages/events/filters/filters.component';
import { ExploreEventComponent } from './pages/explore-event/explore-event.component';
import { FleetComponent } from './pages/fleet/fleet.component';
import { ShipAverageSafetyScoreGraphComponent } from './pages/fleet/ship/ship-average-safety-score-graph/ship-average-safety-score-graph.component';
import { ShipDetailsComponent } from './pages/fleet/ship/ship-details/ship-details.component';
import { ShipImuSamplesGraphComponent } from './pages/fleet/ship/ship-imu-samples-graph/ship-imu-samples-graph.component';
import { ShipComponent } from './pages/fleet/ship/ship.component';
import { LiveComponent } from './pages/live/live.component';
import { FleetSafetyScoreComponent } from './pages/overview/fleet-safety-score/fleet-safety-score.component';
import { InsightsWidgetComponent } from './pages/overview/insights-widget/insights-widget.component';
import { OverviewMapComponent } from './pages/overview/overview-map/overview-map.component';
import { OverviewComponent } from './pages/overview/overview.component';
import { RisksOverviewChartComponent } from './pages/overview/risks-overview-chart/risks-overview-chart.component';
import { HighSafetyScoreComponent } from './pages/overview/safety-score-ships/safety-score-ships.component';
import { ShipsRisksComponent } from './pages/overview/ships-by-risk/ships-risks/ships-risks.component';
import { PrivateComponent } from './pages/private/private.component';
import { ImuSamplesGraphComponent } from './pages/rt-events/imu-samples-graph/imu-samples-graph.component';
import { RtEventExplorationComponent } from './pages/rt-events/rt-event-exploration/rt-event-exploration.component';
import { AppendCameraPipe } from './pipes/append-camera.pipe';
import { ColregClassToDisplayNamePipe } from './pipes/colreg-class-to-display-name.pipe';
import { IsViolatedLimitationPipe } from './pipes/is-violated-limitation.pipe';
import { VideoSortPipe } from './pipes/video-sort.pipe';
import {
  AppHttpInterceptorService,
  orcaAdminApi,
  protectedApi,
} from './services/app-http-interceptor.service';
import { InitCoreService } from './services/init.core.service';
import { AuthEffects } from './store/effects/auth.effects';
import { CommentsEffects } from './store/effects/comments.effects';
import { ShipsEmissionEffects } from './store/effects/efficiency.effects';
import { EventsEffects } from './store/effects/events.effects';
import { FiltersEffects } from './store/effects/filters.effects';
import { MixpanelEffects } from './store/effects/mixpanel.effects';
import { OverviewEffects } from './store/effects/overview.effects';
import { ShipsEffects } from './store/effects/ships.effects';
import {
  authenticationReducer,
  authenticationStateKey,
} from './store/reducers/authentication.reducer';
import {
  commentsReducer,
  commentsStateKey,
} from './store/reducers/comments.reducer';
import {
  efficiencyReducer,
  efficiencyStateKey,
} from './store/reducers/efficiency.reducer';
import { eventsReducer, eventsStateKey } from './store/reducers/events.reducer';
import {
  filtersReducer,
  filtersStateKey,
} from './store/reducers/filters.reducer';
import {
  overviewReducer,
  overviewStateKey,
} from './store/reducers/overview.reducer';
import { shipsReducer, shipsStateKey } from './store/reducers/ships.reducer';

@NgModule({
  declarations: [
    LoginComponent,
    SignUpComponent,
    ResetPassComponent,
    AccountCreatedComponent,
    NewPasswordComponent,
    UserManagementComponent,
    PasswordRecoveryComponent,
    EmailVerifiedComponent,
    RisksOverviewChartComponent,
    FleetSafetyScoreComponent,
    OverviewComponent,
    EventsComponent,
    SideNavComponent,
    PrivateComponent,
    TopbarComponent,
    ShipsRisksComponent,
    HighSafetyScoreComponent,
    OverviewMapComponent,
    EventsListComponent,
    ExploreEventComponent,
    FilteredEventsComponent,
    FiltersComponent,
    CloseEncounterFilterComponent,
    UkcFilterComponent,
    HighPitchRollFilterComponent,
    SpeedDropFilterComponent,
    HighRotFilterComponent,
    SeverityFilterComponent,
    FleetDropdownComponent,
    EventsGraphComponent,
    AdminComponent,
    VideoSortPipe,
    RtEventExplorationComponent,
    ImuSamplesGraphComponent,
    LocationsComponent,
    SavePresetDialogComponent,
    GalleryImageDialogComponent,
    ModuleWillComeSoonDialogComponent,
    PresetsComponent,
    FleetComponent,
    LiveStreamComponent,
    ShipComponent,
    ColregClassToDisplayNamePipe,
    ShipSailDataGraphComponent,
    FleetSafetyGraphComponent,
    ShipEventsBySeverityComponent,
    ShipEventsByAlertNameComponent,
    PieChartComponent,
    ShipEventsByCrewShiftsComponent,
    ShipImuSamplesGraphComponent,
    ShipAverageSafetyScoreGraphComponent,
    LiveComponent,
    ShipDetailsComponent,
    AppendCameraPipe,
  ],
  exports: [AppRoutingModule],
  providers: [
    { provide: protectedApi, useValue: environment.protectedApi },
    { provide: orcaAdminApi, useValue: environment.orcaAdminApi },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpInterceptorService,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (service: InitCoreService) => () => service.init(),
      deps: [InitCoreService],
      multi: true,
    },
  ],
  imports: [
    CommonModule,
    ShipProfileTooltipComponent,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    DeleteCommentDialogComponent,
    ScreenshotDialogComponent,
    MaterialModule,
    LazyLoadImageModule,
    SharedModule,
    MapModule,
    InsightsWidgetComponent,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideFunctions(() => getFunctions(getApp(), 'europe-west3')),
    StoreModule.forRoot({
      [authenticationStateKey]: authenticationReducer,
      [overviewStateKey]: overviewReducer,
      [shipsStateKey]: shipsReducer,
      [eventsStateKey]: eventsReducer,
      [filtersStateKey]: filtersReducer,
      [efficiencyStateKey]: efficiencyReducer,
      [commentsStateKey]: commentsReducer,
    }),
    EffectsModule.forRoot([
      AuthEffects,
      ShipsEffects,
      EventsEffects,
      MixpanelEffects,
      FiltersEffects,
      ShipsEmissionEffects,
      OverviewEffects,
      CommentsEffects,
    ]),
    StoreDevModule,
    WidgetTitleComponent,
    IsViolatedLimitationPipe,
    OrcaLibModule,
    NgxMatSelectSearchModule,
    RedRTEventIndicationComponent,
    CommentSectionComponent,
    CarouselComponent,
    ShipEventHeaderComponent,
    InfoRowComponent,
    InfoColumnComponent,
    EventInfoComponent,
    EventDataComponent,
  ],
})
export class CoreModule {}
