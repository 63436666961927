import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { concatMap, filter, firstValueFrom } from 'rxjs';
import { ShipEventCount } from '../models/ship.model';
import { ShipsService } from '../services/ships.service';
import { CoreState } from '../store/state/core.state';
import {
  AuthenticationSelectors,
  FiltersSelectors,
  ShipsActions,
} from '../store/types';

@Injectable({
  providedIn: 'root',
})
export class ShipEventCountResolver
  implements Resolve<Promise<ShipEventCount>>
{
  constructor(
    private store: Store<CoreState>,
    private shipService: ShipsService,
    private router: Router
  ) {}
  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<ShipEventCount> {
    const shipId = parseInt(route.params['shipId']);

    if (!isNaN(shipId)) {
      const dates = await firstValueFrom(
        this.store.select(AuthenticationSelectors.selectAuthenticatedUser).pipe(
          filter(authUser => authUser?.token != ''),
          concatMap(_ =>
            this.store.select(FiltersSelectors.selectFilteredDates)
          )
        )
      );

      const shipEventCounts: ShipEventCount =
        await this.shipService.getShipEventsGroupCount$(shipId, dates);

      this.store.dispatch(
        ShipsActions.updateSelectedShipEventCountData({
          shipEventCountData: shipEventCounts,
        })
      );

      return shipEventCounts;
    } else {
      this.router.navigateByUrl('/private/overview');
      return {
        totalCount: 0,
        byAlertName: [],
        bySeverity: [],
        byCrewShift: [],
      };
    }
  }
}
