import { createAction, props } from '@ngrx/store';
import { Model } from 'common';
import { Fleet } from '../../models/fleet.model';
import { SignupForm } from '../../view-models/sign-up.view-model';
export const login = createAction(
  '[Login Page] User clicked on login',
  props<{ loginReq: Model.LoginRequest }>()
);

export const signup = createAction(
  '[Signup Page] User clicked on signup',
  props<{ signupForm: SignupForm }>()
);
export const requestResetPassword = createAction(
  '[Reset Password Page] Reset password clicked',
  props<{ resetPasswordReq: Model.ResetPasswordRequest }>()
);
export const passwordResetEmailChecked = createAction(
  '[Reset Password Page] Password Reset Email Checked',
  props<{ passwordReset: Model.PasswordReset }>()
);

export const savePreliminaryUser = createAction(
  '[Auth Effects] On login response',
  props<{ user: Model.PreliminaryUser | null }>()
);

export const initAuthUser = createAction(
  '[Auth Effects] Init auth user state',
  props<{ authUser: Model.User | null }>()
);

export const saveAuthUser = createAction(
  '[Auth Effects] Save auth user on change',
  props<{ authUser: Model.User | null }>()
);

export const clearPreliminaryUser = createAction(
  '[Auth Effects] Clear preliminary user on sign up'
);

export const newPassword = createAction(
  '[New Password Page] Set new password',
  props<{ newPasswordReq: Model.NewPasswordRequest }>()
);

export const changeFleetId = createAction(
  '[Top Bar Admin] Changed fleet id',
  props<{ fleetId: number | null }>()
);

export const updateUserOnAdminSetClaims = createAction(
  '[Auth Effects] Update token after admin sets claims',
  props<{ user: Model.User }>()
);

export const updateTokenOnRegularUserSetClaims = createAction(
  '[Auth Effects] Update token after regular user logs in',
  props<{ token: string }>()
);

export const saveFleets = createAction(
  '[Auth Effects] Save fleets',
  props<{ fleets: Fleet[] }>()
);

export const logoutOnUserClick = createAction(
  '[Side Nav] Logout clicked by user'
);

export const logoutFromAllTab = createAction('[Side Nav] Logout from all tab');

export const logout = createAction('[Side Nav] Logout');

export const saveRefreshedToken = createAction(
  '[Auth Effects] Update refreshed token',
  props<{ token: string }>()
);
