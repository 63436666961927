import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export class FormErrorMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const formInvalid = !!(
      form &&
      control &&
      control.dirty &&
      form.hasError('passwordsNotSame')
    );
    return formInvalid;
  }
}
