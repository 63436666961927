<div class="container">
  <form class="form" [formGroup]="userCreationForm" autocomplete="off">
    <h2>User Creation</h2>
    <div class="inputs-container">
      <mat-form-field
        appearance="fill"
        class="custom-form-field-selection selection">
        <mat-label>Fleet Name</mat-label>
        <mat-select formControlName="fleetId" panelClass="custom-select">
          <mat-option
            *ngFor="let fleet of fleets$ | async"
            [value]="fleet.fleetId">
            {{ fleet.companyName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        appearance="fill"
        class="custom-form-field-selection selection">
        <mat-label>Role</mat-label>
        <mat-select formControlName="role" panelClass="custom-select">
          <mat-option
            *ngFor="let role of rolesDropdownOptions"
            [value]="role.value">
            {{ role.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" class="gray-outline">
        <mat-label>Number of users to create</mat-label>
        <input matInput formControlName="numOfUsers" type="number" />
      </mat-form-field>

      <mat-form-field
        appearance="fill"
        class="custom-form-field-selection selection">
        <mat-label>Ships</mat-label>
        <mat-select
          formControlName="shipId"
          panelClass="custom-select"
          [disabled]="(isShipsDisabled$ | async)!">
          <mat-option *ngFor="let ship of ships$ | async" [value]="ship.shipId">
            {{ ship.shipName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        appearance="fill"
        class="custom-form-field-selection selection">
        <mat-label>Sub Fleet Ships</mat-label>
        <mat-select
          formControlName="subFleetShipsId"
          panelClass="custom-select"
          multiple="true"
          [disabled]="!(isSubFleetManagerRoleSelected$ | async)!">
          <ngx-mat-select-search
            placeholderLabel="Type ship's name"
            noEntriesFoundLabel="'no matching ships found'"
            [formControl]="searchBoxSubShipsControl"></ngx-mat-select-search>

          <mat-option *ngFor="let ship of ships$ | async" [value]="ship.shipId">
            {{ ship.shipName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="btn-container">
      <button
        class="login-btn dark-color primary-text"
        mat-flat-button
        color="primary"
        (click)="createUsers()"
        [disabled]="!userCreationForm.valid">
        Create Users
      </button>
    </div>
  </form>
  <div class="user-list">
    <h2>Preliminary Users List</h2>
    <div class="scrollable">
      <div class="grouped-users" *ngFor="let users of groupedUsers | keyvalue">
        <h4>
          {{ users.key }}
        </h4>

        <div class="user" *ngFor="let user of users.value">
          <div class="user-data">
            <span class="body1">
              Username:<br /><br />
              <ng-container
                *ngIf="editingUserId === user.id; else showUsername">
                <mat-form-field appearance="outline">
                  <input matInput [formControl]="editUsernameForm" />
                </mat-form-field>
              </ng-container>
              <ng-template #showUsername>{{ user.username }}</ng-template>
            </span>
            <span class="body1"
              >Password:<br /><br />
              {{ user.password }}</span
            >
            <span class="body1"
              >Company:<br /><br />{{ user.companyName }}</span
            >
            <span class="body1">Role: <br /><br />{{ user.role }}</span>
            <span class="body1" *ngIf="user.shipId"
              >Ship Name: <br /><br />{{ getShipName(user.shipId) }}</span
            >
            <div class="ship-names-container" *ngIf="user.subFleetShipsId">
              <span class="body1">Ship Names:<br /><br /></span>
              <div class="names">
                <ng-container *ngFor="let shipId of user.subFleetShipsId">
                  <span class="body1">{{ getShipName(shipId) }}</span>
                </ng-container>
              </div>
            </div>
          </div>

          <div class="user-action">
            <ng-container *ngIf="editingUserId === user.id; else showEdit">
              <span class="body1 action" (click)="saveUsername()">Save</span>
              <span class="body1 action" (click)="cancelEdit()">Cancel</span>
            </ng-container>
            <ng-template #showEdit>
              <span class="body1 action" (click)="startEditing(user)"
                >Edit</span
              >
            </ng-template>
          </div>
        </div>
        <div class="divider"></div>
      </div>
    </div>
  </div>
</div>
