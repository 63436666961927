import { createSelector } from '@ngrx/store';
import { MapEntity, MapTooltip } from 'src/app/map/models/map.entity.model';
import { defaultPosition, Position } from 'src/app/map/models/position.model';
import {
  calculateAverage,
  isWithinLastHours,
} from 'src/app/shared/utils/calculators/general';
import { getTimeDiff } from 'src/app/shared/utils/date-transforms/date-diff';
import { getZeroedDate } from 'src/app/shared/utils/date-transforms/only-date';
import {
  buildDayStreams,
  buildThermalStreams,
  buildViewsStreams,
} from '../../utils/videos';
import { SafetyScoreByDate } from '../../view-models/safety.score.view.model';
import {
  defaultShipsBySeverity,
  ShipsBySeverity,
} from '../../view-models/ship.view-model';
import { CoreState } from '../state/core.state';
import { ShipsState } from '../state/ships.state';
import { AuthenticationSelectors } from '../types';
import { selectMapShipEvents, selectSelectedEvent } from './events.selectors';

const selectShipsState = (state: CoreState): ShipsState => state.ships;

export const selectShipsSeverity = createSelector(
  selectShipsState,
  state => state.shipsSeverityScoreByDate
);

export const selectShipsSeverityWithoutNA = createSelector(
  selectShipsState,
  state => ({
    ...state.shipsSeverityScoreByDate,
    shipsSeverityByDate:
      state.shipsSeverityScoreByDate.shipsSeverityByDate.filter(
        shipSeverity => shipSeverity.avgScore
      ),
  })
);

export const selectShipsSeverityDataByDate = createSelector(
  selectShipsSeverity,
  shipsSeverityData => shipsSeverityData.shipsSeverityByDate
);

export const selectEndDate = createSelector(
  selectShipsSeverity,
  shipsSeverityData => shipsSeverityData.endDate
);

export const selectStartDate = createSelector(
  selectShipsSeverity,
  shipsSeverityData => shipsSeverityData.startDate
);

export const selectRangedAvgSeverityScore = createSelector(
  selectShipsSeverity,
  shipsSeverity => {
    if (shipsSeverity.shipsSeverityByDate.length > 0) {
      return shipsSeverity.shipsSeverityByDate.map(e => e.avgScore);
    }
    return [];
  }
);

export const selectEndDateAvgSeverityScore = createSelector(
  selectRangedAvgSeverityScore,
  avgSeverityScores => {
    if (avgSeverityScores.length === 0) return 0;
    return avgSeverityScores[avgSeverityScores.length - 1];
  }
);
export const selectStartDateAvgSeverityScore = createSelector(
  selectRangedAvgSeverityScore,
  avgSeverityScores => {
    if (avgSeverityScores.length === 0) return 0;
    return avgSeverityScores[0];
  }
);

export const selectAvgSeverityLevelsOfShips = createSelector(
  selectShipsSeverity,
  (shipsSeverity): ShipsBySeverity => {
    if (shipsSeverity.totalAvgShipsSeverity) {
      const endDateIndex = shipsSeverity.shipsSeverityByDate.length - 1;
      return {
        countOfExcellentSeverityShips:
          shipsSeverity.totalAvgShipsSeverity.excellentSeverityShips,
        countOfGoodSeverityShips:
          shipsSeverity.totalAvgShipsSeverity.goodSeverityShips,
        countOfModerateSeverityShips:
          shipsSeverity.totalAvgShipsSeverity.moderateSeverityShips,
        countOfLowSeverityShips:
          shipsSeverity.totalAvgShipsSeverity.lowSeverityShips,
        countOfNASeverityShips:
          shipsSeverity.totalAvgShipsSeverity.naSeverityShips,
        totalCount:
          shipsSeverity.totalAvgShipsSeverity.excellentSeverityShips +
          shipsSeverity.totalAvgShipsSeverity.goodSeverityShips +
          shipsSeverity.totalAvgShipsSeverity.moderateSeverityShips +
          shipsSeverity.totalAvgShipsSeverity.lowSeverityShips +
          shipsSeverity.totalAvgShipsSeverity.naSeverityShips,
      };
    }
    return defaultShipsBySeverity;
  }
);

export const selectSafteyScoreShips = createSelector(
  selectShipsState,
  shipsState => shipsState.shipsSafetyScoreData.shipsSafetyScore
);

export const selectAverageFleetSafteyScore = createSelector(
  selectShipsSeverity,
  shipsSeverity => shipsSeverity.avgFleetScore
);

export const selectAverageOrcaSafteyScore = createSelector(
  selectShipsSeverity,
  shipsSeverity => shipsSeverity.avgOrcaScore
);

export const selectLimitedSafetyScoreShips = createSelector(
  selectSafteyScoreShips,
  ships => ships.slice(0, 3)
);

export const selectSafetyScoreByDate = createSelector(
  selectShipsState,
  shipsState =>
    shipsState.shipsSeverityScoreByDate.shipsSeverityByDate.map(
      (ship): SafetyScoreByDate => {
        return { safetyScore: ship.avgScore, date: getZeroedDate(ship.date) };
      }
    )
);

export const selectSafetyScoreByDateWithoutNA = createSelector(
  selectShipsState,
  shipsState =>
    shipsState.shipsSeverityScoreByDate.shipsSeverityByDate
      .filter(shipSeverity => shipSeverity.avgScore)
      .map((ship): SafetyScoreByDate => {
        return { safetyScore: ship.avgScore, date: getZeroedDate(ship.date) };
      })
);

export const selectSafetyScoreData = createSelector(
  selectShipsState,
  shipsState => shipsState.shipsSafetyScoreData
);

export const selectSafteyScoreShipsSortBy = createSelector(
  selectSafetyScoreData,
  safetyScoreData => safetyScoreData.sortby
);

export const selectShips = createSelector(
  selectShipsState,
  state => state.ships
);

export const selectSelectedShipId = createSelector(
  selectShipsState,
  state => state.selectedShipId
);

export const selectSelectedShipName = createSelector(
  selectShips,
  selectSelectedShipId,
  (ships, selectedShipId) => {
    const selectedShip = ships.find(ship => ship.shipId === selectedShipId);
    return selectedShip ? selectedShip.shipName : null;
  }
);

export const selectSelectedShipEventCountData = createSelector(
  selectShipsState,
  state => state.selectedShipEventCountData
);

export const selectSelectedShipTotalEventCount = createSelector(
  selectSelectedShipEventCountData,
  state => state.totalCount
);

export const selectSelectedShipEventCountBySeverity = createSelector(
  selectSelectedShipEventCountData,
  state => state.bySeverity
);

export const selectSelectedShipEventCountByAlertName = createSelector(
  selectSelectedShipEventCountData,
  state => state.byAlertName
);

export const selectSelectedShipEventCountByCrewShifts = createSelector(
  selectSelectedShipEventCountData,
  state => state.byCrewShift
);

export const selectShipStream = createSelector(
  selectShipsState,
  state => state.shipLiveStream
);

export const selectShipStreamCameras = createSelector(
  selectShipStream,
  shipStreamState => shipStreamState.cameras
);

export const selectShipViewsCameras = createSelector(
  selectShipStreamCameras,
  shipCamerasState =>
    buildViewsStreams(shipCamerasState.map(camera => camera.cameraId))
);

export const selectShipThermalCameras = createSelector(
  selectShipStreamCameras,
  shipCamerasState =>
    buildThermalStreams(shipCamerasState.map(camera => camera.cameraId))
);

export const selectShipDayCameras = createSelector(
  selectShipStreamCameras,
  shipCamerasState =>
    buildDayStreams(shipCamerasState.map(camera => camera.cameraId))
);

export const selectOnlineShipCameraId = createSelector(
  selectShipStreamCameras,
  camerasState => {
    return (
      camerasState.find((camera): boolean => camera.isLive)?.cameraId || null
    );
  }
);

export const selectSelectedShipCameraName = createSelector(
  selectShipViewsCameras,
  selectShipThermalCameras,
  selectShipDayCameras,
  selectOnlineShipCameraId,
  (viewCamera, thermalCameras, dayCameras, cameraId) => {
    if (cameraId === null) {
      return null;
    } else if (viewCamera.some(camera => camera.cameraId === cameraId)) {
      return viewCamera.find(camera => camera.cameraId === cameraId)!
        .cameraName;
    } else if (dayCameras.some(camera => camera.cameraId === cameraId)) {
      return dayCameras.find(camera => camera.cameraId === cameraId)!
        .cameraName;
    } else if (thermalCameras.some(camera => camera.cameraId === cameraId)) {
      return thermalCameras.find(camera => camera.cameraId === cameraId)!
        .cameraName;
    }
    return null;
  }
);

export const selectDefaultShipCameraId = createSelector(
  selectShipViewsCameras,
  selectShipThermalCameras,
  selectShipDayCameras,
  (viewCamera, thermalCameras, dayCameras) => {
    if (viewCamera.length != 0) {
      return 'screen_casting';
    } else if (thermalCameras.length != 0 || dayCameras.length != 0) {
      return 'day_center';
    }
    return null;
  }
);

export const selectIsShipStreamMaster = createSelector(
  selectShipsState,
  state => state.shipLiveStream.isStreamMaster
);

export const selectShipStreamingId = createSelector(
  selectShipsState,
  state => state.shipLiveStream.shipId
);

export const selectFleetStreamingId = createSelector(
  selectShipsState,
  state => state.shipLiveStream.fleetId
);

export const selectStreamUrl = createSelector(
  selectShipsState,
  state => state.shipLiveStream.streamUrl
);

export const selectMapShips = createSelector(
  selectShips,
  selectSelectedShipId,
  (ships, selectedShipId) =>
    ships.map((ship): MapEntity => {
      if (ship.shipId === selectedShipId) {
        return {
          id: ship.shipId.toString(),
          lat: ship.latitude,
          long: ship.longitude,
          eyeOffset: [0, 0, -10000],
          image: isWithinLastHours(4, ship.lastConnection)
            ? `/assets/map/ships/${ship.shipType}/ship_icon.png`
            : `/assets/map/ships/${ship.shipType}/faded_ship_icon.png`,
        };
      } else {
        return {
          id: ship.shipId.toString(),
          lat: ship.latitude,
          long: ship.longitude,
          eyeOffset: [0, 0, 0],
          image: isWithinLastHours(4, ship.lastConnection)
            ? `/assets/map/ships/default.png`
            : `/assets/map/ships/faded_default.png`,
        };
      }
    })
);

export const selectMapSelectedShip = createSelector(
  selectShips,
  selectSelectedShipId,
  (ships, selectedShipId): MapEntity[] => {
    if (selectedShipId) {
      const ship = ships.find(ship => ship.shipId === selectedShipId);
      if (ship) {
        return [
          {
            id: ship?.shipId.toString(),
            lat: ship?.latitude,
            long: ship?.longitude,
            eyeOffset: [0, 0, -10000],
            image: isWithinLastHours(4, ship.lastConnection)
              ? `/assets/map/ships/${ship.shipType}/ship_icon.png`
              : `/assets/map/ships/${ship.shipType}/faded_ship_icon.png`,
          },
        ];
      }
      return [];
    }
    return [];
  }
);

export const selectSelectedShipSafetyScores = createSelector(
  selectShipsState,
  state => state.selectedShipSafetyScores
);

export const selectSelectedShipSafetyScoresWithoutNA = createSelector(
  selectShipsState,
  state =>
    state.selectedShipSafetyScores.filter(
      safetyScore => safetyScore.safetyScore
    )
);

export const selectSelectedShipAverageSafetyScoreWithoutNA = createSelector(
  selectSelectedShipSafetyScoresWithoutNA,
  selectedShipSafetyScores =>
    selectedShipSafetyScores.length > 0
      ? calculateAverage(
          selectedShipSafetyScores.map(
            shipSafetyScore => shipSafetyScore.safetyScore
          )
        )
      : 0
);

export const selectSelectedShipSailData = createSelector(
  selectShipsState,
  state => state.selectedShipSailData
);

export const selectSelectedShipGeneralSailData = createSelector(
  selectSelectedShipSailData,
  sailData => sailData.find(d => d.congestionLevel === null)
);

export const selectSelectedShipCongestedSailData = createSelector(
  selectSelectedShipSailData,
  sailData => sailData.find(d => d.congestionLevel === 'Congested')
);

export const selectSelectedShipHyperCongestedSailData = createSelector(
  selectSelectedShipSailData,
  sailData => sailData.find(d => d.congestionLevel === 'Hyper_Congested')
);

export const selectSelectedShipOpenWaterSailData = createSelector(
  selectSelectedShipSailData,
  sailData => sailData.find(d => d.congestionLevel === 'Open')
);

export const selectSelectedShipOpenWaterCeData = createSelector(
  selectSelectedShipSailData,
  ceEventsData => ceEventsData.find(d => d.congestionLevel === 'Open')
);

export const selectSelectedShipCongestedCeData = createSelector(
  selectSelectedShipSailData,
  ceEventsData => ceEventsData.find(d => d.congestionLevel === 'Congested')
);

export const selectSelectedShipHyperCongestedCeData = createSelector(
  selectSelectedShipSailData,
  ceEventsData =>
    ceEventsData.find(d => d.congestionLevel === 'Hyper_Congested')
);

export const selectSelectedShipCongestedCollisionRiskSailData = createSelector(
  selectSelectedShipSailData,
  sailData =>
    sailData.find(
      d => d.congestionLevel === 'Congested' && d.alertType === 'Collision risk'
    )
);

export const selectSelectedShipHyperCongestedCollisionRiskSailData =
  createSelector(selectSelectedShipSailData, sailData =>
    sailData.find(
      d =>
        d.congestionLevel === 'Hyper_Congested' &&
        d.alertType === 'Collision risk'
    )
  );

export const selectSelectedShipOpenWaterCollisionRiskSailData = createSelector(
  selectSelectedShipSailData,
  sailData =>
    sailData.find(
      d => d.congestionLevel === 'Open' && d.alertType === 'Collision risk'
    )
);

export const selectLastMonthsGeneralSailEventCount = createSelector(
  selectSelectedShipGeneralSailData,
  sailData => {
    if (sailData) {
      return sailData.sailData[sailData.sailData.length - 1].avgNumOfEvents;
    }
    return 0;
  }
);

export const selectChangeInGeneralSailEventCount = createSelector(
  selectSelectedShipGeneralSailData,
  sailData => {
    if (
      sailData &&
      sailData.sailData.length > 0 &&
      sailData.sailData[0].avgNumOfEvents > 0
    ) {
      return (
        (sailData.sailData[sailData.sailData.length - 1].avgNumOfEvents -
          sailData.sailData[0].avgNumOfEvents) /
        sailData.sailData[0].avgNumOfEvents
      );
    }
    return 0;
  }
);

export const selectLastMonthsOpenWaterSailEventCount = createSelector(
  selectSelectedShipOpenWaterSailData,
  sailData => {
    if (sailData) {
      return sailData.sailData[sailData.sailData.length - 1].avgNumOfEvents;
    }
    return 0;
  }
);

export const selectLastMonthsOpenWaterCeEventCount = createSelector(
  selectSelectedShipOpenWaterCeData,
  sailData => {
    if (sailData) {
      return sailData.ceEventsData[sailData.ceEventsData.length - 1]
        .avgMinDistance;
    }
    return 0;
  }
);

export const selectLastMonthsCongestedCeEventCount = createSelector(
  selectSelectedShipCongestedCeData,
  sailData => {
    if (sailData) {
      return sailData.ceEventsData[sailData.ceEventsData.length - 1]
        .avgMinDistance;
    }
    return 0;
  }
);

export const selectLastMonthsHyperCongestedCeEventCount = createSelector(
  selectSelectedShipHyperCongestedCeData,
  sailData => {
    if (sailData) {
      return sailData.ceEventsData[sailData.ceEventsData.length - 1]
        .avgMinDistance;
    }
    return 0;
  }
);

export const selectChangeInOpenWaterSailEventCount = createSelector(
  selectSelectedShipOpenWaterSailData,
  sailData => {
    if (
      sailData &&
      sailData.sailData.length > 0 &&
      sailData.sailData[0].avgNumOfEvents > 0
    ) {
      return (
        (sailData.sailData[sailData.sailData.length - 1].avgNumOfEvents -
          sailData.sailData[0].avgNumOfEvents) /
        sailData.sailData[0].avgNumOfEvents
      );
    }
    return 0;
  }
);

export const selectChangeInOpenWaterCeEventCount = createSelector(
  selectSelectedShipOpenWaterCeData,
  sailData => {
    if (
      sailData &&
      sailData.ceEventsData.length > 0 &&
      sailData.ceEventsData[0].avgMinDistance > 0
    ) {
      return (
        (sailData.ceEventsData[sailData.ceEventsData.length - 1]
          .avgMinDistance -
          sailData.ceEventsData[0].avgMinDistance) /
        sailData.ceEventsData[0].avgMinDistance
      );
    }
    return 0;
  }
);

export const selectChangeInCongestedCeEventCount = createSelector(
  selectSelectedShipCongestedCeData,
  sailData => {
    if (
      sailData &&
      sailData.ceEventsData.length > 0 &&
      sailData.ceEventsData[0].avgMinDistance > 0
    ) {
      return (
        (sailData.ceEventsData[sailData.ceEventsData.length - 1]
          .avgMinDistance -
          sailData.ceEventsData[0].avgMinDistance) /
        sailData.ceEventsData[0].avgMinDistance
      );
    }
    return 0;
  }
);

export const selectChangeInHyperCongestedCeEventCount = createSelector(
  selectSelectedShipHyperCongestedCeData,
  sailData => {
    if (
      sailData &&
      sailData.ceEventsData.length > 0 &&
      sailData.ceEventsData[0].avgMinDistance > 0
    ) {
      return (
        (sailData.ceEventsData[sailData.ceEventsData.length - 1]
          .avgMinDistance -
          sailData.ceEventsData[0].avgMinDistance) /
        sailData.ceEventsData[0].avgMinDistance
      );
    }
    return 0;
  }
);

export const selectLastMonthsOpenWaterCollisionRiskSailEventCount =
  createSelector(selectSelectedShipOpenWaterCollisionRiskSailData, sailData => {
    if (sailData) {
      return sailData.sailData[sailData.sailData.length - 1].avgNumOfEvents;
    }
    return 0;
  });

export const selectChangeInOpenWaterCollisionRiskSailEventCount =
  createSelector(selectSelectedShipOpenWaterCollisionRiskSailData, sailData => {
    if (
      sailData &&
      sailData.sailData.length > 0 &&
      sailData.sailData[0].avgNumOfEvents > 0
    ) {
      return (
        (sailData.sailData[sailData.sailData.length - 1].avgNumOfEvents -
          sailData.sailData[0].avgNumOfEvents) /
        sailData.sailData[0].avgNumOfEvents
      );
    }
    return 0;
  });

export const selectLastMonthsCongestedSailEventCount = createSelector(
  selectSelectedShipCongestedSailData,
  sailData => {
    if (sailData) {
      return sailData.sailData[sailData.sailData.length - 1].avgNumOfEvents;
    }
    return 0;
  }
);

export const selectChangeInCongestedSailEventCount = createSelector(
  selectSelectedShipCongestedSailData,
  sailData => {
    if (
      sailData &&
      sailData.sailData.length > 0 &&
      sailData.sailData[0].avgNumOfEvents > 0
    ) {
      return (
        (sailData.sailData[sailData.sailData.length - 1].avgNumOfEvents -
          sailData.sailData[0].avgNumOfEvents) /
        sailData.sailData[0].avgNumOfEvents
      );
    }
    return 0;
  }
);

export const selectLastMonthsCongestedCollisionRiskSailEventCount =
  createSelector(selectSelectedShipCongestedCollisionRiskSailData, sailData => {
    if (sailData) {
      return sailData.sailData[sailData.sailData.length - 1].avgNumOfEvents;
    }
    return 0;
  });

export const selectChangeInCongestedCollisionRiskSailEventCount =
  createSelector(selectSelectedShipCongestedCollisionRiskSailData, sailData => {
    if (
      sailData &&
      sailData.sailData.length > 0 &&
      sailData.sailData[0].avgNumOfEvents > 0
    ) {
      return (
        (sailData.sailData[sailData.sailData.length - 1].avgNumOfEvents -
          sailData.sailData[0].avgNumOfEvents) /
        sailData.sailData[0].avgNumOfEvents
      );
    }
    return 0;
  });

export const selectLastMonthsHyperCongestedSailEventCount = createSelector(
  selectSelectedShipHyperCongestedSailData,
  sailData => {
    if (sailData) {
      return sailData.sailData[sailData.sailData.length - 1].avgNumOfEvents;
    }
    return 0;
  }
);

export const selectChangeInHyperCongestedSailEventCount = createSelector(
  selectSelectedShipHyperCongestedSailData,
  sailData => {
    if (
      sailData &&
      sailData.sailData.length > 0 &&
      sailData.sailData[0].avgNumOfEvents > 0
    ) {
      return (
        (sailData.sailData[sailData.sailData.length - 1].avgNumOfEvents -
          sailData.sailData[0].avgNumOfEvents) /
        sailData.sailData[0].avgNumOfEvents
      );
    }
    return 0;
  }
);

export const selectLastMonthsHyperCongestedCollisionRiskSailEventCount =
  createSelector(
    selectSelectedShipHyperCongestedCollisionRiskSailData,
    sailData => {
      if (sailData) {
        return sailData.sailData[sailData.sailData.length - 1].avgNumOfEvents;
      }
      return 0;
    }
  );

export const selectChangeInHyperCongestedCollisionRiskSailEventCount =
  createSelector(
    selectSelectedShipHyperCongestedCollisionRiskSailData,
    sailData => {
      if (
        sailData &&
        sailData.sailData.length > 0 &&
        sailData.sailData[0].avgNumOfEvents > 0
      ) {
        return (
          (sailData.sailData[sailData.sailData.length - 1].avgNumOfEvents -
            sailData.sailData[0].avgNumOfEvents) /
          sailData.sailData[0].avgNumOfEvents
        );
      }
      return 0;
    }
  );

export const selectSelectedShipTotalDistanceSailed = createSelector(
  selectShips,
  selectSelectedShipId,
  (ships, selectedShipId) => {
    if (selectedShipId) {
      const ship = ships.find(ship => ship.shipId === selectedShipId);
      if (ship) {
        return ship.totalSailedDistance;
      }
    }
    return 0;
  }
);

export const selectShipAndEventsMapEntities = createSelector(
  selectMapShipEvents,
  selectSelectedEvent,
  selectSelectedShipId,
  selectShips,
  (mapEventsEntities, selectedEvent, selectedShipId, ships) => {
    const selectedShip = ships.find(ship => ship.shipId === selectedShipId);
    let shipMapEvent: MapEntity;
    if (selectedEvent) {
      shipMapEvent = {
        id: selectedShip!.shipId.toString(),
        lat: selectedShip!.latitude,
        long: selectedShip!.longitude,
        eyeOffset: [0, 0, -10000],
        image: `/assets/map/ships/default.png`,
      };
    } else {
      shipMapEvent = {
        id: selectedShip!.shipId.toString(),
        lat: selectedShip!.latitude,
        long: selectedShip!.longitude,
        eyeOffset: [0, 0, -10000],
        image: `/assets/map/ships/${selectedShip?.shipType}/ship_icon.png`,
      };
    }
    return [...mapEventsEntities, shipMapEvent];
  }
);

export const selectEndDateAvgShipSafetyScore = createSelector(
  selectSelectedShipSafetyScores,
  shipSafetyScores => {
    if (shipSafetyScores.length === 0) return 0;
    return shipSafetyScores[shipSafetyScores.length - 1].safetyScore;
  }
);

export const selectStartDateAvgShipSafetyScore = createSelector(
  selectSelectedShipSafetyScores,
  shipSafetyScores => {
    if (shipSafetyScores.length === 0) return 0;
    return shipSafetyScores[0].safetyScore;
  }
);

export const selectShipsScoreExplaination = createSelector(
  selectShipsState,
  state => state.shipsScoreExplaination
);

export const selectScreenshots = createSelector(
  selectShipsState,
  state => state.screenshots
);

export const selectRTEvents = createSelector(
  selectShipsState,
  (state: ShipsState) => state.rtEvents
);

export const selectEnhancedScreenshots = createSelector(
  selectScreenshots,
  selectRTEvents,
  AuthenticationSelectors.selectToken,
  (screenshots, rtEvents, token) => {
    const currentUtctime = new Date(
      new Date().getTime() + new Date().getTimezoneOffset() * 60000
    );
    return screenshots
      .map(screenshot => ({
        ...screenshot,
        rtEventName: rtEvents[screenshot.shipId]?.alertName ?? null,
        rtEventTime: rtEvents[screenshot.shipId]
          ? getTimeDiff(
              currentUtctime,
              new Date(rtEvents[screenshot.shipId].timestampStart)
            )
          : null,
        cdnUrl: token ? `${screenshot.cdnUrl}?jwt=${token}` : screenshot.cdnUrl,
      }))
      .sort((a, b) => (b.rtEventName ? 1 : a.rtEventName ? -1 : 0));
  }
);

export const selectShipRealTimeData = createSelector(
  selectShipsState,
  state => state.shipRealTimeData
);

export const selectShipScreenshot = createSelector(
  selectShipRealTimeData,
  selectScreenshots,
  selectRTEvents,
  AuthenticationSelectors.selectToken,
  (shipRealTimeData, screenshots, rtEvents, token) => {
    const screenshot = screenshots.find(
      screenshot => screenshot.shipId === shipRealTimeData?.shipId
    );
    if (screenshot) {
      const currentUtctime = new Date(
        new Date().getTime() + new Date().getTimezoneOffset() * 60000
      );
      return {
        ...screenshot,
        rtEventName: rtEvents[screenshot.shipId]?.alertName ?? null,
        rtEventTime: rtEvents[screenshot.shipId]
          ? getTimeDiff(
              currentUtctime,
              new Date(rtEvents[screenshot.shipId].timestampStart)
            )
          : null,
        cdnUrl: token ? `${screenshot.cdnUrl}?jwt=${token}` : screenshot.cdnUrl,
      };
    } else return null;
  }
);

export const selectSelectedShipPosition = createSelector(
  selectSelectedShipId,
  selectShips,
  selectShipRealTimeData,
  (shipId, ships, shipRealTimeData): Position => {
    if (shipId) {
      const selectedShip = ships.find(ship => ship.shipId === shipId);
      if (selectedShip)
        return {
          lat: shipRealTimeData?.latitude ?? selectedShip.latitude,
          long: shipRealTimeData?.longitude ?? selectedShip.longitude,
        };
    }
    return defaultPosition;
  }
);

export const selectSelectedPoisitionInShip = createSelector(
  selectSelectedShipPosition,
  selectSelectedEvent,
  (selectedShipPoisition, selectedEvent): Position => {
    if (selectedEvent) {
      return { lat: selectedEvent.lat, long: selectedEvent.long };
    }
    return selectedShipPoisition;
  }
);

export const selectShipPitchRoll = createSelector(
  selectShipsState,
  state => state.shipPitchRoll
);

export const selectShipLiveData = createSelector(
  selectShipsState,
  state => state.liveShipData
);

export const selectMapTooltip = createSelector(
  selectShipRealTimeData,
  selectShipScreenshot,
  selectStartDateAvgShipSafetyScore,
  selectEndDateAvgShipSafetyScore,
  (selectedShip, screenshot, startDateAvg, endDateAvg): MapTooltip => ({
    shipData: selectedShip,
    endDateAvgShipSafetyScore: startDateAvg,
    startDateAvgShipSafetyScore: endDateAvg,
    showLiveStreamFlag: true,
    isLiveStreamOpen: false,
    screenshot: screenshot!,
    isVisible: false,
    visibleByLoading: false,
  })
);
