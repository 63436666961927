import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-comment-dialog',
  templateUrl: './delete-comment-dialog.component.html',
  styleUrls: ['./delete-comment-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogModule, MatButtonModule],
})
export class DeleteCommentDialogComponent {
  constructor(public dialogRef: MatDialogRef<DeleteCommentDialogComponent>) {}

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onDiscard(): void {
    this.dialogRef.close(true);
  }
}
