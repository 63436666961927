import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { MapEntity } from 'src/app/map/models/map.entity.model';
import { Position } from 'src/app/map/models/position.model';
import { CoreState } from '../../store/state/core.state';
import { EventsActions, EventsSelectors } from '../../store/types';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
})
export class EventsComponent implements OnInit {
  dataExists$!: Observable<boolean>;
  mapEvents$!: Observable<MapEntity[]>;
  selectedEventPosition$!: Observable<Position>;
  constructor(private store: Store<CoreState>) {}
  ngOnInit(): void {
    this.dataExists$ = this.store
      .select(EventsSelectors.selectGeneralFilters)
      .pipe(
        // eslint-disable-next-line @ngrx/avoid-mapping-selectors
        map(generalFilters =>
          generalFilters.shipNames.length > 0 ? true : false
        )
      );

    this.mapEvents$ = this.store.select(EventsSelectors.selectMapEvents);
    this.selectedEventPosition$ = this.store.select(
      EventsSelectors.selectSelectedEventPosition
    );
  }

  onSelectedMapEvent(entityParams: string[]): void {
    const eventId = entityParams[0];
    this.store.dispatch(EventsActions.setSelectedEventsMapEvent({ eventId }));
  }
}
