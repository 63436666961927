import {
  AfterViewInit,
  Component,
  ComponentRef,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import * as _ from 'lodash';
import * as mapboxgl from 'mapbox-gl';
import { filter, Observable, Subject, takeUntil } from 'rxjs';
import { PolygonType } from 'src/app/core/models/fleet.model';
import { FeatureFlagService } from 'src/app/core/services/feature-toggle.service';
import { ShipLastLocation } from 'src/app/core/view-models/event.view.model';
import {
  EntityCategory,
  FeatureLabel,
  MapEntity,
  MapLabel,
  MapLayerChange,
  MapSettingsOption,
  MapTooltip,
  PolygonEntity,
  PolyLinePoint,
} from '../../models/map.entity.model';
import { LabelSource, ZoomOption } from '../../models/map.view.models';
import { defaultPosition, Position } from '../../models/position.model';
import { CameraService } from '../../services/camera.service';
import { ImageEntitiesService } from '../../services/image-entities.service';
import { MapLayersMenuService } from '../../services/map-layers-menu.service';
import { MapTilesService } from '../../services/map-tiles.service';
import { ViewerFactoryService } from '../../services/viewer-factory.service';
import { ShipProfileTooltipComponent } from '../tooltip/ship-profile-tooltip/ship-profile-tooltip.component';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  providers: [ViewerFactoryService, CameraService, MapTilesService],
})
export class MapComponent implements AfterViewInit, OnDestroy, OnChanges {
  @Input() zoomEnabled = true;
  @Input() zoom: ZoomOption = 4;
  @Input() offsetLongPositionDestination: number = 0;
  @Input() offsetLatPositionDestination: number = 0;
  @Input() shipNamelabels!: MapLabel[] | null;
  @Input() rtEvents!: MapLabel[];
  @Input() events!: MapEntity[] | null;
  @Input() ships!: MapEntity[] | null;
  @Input() tooltip!: MapTooltip | null;
  @Input() position: Position = defaultPosition;
  @Input() polygons: PolygonEntity[] = [];
  @Input() shipLastLocations!: ShipLastLocation[] | null;
  @Input() showShipTooltip: boolean = false;
  @Input() showLayerControl: boolean = false;

  @Output() selectedMapEvent = new EventEmitter<string[]>();
  @Output() mapTooltipClosed = new EventEmitter();
  @Output() mapTooltipButtonClicked = new EventEmitter();

  @Input() mapLayersOptionsChecked: MapSettingsOption[] = [];

  SHIP_LAYER = 'ship';
  EVENT_LAYER = 'event';
  POINT_LAYER = 'point';
  DASHED_LINE = 'dashed_line';
  POLYGON_LAYER = 'polygon';

  mapId: string = _.uniqueId('map_');
  map!: mapboxgl.Map;
  resizeObserver!: ResizeObserver;
  private isDragging = false;
  private destroy$ = new Subject<void>();
  private popups: mapboxgl.Popup[] = [];
  private shipTooltipPopUp!: mapboxgl.Popup | null;

  menuOpen = false;
  mapSettingsOption = MapSettingsOption;
  selectedOption: MapSettingsOption | null = null;
  minZoomToShowPolygonLabels: number = 4;
  infoLabelComponentRef: any;

  showWeatherFlag$!: Observable<boolean>;

  @ContentChild('layerMenus')
  layerMenusTemplate!: TemplateRef<any>;

  constructor(
    private viewerFactoryService: ViewerFactoryService,
    private cameraService: CameraService,
    private imageEntityService: ImageEntitiesService,
    private mapTilesService: MapTilesService,
    private viewContainerRef: ViewContainerRef,
    private featureFlag: FeatureFlagService,
    private mapLayersMenuService: MapLayersMenuService
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (!this.map) {
      return;
    }

    if (changes['ships']) {
      this.updateMapEntities(this.ships, this.SHIP_LAYER);
    }
    if (changes['tooltip']) {
      if (this.showShipTooltip) this.addOrUpdateTooltip();
    }
    if (changes['shipNamelabels']) {
      this.updateMapLabels(this.shipNamelabels, 'large-white');
    }
    if (changes['rtEvents']) {
      if (this.shouldDisplayLayer(MapSettingsOption.LiveEvents))
        this.updateMapLabels(this.rtEvents, 'rt-event');
    }
    if (changes['events']) {
      if (this.shouldDisplayLayer(MapSettingsOption.Events)) {
        this.updateMapEntities(this.events, this.EVENT_LAYER);
      }
    }
    if (changes['position']) {
      this.cameraService.flyTo(
        this.position,
        this.offsetLongPositionDestination,
        this.offsetLatPositionDestination,
        2
      );
    }
    if (changes['polygons']) {
      if (this.shouldDisplayLayer(MapSettingsOption.ComplianceAreas))
        this.updatePolygons('compliance');
      if (this.shouldDisplayLayer(MapSettingsOption.NoGoAreas))
        this.updatePolygons('no_go_zone');
    }
    if (changes['shipLastLocations']) {
      this.updatePolyLine();
    }
    if (changes['zoomEnabled']) {
      this.cameraService.enableZoom(this.zoomEnabled);
    }
  }

  ngAfterViewInit() {
    this.showWeatherFlag$ = this.featureFlag.getFeatureFlag$('weather');
    this.mapLayersMenuService.settingsChanged$.subscribe(
      (event: MapLayerChange) => {
        this.updateSettings(event);
      }
    );

    this.map = this.viewerFactoryService.createMap(this.mapId, this.zoom);
    this.mapTilesService.init(this.map);
    this.cameraService.init(this.map);
    this.imageEntityService.init(this.map);
    this.imageEntityService.loadImageEntity();
    const initMapEntitiesOnLoad = () => {
      this.updateMapEntities(this.ships, this.SHIP_LAYER);
      if (this.shouldDisplayLayer(MapSettingsOption.LiveEvents))
        this.updateMapLabels(this.rtEvents, 'rt-event');
      if (this.shouldDisplayLayer(MapSettingsOption.Events))
        this.updateMapEntities(this.events, this.EVENT_LAYER);
      if (this.shouldDisplayLayer(MapSettingsOption.NoGoAreas))
        this.updatePolygons('no_go_zone');
      if (this.shouldDisplayLayer(MapSettingsOption.ComplianceAreas))
        this.updatePolygons('compliance');
    };
    this.map.on('load', () => {
      this.initCameraSettings();
      this.initMapShipEntities();
      this.initMapLabels();
      this.updatePolyLine();
      this.addEntityEventListeners();
      this.toggleTooltip((this.tooltip && this.tooltip.visibleByLoading)!);
      this.addOrUpdateTooltip();
      initMapEntitiesOnLoad();
      this.map.on('zoom', this.handleZoomChange.bind(this));
      this.map.addControl(
        new mapboxgl.NavigationControl({
          showCompass: false,
          showZoom: true,
        }),
        'bottom-right'
      );
    });
    this.resizeObserver = new ResizeObserver(() => {
      this.resizeMap();
      this.resetZoom();
    });
    this.resizeObserver.observe(this.map.getContainer());
  }

  private toggleLabelVisibility(
    labelElement: HTMLElement,
    shouldShow: boolean
  ) {
    labelElement.style.visibility = shouldShow ? 'visible' : 'hidden';
  }

  private handleZoomChange(event: any) {
    const polygonLabels = document.querySelectorAll('.polygon_label');
    const zoomLevel = event.target.getZoom();
    const showLabel = this.shouldShowLabel(zoomLevel);
    polygonLabels.forEach(label => {
      const labelElement = label as HTMLElement;
      this.toggleLabelVisibility(labelElement, showLabel);
    });
  }

  private updatePolygons(polygonType: PolygonType): void {
    const polygonsByType = (this.polygons ?? []).filter(polygon => {
      return polygon.labelSource === polygonType;
    });
    this.addPolygonSourceAndLayerIfNotExist(polygonType);
    if (polygonsByType.length >= 1) {
      this.removePolygonLabels(polygonType);
      this.updatePolygonsData(polygonsByType, polygonType);
    } else {
      this.removePolygonEntities(polygonType);
    }
  }

  private removePolygonEntities(polygonType: PolygonType): void {
    if (this.map.getLayer(polygonType)) {
      this.map.removeLayer(polygonType);
      this.map.removeSource(polygonType);
      this.removePolygonLabels(polygonType);
    }
  }

  private shouldDisplayLayer(option: MapSettingsOption): boolean {
    return (
      this.mapLayersOptionsChecked.includes(option) || !this.showLayerControl
    );
  }
  private updateMapEntities(entities: MapEntity[] | null, layerId: string) {
    const existsLayers = this.getEntityLayers();
    this.addEntitySourceAndLayerIfNotExist(layerId);

    if (entities) {
      this.updateEntities(entities, layerId);
      if (!existsLayers.includes(layerId) && this.showLayerControl)
        this.addEntityEventListeners();
    } else {
      this.removeAllEntities(layerId);
    }
  }

  private addEntitySourceAndLayerIfNotExist(layerId: string): void {
    if (!this.map.getSource(layerId)) {
      this.map.addSource(layerId, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      });
    }

    if (!this.map.getLayer(layerId)) {
      this.map.addLayer({
        id: layerId,
        type: 'symbol',
        source: layerId,
        layout: {
          'icon-image': ['get', 'icon'],
          'icon-size': 1,
          'icon-allow-overlap': true,
        },
      });
    }
  }
  private initMapShipEntities(): void {
    this.updateMapEntities(this.ships, this.SHIP_LAYER);
  }
  private initCameraSettings(): void {
    this.cameraService.enableZoom(this.zoomEnabled);

    this.cameraService.flyTo(
      this.position,
      this.offsetLongPositionDestination,
      this.offsetLatPositionDestination,
      0
    );
  }

  private initMapLabels(): void {
    this.updateMapLabels(this.shipNamelabels, 'large-white');
  }

  private updateEntities(entities: MapEntity[], entity_sourc: string): void {
    const features: GeoJSON.Feature<GeoJSON.Point>[] = entities.map(entity => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [entity.long, entity.lat],
      },
      properties: this.updateEntityProperties(entity),
    }));

    const source = this.map.getSource(entity_sourc) as mapboxgl.GeoJSONSource;
    source.setData({
      type: 'FeatureCollection',
      features: features,
    });
  }

  private updateMapLabels(labels: MapLabel[] | null, labelSource: LabelSource) {
    this.removeLabels(labelSource);
    if (labels) this.updateLabels(labels);
  }

  private createPopupLabel(featureLabel: FeatureLabel): mapboxgl.Popup {
    let popupHtml = '';
    const text: string[] = featureLabel.textLabel.split('\n');
    if (featureLabel.iconSrc)
      popupHtml += `<img class="icon" src="${featureLabel.iconSrc}"/>`;
    if (featureLabel.textLabel) {
      popupHtml += '<span class="text">';
      text.forEach(textLine => (popupHtml += `<span>${textLine}</span>`));
      popupHtml += '</span>';
    }
    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
    });
    if (popupHtml) {
      popup.setHTML(popupHtml).addClassName(featureLabel.labelType);
      popup.addClassName(featureLabel.labelSource!);
    }

    return popup;
  }

  private addOrUpdateTooltip(): void {
    if (this.shipTooltipPopUp) this.shipTooltipPopUp.remove();
    if (
      this.tooltip &&
      this.tooltip.shipData &&
      this.tooltip.isVisible &&
      this.showShipTooltip
    ) {
      const tooltipComponent = this.createTooltipElement();
      if (tooltipComponent) {
        const tooltipEl = tooltipComponent.location
          .nativeElement as HTMLElement;
        const coordinates = new mapboxgl.LngLat(
          this.position.long,
          this.position.lat
        );

        const calculateOffset = (): number => {
          var heightOffset = this.tooltip?.screenshot ? 455 : 320;
          if (this.tooltip?.isShipCaptain) heightOffset -= 60;
          return heightOffset;
        };

        this.shipTooltipPopUp = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
        })
          .setDOMContent(tooltipEl!)
          .setOffset([0, -calculateOffset()])
          .setLngLat(coordinates.wrap())
          .addTo(this.map);

        tooltipComponent!.instance.closeTooltipClicked
          .pipe(takeUntil(this.mapTooltipClosed))
          .subscribe(() => {
            this.shipTooltipPopUp!.remove();
            this.shipTooltipPopUp = null;
            tooltipComponent?.destroy();
            this.mapTooltipClosed.emit();
            this.toggleTooltip(false);
          });
        tooltipComponent!.instance.buttonClicked
          .pipe(
            filter(navigateUrl => navigateUrl != null),
            takeUntil(this.mapTooltipClosed)
          )
          .subscribe(navigateUrl =>
            this.mapTooltipButtonClicked.emit(navigateUrl)
          );
      }
    }
  }

  private updateLabels(labels: MapLabel[]): void {
    labels.forEach(label => {
      const coordinates = new mapboxgl.LngLat(label.long, label.lat).wrap();
      const popup: mapboxgl.Popup = this.createPopupLabel(label);
      popup.setLngLat(coordinates).setOffset(label.offset).addTo(this.map);

      this.popups.push(popup);
    });
  }

  private removeAllEntities(layer: string): void {
    this.map.removeLayer(layer);
    this.map.removeSource(layer);
  }

  private removeLabels(labelSource: LabelSource) {
    const popups = Array.from(document.getElementsByClassName(labelSource));
    popups.forEach(popup => {
      popup.parentNode!.removeChild(popup);
    });
  }

  updateSettings(layerChange: MapLayerChange) {
    const option = layerChange?.layerOption;
    const optionChecked = layerChange.isChecked;
    optionChecked
      ? this.mapLayersOptionsChecked.push(option)
      : (this.mapLayersOptionsChecked = this.mapLayersOptionsChecked.filter(
          opt => opt !== option
        ));

    switch (option) {
      case MapSettingsOption.Events:
        if (layerChange?.isChecked)
          this.updateMapEntities(this.events, this.EVENT_LAYER);
        else this.removeAllEntities(this.EVENT_LAYER);
        break;
      case MapSettingsOption.LiveEvents:
        if (layerChange?.isChecked) this.updateLabels(this.rtEvents);
        else this.removeLabels('rt-event');
        break;
      case MapSettingsOption.ComplianceAreas:
        if (layerChange?.isChecked) this.updatePolygons('compliance');
        else this.removePolygonEntities('compliance');
        break;
      case MapSettingsOption.NoGoAreas:
        if (layerChange?.isChecked) this.updatePolygons('no_go_zone');
        else this.removePolygonEntities('no_go_zone');
        break;
      case MapSettingsOption.Wind:
        if (layerChange?.isChecked) {
          this.selectedOption = option;
          this.mapTilesService.removeAllWeatherLayers();
          this.mapTilesService.addWindLayers();
        } else {
          this.mapTilesService.removeWindLayers();
          this.selectedOption = null;
        }
        break;

      case MapSettingsOption.WaveSwell:
        if (layerChange?.isChecked) {
          this.selectedOption = option;
          this.mapTilesService.removeAllWeatherLayers();
          this.mapTilesService.addWaveSwellLayers();
        } else {
          this.mapTilesService.removeWaveSwellLayers();
          this.selectedOption = null;
        }
        break;

      case MapSettingsOption.WaveSignificant:
        if (layerChange?.isChecked) {
          this.selectedOption = option;
          this.mapTilesService.removeAllWeatherLayers();
          this.mapTilesService.addWaveSignificantLayers();
        } else {
          this.mapTilesService.removeWaveSignificantLayers();
          this.selectedOption = null;
        }
        break;

      case MapSettingsOption.Currents:
        if (layerChange?.isChecked) {
          this.selectedOption = option;
          this.mapTilesService.removeAllWeatherLayers();
          this.mapTilesService.addCurrentsLayers();
        } else {
          this.mapTilesService.removeCurrentsLayers();
          this.selectedOption = null;
        }
        break;

      case MapSettingsOption.Humidity:
        if (layerChange?.isChecked) {
          this.selectedOption = option;
          this.mapTilesService.removeAllWeatherLayers();
          this.mapTilesService.addHumidityLayer();
        } else {
          this.mapTilesService.removeHumidityLayer();
          this.selectedOption = null;
        }
        break;

      case MapSettingsOption.Temperature:
        if (layerChange?.isChecked) {
          this.selectedOption = option;
          this.mapTilesService.removeAllWeatherLayers();
          this.mapTilesService.addTemperatureLayer();
        } else {
          this.mapTilesService.removeTemperatureLayer();
          this.selectedOption = null;
        }
        break;

      case MapSettingsOption.Clouds:
        if (layerChange?.isChecked) {
          this.selectedOption = option;
          this.mapTilesService.removeAllWeatherLayers();
          this.mapTilesService.addCloudsLayer();
        } else {
          this.mapTilesService.removeCloudsLayer();
          this.selectedOption = null;
        }
        break;
    }
    this.ensureLayerOrder();
  }

  private createTooltipElement(): ComponentRef<ShipProfileTooltipComponent> | null {
    if (this.tooltip?.shipData) {
      const tooltipComponentRef = this.viewContainerRef.createComponent(
        ShipProfileTooltipComponent
      );
      tooltipComponentRef.instance.shipData = this.tooltip.shipData;
      tooltipComponentRef.instance.endDateAvgShipSafetyScore =
        this.tooltip.endDateAvgShipSafetyScore!;
      tooltipComponentRef.instance.startDateAvgShipSafetyScore =
        this.tooltip.startDateAvgShipSafetyScore!;
      tooltipComponentRef.instance.showLiveStreamFlag =
        this.tooltip.showLiveStreamFlag!;
      tooltipComponentRef.instance.isLiveStreamOpen =
        this.tooltip.isLiveStreamOpen!;
      tooltipComponentRef.instance.screenshot = this.tooltip.screenshot!;
      tooltipComponentRef.instance.isShipCaptain = this.tooltip.isShipCaptain!;
      return tooltipComponentRef;
    }
    return null;
  }

  addPolygonSourceAndLayerIfNotExist(polygonType: PolygonType): void {
    if (!this.map.getSource(polygonType))
      this.map.addSource(polygonType, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      });
    if (!this.map.getLayer(polygonType))
      this.map.addLayer({
        id: polygonType,
        type: 'fill',
        source: polygonType,
      });
  }

  private updatePolygonsData(
    polygons: PolygonEntity[],
    polygonType: PolygonType
  ): void {
    if (polygons.length > 0) {
      const polygonEntity = polygons[0];
      this.map.setPaintProperty(polygonType, 'fill-color', polygonEntity.color);
      this.map.setPaintProperty(
        polygonType,
        'fill-outline-color',
        polygonEntity.borderColor
      );
    }

    const features: GeoJSON.Feature<GeoJSON.Polygon>[] = polygons.map(
      polygonEntity => ({
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [polygonEntity.coordinates],
        },
        properties: polygonEntity,
      })
    );
    const source = this.map.getSource(polygonType) as mapboxgl.GeoJSONSource;
    source.setData({
      type: 'FeatureCollection',
      features: features,
    });
    features.forEach(feature => this.addPolygonLabel(feature, polygonType));
  }

  private addPolygonLabel(
    polygonEntity: GeoJSON.Feature<GeoJSON.Polygon>,
    polygonType: PolygonType
  ): void {
    if (
      !polygonEntity.properties ||
      !polygonEntity.properties['textLabel'] ||
      !polygonEntity.properties['labelType']
    )
      return;
    const center = this.calculatePolygonCenter(
      polygonEntity.geometry.coordinates[0]
    );
    const featureLabel: FeatureLabel = {
      textLabel: polygonEntity.properties['textLabel'],
      labelType: polygonEntity.properties['labelType'],
      labelSource: polygonEntity.properties['labelSource'],
    };
    const popup: mapboxgl.Popup = this.createPopupLabel(featureLabel);
    popup.setLngLat(center as [number, number]).addTo(this.map);
    popup.addClassName(polygonType);
    popup.addClassName('polygon_label');

    const zoomLevel = this.map.getZoom();
    this.toggleLabelVisibility(
      popup.getElement(),
      this.shouldShowLabel(zoomLevel)
    );
  }

  private shouldShowLabel(zoomLevel: number) {
    return zoomLevel > this.minZoomToShowPolygonLabels;
  }

  private calculatePolygonCenter(coordinates: number[][]): number[] {
    const bounds = coordinates.reduce(
      (bounds, coord) => {
        return [
          Math.min(bounds[0], coord[0]),
          Math.min(bounds[1], coord[1]),
          Math.max(bounds[2], coord[0]),
          Math.max(bounds[3], coord[1]),
        ];
      },
      [Infinity, Infinity, -Infinity, -Infinity]
    );

    return [(bounds[0] + bounds[2]) / 2, (bounds[1] + bounds[3]) / 2];
  }
  private removePolygonLabels(polygonType: PolygonType): void {
    const popups = Array.from(document.getElementsByClassName(polygonType));
    popups.forEach(popup => {
      popup.parentNode!.removeChild(popup);
    });
  }

  private addPolylineDashedLineSourceAndLayerIfNotExist(): void {
    if (!this.map.getSource(this.DASHED_LINE)) {
      this.map.addSource(this.DASHED_LINE, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      });
    }

    if (!this.map.getLayer(this.DASHED_LINE)) {
      this.map.addLayer({
        id: this.DASHED_LINE,
        type: 'line',
        source: this.DASHED_LINE,
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': '#5B588C',
          'line-width': 1,
          'line-dasharray': [2, 2],
        },
      });
    }
  }

  private addPolylinePointSourceAndLayerIfNotExist(): void {
    if (!this.map.getSource(this.POINT_LAYER)) {
      this.map.addSource(this.POINT_LAYER, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      });
    }

    if (!this.map.getLayer(this.POINT_LAYER)) {
      this.map.addLayer({
        id: this.POINT_LAYER,
        type: 'circle',
        source: this.POINT_LAYER,
        paint: {
          'circle-radius': 4,
          'circle-color': '#5B588C',
        },
      });
    }
  }

  private updateShipLastLocationLine(
    shipLastLocations: ShipLastLocation[]
  ): void {
    this.addPolylineDashedLineSourceAndLayerIfNotExist();
    const pointsCoordinates = shipLastLocations.map(point => [
      point.longitude,
      point.latitude,
    ]);
    pointsCoordinates.push([this.position.long, this.position.lat]);

    const featureDashedPolyline: GeoJSON.Feature<GeoJSON.LineString> = {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: pointsCoordinates,
      },
      properties: {},
    };
    const sourceDashedPolyline = this.map.getSource(
      this.DASHED_LINE
    ) as mapboxgl.GeoJSONSource;
    sourceDashedPolyline.setData(featureDashedPolyline);
  }

  private updateShipLastLocationsPoint(
    shipLastLocations: ShipLastLocation[]
  ): void {
    this.addPolylinePointSourceAndLayerIfNotExist();

    const allFeatures: GeoJSON.Feature<GeoJSON.Point>[] = shipLastLocations.map(
      shipLastLocation => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [shipLastLocation.longitude, shipLastLocation.latitude],
        },
        properties: this.updatePolyLineProperties(shipLastLocation),
      })
    );

    // Filter the points to be displayed (e.g., every nth point)
    //index % 6 === 0 || index === allFeatures.length;
    const filteredFeatures = allFeatures.filter((_, index) => {
      return index % 6 === 0 || index === allFeatures.length - 1;
    });

    // Draw the polyline using all the points
    const polylineSource = this.map.getSource(
      this.POINT_LAYER
    ) as mapboxgl.GeoJSONSource;

    polylineSource.setData({
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: allFeatures.map(
              feature => feature.geometry.coordinates
            ),
          },
          properties: {},
        },
      ],
    });

    // Display only the filtered points
    const pointSource = this.map.getSource(
      this.POINT_LAYER
    ) as mapboxgl.GeoJSONSource;

    pointSource.setData({
      type: 'FeatureCollection',
      features: filteredFeatures,
    });

    this.addPointEventLister();
  }

  private addPointEventLister() {
    this.displayLabelOnHoverToLayer(this.POINT_LAYER, 25);
  }

  private updatePolyLine(): void {
    if (!this.shipLastLocations) return;

    this.updateShipLastLocationLine(this.shipLastLocations);
    this.updateShipLastLocationsPoint(this.shipLastLocations);
  }

  private displayLabelOnHoverToLayer(
    layerId: string,
    labelOffset: number
  ): void {
    let popup: mapboxgl.Popup;

    this.map.on('mouseenter', layerId, e => {
      this.map.getCanvas().style.cursor = 'pointer';

      const features = e.features;
      if (features && features.length > 0) {
        const entity = features[0];
        if (
          !entity.properties ||
          !entity.properties['textLabel'] ||
          !entity.properties['labelType']
        )
          return;
        const featureLabel: FeatureLabel = {
          textLabel: entity.properties['textLabel'],
          labelType: entity.properties['labelType'],
        };

        const coordinates = (entity.geometry as GeoJSON.Point).coordinates;

        popup = this.createPopupLabel(featureLabel);
        popup
          .setLngLat(coordinates as [number, number])
          .setOffset(labelOffset)
          .addTo(this.map);
      }
    });

    this.map.on('mouseleave', layerId, () => {
      this.map.getCanvas().style.cursor = '';
      popup.remove();
      if (this.infoLabelComponentRef) {
        this.infoLabelComponentRef.destroy();
        this.infoLabelComponentRef = null;
      }
    });
  }

  private addEntityEventListeners(): void {
    this.map.on('click', this.getEntityLayers(), e => {
      const features = this.map.queryRenderedFeatures(e.point, {
        layers: this.getEntityLayers(),
      });
      if (features.length > 0) {
        const entity = features[0];
        const entityId = entity.properties!['id'] as string;
        const entityCategory = this.getCategory(
          entity.properties!['icon'] as string
        );
        this.selectedMapEvent.emit([entityId, entityCategory]);
        if (
          entityCategory === EntityCategory.Ship ||
          entityCategory === EntityCategory.Event
        ) {
          this.toggleTooltip(this.tooltip?.isVisible!);
          if (this.tooltip?.isVisible) this.addOrUpdateTooltip();
        }
      }
    });

    this.map.on('mouseenter', this.getEntityLayers(), () => {
      this.map.getCanvas().style.cursor = 'pointer';
    });

    this.map.on('mouseleave', this.getEntityLayers(), () => {
      this.map.getCanvas().style.cursor = '';
    });

    this.map.on('mousedown', this.getEntityLayers(), () => {
      this.isDragging = true;
    });

    this.map.on('mouseup', () => {
      this.isDragging = false;
    });
  }

  private getEntityLayers(): string[] {
    return this.map
      .getStyle()!
      .layers.filter(
        layer => layer.id === this.SHIP_LAYER || layer.id === this.EVENT_LAYER
      )
      .map(layer => layer.id);
  }

  private getCategory(path?: string): EntityCategory {
    if (!path) return EntityCategory.Unknown;
    const parts = path.split('/');
    if (parts.includes('ships')) return EntityCategory.Ship;
    if (parts.includes('events')) return EntityCategory.Event;
    return EntityCategory.Unknown;
  }

  private updateEntityProperties(entity: MapEntity): any {
    return {
      id: entity.id,
      icon: entity.image,
      category: this.getCategory(entity.image),
      textLabel: entity.textLabel,
      labelType: entity.labelType,
    };
  }

  private updatePolyLineProperties(
    shipLastLocations: ShipLastLocation
  ): PolyLinePoint {
    return {
      latitude: shipLastLocations.latitude,
      longitude: shipLastLocations.longitude,
      sog: shipLastLocations.sog,
      time: shipLastLocations.time,
      textLabel: `${shipLastLocations.time}\n${shipLastLocations.sog} Knts`,
      labelType: 'white',
    };
  }

  private toggleTooltip(isVisible: boolean) {
    this.showShipTooltip = isVisible;
  }

  private resizeMap() {
    this.map?.resize();
  }

  private resetZoom() {
    this.cameraService.setMinZoomToFitWorldBounds();
    this.cameraService.setZoom(this.zoom);
  }

  private ensureLayerOrder(): void {
    const layerOrder = [
      // Wave Swell layers
      'wave-swell-height-fill',
      'wave-swell-height-line',
      // Wave Significant layers
      'wave-significant-height-fill',
      'wave-significant-height-line',
      // Ocean Currens layers
      'currents-height-fill',
      'currents-height-line',
      // Land Layer
      'country-boundaries',
      'state-label',
      'country-label',
      'continent-label',
      // Wind layers
      'wind-speed-fill',
      'wind-arrows',
      // Humidity layer
      'humidity-png',
      // Clouds layers
      'clouds-png',
      'clouds-height-fill',
      // Temperature layer
      'temperature-png',
      // Dashed polyline layer
      this.DASHED_LINE,
      // Ship last location point layers
      this.POINT_LAYER,
      // Polygon layers
      this.POLYGON_LAYER,
      // Entity layer
      this.EVENT_LAYER,
      this.SHIP_LAYER,
    ];
    for (let i = 0; i < layerOrder.length; i++) {
      const layerId = layerOrder[i];
      if (this.map.getLayer(layerId)) {
        this.map.moveLayer(layerId);
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.map.remove();
    this.resizeObserver.disconnect();
    if (this.infoLabelComponentRef) {
      this.infoLabelComponentRef.destroy();
    }
    if (this.shipTooltipPopUp) {
      this.tooltip = null;
      this.toggleTooltip(false);
      this.shipTooltipPopUp.remove();
    }
  }
}
