<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding [disabled]="vap.disabled$ | async">
    <button mat-icon-button disabled></button>
    <orca-on-off-button uni-check
      [value]="selection.isSelected(node)"
      (toggled)="toggleNode(node)"
    >
      <ng-container *ngIf="node.item?.content?.template as template">
        <ng-container *ngTemplateOutlet="template"></ng-container>
      </ng-container>
      <ng-container *ngIf="!node.item?.content?.template">
        {{ node.key }}
      </ng-container>
    </orca-on-off-button>
  </mat-tree-node>
  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
    <button mat-icon-button matTreeNodeToggle>
      <mat-icon class="mat-icon-rtl-mirror">
        {{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
      </mat-icon>
    </button>
    <orca-on-off-button uni-check
      [value]="getSelectionState(node)"
      (toggled)="toggleNode(node)"
  >
    <ng-container *ngIf="node.item?.content?.template as template">
      <ng-container *ngTemplateOutlet="template"></ng-container>
    </ng-container>
    <ng-container *ngIf="!node.item?.content?.template">
      {{ node.key }}
    </ng-container>
    </orca-on-off-button>
</mat-tree-node></mat-tree
>
