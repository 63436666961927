import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as shape from 'd3-shape';
import { map, Observable } from 'rxjs';
import { EventDayFilter } from 'src/app/core/models/events-filters.model';
import { CoreState } from 'src/app/core/store/state/core.state';
import {
  EventsActions,
  EventsSelectors,
  ShipsSelectors,
} from 'src/app/core/store/types';
import { SafetyScoreByDate } from 'src/app/core/view-models/safety.score.view.model';
import { tranformDateToDayMonth } from 'src/app/shared/utils/date-transforms/dateToDayMonth';
import { customXTicks } from 'src/app/shared/utils/graphs/general';
import { Dates } from 'src/app/shared/view-models/dates.view.model';

@Component({
  selector: 'app-events-graph',
  templateUrl: './events-graph.component.html',
  styleUrls: ['./events-graph.component.scss'],
})
export class EventsGraphComponent implements OnInit {
  safetyScoreByDate$!: Observable<SafetyScoreByDate[]>;
  safetyScoreByDateView$: any;

  linearCurve = shape.curveLinear;
  xAxisTicks$!: Observable<Date[]>;

  dataExists$!: Observable<boolean>;
  showEventsDayFilterTitle$!: Observable<boolean>;
  eventsDateFilter$!: Observable<Date>;
  eventDayFilterScore$!: Observable<number | null>;

  constructor(private store: Store<CoreState>) {}
  ngOnInit(): void {
    this.safetyScoreByDate$ = this.store.select(
      ShipsSelectors.selectSafetyScoreByDateWithoutNA
    );

    this.safetyScoreByDateView$ = this.safetyScoreByDate$.pipe(
      map(safetyScoreByDate =>
        safetyScoreByDate.map(safetyScore => {
          const customSafetyScore = {
            ...safetyScore,
            name: safetyScore.date,
            value: safetyScore.safetyScore,
          };
          return customSafetyScore;
        })
      ),
      map(safetyScoreView => {
        return [
          {
            name: 'Safety Score By Date',
            series: safetyScoreView,
          },
        ];
      })
    );

    this.xAxisTicks$ = this.safetyScoreByDate$.pipe(
      map(safetyScoreByDate =>
        safetyScoreByDate.map(safetyScore => safetyScore.date)
      ),
      map(dates => customXTicks(dates))
    );

    this.dataExists$ = this.safetyScoreByDate$.pipe(
      map(safetyScore => safetyScore.length > 0)
    );

    this.showEventsDayFilterTitle$ = this.store.select(
      EventsSelectors.selectIsEventsFilterByDay
    );

    this.eventsDateFilter$ = this.store.select(
      EventsSelectors.selectEventsDateFilterStartDate
    );

    this.eventDayFilterScore$ = this.store.select(
      EventsSelectors.selectEventDaySafetyScore
    );
  }

  formatXLabel(date: Date): string {
    return tranformDateToDayMonth(date);
  }

  onEventDateFilter(event: { date: Date; safetyScore: number }) {
    const localTimeToUtcOffset = -event.date.getTimezoneOffset() / 60;
    // Must create new date from event.date before manipulating
    // in order to not actually change event
    const startDateWithUtcOffset = new Date(
      new Date(event.date).setHours(localTimeToUtcOffset, 0, 0, 0)
    );
    const endDateWithUtcOffset = new Date(
      new Date(event.date).setHours(23 + localTimeToUtcOffset, 59, 59, 999)
    );
    const dates: Dates = {
      startDate: startDateWithUtcOffset,
      endDate: endDateWithUtcOffset,
    };

    const eventDayFilter: EventDayFilter = {
      dates,
      safetyScore: event.safetyScore,
    };

    this.store.dispatch(
      EventsActions.updateEventsDateFilterOnUserClick({ eventDayFilter })
    );
  }

  clearEventsDateFilter() {
    this.store.dispatch(EventsActions.resetEventsDateFilterOnClear());
  }
}
