<app-no-data [dataExists]="dataExists">
  <div class="container">
    <app-widget-title
      class="title"
      [widgetTitleType]="'subTitle'"
      [showEventsDayFilterTitle$]="showEventsDayFilterTitle$"
      [selectedDateRange$]="selectedDateRange$"
      [title]="title"></app-widget-title>
    <div class="pie-wrapper" appDynamicSquare [toSquare]="pie">
      <div class="pie-container" #pie>
        <ngx-charts-pie-chart
          class="graph"
          [results]="graphData"
          [doughnut]="true"
          [customColors]="colorScheme"
          [tooltipDisabled]="true"
          (select)="sliceClick.emit($event.name)"
          [arcWidth]="0.2">
        </ngx-charts-pie-chart>
        <div class="center-chart-text" (click)="centerTitleClick.emit($event)">
          <h4>{{ totalCount }}</h4>
          <span class="caption">{{ countTitle }}</span>
        </div>
      </div>
      <div class="content">
        <ng-container *ngFor="let groupCount of countByGroup">
          <div
            *ngIf="groupCount.value > 0"
            appGraphTooltipItem
            [class]="legendClass"
            [colorClass]="tooltipMapping[groupCount.name].divClass"
            [text]="tooltipMapping[groupCount.name].text"
            [value]="groupCount.value"
            (click)="labelClick.emit(groupCount.name)"></div>
        </ng-container>
      </div>
    </div>
  </div>
</app-no-data>
