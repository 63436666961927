import {
  KpisData,
  KpisDataDto,
  SailCost,
  SailCostDto,
  SailFuelExpenditureGraphData,
  ShipEfficiency,
  ShipEfficiencyDto,
  TimeFilter,
} from '../models/efficiency.models';

export function mapShipEfficiencyDtoToShipEfficiency(
  shipEfficiencyDto: ShipEfficiencyDto
): ShipEfficiency {
  return {
    shipId: shipEfficiencyDto.ship_id,
    shipName: shipEfficiencyDto.ship_name,
    fleetName: shipEfficiencyDto.fleet_name,
    extraCostDistanceSailed:
      shipEfficiencyDto.cost_of_extra_distance_sailed_dollar,
    numLateActions: shipEfficiencyDto.num_of_late_actions,
    numEarlyActions: shipEfficiencyDto.num_of_early_actions,
    extraFuelConsumedMt: shipEfficiencyDto.extra_fuel_consumed_mt,
    extraDistanceSailedNm: shipEfficiencyDto.extra_distance_sailed_nm,
    nmSailedInOpenWaters: shipEfficiencyDto.nm_sailed_in_open_waters,
    extraEmissionMt: shipEfficiencyDto.extra_emission_mt,
  };
}

export function mapKpiDataDtoToKpiData(kpisData: KpisDataDto): KpisData {
  return {
    totalExtraEmissionMt: kpisData.total_extra_emission_mt,
    totalExtraCostDollar: kpisData.total_extra_cost_dollar,
    totalExtraFuelMt: kpisData.total_extra_fuel_mt,
    totalNumOfLateActions: kpisData.total_num_of_late_actions,
    totalNumOfEarlyActions: kpisData.total_num_of_early_actions,
  };
}

export function getThreeMonthsAgoDate(): string {
  const today = new Date();
  const currentMonth = today.getMonth();
  const yearThreeMonthsAgo = today.getFullYear() - (currentMonth < 3 ? 1 : 0);
  const monthThreeMonthsAgo = (currentMonth + 9) % 12;
  return new Date(yearThreeMonthsAgo, monthThreeMonthsAgo, 1).toISOString();
}

export function getDateAlmostFiveWeeksAgo(): string {
  const today = new Date();
  const lastMonday = new Date(
    today.setDate(today.getDate() - ((today.getDay() + 6) % 7 || 7))
  );
  const fiveWeeksAgoBeforeLastMonday = new Date(
    lastMonday.setDate(lastMonday.getDate() - 28)
  );
  return fiveWeeksAgoBeforeLastMonday.toISOString();
}

export function mapSailCostDtoToSailCost(sailCostDto: SailCostDto): SailCost {
  return {
    startDate: sailCostDto.start_date,
    totalSpentDollar: sailCostDto.total_spent_dollar,
    extraSpentDollar: sailCostDto.extra_spent_dollar,
  };
}

function formatDate(date: string): string {
  const [year, month, day] = date.split('-');
  return `${day}/${month}/${year.substring(2)}`;
}

export function getAccumulatedSailCosts(
  sailCosts: SailCost[],
  timeFilter: TimeFilter
): SailFuelExpenditureGraphData[] {
  const accumulatedSailCosts: SailFuelExpenditureGraphData[] = [];
  let accumulatedNecessarySpent = 0;
  let accumulatedExtraSpent = 0;
  sailCosts.forEach((sailCost, index) => {
    accumulatedNecessarySpent +=
      sailCost.totalSpentDollar - sailCost.extraSpentDollar;
    accumulatedExtraSpent += sailCost.extraSpentDollar;
    accumulatedSailCosts.push({
      name:
        timeFilter === TimeFilter.FOUR_WEEKS_AND_THIS_WEEK
          ? formatDate(sailCost.startDate)
          : new Date(sailCost.startDate).toLocaleString('en-us', {
              month: 'short',
            }) + (index > 11 ? '\u200B' : ''), // Empty character to differentiate duplicate months
      series: [
        {
          name: 'Fuel Expenditure',
          value: accumulatedNecessarySpent,
        },
        {
          name: 'Fuel Expenditure Due to Late Actions',
          value: accumulatedExtraSpent,
        },
      ],
    });
  });
  return accumulatedSailCosts;
}
