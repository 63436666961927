import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function isSamePasswordValidator(): ValidatorFn {
  return (form: AbstractControl): ValidationErrors | null => {
    const password = form.get('password')?.value;
    const confirmedPassword = form.get('confirmPassword')?.value;

    if (password && confirmedPassword && password === confirmedPassword)
      return null;

    return {
      passwordsNotSame: true,
    };
  };
}
