<div class="container">
  <div class="form-container">
    <div class="logo-container">
      <img class="logo" src="./assets/icons/logo_auth.svg" />
      <h2>ORCA AI</h2>
    </div>
    <form class="form" [formGroup]="newPasswordForm" autocomplete="off">
      <h2>Create a new password</h2>
      <mat-form-field appearance="outline" class="primary-outline">
        <mat-label>Password</mat-label>
        <input matInput formControlName="password" #password type="password" />

        <mat-icon matSuffix appTogglePasswordVisibility [inputRef]="password"
          >visibility_off</mat-icon
        >
      </mat-form-field>
      <mat-form-field appearance="outline" class="primary-outline">
        <mat-label>Confirm Password</mat-label>
        <input
          matInput
          type="password"
          formControlName="confirmPassword"
          #confirmPassword
          [errorStateMatcher]="formErrorMatcher" />
        <mat-icon
          matSuffix
          appTogglePasswordVisibility
          [inputRef]="confirmPassword"
          >visibility_off</mat-icon
        >
        <mat-error *ngIf="newPasswordForm.hasError('passwordsNotSame')">{{
          'Passwords does not match'
        }}</mat-error>
      </mat-form-field>

      <div class="btn-container">
        <button
          data-test="save-new-password-btn"
          class="stay-primary-on-disabled btn"
          mat-flat-button
          color="primary"
          (click)="savePassword()"
          [disabled]="!newPasswordForm.valid">
          Save new password
        </button>
      </div>
    </form>
  </div>
</div>
