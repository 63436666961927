import { Observable, Subject, combineLatest, map, takeUntil } from "rxjs";

export type Node<T> = {
    item: T,
    children: Node<T>[]
}

function node<T>(item: T, children: Node<T>[]): Node<T> {
    return {
        item, 
        children
    }    
}


type Operator<T, K> = (source$: Observable<T[]>) => Observable<K[]>;

function _recCombineTree<T>(source$: Observable<T[]>, childSelector: (t: T) => Observable<T[]>): Observable<Node<T>[]> {
    return new Observable<Node<T>[]>(observer => {
        const destroyed$ = new Subject<void>();

        source$.pipe(takeUntil(destroyed$)).subscribe(ts => {
            if (ts.length === 0) {
                observer.next([]);
            } else {
                const many = ts.map(t => 
                    _recCombineTree(childSelector(t), childSelector).pipe(map(nodes => node(t, nodes))));

                const combined = combineLatest(many);
                combined.pipe(takeUntil(destroyed$)).subscribe(observer);
            }
        })
    
        return () => {
            destroyed$.next();
            destroyed$.complete();
        }
    });
}

function _recTransform<T, K>(nodes: Node<T>[], projection: (t: T, children: K[]) => K): K[] {
    if (nodes.length === 0) return [];

    return nodes.map(node => {
        const childNodes = _recTransform(node.children, projection);
        return projection(node.item, childNodes);
    })
}

export function combineTree<T>(childrenSelector: (t: T) => Observable<T[]>): Operator<T, Node<T>>
export function combineTree<T, K>(childrenSelector: (t: T) => Observable<T[]>, projection?: (t: T, children: K[]) => K): Operator<T, K> {

    if (projection === undefined) {
        projection = (t, children: K[]) => ({
            item: t, 
            children
        }) as unknown as K;
    }

    const res =  (source$: Observable<T[]>) => _recCombineTree(source$, childrenSelector).pipe(
        map(nodes => _recTransform(nodes, projection!))
    )
    return res;
}

