import { createReducer, on } from '@ngrx/store';
import { defaultShipLiveStream } from '../../models/ship.model';
import { initialShipsState, ShipsState } from '../state/ships.state';
import { EventsActions, OverviewActions, ShipsActions } from '../types';

export const shipsStateKey = 'ships';
export const shipsReducer = createReducer(
  initialShipsState,
  on(
    ShipsActions.saveShipsSeverityOnAuth,
    (state, action): ShipsState => ({
      ...state,
      shipsSeverityScoreByDate: { ...action.ships },
    })
  ),
  on(
    ShipsActions.saveHighestSafteyScoreShipsOnAuth,
    (state, action): ShipsState => ({
      ...state,
      shipsSafetyScoreData: {
        ...state.shipsSafetyScoreData,
        shipsSafetyScore: action.ships,
      },
    })
  ),
  on(
    ShipsActions.updateShipsSeverityOnRangeChange,
    (state, action): ShipsState => ({
      ...state,
      shipsSeverityScoreByDate: { ...action.ships },
    })
  ),
  on(
    ShipsActions.updateShipsSeverityOnFleetChange,
    (state, action): ShipsState => ({
      ...state,
      shipsSeverityScoreByDate: { ...action.ships },
    })
  ),
  on(
    ShipsActions.updateHighestSafteyScoreShipsOnRangeChange,
    (state, action): ShipsState => ({
      ...state,
      shipsSafetyScoreData: {
        ...state.shipsSafetyScoreData,
        shipsSafetyScore: action.ships,
      },
    })
  ),
  on(
    ShipsActions.updateHighestSafteyScoreShipsOnFleetChange,
    (state, action): ShipsState => ({
      ...state,
      shipsSafetyScoreData: {
        ...state.shipsSafetyScoreData,
        shipsSafetyScore: action.ships,
      },
    })
  ),
  on(
    ShipsActions.saveSortByOptionOnUserChange,
    (state, action): ShipsState => ({
      ...state,
      shipsSafetyScoreData: {
        ...state.shipsSafetyScoreData,
        sortby: action.sortBy,
      },
    })
  ),
  on(
    ShipsActions.saveSortedShipsScoreOnUserChange,
    (state, action): ShipsState => ({
      ...state,
      shipsSafetyScoreData: {
        ...state.shipsSafetyScoreData,
        shipsSafetyScore: action.ships,
      },
    })
  ),
  on(
    ShipsActions.saveShipsOnAdminUserAuth,
    (state, action): ShipsState => ({
      ...state,
      ships: [...action.ships],
    })
  ),
  on(
    ShipsActions.saveShipsOnRegularUserAuth,
    (state, action): ShipsState => ({
      ...state,
      ships: [...action.ships],
    })
  ),
  on(
    ShipsActions.setSelectedShipIdOnShipListClick,
    (state, action): ShipsState => ({
      ...state,
      selectedShipId: action.shipId,
    })
  ),
  on(
    ShipsActions.setSelectedShipIdOnShipMapClick,
    (state, action): ShipsState => ({
      ...state,
      selectedShipId: action.shipId,
    })
  ),
  on(
    ShipsActions.setSelectedShipIdOnViewShipProfile,
    (state, action): ShipsState => ({
      ...state,
      selectedShipId: action.shipId,
    })
  ),
  on(
    ShipsActions.setSelectedShipIdOnViewRtEvent,
    (state, action): ShipsState => ({
      ...state,
      selectedShipId: action.shipId,
    })
  ),
  on(
    ShipsActions.setShipSafetyScoresOnShipListClick,
    (state, action): ShipsState => ({
      ...state,
      selectedShipSafetyScores: action.shipSafetyScores.safetyScores,
    })
  ),
  on(
    ShipsActions.setShipSafetyScoresOnDateRangeChanged,
    (state, action): ShipsState => ({
      ...state,
      selectedShipSafetyScores: action.shipSafetyScores.safetyScores,
    })
  ),
  on(
    ShipsActions.updateShipsOnDateRangeChange,
    (state, action): ShipsState => ({
      ...state,
      ships: [...action.ships],
    })
  ),
  on(
    ShipsActions.updateShipConnectionStatus,
    (state, action): ShipsState => ({
      ...state,
      shipLiveStream: {
        ...state.shipLiveStream,
        ...action.shipConnection,
      },
    })
  ),
  on(
    ShipsActions.closeShipConnectionStatus,
    (state, action): ShipsState => ({
      ...state,
      shipLiveStream: { ...defaultShipLiveStream },
    })
  ),
  on(
    ShipsActions.updateShipCameras,
    (state, action): ShipsState => ({
      ...state,
      shipLiveStream: {
        ...state.shipLiveStream,
        cameras: action.cameras,
      },
    })
  ),
  on(
    ShipsActions.updateIsShipStreamMaster,
    (state, action): ShipsState => ({
      ...state,
      shipLiveStream: {
        ...state.shipLiveStream,
        isStreamMaster: action.isStreamMaster,
      },
    })
  ),
  on(
    ShipsActions.updateShipStream,
    (state, action): ShipsState => ({
      ...state,
      shipLiveStream: {
        ...state.shipLiveStream,
        ...action.shipStream,
      },
    })
  ),
  on(
    ShipsActions.resetSelectedShipIdOnFleetChange,
    (state): ShipsState => ({
      ...state,
      selectedShipId: null,
    })
  ),
  on(
    ShipsActions.closePollingLiveStream,
    (state, action): ShipsState => ({
      ...state,
      shipLiveStream: {
        ...state.shipLiveStream,
        ...action.shipDialogDef,
      },
    })
  ),
  on(
    ShipsActions.updateSelectedShipSailData,
    (state, action): ShipsState => ({
      ...state,
      selectedShipSailData: [...action.sailData],
    })
  ),
  on(
    ShipsActions.updateSelectedShipSailDataOnNavigation,
    (state, action): ShipsState => ({
      ...state,
      selectedShipSailData: [...action.sailData],
    })
  ),
  on(
    ShipsActions.updateSelectedShipEventCountData,
    (state, action): ShipsState => ({
      ...state,
      selectedShipEventCountData: action.shipEventCountData,
    })
  ),
  on(
    ShipsActions.updateShipsScoringFactorsStatistics,
    (state, action): ShipsState => ({
      ...state,
      shipsScoreExplaination: action.shipsScoreStats,
    })
  ),
  on(
    EventsActions.updateRTEvents,
    (state, { rtEvents }): ShipsState => ({
      ...state,
      rtEvents,
    })
  ),
  on(
    ShipsActions.updateScreenshots,
    (state, { screenshots }): ShipsState => ({
      ...state,
      screenshots: screenshots,
    })
  ),
  on(
    ShipsActions.updateShipRealTimeData,
    (state, { shipRealTimeData }): ShipsState => ({
      ...state,
      shipRealTimeData: shipRealTimeData,
    })
  ),
  on(
    ShipsActions.updateLatestShipImu,
    (state, { shipPitchRoll }): ShipsState => ({
      ...state,
      shipPitchRoll: shipPitchRoll,
    })
  ),
  on(
    ShipsActions.updateShipLiveData,
    (state, { liveShipData }): ShipsState => ({
      ...state,
      liveShipData,
    })
  ),
  on(
    OverviewActions.setSelectedShipIdOnShipProfileClickOnMap,
    (state, action): ShipsState => ({
      ...state,
      selectedShipId: action.shipId,
    })
  )
);
