import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  MatCheckboxChange,
  MatCheckboxModule,
} from '@angular/material/checkbox';

import {
  areaLayersOptions,
  eventLayersOptions,
  MapLayerChange,
  MapSettingsOption,
} from 'src/app/map/models/map.entity.model';
import { MapLayersMenuService } from 'src/app/map/services/map-layers-menu.service';
import { MenuButtonComponent } from '../menu-button/menu-button.component';

@Component({
  selector: 'app-map-layers-menu',
  standalone: true,
  imports: [CommonModule, FormsModule, MatCheckboxModule, MenuButtonComponent],
  templateUrl: './map-layers-menu.component.html',
  styleUrls: ['./map-layers-menu.component.scss'],
})
export class MapLayersMenuComponent {
  @Input() initialEnabledLayers!: MapSettingsOption[];

  eventLayersOptions = eventLayersOptions;
  areaLayersOptions = areaLayersOptions;
  constructor(private mapLayersMenuService: MapLayersMenuService) {}

  updateSettings(event: MatCheckboxChange, layerOption: string) {
    const layerChange: MapLayerChange = {
      isChecked: event.checked,
      layerOption: layerOption as MapSettingsOption,
    };
    this.mapLayersMenuService.emitSettingsChanged(layerChange);
  }
}
