<div class="container">
  <div class="title">
    <span class="body1">{{ title }}</span>
    <span class="body1 clear-btn" (click)="clear()">Clear</span>
  </div>
  <div class="custom-slider">
    <ngx-slider
      [value]="customSliderOptions.lowRangeValue"
      (userChangeEnd)="onRangeChange($event)"
      [highValue]="customSliderOptions.highRangeValue"
      [options]="customSliderOptions.ngxSliderOptions"></ngx-slider>
  </div>
</div>
