import {
  CommentsUser,
  IComment,
} from 'src/app/shared/view-models/comments.view.model';

export interface CommentsState {
  readonly comments: IComment[];
  readonly commentsUsers: CommentsUser[];
}

export const initialCommentsState: CommentsState = {
  comments: [],
  commentsUsers: [],
};
