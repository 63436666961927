import { createReducer, on } from '@ngrx/store';
import { defaultOverviewEventsData } from '../../models/overview.models';
import { initialOverviewState, OverviewState } from '../state/overview.state';
import { OverviewActions } from '../types';

export const overviewStateKey = 'overview';
export const overviewReducer = createReducer(
  initialOverviewState,
  on(
    OverviewActions.saveOverviewInsightsOnChange,
    (state, action): OverviewState => ({
      ...state,
      insights: action.insights.map((insight, index) => {
        if (index === 0) {
          return { ...insight, readStatus: true };
        }
        return insight;
      }),
    })
  ),
  on(
    OverviewActions.updateVoteForInsight,
    (state, action): OverviewState => ({
      ...state,
      insights: state.insights.map(insight =>
        insight.insightId === action.insightVote.insightId
          ? { ...insight, likeVote: action.insightVote.likeVote }
          : insight
      ),
    })
  ),
  on(
    OverviewActions.updateReadStatusForInsight,
    (state, action): OverviewState => ({
      ...state,
      insights: state.insights.map(insight =>
        insight.insightId === action.insightReadId
          ? { ...insight, readStatus: true }
          : insight
      ),
    })
  ),
  on(
    OverviewActions.saveOverviewEventsLocationOnChange,
    (state, action): OverviewState => ({
      ...state,
      eventsLocation: action.eventsLocation,
    })
  ),
  on(
    OverviewActions.setSelectedShipOnShipMapClick,
    (state, action): OverviewState => ({
      ...state,
      selectedShip: action.selectedShip,
    })
  ),
  on(
    OverviewActions.resetSelectedShipDataOnShipsChange,
    (state, _): OverviewState => ({
      ...state,
      selectedShip: null,
      selectedShipEventsData: defaultOverviewEventsData,
      selectedShipLastLocations: [],
      selectedShipSafetyScores: [],
    })
  ),
  on(
    OverviewActions.saveSelectedShipEventsData,
    (state, action): OverviewState => ({
      ...state,
      selectedShipEventsData: {
        totalCount: action.selectedShipEventsData.totalCount,
        events: action.selectedShipEventsData.events,
        pageNumber: 1,
        scrollToTop: true,
      },
    })
  ),
  on(
    OverviewActions.setSelectedOverviewEventList,
    (state, action): OverviewState => ({
      ...state,
      selectedEvent: { ...action.selectedEvent },
    })
  ),
  on(
    OverviewActions.setSelectedOverviewMapEvent,
    (state, action): OverviewState => ({
      ...state,
      selectedEvent: { ...action.selectedEvent },
    })
  ),
  on(
    OverviewActions.getMoreEventsOnUserScroll,
    (state, action): OverviewState => ({
      ...state,
      selectedShipEventsData: {
        ...state.selectedShipEventsData,
        pageNumber: state.selectedShipEventsData.pageNumber! + 1,
      },
    })
  ),
  on(
    OverviewActions.addMoreEventsAfterScroll,
    (state, action): OverviewState => ({
      ...state,
      selectedShipEventsData: {
        ...state.selectedShipEventsData,
        events: [...state.selectedShipEventsData.events!, ...action.events],
      },
    })
  ),
  on(
    OverviewActions.reachedEndOfEvents,
    (state, action): OverviewState => ({
      ...state,
      selectedShipEventsData: {
        ...state.selectedShipEventsData,
        pageNumber: state.selectedShipEventsData.pageNumber! - 1,
      },
    })
  ),

  on(
    OverviewActions.resetPagination,
    (state, action): OverviewState => ({
      ...state,
      selectedShipEventsData: {
        ...state.selectedShipEventsData,
        pageNumber: 1,
      },
    })
  ),
  on(
    OverviewActions.resetScroll,
    (state, action): OverviewState => ({
      ...state,
      selectedShipEventsData: {
        ...state.selectedShipEventsData,
        scrollToTop: true,
      },
    })
  ),
  on(
    OverviewActions.updateScrollToTopByUser,
    (state, action): OverviewState => ({
      ...state,
      selectedShipEventsData: {
        ...state.selectedShipEventsData,
        scrollToTop: false,
      },
    })
  ),
  on(
    OverviewActions.resetSelectedShipIdOnCloseTooltip,
    (state, action): OverviewState => ({
      ...state,
      selectedEvent: null,
      selectedShip: null,
      selectedShipEventsData: defaultOverviewEventsData,
      selectedShipLastLocations: [],
    })
  ),
  on(
    OverviewActions.saveOverviewShipLastLocations,
    (state, action): OverviewState => ({
      ...state,
      selectedShipLastLocations: action.selectedShipLastLocations,
    })
  ),
  on(
    OverviewActions.setShipSafetyScoresOnShipMapClick,
    (state, action): OverviewState => ({
      ...state,
      selectedShipSafetyScores: action.shipSafetyScores.safetyScores,
    })
  ),
  on(
    OverviewActions.updateMapPositionOnSelectEntity,
    (state, action): OverviewState => ({
      ...state,
      mapPosition: action.position,
    })
  ),
  on(
    OverviewActions.saveOverviewPolygons,
    (state, action): OverviewState => ({
      ...state,
      polygons: action.polygons,
    })
  )
);
