import { LegendColors } from '../models/map.entity.model';

export const WindLegendColors: LegendColors = [
  { value: 0, color: 'transparent' },
  { value: 0.5, color: '#5579a9' },
  { value: 1, color: '#4d8fc1' },
  { value: 1.5, color: '#4da3d3' },
  { value: 2, color: '#4db9d8' },
  { value: 3, color: '#65c8cb' },
  { value: 4, color: '#4dacb5' },
  { value: 5, color: '#4d9B99' },
  { value: 6, color: '#4f9175' },
  { value: 7, color: '#5caa76' },
  { value: 8, color: '#75bc7e' },
  { value: 10, color: '#b0d292' },
  { value: 12, color: '#ccdc85' },
  { value: 14, color: '#e8e55d' },
  { value: 16, color: '#fdf483' },
  { value: 18, color: '#fdf4cb' },
  { value: 20, color: '#fcdca9' },
  { value: 25, color: '#f5cf61' },
  { value: 30, color: '#f19c51' },
  { value: 35, color: '#ee8659' },
  { value: 45, color: '#ec6e5f' },
  { value: 55, color: '#e8515f' },
  { value: 65, color: '#d5515f' },
  { value: 75, color: '#b95d63' },
  { value: 90, color: '#a16363' },
  { value: 105, color: '#896b68' },
];

export const WaveLegendColors: LegendColors = [
  { value: 0, color: 'transparent' },
  { value: 0.3, color: '#dde3ed' },
  { value: 0.5, color: '#c4d7e9' },
  { value: 1, color: '#a5c2e2' },
  { value: 1.5, color: '#88dcc6' },
  { value: 2, color: '#a5f5ae' },
  { value: 2.5, color: '#efe976' },
  { value: 3, color: '#efc177' },
  { value: 4, color: '#e8a07d' },
  { value: 5, color: '#ed7579' },
  { value: 6, color: '#d2777c' },
  { value: 7, color: '#d776e1' },
  { value: 8, color: '#b276c5' },
  { value: 9, color: '#d9dfe3' },
  { value: 10, color: '#bcc0c3' },
  { value: 11, color: '#969b9f' },
];

export const CurrentsLegendColors: LegendColors = [
  { value: 0, color: 'transparent' },
  { value: 0.1, color: '#0C6BEB' },
  { value: 0.2, color: '#5591ED' },
  { value: 0.4, color: '#639DEB' },
  { value: 0.6, color: '#6BA9E9' },
  { value: 0.8, color: '#71B6E5' },
  { value: 1, color: '#75C3E3' },
  { value: 1.2, color: '#76D0E0' },
  { value: 1.4, color: '#75DDDD' },
  { value: 1.6, color: '#96E1D2' },
  { value: 1.9, color: '#ADE6C9' },
  { value: 2.2, color: '#C1EBBE' },
  { value: 2.5, color: '#D2F0B3' },
  { value: 2.8, color: '#E2F5A8' },
  { value: 3.2, color: '#F1FA9B' },
  { value: 3.8, color: '#FFFF8E' },
];

export const HumidityLegendColors: LegendColors = [
  { value: 0, color: 'transparent' },
  { value: 0.1, color: '#FBFBF2' },
  { value: 5, color: '#ECF4D3' },
  { value: 10, color: '#DBF1BD' },
  { value: 15, color: '#CAF0B1' },
  { value: 20, color: '#AEEDA1' },
  { value: 25, color: '#96EB9C' },
  { value: 30, color: '#8EEC9F' },
  { value: 35, color: '#84F0AA' },
  { value: 40, color: '#79EDB9' },
  { value: 45, color: '#6EE5C8' },
  { value: 50, color: '#62E2D7' },
  { value: 55, color: '#5AE0E0' },
  { value: 60, color: '#54CCDD' },
  { value: 65, color: '#49B2D8' },
  { value: 70, color: '#3F9FD6' },
  { value: 75, color: '#3A8AE0' },
  { value: 80, color: '#3C7AD6' },
  { value: 85, color: '#3E6AC6' },
  { value: 90, color: '#4660B2' },
  { value: 95, color: '#4B60A5' },
  { value: 100, color: '#4B5A91' },
];

export const TemperatureLegendColors: LegendColors = [
  { value: -10.0, color: '#348CED' },
  { value: -8.0, color: '#44B1F6' },
  { value: -6.0, color: '#51CBFA' },
  { value: -4.0, color: '#80E0F7' },
  { value: -2.0, color: '#A0EAF7' },
  { value: 0.0, color: '#00EF7C' },
  { value: 2.0, color: '#00E452' },
  { value: 4.0, color: '#00C848' },
  { value: 6.0, color: '#10B87A' },
  { value: 8.0, color: '#297B5D' },
  { value: 10.0, color: '#007229' },
  { value: 12.0, color: '#3CA12C' },
  { value: 14.0, color: '#79D030' },
  { value: 16.0, color: '#B5FF33' },
  { value: 18.0, color: '#D8F7A1' },
  { value: 20.0, color: '#FFF600' },
  { value: 22.0, color: '#F8DF0B' },
  { value: 24.0, color: '#FDCA0C' },
  { value: 26.0, color: '#FCAC05' },
  { value: 28.0, color: '#F88D00' },
  { value: 30.0, color: '#FF6600' },
  { value: 32.0, color: '#FC4F00' },
  { value: 34.0, color: '#FF0100' },
  { value: 36.0, color: '#F31A00' },
  { value: 38.0, color: '#F31861' },
  { value: 40.0, color: '#F316C2' },
  { value: 42.0, color: '#D40F8C' },
  { value: 44.0, color: '#B40957' },
  { value: 46.0, color: '#950221' },
];

export const CloudsLegendColors: LegendColors = [
  { value: 0.25, color: '#85F7F4' },
  { value: 0.5, color: '#6994FC' },
  { value: 1.0, color: '#5A7BF8' },
  { value: 1.5, color: '#017CFE' },
  { value: 2.0, color: '#0268D5' },
  { value: 3.0, color: '#039787' },
  { value: 5.0, color: '#02C621' },
  { value: 7.0, color: '#AEFF03' },
  { value: 10.0, color: '#DAFF35' },
  { value: 15.0, color: '#FFAD02' },
  { value: 20.0, color: '#FF6101' },
  { value: 25.0, color: '#FC3C03' },
  { value: 30.0, color: '#FB1403' },
];
