import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, firstValueFrom, switchMap } from 'rxjs';
import { ShipSailData } from '../models/ship.model';
import { ShipsService } from '../services/ships.service';
import { CoreState } from '../store/state/core.state';
import { AuthenticationSelectors, ShipsActions } from '../store/types';

@Injectable({
  providedIn: 'root',
})
export class ShipSailDataResolver implements Resolve<Promise<ShipSailData[]>> {
  /**
   *
   */
  constructor(
    private http: HttpClient,
    private store: Store<CoreState>,
    private shipService: ShipsService,
    private router: Router
  ) {}
  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<ShipSailData[]> {
    const shipId = parseInt(route.params['shipId']);

    if (!isNaN(shipId)) {
      const sailData = await firstValueFrom(
        this.store.select(AuthenticationSelectors.selectAuthenticatedUser).pipe(
          filter(authUser => authUser?.token != ''),
          switchMap(_ => this.shipService.getShipSailData$(shipId, 90))
        )
      );

      this.store.dispatch(
        ShipsActions.updateSelectedShipSailDataOnNavigation({ sailData })
      );
      return sailData;
    } else {
      this.router.navigateByUrl('/private/overview');
      return [];
    }
  }
}
