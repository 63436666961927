<div class="container">
  <div class="header">
    <span class="title">{{ titleText }}</span>
    <span *ngIf="showDisclaimer" class="disclaimer caption">
      {{ disclaimer }}
    </span>
    <mat-divider></mat-divider>
  </div>
  <div class="text-explaination caption" *ngIf="textExplaination !== null">
    {{ textExplaination }}
  </div>
  <div class="score-bar">
    <app-string-score-widget
      [scoreDetail]="scores"
      [score]="round(score)"></app-string-score-widget>
  </div>
  <div class="data-container">
    <div class="data" *ngIf="scoreExplainations$ | async">
      <div
        class="explaination"
        *ngFor="
          let scoreExplaination of sortScoreExplaination(
            (scoreExplainations$ | async)!
          )
        ">
        <div class="value-description-container">
          <div class="value-container">
            <h4 class="regular value">{{ scoreExplaination.value }}</h4>
          </div>
          <div class="description-container">
            <div class="caption description">
              {{ scoreExplaination.description }}
            </div>
          </div>
        </div>
        <div
          *ngIf="scoreExplaination.label !== 'neutral'"
          class="label-container">
          <div class="caption label {{ scoreExplaination.label }}">
            {{ scoreExplaination.label | underscoreToDisplayedString }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="information">
    <a class="info-link button-text">Learn how to improve</a>
  </div> -->
</div>
