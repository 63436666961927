import { Injectable, OnDestroy, Optional } from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable()
export class ValueAccessorProxy<T> implements ControlValueAccessor, OnDestroy {
    constructor(@Optional() ngControl: NgControl | null){
        if (ngControl) {
            ngControl.valueAccessor = this;
        }
    }
    ngOnDestroy(): void {
        this._value$.complete();
        this._disabled$.complete();
    }

    private _value$ = new Subject<T>();
    value$ = this._value$.asObservable();
    writeValue(obj: T): void {
        this._value$.next(obj);
    }


    private valueCallback: (val: T) => void = _ => {};
    registerOnChange(fn: (val: T) => void): void {
        this.valueCallback = fn;
    }
    valueChanged(val: T) {
        this.valueCallback(val);
    }

    private touchCallback = () => {};
    private touched = false;
    registerOnTouched(fn: () => void): void {
        this.touchCallback = fn;
    }
    touch() {
        if (!this.touched) {
            this.touched = true;
            this.touchCallback();
        }
    }


    private _disabled$ = new BehaviorSubject<boolean>(false);
    disabled$ = this._disabled$.asObservable();
    setDisabledState?(isDisabled: boolean): void {
        this._disabled$.next(isDisabled);
    }

}