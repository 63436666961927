<div class="title-container">
  <div class="title">
    <div
      matBadge="{{ unreadInsightsCount$ | async }}"
      matBadgeSize="small"
      matBadgeColor="warn"
      [matBadgeHidden]="(unreadInsightsCount$ | async)! < 1">
      <img class="img" src="/assets/icons/unread_notification.svg" />
    </div>
    <div class="text">
      <div class="insights">Insights</div>
    </div>
  </div>
  <app-new-badge></app-new-badge>
</div>
