import {
  DateFilter,
  dateFilters,
} from 'src/app/shared/view-models/dates.view.model';

export interface FiltersState {
  readonly dateFilter: Partial<DateFilter>;
  readonly isDateFilterDisabled: boolean;
  readonly isCustomDateOptionDisabled: boolean;
}

export const initialFiltersState: FiltersState = {
  dateFilter: { ['Last 30 days']: dateFilters['Last 30 days'] },
  isDateFilterDisabled: false,
  isCustomDateOptionDisabled: false,
};
