import { createAction, props } from '@ngrx/store';
import { SortDetails } from '../../../shared/components/table/models/sort-details.model';
import {
  KpisData,
  SailFuelExpenditureGraphData,
  ShipEfficiency,
  TimeFilter,
} from '../../models/efficiency.models';

export const getShipsEfficiencyData = createAction(
  '[Ships Efficiency] Get kpi and ships data'
);

export const saveShipsEfficiencyData = createAction(
  '[Ships Efficiency] Save kpi and ships data',
  props<{ kpisData: KpisData; shipsEfficiency: ShipEfficiency[] }>()
);
export const saveFuelCostData = createAction(
  '[Ships Efficiency] Save fuel cost data',
  props<{ sailFuelExpenditures: SailFuelExpenditureGraphData[] }>()
);

export const saveInefficientEventData = createAction(
  '[Ships Efficiency] Save inefficient event data',
  props<{ inefficientEventPerShip: { [key: number]: string } }>()
);

export const changeSailFuelExpendituresTimeFilter = createAction(
  '[Ships Efficiency] Sail fuel expenditures time filter is change',
  props<{ sailFuelExpendituresTimeFilter: TimeFilter }>()
);

export const getFuelCostData = createAction(
  '[Ships Efficiency] Get fuel cost data'
);

export const enterEfficiencyPage = createAction(
  '[Ships Efficiency] Enter Efficiency Page'
);

export const leaveEfficiencyPage = createAction(
  '[Ships Efficiency] Leave Efficiency Page'
);

export const highlightShipEfficiencySelected = createAction(
  '[Ships Efficiency] highlight of ship efficiency is clicked',
  props<{
    fleetName: string;
    shipName: string;
    extraCostOrSavings: number;
    extraSailedValue: number;
    extraSailedPercentage: number;
    extraEmissionValue: number;
  }>()
);

export const sortShipsEfficiency = createAction(
  '[Ships Efficiency] sort ships efficiency',
  props<{
    sortDetails: SortDetails;
  }>()
);
