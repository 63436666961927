<app-no-data [dataExists]="dataExists$ | async">
  <div class="container">
    <div class="header">
      <div class="title">
        <app-widget-title
          [widgetTitleType]="'basic'"
          [title]="'Safety Score By Date'"></app-widget-title>
        <span class="body1 clear" (click)="clearEventsDateFilter()">Clear</span>
      </div>
      <div
        class="day-filter"
        [ngClass]="{ hide: (showEventsDayFilterTitle$ | async) === false }">
        <div class="score">
          <h2 class="regular">
            {{ eventDayFilterScore$ | async | number: '1.0-0' }}
          </h2>
          <span class="subtitle1">/10</span>
        </div>
        <span class="simple-dot"></span>
        <span class="caption">{{
          eventsDateFilter$ | async | dateToDayMonthTime
        }}</span>
      </div>
    </div>
    <div class="graph-container">
      <ngx-charts-line-chart
        class="primary-line-chart"
        [results]="safetyScoreByDateView$ | async"
        [xAxis]="true"
        [yAxis]="false"
        [curve]="linearCurve"
        [tooltipDisabled]="false"
        [xAxisTickFormatting]="formatXLabel"
        [xAxisTicks]="(xAxisTicks$ | async)!"
        (select)="onEventDateFilter($event)">
        <ng-template
          #seriesTooltipTemplate
          #tooltipTemplate
          let-safetyScore="model">
          <div class="tooltip-container">
            <div class="score">
              <h2 class="regular">
                {{
                  safetyScore.value || safetyScore[0].value | number: '1.0-0'
                }}
              </h2>
              <span class="subtitle1">/10</span>
            </div>

            <caption>
              {{
                safetyScore.name || safetyScore[0].date | dateToDayMonthTime
              }}
            </caption>
          </div>
        </ng-template>
      </ngx-charts-line-chart>
    </div>
  </div>
</app-no-data>
