import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function isShipIdRequired(): ValidatorFn {
  return (form: AbstractControl): ValidationErrors | null => {
    const role = form.get('role')?.value;
    const shipId = form.get('shipId')?.value;
    const subFleetShipsId = form.get('subFleetShipsId')?.value;

    if (
      role &&
      ((role === 'ship-captain' && shipId == null) ||
        (role === 'sub-fleet-manager' &&
          (subFleetShipsId == null || subFleetShipsId.length === 0)))
    ) {
      return { required: true };
    }
    return null;
  };
}
