<div class="container">
  <div class="title">
    <div class="wrap-text">
      <h4>{{ data.name }}</h4>
      <app-red-rt-event-indication
        *ngIf="data.rtEventName"
        [rtEventName]="data.rtEventName"
        [rtEventTime]="data.rtEventTime"></app-red-rt-event-indication>
    </div>
    <button class="close" mat-button (click)="onNoClick()">
      <img class="icon" src="./assets/icons/exit.svg" />
    </button>
  </div>
  <div class="separator"></div>
  <div class="flexbox">
    <div class="wrap-text">
      <span class="subtitle">Location: {{ data.location }}</span>
      <span class="subtitle"
        >Received: {{ data.timestamp | dateToTimeAgo }}</span
      >
    </div>
    <app-live-stream-button
      class="live-stream-button"
      [shouldOpenDialog]="false"
      (click)="openLivestream(data.shipId)">
    </app-live-stream-button>
  </div>
  <img [src]="data.url" />
</div>
