import { Component, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { FeatureFlagService } from '../../services/feature-toggle.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit {
  showInsights!: boolean;

  constructor(private featureFlag: FeatureFlagService) {}

  async ngOnInit(): Promise<void> {
    this.showInsights = await firstValueFrom(
      this.featureFlag.getFeatureFlag$('insights')
    );
  }
}
