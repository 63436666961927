<div class="dialog-container">
  <div class="content">
    <div class="container">
      <img src="./assets/icons/lock_access.svg" alt="Lock Icon" />
      <div>
        <div class="title">Premium Feature</div>
        <div class="text-message">
          To gain access and unlock its capabilities, please contact your
          customer success representative
        </div>
      </div>
    </div>
  </div>
  <hr />
  <div class="footer">
    <div class="back-button" (click)="onBackClick()">Back</div>
  </div>
</div>
