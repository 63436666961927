export type DateFilterKeys =
  | 'Last 7 days'
  | 'Last 30 days'
  | 'Last 90 days'
  | 'Last Year'
  | 'Custom dates average';
export type DateFilter = {
  [key in DateFilterKeys]: Dates | undefined;
};

export const dateFilters: DateFilter = {
  'Last 7 days': {
    startDate: new Date(new Date().setDate(new Date().getDate() - 6)),
    endDate: new Date(),
  },
  'Last 30 days': {
    startDate: new Date(new Date().setDate(new Date().getDate() - 29)),
    endDate: new Date(),
  },
  'Last 90 days': {
    startDate: new Date(new Date().setDate(new Date().getDate() - 89)),
    endDate: new Date(),
  },
  'Last Year': {
    startDate: new Date(new Date().setDate(new Date().getDate() - 364)),
    endDate: new Date(),
  },
  // eslint-disable-next-line prettier/prettier
  'Custom dates average': undefined,
};

export interface Dates {
  readonly startDate: Date;
  readonly endDate: Date;
}
