export function tranformDateToDayMonth(date: Date | null): string {
  if (date === null || date === undefined) return '';
  const dateString = date.toDateString();
  const day = dateString.slice(8, 10);
  const month = dateString.slice(4, 7);
  return `${day} ${month}`;
}

export function transformDateToHourMinute(date: Date | null): string {
  if (date === null || date === undefined) return '';
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const hoursString = hours < 10 ? `0${hours}` : `${hours}`;
  const minutesString = minutes < 10 ? `0${minutes}` : `${minutes}`;
  return `${hoursString}:${minutesString}`;
}
