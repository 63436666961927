<div class="container">
  <ng-container *ngIf="eventOfSelectedShip$ | async as eventOfSelectedShip">
    <div *ngIf="eventOfSelectedShip.length" class="events">
      <div class="events-header" *ngIf="selectedShip$ | async as selectedShip">
        <span class="ship-name">{{ selectedShip.shipName }}</span>
        <span class="events-count"
          >{{ eventOfSelectedShip.length }} Events</span
        >
      </div>
      <div
        class="events-container"
        #eventsContainer
        appInfiniteScrolling
        (scrolled)="onEventListScrollToEnd()">
        <app-events-list
          [events]="eventOfSelectedShip"
          (eventClicked)="onSelectedEvent($event)"
          (eventExplorationClicked)="onEventExploration($event)"
          [selectedEvent]="selectedEvent$ | async"
          [showTargetEventData]="false"></app-events-list>
      </div>
    </div>
  </ng-container>
  <div class="map">
    <app-map
      [ships]="mapShipEntities$ | async"
      [showLayerControl]="true"
      [events]="mapEventEntities$ | async"
      [polygons]="(polygons$ | async)!"
      [shipNamelabels]="shipNamelabels$ | async"
      [rtEvents]="(rtEventlabels$ | async)!"
      [mapLayersOptionsChecked]="initialEnabledLayers"
      [tooltip]="mapTooltip$ | async"
      [shipLastLocations]="shipLastLocations$ | async"
      [position]="(mapPosition$ | async)!"
      [offsetLongPositionDestination]="offsetLongPositionDestination"
      [offsetLatPositionDestination]="100"
      [zoom]="'fit'"
      (selectedMapEvent)="onSelectedMapEntity($event)"
      (mapTooltipClosed)="onCloseTooltip()"
      (mapTooltipButtonClicked)="shipTooltipButtonClicked($event)">
      <ng-template #layerMenus>
        <app-map-layers-menu
          [initialEnabledLayers]="initialEnabledLayers"></app-map-layers-menu>
        <app-weather-menu></app-weather-menu>
      </ng-template>
    </app-map>
  </div>
</div>
