import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommentsUser } from 'src/app/shared/view-models/comments.view.model';

@Injectable({
  providedIn: 'root',
})
export class CommentService {
  parseMentions(
    content: string,
    users: CommentsUser[]
  ): Observable<{ parsedContent: string; mentionedIds: string[] }> {
    return of(users).pipe(
      map(users => {
        const mentionedIds: string[] = [];
        const parsedContent = content.replace(/@(\w+\s\w+)/g, (match, name) => {
          const user = users.find(u => `${u.firstName} ${u.lastName}` === name);
          if (user) {
            mentionedIds.push(user.id);
            return `@${name}`;
          }
          return match;
        });
        return { parsedContent, mentionedIds };
      })
    );
  }
}
