export function calculateAverage(array: number[]): number {
  if (array === null) return 0;
  const sum = array.reduce((a: number, b: number): number => a + b);
  return sum / array.length;
}

export function calculatePercentage(num: number, upperBound: number): number {
  if (num && upperBound && upperBound > 0) {
    return (Math.round(num) / upperBound) * 100;
  }
  return 0;
}

export function inThousands(num: number) {
  return num / 1000;
}

export const isWithinLastHours = (hours: number, date: Date | null) => {
  if (date === null) {
    return false;
  }
  const dateInMilliseconds = new Date(date).getTime();
  const currentDateInMilliseconds = new Date().getTime();
  const difference = currentDateInMilliseconds - dateInMilliseconds;
  return difference <= hours * 60 * 60 * 1000;
};
