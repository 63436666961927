<ng-template #defaultCellTemplate> cell </ng-template>
<ng-template #defaultHeaderTemplate let-header>
  {{ header }}
</ng-template>

<ng-container *ngIf="vm$ | async as vm">
  <div class="header-area">
    <div class="row">
      <div
        data-test="col-sort-btn"
        class="cell"
        *ngFor="let col of vm.columns; trackBy: trackByColId"
        [class.sortable]="col.sortable"
        [style.--width]="col.width"
        (click)="toggleSort(col)">
        <div class="header-content" data-test="header-content">
          <ng-container
            *ngTemplateOutlet="
              col.template;
              context: { $implicit: col.header }
            "></ng-container>
        </div>
        <div class="header-tools" data-test="sort-arrow-dir">
          <orc-sort-arrow [direction]="col.sortDirection"></orc-sort-arrow>
        </div>
      </div>
    </div>
  </div>
  <div class="data-area">
    <cdk-virtual-scroll-viewport itemSize="90" [scroll]="scrollNow$">
      <orc-data-row
        class="row"
        [row]="row"
        *cdkVirtualFor="let row of vm.rows; trackBy: trackByRowKey"
        [class.selected]="row.isSelected"
        (click)="toggleSelection(row.key)"
        (mouseenter)="onRowHover(row.key)"
        (mouseleave)="onRowHover(null)">
      </orc-data-row>
    </cdk-virtual-scroll-viewport>
  </div>
</ng-container>
