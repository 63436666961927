import {
  getTitleAndLinkDetails,
  insightTitleByType,
} from 'src/app/shared/models/InsightData.model';
import {
  InsightCard,
  InsightDataType,
} from 'src/app/shared/view-models/insight.view.model';
import { InsightDataDto } from '../models/insights.models';

export function mapInsightsDtoToInsights(
  insights: InsightDataDto[]
): InsightCard[] {
  return insights.map((insight: InsightDataDto) => {
    return {
      insightId: insight.insightId,
      title: insightTitleByType[insight.insightType],
      content: insight.insightData as InsightDataType,
      dateUpdated: new Date(insight.lastUpdatedAt),
      link: getTitleAndLinkDetails(insight.insightType),
      likeVote: insight.likeVote,
      readStatus: insight.readStatus,
    };
  });
}
