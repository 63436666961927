import { Locations, LocationsDto } from '../models/events-filters.model';

export function mapLocationsDtoToLocations(
  locationsDto: LocationsDto[]
): Locations[] {
  return locationsDto.map(
    (location): Locations => ({
      parentLocation: location.location,
      subLocations: [...location.sub_locations],
    })
  );
}
