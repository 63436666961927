<div class="container">
  <span class="body1 title">Severity</span>
  <div class="severity">
    <div class="severity-checkbox">
      <mat-checkbox
        color="primary"
        class="primary-outline"
        [checked]="highSeverityLevel$ | async"
        (change)="onSeverityChange($event.checked, 'high')"
        ><div class="severity-checkbox"></div>
      </mat-checkbox>
      <span class="body1 severity-box severity-high">High</span>
    </div>
    <div class="severity-checkbox">
      <mat-checkbox
        color="primary"
        class="primary-outline"
        [checked]="mediumSeverityLevel$ | async"
        (change)="onSeverityChange($event.checked, 'medium')"
        ><div class="severity-checkbox"></div>
      </mat-checkbox>
      <span class="body1 severity-box severity-medium">Medium</span>
    </div>

    <div class="severity-checkbox">
      <mat-checkbox
        color="primary"
        class="primary-outline"
        [checked]="lowSeverityLevel$ | async"
        (change)="onSeverityChange($event.checked, 'low')"
        ><div class="severity-checkbox"></div>
      </mat-checkbox>
      <span class="body1 severity-box severity-low">Low</span>
    </div>
  </div>
</div>
