import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { Moment } from 'moment';
import {
  DateFilter,
  DateFilterKeys,
  dateFilters,
  Dates,
} from '../../view-models/dates.view.model';
interface DatePickerForm {
  readonly startDate: FormControl<Date | null>;
  readonly endDate: FormControl<Date | null>;
}
@Component({
  selector: 'app-time-frame',
  templateUrl: './time-frame.component.html',
  styleUrls: ['./time-frame.component.scss'],
})
export class TimeFrameComponent {
  @Output()
  dateFilterChange = new EventEmitter<Partial<DateFilter>>();

  @Input()
  currentDateFilter!: DateFilterKeys;

  @Input()
  isDisabled!: boolean;

  @Input()
  isCustomDatesDisabled: boolean = false;

  maxDate = new Date();
  dateFilters = dateFilters;

  orderedDateFilters: DateFilterKeys[] = [
    'Last 7 days',
    'Last 30 days',
    'Last 90 days',
    'Last Year',
  ];

  datePickerRange = this.fb.group({
    startDate: [null as Moment | null, [Validators.required]],
    endDate: [null as Moment | null, [Validators.required]],
  });

  constructor(private fb: FormBuilder) {}

  onConstDatesSelected(event: MatRadioChange) {
    this.currentDateFilter = event.value as DateFilterKeys;
    const constDateFilter: Partial<DateFilter> = {
      [this.currentDateFilter]: this.dateFilters[this.currentDateFilter],
    };
    this.dateFilterChange.emit(constDateFilter);
  }

  onCustomDatesSelected() {
    if (this.datePickerRange.controls.endDate.valid) {
      this.currentDateFilter = 'Custom dates average';
      const customDates: Dates = {
        startDate: this.datePickerRange.value.startDate!.toDate(),
        endDate: this.datePickerRange.value.endDate!.toDate(),
      };
      const customDateFilter: Partial<DateFilter> = {
        [this.currentDateFilter]: { ...customDates },
      };

      this.dateFilterChange.emit(customDateFilter);
    }
  }
}
