import { MetricEvents } from './metrics.model';

export type DeltaTypes =
  | 'shipName'
  | 'severityLevels'
  | 'eventType'
  | 'congestionLevel'
  | 'locations'
  | 'ownSog'
  | 'inPort'
  | 'minDistance'
  | 'colregClassifications'
  | 'targetSog'
  | 'targetType'
  | 'minDepth'
  | 'roll'
  | 'pitch'
  | 'sogDiff'
  | 'rot';

export interface DeltaResponse {
  delta: {
    [key in DeltaTypes]: {
      newVal: number | string | Array<string> | boolean | null;
      oldVal: number | string | Array<string> | boolean | null;
    };
  };
}

export const DeltaTypesToMetricEvents: Record<DeltaTypes, MetricEvents> = {
  shipName: 'ShipFilterSelected',
  severityLevels: 'SeverityFilterSelected',
  eventType: 'EventTypeFilterSelected',
  congestionLevel: 'CongestionLevelFilterSelected',
  locations: 'LocationFilterSelected',
  ownSog: 'OwnSogFilterSelected',
  inPort: 'ShowEventsInPortsFilterSelected',
  minDistance: 'CloseEncounterMinDistFilterSelected',
  colregClassifications: 'ColregClassFilterSelected',
  targetSog: 'TargetSogFilterSelected',
  targetType: 'TargetTypeFilterSelected',
  minDepth: 'UKCFilterSelected',
  roll: 'RollFilterSelected',
  pitch: 'PitchFilterSelected',
  sogDiff: 'SpeedDropSOGDiffFilterSelected',
  rot: 'HighROTFilterSelected',
};
