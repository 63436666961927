import { CommonModule } from '@angular/common';
import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { CoreState } from 'src/app/core/store/state/core.state';
import { OverviewActions } from 'src/app/core/store/types';
import { InsightDataDirective } from '../../directives/insight-data.directive';
import {
  InsightCard,
  InsightDataType,
} from '../../view-models/insight.view.model';
import { LikeUnlikeComponent } from '../like-unlike/like-unlike.component';

@Component({
  selector: 'app-insight-card',
  standalone: true,
  imports: [CommonModule, LikeUnlikeComponent, RouterModule],
  templateUrl: './insight-card.component.html',
  styleUrls: ['./insight-card.component.scss'],
})
export class InsightCardComponent {
  @Input()
  insight!: InsightCard;

  @ContentChild(InsightDataDirective, { read: TemplateRef })
  insightData!: TemplateRef<InsightDataType> | null;

  constructor(private store: Store<CoreState>) {}

  updateStatusLike(statusLike: boolean): void {
    this.store.dispatch(
      OverviewActions.setVoteForInsight({
        insightId: this.insight.insightId,
        likeVote: statusLike,
      })
    );
  }
}
