import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-event-data',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './event-data.component.html',
  styleUrls: ['./event-data.component.scss'],
})
export class EventDataComponent {
  @Input() title!: string;
  @Input() value: string | undefined | null;
  @Input() violatedValue: boolean | 0 | 1 | undefined | null = false;
  @Input() unit: string | undefined;

  constructor() {}
}
