import { NgModule, Type } from '@angular/core';
import { MatTreeModule } from '@angular/material/tree';
import { TreeSelectComponent } from './components/tree-select/tree-select.component';
import { TreeOptionComponent } from './components/tree-select/tree-option/tree-option.component';
import { TreeContentComponent } from './components/tree-select/tree-content/tree-content.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { OnOffButtonComponent } from './components/on-off-button/on-off-button.component';
import { DestroyRef } from './services/destroy-ref.service';

const exportables: Type<any>[] = [
  TreeSelectComponent, 
  TreeOptionComponent, 
  TreeContentComponent, 
  OnOffButtonComponent
];


@NgModule({
  declarations: [  
    ...exportables, 
  ],
  imports: [
    CommonModule,
    MatTreeModule, 
    MatIconModule, 
    MatButtonModule, 
    MatCheckboxModule, 
    MatRippleModule
  ],
  exports: [
    ...exportables
  ],
})
export class OrcaLibModule {
}
