import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { concatMap, filter, firstValueFrom } from 'rxjs';
import { CoreState } from '../store/state/core.state';
import {
  AuthenticationSelectors,
  EventsActions,
  EventsSelectors,
} from '../store/types';

@Injectable({
  providedIn: 'root',
})
export class EventsResolver implements Resolve<void> {
  constructor(private store: Store<CoreState>) {}
  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<void> {
    const events = await firstValueFrom(
      this.store.select(AuthenticationSelectors.selectAuthenticatedUser).pipe(
        filter(authUser => authUser?.token != ''),
        concatMap(_ => this.store.select(EventsSelectors.selectEvents))
      )
    );

    if (events.length === 0) {
      this.store.dispatch(
        EventsActions.setSelectedEventOnOverviewResolver({
          selectedEvent: null,
        })
      );
    } else {
      const selectedEvent = await firstValueFrom(
        this.store.select(EventsSelectors.selectSelectedEvent)
      );

      if (
        !selectedEvent ||
        !events.find(event => event.eventId === selectedEvent?.eventId)
      ) {
        this.store.dispatch(
          EventsActions.setSelectedEventOnOverviewResolver({
            selectedEvent: events[0],
          })
        );
      }
    }
  }
}
