import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-new-badge',
  templateUrl: './new-badge.component.html',
  standalone: true,
  imports: [CommonModule],
  styleUrls: ['./new-badge.component.scss'],
})
export class NewBadgeComponent {
  constructor() {}
}
