<div class="container">
  <div class="title">
    <div class="wrap-text">
      <h4>Screenshot</h4>
    </div>
    <button class="close" mat-button (click)="onNoClick()">
      <img class="icon" src="./assets/icons/exit.svg" />
    </button>
  </div>
  <div class="separator"></div>
  <div class="flexbox">
    <div class="wrap-text">
      <span *ngIf="data.timestamp" class="subtitle"
        >Received: {{ data.timestamp | dateToTimeAgo }}</span
      >
    </div>
  </div>
  <img [src]="data.url" />
</div>
