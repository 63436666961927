import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-info-column',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './info-column.component.html',
  styleUrls: ['./info-column.component.scss'],
})
export class InfoColumnComponent {
  @Input() title!: string;
  @Input() value: string | undefined | null;

  constructor() {}
}
