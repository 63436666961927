import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, firstValueFrom, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  defaultFleet,
  Fleet,
  FleetDto,
  PolygonDto,
  polygonType,
  Ship,
  ShipDto,
  ShipLastLocationDto,
  ShipLastLocationsDto,
} from '../models/fleet.model';
import { Polygon, ShipLastLocation } from '../view-models/event.view.model';
import { Screenshot, ScreenshotDto } from '../view-models/gallery.view.model';
import { IsBusyService } from './is-busy.service';

@Injectable({
  providedIn: 'root',
})
export class FleetService {
  constructor(private http: HttpClient, private isBusy: IsBusyService) {}

  getFleets$() {
    return this.http.get<FleetDto[]>(environment.api.admin_fleets).pipe(
      map(fleetsDto => {
        const fleets = fleetsDto
          .map(fleetDto => {
            const fleet: Fleet = {
              companyName: fleetDto.fleet_name,
              fleetId: fleetDto.fleet_id,
            };
            return fleet;
          })
          .sort((fleetNameA, fleetNameB) =>
            fleetNameA.companyName.localeCompare(fleetNameB.companyName)
          );
        return [defaultFleet, ...fleets];
      })
    );
  }

  getShips$(fleetId: number): Promise<Ship[]> {
    return this.isBusy.add(() =>
      firstValueFrom(
        this.http
          .get<ShipDto[]>(environment.api.admin_ships, {
            params: { fleet_id: fleetId },
          })
          .pipe(
            map((ships): Ship[] =>
              ships.map(ship => ({
                shipId: ship.ship_id,
                shipName: ship.ship_name,
              }))
            ),
            catchError(_ => of([]))
          )
      )
    );
  }

  getGeoPolygons$(): Promise<Polygon[]> {
    return this.isBusy.add(() =>
      firstValueFrom(
        this.http.get<PolygonDto[]>(environment.api.shipsGeoPolygons).pipe(
          map((polygons): Polygon[] => {
            return polygons.map(polygon => ({
              polygonId: polygon.polygon_id,
              polygonArea: polygon.polygon_area,
              polygonType: polygon.polygon_type_name as polygonType,
              edges: polygon.edges
                ? polygon.edges
                    .replace('LINESTRING(', '')
                    .replace(')', '')
                    .split(',')
                    .map(pair => pair.split(' ').map(Number))
                : [],
            }));
          }),
          catchError(_ => of([]))
        )
      )
    );
  }

  getShipLastLocations$(
    shipId: number | undefined,
    endDate: Date
  ): Promise<ShipLastLocation[]> {
    return this.isBusy.add(() =>
      firstValueFrom(
        this.http
          .get<ShipLastLocationsDto>(
            `${environment.api.ships}/${shipId}/last_locations`,
            {
              params: {
                end_date: endDate.toISOString(),
                start_date: new Date(
                  endDate.getTime() - 24 * 60 * 60 * 1000
                ).toISOString(),
              },
            }
          )
          .pipe(
            map((shipLastLocations: ShipLastLocationsDto): ShipLastLocation[] =>
              shipLastLocations.locations.map(
                (lastLocation: ShipLastLocationDto) => {
                  const date = new Date(lastLocation.time);
                  const hours = date.getHours().toString().padStart(2, '0');
                  const minutes = date.getMinutes().toString().padStart(2, '0');
                  return {
                    latitude: lastLocation.latitude,
                    longitude: lastLocation.longitude,
                    sog: Math.floor(lastLocation.sog),
                    time: `${hours}:${minutes}`,
                  };
                }
              )
            ),
            catchError(_ => of([]))
          )
      )
    );
  }

  async getScreenshots(): Promise<Screenshot[]> {
    try {
      const screenshotDtos = await firstValueFrom(
        this.http.get<ScreenshotDto[]>(`${environment.api.shipsGallery}`)
      );
      const screenshots = screenshotDtos
        .filter(screenshotDto => screenshotDto.cdn_url !== 'null')
        .map((dto: ScreenshotDto) => ({
          shipId: dto.ship_id,
          shipName: dto.ship_name,
          timestamp: dto.timestamp,
          fleetId: dto.fleet_id,
          fleetName: dto.fleet_name,
          cdnUrl: dto.cdn_url,
          location: dto.textual_location,
        }))
        .sort((a, b) => a.shipName.localeCompare(b.shipName));
      return screenshots;
    } catch (error) {
      return [];
    }
  }
}
