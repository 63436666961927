import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { RedRTEventIndicationComponent } from 'src/app/core/components/red-rt-event-indication/red-rt-event-indication.component';
import { Ship } from 'src/app/core/models/ship.model';
import { Screenshot } from 'src/app/core/view-models/gallery.view.model';
import { DeltaRisksComponent } from 'src/app/shared/components/delta-risks/delta-risks.component';
import { LiveStreamButtonComponent } from 'src/app/shared/components/live-stream-button/live-stream-button.component';
import { DateToHoursMinutesAgoPipe } from 'src/app/shared/pipes/date-to-hours-minutes-ago.pipe';

@Component({
  selector: 'app-ship-profile-tooltip',
  standalone: true,
  imports: [
    CommonModule,
    LiveStreamButtonComponent,
    DateToHoursMinutesAgoPipe,
    DeltaRisksComponent,
    MatButtonModule,
    LazyLoadImageModule,
    MatIconModule,
    RedRTEventIndicationComponent,
  ],
  templateUrl: './ship-profile-tooltip.component.html',
  styleUrls: ['./ship-profile-tooltip.component.scss'],
})
export class ShipProfileTooltipComponent {
  @Input()
  shipData!: Ship | null;

  @Input()
  endDateAvgShipSafetyScore!: number | null;

  @Input()
  startDateAvgShipSafetyScore!: number | null;

  @Input()
  showLiveStreamFlag!: boolean | null;

  @Input()
  isLiveStreamOpen: boolean = false;

  @Input()
  screenshot!: Screenshot | null;

  @Input() isShipCaptain!: boolean;

  @Output()
  buttonClicked = new EventEmitter<string>();

  @Output()
  closeTooltipClicked = new EventEmitter();

  isConnectionWithinFourHours(): boolean {
    if (this.shipData && this.shipData.lastConnection) {
      const fourHoursAgo = new Date(new Date().getTime() - 4 * 60 * 60 * 1000);
      return this.shipData.lastConnection >= fourHoursAgo;
    }
    return false;
  }

  onButtonClicked(
    link: string,
    shipId?: number,
    isLiveStreamOpen: boolean = false
  ): void {
    let navigateUrl = `${link}${shipId}`;
    if (isLiveStreamOpen) {
      navigateUrl = navigateUrl + '?isLiveStreamOpen=true';
    }
    this.buttonClicked.emit(navigateUrl);
  }

  close(): void {
    this.closeTooltipClicked.emit();
  }
}
