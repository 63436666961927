import { InsightType } from 'src/app/core/models/insights.models';
import { InsightTypeFromat } from '../view-models/insight.view.model';

export interface InsightLink {
  text: string;
  linkTo: string;
}

export const insightTitleByType: Record<InsightType, string> = {
  ShipWithMostEventsInsight: 'Ship with most events per 1000NM',
  HighDuringLastWeekInsight: 'High Severity Events During Last Week',
};

const insightLinkMap: { [key in InsightType]: InsightLink } = {
  [InsightTypeFromat.ShipWithMostEventsInsight]: {
    text: 'Explore Ship Events',
    linkTo: '/private/events',
  },
  [InsightTypeFromat.HighDuringLastWeekInsight]: {
    text: 'Explore in the Events page',
    linkTo: '/private/events',
  },
};

export function getTitleAndLinkDetails(insightType: InsightType): InsightLink {
  return insightLinkMap[insightType] || { text: '', linkTo: '' };
}
