import { Pipe, PipeTransform } from '@angular/core';
import { tranformDateToDayMonth } from '../utils/date-transforms/dateToDayMonth';

@Pipe({
  name: 'dateToDayMonthTime',
})
export class DateToDayMonthTimePipe implements PipeTransform {
  transform(date: Date | null): string {
    return tranformDateToDayMonth(date);
  }
}
