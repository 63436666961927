<app-no-data
  *ngIf="screenshots$ | async as screenshots"
  [dataExists]="screenshots.length > 0">
  <div class="search-count-section">
    <div class="search-box">
      <mat-form-field appearance="outline" class="primary-outline">
        <mat-label>Ship name</mat-label>
        <input
          matInput
          [(ngModel)]="searchQuery"
          (input)="onSearchQueryChange()" />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
      <div
        class="ship-count"
        *ngIf="filteredScreenshots$ | async as filteredScreenshots">
        {{ filteredScreenshots.length }} ships
      </div>
    </div>
    <div class="flex-container">
      <a
        *ngIf="(hideNewTabButton$ | async) === false"
        class="flex-gap-container"
        [href]="this.newTabUrl"
        target="_blank">
        <img class="icon icon-height" [src]="'./assets/icons/new_tab.svg'" />
        <div class="colored-text">Open in a new tab</div>
      </a>
      <img
        class="icon"
        (click)="onLayoutClick($event)"
        [src]="
          showRightLayout
            ? './assets/icons/gallery_layout_right.svg'
            : './assets/icons/gallery_layout_left.svg'
        " />
    </div>
  </div>
  <div class="picture-container">
    <div
      *ngFor="let picture of filteredScreenshots$ | async"
      [class]="showRightLayout ? 'picture-item-four' : 'picture-item-two'"
      class="picture-item">
      <div
        class="image-container"
        (mouseover)="hover = true"
        (click)="openImageDialog(picture)">
        <img [lazyLoad]="picture.cdnUrl" [alt]="picture.shipName" />
        <span class="top-text">
          <div class="box-wrapper">
            <app-red-rt-event-indication
              (click)="$event.stopPropagation()"
              *ngIf="picture.rtEventName && (showFlag$ | async)"
              [rtEventName]="picture.rtEventName"
              [rtEventTime]="picture.rtEventTime"></app-red-rt-event-indication>
          </div>
        </span>
        <span class="body1-bold hover-text" *ngIf="hover">
          <div class="location-and-time">
            <span> Location: {{ picture.location }} </span>
            <span> Received: {{ picture.timestamp | dateToTimeAgo }} </span>
          </div>
          <app-live-stream-button
            class="live-stream-button"
            [shouldOpenDialog]="false"
            (click)="openLivestream(picture.shipId, $event)">
          </app-live-stream-button>
        </span>
      </div>
      <h5 (click)="openProfile(picture.shipId)" class="picture-name">
        {{ picture.shipName }}
      </h5>
    </div>
  </div>
</app-no-data>
