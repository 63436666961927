import { Directive, Input } from '@angular/core';
import { EventDataContext } from '../models/EventDataContext.model';
import { GeneralEventViewModel } from '../view-models/general.event.view.model';

@Directive({
  selector: 'ng-template[appEventData]',
})
export class EventDataDirective<TEvent extends GeneralEventViewModel> {
  @Input()
  event!: TEvent;

  static ngTemplateContextGuard<TEvent extends GeneralEventViewModel>(
    _: EventDataDirective<TEvent>,
    ctx: unknown
  ): ctx is EventDataContext<TEvent> {
    return true;
  }
}
