import { createReducer, on } from '@ngrx/store';
import { CommentsState, initialCommentsState } from '../state/comments.state';
import { CommentsActions } from '../types';

export const commentsStateKey = 'comments';

export const commentsReducer = createReducer(
  initialCommentsState,
  on(
    CommentsActions.setCommentsOfCurrentThreadInStore,
    (state, action): CommentsState => ({
      ...state,
      comments: [...action.comments],
    })
  ),
  on(
    CommentsActions.emptyCurrentThreadInStore,
    (state, action): CommentsState => ({
      ...state,
      comments: [],
      commentsUsers: [],
    })
  ),
  on(
    CommentsActions.setUsersOfCurrentThreadInStore,
    (state, action): CommentsState => ({
      ...state,
      commentsUsers: action.users,
    })
  )
);
