import { DropdownViewModel } from 'src/app/shared/view-models/dropdown.options.view.model';

export const rolesDropdownOptions: DropdownViewModel[] = [
  {
    value: 'fleet-manager',
    viewValue: 'Fleet Manager',
  },
  {
    value: 'ship-captain',
    viewValue: 'Captain',
  },
  {
    value: 'sub-fleet-manager',
    viewValue: 'Sub Fleet Manager',
  },
];
