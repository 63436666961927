<div class="container">
  <div class="title">
    <span class="body1">{{ title }}</span>
    <span class="body1 clear-btn" (click)="clear()">Clear</span>
  </div>
  <form [formGroup]="dropdownForm" class="form">
    <mat-form-field
      appearance="fill"
      class="custom-form-field-selection selection">
      <mat-select
        formControlName="dropdownControl"
        panelClass="custom-select"
        [multiple]="isMultipleChoice">
        <mat-select-trigger>
          {{ getSelectLabel() }}
        </mat-select-trigger>
        <mat-option
          *ngIf="isMultipleChoice"
          #allSelected
          (click)="toggleAllSelection()"
          [value]="'all'"
          >All</mat-option
        >
        <mat-option
          *ngFor="let option of options"
          [value]="option.value"
          (click)="togglePerOne()">
          {{ option.viewValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>
