import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-red-rt-event-indication',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './red-rt-event-indication.component.html',
  styleUrls: ['./red-rt-event-indication.component.scss'],
})
export class RedRTEventIndicationComponent {
  @Input()
  rtEventName!: string;
  @Input()
  rtEventTime!: string | null | undefined;
  @Input()
  displayDurationText: boolean = true;

  constructor() {}
}
