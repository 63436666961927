import { Pipe, PipeTransform } from '@angular/core';
import { ColregClassificationNames } from '../models/events-filters.model';

@Pipe({
  name: 'colregClassToDisplayName',
})
export class ColregClassToDisplayNamePipe implements PipeTransform {
  transform(value: ColregClassificationNames): string {
    if (value === 'Headon') return 'Head-On';
    return value;
  }
}
