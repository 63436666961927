import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { first, timer } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class EmailVerificationGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const code = route.queryParams['oobCode'];
    const mode = route.queryParams['mode'];
    if (code && mode && mode === 'verifyEmail') {
      const res = await this.auth.confirmEmail(code);
      if (res) {
        timer(3000)
          .pipe(first())
          .subscribe(_ => this.router.navigateByUrl('/private/overview'));
        return true;
      }
    }
    this.router.navigateByUrl('/login');
    return false;
  }
}
