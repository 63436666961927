import {
  defaultKpisData,
  KpisData,
  SailFuelExpenditureGraphData,
  ShipEfficiency,
  TimeFilter,
} from '../../models/efficiency.models';

export interface EfficiencyState {
  readonly kpisData: KpisData;
  readonly shipsEfficiency: ShipEfficiency[];
  readonly inefficientEventPerShip: { [key: number]: string };
  readonly sailFuelExpendituresTimeFilter: TimeFilter;
  readonly sailFuelExpenditures: SailFuelExpenditureGraphData[];
  readonly isEfficiencyPageActive: boolean;
}

export const initialEfficiencyState: EfficiencyState = {
  kpisData: defaultKpisData,
  shipsEfficiency: [],
  inefficientEventPerShip: {},
  sailFuelExpendituresTimeFilter: TimeFilter.YEAR_AND_THIS_MONTH,
  sailFuelExpenditures: [],
  isEfficiencyPageActive: false,
};
