import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateToDayTime',
})
export class DateToDayTimePipe implements PipeTransform {
  transform(date: Date | null): string {
    if (date == null) return '';
    const hours = date.getHours();
    if (hours >= 6 && hours < 12) {
      return 'morning';
    } else if (hours >= 12 && hours < 18) {
      return 'afternoon';
    } else if (hours >= 18 && hours <= 21) {
      return 'evening';
    }
    return 'night';
  }
}
