import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { ShipsSeverityByDate } from 'src/app/core/models/ship.model';
import { CoreState } from 'src/app/core/store/state/core.state';
import {
  EventsSelectors,
  FiltersSelectors,
  ShipsSelectors,
} from 'src/app/core/store/types';
import { DateFilterKeys } from 'src/app/shared/view-models/dates.view.model';

@Component({
  selector: 'app-fleet-safety-score',
  templateUrl: './fleet-safety-score.component.html',
  styleUrls: ['./fleet-safety-score.component.scss'],
})
export class FleetSafetyScoreComponent implements OnInit {
  endDateAvgSeverityScore$!: Observable<number>;
  startDateAvgSeverityScore$!: Observable<number>;
  selectedDateRange$!: Observable<DateFilterKeys>;
  fleetSeverity$!: Observable<ShipsSeverityByDate>;
  dataExists$!: Observable<boolean>;
  fleetAverageSafetyScore$!: Observable<number>;
  showEventsDayFilterTitle$!: Observable<boolean>;

  constructor(public store: Store<CoreState>) {}

  ngOnInit(): void {
    this.endDateAvgSeverityScore$ = this.store.select(
      ShipsSelectors.selectEndDateAvgSeverityScore
    );
    this.showEventsDayFilterTitle$ = this.store.select(
      EventsSelectors.selectIsEventsFilterByDay
    );
    this.startDateAvgSeverityScore$ = this.store.select(
      ShipsSelectors.selectStartDateAvgSeverityScore
    );
    this.selectedDateRange$ = this.store.select(
      FiltersSelectors.selectFilterdDatesKey
    );
    this.fleetSeverity$ = this.store.select(
      ShipsSelectors.selectShipsSeverityWithoutNA
    );

    this.fleetAverageSafetyScore$ = this.store.select(
      ShipsSelectors.selectAverageFleetSafteyScore
    );

    this.dataExists$ = this.fleetSeverity$.pipe(
      map(severity => severity.shipsSeverityByDate.length > 0)
    );
  }
}
