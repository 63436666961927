<div class="container">
  <app-range-slider
    (rangeChanged)="onEventFiltersChange($event, 'roll')"
    [customSliderOptions]="(sliderRoll$ | async)!"
    [title]="'Roll'"></app-range-slider>
  <app-range-slider
    (rangeChanged)="onEventFiltersChange($event, 'pitch')"
    [customSliderOptions]="(sliderPitch$ | async)!"
    [title]="'Pitch'"></app-range-slider>
</div>
