import { Location } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { Ship } from 'src/app/core/models/ship.model';
import { fetchShipDetails } from 'src/app/core/store/actions/ships.actions';
import { CoreState } from 'src/app/core/store/state/core.state';
import { ShipsSelectors } from 'src/app/core/store/types';
import { getTimeDiff } from 'src/app/shared/utils/date-transforms/date-diff';

@Component({
  selector: 'app-ship-details',
  templateUrl: './ship-details.component.html',
  styleUrls: ['./ship-details.component.scss'],
})
export class ShipDetailsComponent implements OnInit, OnDestroy {
  @Input() ship!: Ship;
  realTimeData$!: Observable<Ship | null>;
  rtEventName: string | null = null;
  rtEventTime: string | null = null;
  private subscriptions = new Subscription();

  constructor(
    private store: Store<CoreState>,
    private router: Router,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.loadRtEventData();
    this.store.dispatch(fetchShipDetails({ shipId: this.ship.shipId }));
    this.realTimeData$ = this.store.select(
      ShipsSelectors.selectShipRealTimeData
    );
  }

  loadRtEventData() {
    const rtEventsSubscription = this.store
      .select(ShipsSelectors.selectRTEvents)
      // eslint-disable-next-line @ngrx/no-store-subscription
      .subscribe(rtEvents => {
        const rtEvent = rtEvents[this.ship.shipId];
        if (rtEvent) {
          this.rtEventName = rtEvent.alertName ?? null;
          const currentUtctime = new Date(
            new Date().getTime() + new Date().getTimezoneOffset() * 60000
          );
          this.rtEventTime = getTimeDiff(
            currentUtctime,
            new Date(rtEvent.timestampStart)
          );
        } else {
          this.rtEventName = null;
          this.rtEventTime = null;
        }
      });
    this.subscriptions.add(rtEventsSubscription);
  }

  navigateToShip(shipId: number): void {
    const currentPath = this.location.path().split('/')[2];
    if (currentPath === 'fleet') {
      this.router.navigate(['/private/fleet/ship', shipId]);
    } else if (currentPath === 'live') {
      this.router.navigate(['/private/live/ship', shipId]);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
