import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toPercentage',
})
export class ToPercentagePipe implements PipeTransform {
  transform(num: number, upperBound: number): number {
    if (num && upperBound && upperBound > 0) {
      return (Math.round(num) / upperBound) * 100;
    }
    return 0;
  }
}
