import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { format } from 'd3-format';
import { map, Observable } from 'rxjs';
import { AlertNames } from 'src/app/core/models/events-filters.model';
import {
  CrewShifts,
  EventCountByGroup,
  EventSeverity,
} from 'src/app/core/models/events.model';
import { CoreState } from 'src/app/core/store/state/core.state';
import { ShipsSelectors } from 'src/app/core/store/types';

@Component({
  selector: 'app-ship-events-by-crew-shifts',
  templateUrl: './ship-events-by-crew-shifts.component.html',
  styleUrls: ['./ship-events-by-crew-shifts.component.scss'],
})
export class ShipEventsByCrewShiftsComponent implements OnInit {
  dataExists$!: Observable<boolean>;
  shipEventCountByCrewShift$!: Observable<EventCountByGroup[]>;
  totalShipEventCount$!: Observable<number>;
  shipEventCountByCrewShiftView$!: Observable<
    {
      name: CrewShifts | AlertNames | EventSeverity;
      value: number;
    }[]
  >;

  yAxisLabelFormat = format('.0f');
  formatYAxisLabel = (value: number) => {
    return this.yAxisLabelFormat(value);
  };

  crewShiftNameClass = 'crew-shift';
  colorScheme: { name: string; value: string }[] = [
    { name: '00-04', value: '#B2BFEC' },
    { name: '04-08', value: '#B2BFEC' },
    { name: '08-12', value: '#B2BFEC' },
    { name: '12-16', value: '#B2BFEC' },
    { name: '16-20', value: '#B2BFEC' },
    { name: '20-00', value: '#B2BFEC' },
  ];

  constructor(private store: Store<CoreState>) {}

  ngOnInit(): void {
    this.totalShipEventCount$ = this.store.select(
      ShipsSelectors.selectSelectedShipTotalEventCount
    );

    this.shipEventCountByCrewShift$ = this.store.select(
      ShipsSelectors.selectSelectedShipEventCountByCrewShifts
    );

    this.shipEventCountByCrewShiftView$ = this.shipEventCountByCrewShift$.pipe(
      map(eventCountList =>
        eventCountList.map(eventCountObject => ({
          name: eventCountObject.group_title,
          value: eventCountObject.count,
        }))
      )
    );
    this.dataExists$ = this.totalShipEventCount$.pipe(map(count => count > 0));
  }
}
