import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'scoreToStringLevel',
})
export class ScoreToStringLevelPipe implements PipeTransform {
  transform(score: number): string {
    if (score) {
      if (score > 0 && score < 6.5) {
        return 'Low';
      }
      if (score >= 6.5 && score < 7.5) {
        return 'Moderate';
      }
      if (score >= 7.5 && score < 9.5) {
        return 'Good';
      } else {
        return 'Excellent';
      }
    }
    return 'Score not found';
  }
}
