import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  debounceTime,
  map,
  Observable,
  startWith,
  Subject,
  switchMap,
} from 'rxjs';
import { GalleryImageDialogComponent } from '../../components/dialogs/gallery-image-dialog/gallery-image-dialog.component';
import { FeatureFlagService } from '../../services/feature-toggle.service';
import { RoutingService } from '../../services/routing.service';
import { fetchScreenshotsInitiated } from '../../store/actions/ships.actions';
import { CoreState } from '../../store/state/core.state';
import { ShipsSelectors } from '../../store/types';
import { Screenshot } from '../../view-models/gallery.view.model';

@Component({
  selector: 'app-live',
  templateUrl: './live.component.html',
  styleUrls: ['./live.component.scss'],
})
export class LiveComponent implements OnInit {
  screenshots$!: Observable<Screenshot[]>;
  filteredScreenshots$!: Observable<Screenshot[]>;
  searchQuery: string = '';
  hover: boolean = false;
  showRightLayout: boolean = true;
  hideNewTabButton$!: Observable<boolean>;
  newTabUrl = window.location.origin + '/private/live?newTab=true';
  searchQuerySubject = new Subject<string>();

  showFlag$!: Observable<boolean>;

  constructor(
    private store: Store<CoreState>,
    public dialog: MatDialog,
    private routingService: RoutingService,
    private router: Router,
    private featureFlag: FeatureFlagService
  ) {}

  ngOnInit(): void {
    this.store.dispatch(fetchScreenshotsInitiated());
    this.hideNewTabButton$ = this.routingService.isLiveNewTab$();
    this.screenshots$ = this.store.select(
      ShipsSelectors.selectEnhancedScreenshots
    );

    this.filteredScreenshots$ = this.searchQuerySubject.pipe(
      debounceTime(300),
      startWith(''),
      switchMap(query => this.filterScreenshots(query))
    );

    this.showFlag$ = this.featureFlag.getFeatureFlag$('LiveView');
  }

  onLayoutClick(event: any): void {
    const elementWidth = (event.target as HTMLElement).offsetWidth;
    const clickX =
      event.clientX -
      (event.target as HTMLElement).getBoundingClientRect().left;
    if (clickX < elementWidth / 2) {
      this.showRightLayout = false;
    } else {
      this.showRightLayout = true;
    }
  }

  openImageDialog(picture: Screenshot): void {
    this.dialog.open(GalleryImageDialogComponent, {
      data: {
        shipId: picture.shipId,
        name: picture.shipName,
        rtEventName: picture.rtEventName,
        rtEventTime: picture.rtEventTime,
        timestamp: picture.timestamp,
        url: picture.cdnUrl,
        location: picture.location,
      },
    });
  }

  openProfile(shipId: number): void {
    this.router.navigate(['/private/live/ship', shipId]);
  }

  openLivestream(shipId: number, event: Event): void {
    event.stopPropagation();
    this.router.navigate(['/private/live/ship', shipId], {
      queryParams: { isLiveStreamOpen: true },
    });
  }

  onSearchQueryChange(): void {
    this.searchQuerySubject.next(this.searchQuery);
  }

  filterScreenshots(query: string): Observable<Screenshot[]> {
    return this.screenshots$.pipe(
      map(screenshots =>
        screenshots.filter(screenshot =>
          query
            ? screenshot.shipName.toLowerCase().includes(query.toLowerCase())
            : true
        )
      )
    );
  }
}
