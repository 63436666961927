<div class="container">
  <div class="form-container">
    <div class="login-logo-container">
      <img class="logo" src="./assets/icons/logo_auth.svg" />
      <h2>ORCA AI</h2>
    </div>

    <form class="form" [formGroup]="loginForm" autocomplete="off">
      <div class="inputs-container">
        <h2>Sign in to your account</h2>
        <mat-form-field appearance="outline" class="primary-outline">
          <mat-label>E-mail</mat-label>
          <input matInput formControlName="email" name="email" />
        </mat-form-field>

        <mat-form-field appearance="outline" class="primary-outline">
          <mat-label>Password</mat-label>
          <input
            matInput
            formControlName="password"
            #password
            type="password"
            name="password" />
          <mat-icon matSuffix appTogglePasswordVisibility [inputRef]="password"
            >visibility_off</mat-icon
          >
        </mat-form-field>
      </div>

      <a class="forgot-pass" routerLink="/reset-password">Forgot password?</a>

      <div class="btn-container">
        <button
          data-test="login-btn"
          class="stay-primary-on-disabled login-btn dark-color"
          mat-flat-button
          color="primary"
          (click)="login()"
          [disabled]="!loginForm.valid">
          Sign in
        </button>
      </div>
    </form>
  </div>
</div>
