import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import {
  AlertNames,
  alertNamesToEventTypes,
} from 'src/app/core/models/events-filters.model';
import {
  CrewShifts,
  EventCountByGroup,
  EventSeverity,
} from 'src/app/core/models/events.model';
import { CoreState } from 'src/app/core/store/state/core.state';
import { ShipsSelectors } from 'src/app/core/store/types';
import { GraphTooltipMapping } from 'src/app/shared/models/graph-data-model';

@Component({
  selector: 'app-ship-events-by-alert-name',
  templateUrl: './ship-events-by-alert-name.component.html',
  styleUrls: ['./ship-events-by-alert-name.component.scss'],
})
export class ShipEventsByAlertNameComponent implements OnInit {
  divClassMap: { [key in AlertNames]: string } = {
    'Dangerous ROT': 'dangerous-rot',
    'Dangerous Speed Drop': 'speed-drop',
    'Grounding risk': 'grounding-risk',
    'Dangerous motion': 'dangerous-motion',
    'Collision risk': 'collision-risk',
    'No Go Zone': 'no-go-zone',
    Compliance: 'compliance',
  };
  colorScheme = [
    { name: 'Dangerous ROT', value: '#64a7d8' },
    { name: 'Collision risk', value: '#9b6fa9' },
    {
      name: 'Dangerous Speed Drop',
      value: '#28659e',
    },
    {
      name: 'Grounding risk',
      value: '#b276ff',
    },
    {
      name: 'Dangerous motion',
      value: '#789797',
    },
  ];

  shipEventCountByAlertNameWithPercentsAsValuesView$!: Observable<
    { name: EventSeverity | AlertNames | CrewShifts; value: number }[]
  >;
  dataExists$!: Observable<boolean>;
  shipEventCountByAlertName$!: Observable<EventCountByGroup[]>;
  totalShipEventCount$!: Observable<number>;
  shipEventCountByAlertNameView$!: Observable<
    {
      name: EventSeverity | AlertNames | CrewShifts;
      value: number;
    }[]
  >;
  alertNameMap: GraphTooltipMapping = {
    'Dangerous ROT': {
      divClass: this.divClassMap['Dangerous ROT'],
      text: alertNamesToEventTypes['Dangerous ROT'],
    },
    'Dangerous Speed Drop': {
      divClass: this.divClassMap['Dangerous Speed Drop'],
      text: alertNamesToEventTypes['Dangerous Speed Drop'],
    },
    'Grounding risk': {
      divClass: this.divClassMap['Grounding risk'],
      text: alertNamesToEventTypes['Grounding risk'],
    },
    'Dangerous motion': {
      divClass: this.divClassMap['Dangerous motion'],
      text: alertNamesToEventTypes['Dangerous motion'],
    },
    'Collision risk': {
      divClass: this.divClassMap['Collision risk'],
      text: alertNamesToEventTypes['Collision risk'],
    },
  };

  constructor(private store: Store<CoreState>) {}

  ngOnInit(): void {
    this.totalShipEventCount$ = this.store.select(
      ShipsSelectors.selectSelectedShipTotalEventCount
    );
    this.shipEventCountByAlertName$ = this.store.select(
      ShipsSelectors.selectSelectedShipEventCountByAlertName
    );
    this.dataExists$ = this.totalShipEventCount$.pipe(map(count => count > 0));

    this.shipEventCountByAlertNameView$ = this.shipEventCountByAlertName$.pipe(
      map(eventCountList =>
        eventCountList.map(eventCountObject => ({
          name: eventCountObject.group_title,
          value: eventCountObject.count,
        }))
      )
    );
  }
}
