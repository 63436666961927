<div #root class="root">
  <div class="symbol-area" [class.is-on]="value === true" [class.is-partial]="value === null" (click)="onClick()">
    <span class="outer-element" [class.is-on]="value === true" [class.is-partial]="value === null">
      <span class="inner-element" [class.is-on]="value === true" [class.is-partial]="value === null">
        <svg 
          version="1.1"
          focusable="false"
          viewBox="0 0 24 24"
          aria-hidden="true"
        >
          <path
            fill="none"
            stroke="white"
            stroke-dasharray="22.910259"
            stroke-width="2.1333333333"
            d="M4.1,12.7 9,17.6 20.3,6.3"
          ></path>
        </svg>
        <span class="minus-sign"></span>
      </span>
    </span>
    <span matRipple [matRippleTrigger]="root"  [matRippleCentered]="true" class="ripple"></span>
  </div>
  <span class="content" (click)="onClick()">
    <ng-content></ng-content>
  </span>  
</div>
