<div class="container">
  <div class="ship-list">
    <div
      class="header"
      data-test="ships-section-header"
      *ngIf="fleetName$ | async as fleetName">
      <app-widget-title
        data-test="ships-fleet-name"
        [widgetTitleType]="'basic'"
        [title]="fleetName"></app-widget-title>
      <div class="search-box">
        <span class="body1">Ships</span>
        <mat-form-field
          appearance="outline"
          class="primary-outline"
          data-test="ships-search-box">
          <mat-label>Type ship's name</mat-label>
          <input matInput [formControl]="searchBoxControl" autocomplete="off" />
          <mat-icon matSuffix class="my-icon">search</mat-icon>
        </mat-form-field>
      </div>
    </div>
    <!--trigger build-->
    <orc-table
      (selectionChanged)="onShipListSelection($event)"
      [selected]="selectedShipId$ | async"
      [data]="(ships$ | async)!"
      [columns]="columns"
      [trackBy]="trackBy"
      [sortBy]="sortBy">
      <orc-column id="name" data-test="ship-name-col">
        <div *orcTableCell="let item = item" class="cell name">
          <div class="body1" data-test="ship-name-value">
            {{ item.shipName }}
          </div>
          <div
            class="caption"
            *ngIf="isAdmin$ | async"
            data-test="fleet-name-value">
            {{ item.fleetName }}
          </div>
        </div>
      </orc-column>
      <orc-column id="safety" data-test="ship-score-col">
        <div *orcTableHeader class="safety-header">
          <div>
            Safety <br />
            Score
          </div>
        </div>
        <div *orcTableCell="let item = item" class="cell safety">
          <div
            class="safety-score-container"
            *ngIf="item.safetyScore > 0; else noScore">
            <div class="score-container">
              <div class="body1-bold" data-test="ship-score-value">
                {{ item.safetyScore | number: '1.0-0' }}
              </div>
              <div class="caption">/10</div>
            </div>
            <div
              class="caption score-box"
              data-test="ship-score-level-value"
              [class.excellent]="
                (item.safetyScore | scoreToStringLevel) === 'Excellent'
              "
              [class.good]="(item.safetyScore | scoreToStringLevel) === 'Good'"
              [class.moderate]="
                (item.safetyScore | scoreToStringLevel) === 'Moderate'
              "
              [class.low]="(item.safetyScore | scoreToStringLevel) === 'Low'">
              {{ item.safetyScore | scoreToStringLevel }}
            </div>
          </div>
          <ng-template #noScore>N/A</ng-template>
        </div>
      </orc-column>
      <orc-column id="score-trend" data-test="ship-trend-col">
        <div
          *orcTableCell="let item = item"
          class="cell trend"
          [class.up]="item.scoreTrend > 0"
          [class.level]="item.scoreTrend === 0"
          [class.down]="item.scoreTrend < 0"
          data-test="score-trend-value">
          <mat-icon *ngIf="item.scoreTrend > 0" data-test="score-trend-arrow"
            >north_east</mat-icon
          >
          <mat-icon *ngIf="item.scoreTrend < 0" data-test="score-trend-arrow"
            >south_west</mat-icon
          >
          {{ item.scoreTrend | number: '0.1-1' }}%
        </div>
      </orc-column>
      <orc-column id="last-connection" data-test="last-conn-col">
        <div *orcTableCell="let item = item" class="cell connection">
          <div class="body1" data-test="last-conn-time">
            {{ item.lastConnection | date: 'HH:mm':'utc' }}
          </div>
          <div class="caption" data-test="last-conn-date">
            {{ item.lastConnection | date: 'dd/MM/yy':'utc' | dateToToday }}
          </div>
        </div>
      </orc-column>
      <orc-column id="view">
        <div *orcTableCell="let item = item" class="cell view">
          <button
            mat-stroked-button
            class="primary-border"
            color="primary"
            [routerLink]="['ship', item.shipId]"
            data-test="view-btn">
            View
          </button>
        </div>
      </orc-column>
    </orc-table>
  </div>
  <div class="graph-map-container">
    <div class="fleet-safety-graph">
      <app-fleet-safety-graph></app-fleet-safety-graph>
    </div>
    <div class="map">
      <app-map
        [ships]="shipsOnMap$ | async"
        [position]="(mapPosition$ | async)!"
        [offsetLatPositionDestination]="100"
        [tooltip]="mapTooltip$ | async"
        (selectedMapEvent)="onShipMapSelection($event)"
        (mapTooltipButtonClicked)="shipTooltipButtonClicked($event)">
        <ng-template #layerMenus>
          <app-weather-menu></app-weather-menu>
        </ng-template>
      </app-map>
    </div>
  </div>
</div>
