<div class="container">
  <h2>Discard Changes?</h2>
  <div class="separator"></div>
  <p>Are you sure you want to discard this change?</p>
  <div class="actions">
    <button mat-button class="cancel" (click)="onCancel()">Cancel</button>
    <button
      mat-flat-button
      color="warn"
      class="discard"
      (click)="onDiscard()"
      cdkFocusInitial>
      Discard
    </button>
  </div>
</div>
