import { createReducer, on } from '@ngrx/store';
import {
  changeSailFuelExpendituresTimeFilter,
  enterEfficiencyPage,
  leaveEfficiencyPage,
  saveFuelCostData,
  saveInefficientEventData,
  saveShipsEfficiencyData,
} from '../actions/efficiency.actions';
import {
  EfficiencyState,
  initialEfficiencyState,
} from '../state/efficiency.state';

export const efficiencyStateKey = 'efficiency';
export const efficiencyReducer = createReducer(
  initialEfficiencyState,
  on(
    saveShipsEfficiencyData,
    (state, action): EfficiencyState => ({
      ...state,
      kpisData: action.kpisData,
      shipsEfficiency: action.shipsEfficiency,
    })
  ),
  on(
    saveInefficientEventData,
    (state, action): EfficiencyState => ({
      ...state,
      inefficientEventPerShip: action.inefficientEventPerShip,
    })
  ),
  on(
    changeSailFuelExpendituresTimeFilter,
    (state, action): EfficiencyState => ({
      ...state,
      sailFuelExpendituresTimeFilter: action.sailFuelExpendituresTimeFilter,
    })
  ),
  on(
    saveFuelCostData,
    (state, action): EfficiencyState => ({
      ...state,
      sailFuelExpenditures: action.sailFuelExpenditures,
    })
  ),
  on(
    enterEfficiencyPage,
    (state): EfficiencyState => ({
      ...state,
      isEfficiencyPageActive: true,
    })
  ),
  on(
    leaveEfficiencyPage,
    (state): EfficiencyState => ({
      ...state,
      isEfficiencyPageActive: false,
    })
  )
);
