<div class="container">
  <app-fleet-safety-score></app-fleet-safety-score>
  <app-ships-risks></app-ships-risks>

  <app-insights-widget
    *ngIf="showInsights; else showSafetyScore"
    class="responsivity-display">
  </app-insights-widget>

  <ng-template #showSafetyScore>
    <app-safety-score-ships
      class="responsivity-display"></app-safety-score-ships>
  </ng-template>
  <app-overview-map></app-overview-map>
</div>
