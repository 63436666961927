import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { CoreState } from '../store/state/core.state';
import { AuthenticationSelectors } from '../store/types';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private store: Store<CoreState>, private router: Router) {}
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const authenticated = await firstValueFrom(
      this.store.select(AuthenticationSelectors.selectIsAuthenticated)
    );

    if (!authenticated) {
      return true;
    }

    this.router.navigateByUrl('/private/overview');
    return false;
  }
}
