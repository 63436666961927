import { Injectable } from '@angular/core';
import {
  collection,
  doc,
  Firestore,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  Unsubscribe,
  updateDoc,
} from '@angular/fire/firestore';
import { Model } from 'common';
import { UserUpdate } from 'functions/src/common/models/user.model';
import { IsBusyService } from './is-busy.service';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  usersSubscribsion!: Unsubscribe;
  constructor(
    private firestore: Firestore,
    private isBusyService: IsBusyService
  ) {}

  createUsers(users: Model.PreliminaryUser[]): Promise<void> {
    return this.isBusyService.add(() => {
      const promises = users.map(user =>
        setDoc(doc(this.firestore, 'preliminary-users', user.id), user)
      );

      return Promise.all(promises);
    });
  }
  updateUser(user: UserUpdate): Promise<void> {
    return this.isBusyService.add(() => {
      const userRef = doc(this.firestore, 'preliminary-users', user.id);
      return updateDoc(userRef, { ...user });
    });
  }

  async getPreliminaryUsers(): Promise<Model.PreliminaryUser[]> {
    const querySnapshot = await getDocs(
      collection(this.firestore, 'preliminary-users')
    );
    return querySnapshot.docs.map(
      (doc): Model.PreliminaryUser => doc.data() as Model.PreliminaryUser
    );
  }

  registerToUsersDb(func: (users: Model.PreliminaryUser[]) => void) {
    const q = query(collection(this.firestore, 'preliminary-users'));
    this.usersSubscribsion = onSnapshot(q, querySnapshot => {
      const users = querySnapshot.docs.map(
        doc => doc.data() as Model.PreliminaryUser
      );
      func(users);
    });
  }

  unRegisterToUsersDb() {
    this.usersSubscribsion();
  }
}
