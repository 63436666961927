<div class="carousel">
  <ng-container *ngFor="let item of carouselItems; let i = index">
    <div *ngIf="i === currentIndex" class="carousel-item">
      <ng-container *ngTemplateOutlet="item"></ng-container>
    </div>
  </ng-container>

  <div class="divider"></div>

  <div class="carousel-footer">
    <div class="prev-button" (click)="prev()">
      <img src="./assets/icons/arrow_left.svg" alt="Previous" />
    </div>
    <div class="indicators">
      <ng-container *ngFor="let item of carouselItems; let i = index">
        <span
          [ngClass]="{
            active: i === currentIndex
          }"
          class="indicator"
          (click)="selectIndex(i)"></span>
      </ng-container>
    </div>
    <div class="next-button" (click)="next()">
      <img src="./assets/icons/arrow_right.svg" alt="Next" />
    </div>
  </div>
</div>
