import { DropdownViewModel } from 'src/app/shared/view-models/dropdown.options.view.model';

export const eventsSortList: DropdownViewModel[] = [
  {
    value: 'default',
    viewValue: 'All',
  },
  {
    value: 'date',
    viewValue: 'Date',
  },
  {
    value: 'severity',
    viewValue: 'Severity',
  },
  {
    value: 'ship_name',
    viewValue: 'Ship Name',
  },
  {
    value: 'alert_name',
    viewValue: 'Event Type',
  },
];

export interface EventsBySeverity {
  readonly countOfHighSeverityEvents: number;
  readonly countOfMediumSeverityEvents: number;
  readonly countOfLowSeverityEvents: number;
  readonly totalCount: number;
}
