<app-insight-card [insight]="insight">
  <ng-container
    *ngIf="insight.content | insightTypeCheck: isHighDuringLastWeekInsight">
    <ng-template appInsightData let-insightData>
      <span
        >During the last 7 days, your ships ({{
          insightData.content.numOfCommissionedShips
        }}
        ships commissioned with Orca AI's SeaPod) had
        <span class="bold">
          {{ insightData.content.numOfSeverityEvents | number: '1.0-2' || '0' }}
          high severity events</span
        >
        , which makes an average of
        {{
          insightData.content.avgNumOfHighEventsPerShip | number: '1.0-2' || '0'
        }}
        events per ship.For comparison, other fleets with the same type of
        vessels, had
        <span class="bold"
          >{{
            insightData.content.benchmarkAvgNumOfHighEventsPerShip
              | number: '1.0-2' || '0'
          }}
          high severity events per ship.</span
        >
      </span>
    </ng-template></ng-container
  >
  <ng-container
    *ngIf="insight.content | insightTypeCheck: isShipWithMostEventsInsight">
    <ng-template appInsightData let-insightData>
      <span class="bold"
        >{{ insightData.content.outsandShipName }} had
        {{
          insightData.content.outstandShipNumOfEventsPer1000Nm
            | number: '1.0-2' || '0'
        }}</span
      ><span>
        events per 1000 NM of sailing ({{
          insightData.content.outstandShipTotalNumOfEvents
            | number: '1.0-2' || '0'
        }}
        events in total) during the last 3 months, which is more events then all
        other ships in your fleet. On average, your fleet's ships had
        {{
          insightData.content.fleetAvgNumOfEventPer1000Nm
            | number: '1.0-2' || '0'
        }}
        events per every 1000 NM.</span
      >
    </ng-template></ng-container
  >
</app-insight-card>
