<app-pie-chart
  [dataExists]="(dataExists$ | async)!"
  [title]="'Events By Severity'"
  [graphData]="(shipEventCountBySeverityView$ | async)!"
  [colorScheme]="colorScheme"
  [legendClass]="'severity'"
  [totalCount]="(totalShipEventCount$ | async)!"
  [countTitle]="'Total Events'"
  [tooltipMapping]="risksMap"
  [countByGroup]="(shipEventCountBySeverityView$ | async)!"></app-pie-chart>
