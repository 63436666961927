import { createReducer, on } from '@ngrx/store';
import {
  AuthenticationState,
  initialAuthenticationState,
} from '../state/authentication.state';
import { AuthenticationActions } from '../types';

export const authenticationStateKey = 'authentication';
export const authenticationReducer = createReducer(
  initialAuthenticationState,
  on(
    AuthenticationActions.savePreliminaryUser,
    (state, action): AuthenticationState => ({
      ...state,
      preliminaryUser: action.user,
    })
  ),
  on(
    AuthenticationActions.initAuthUser,
    (state, action): AuthenticationState => ({
      ...state,
      authenticatedUser: action.authUser,
    })
  ),
  on(
    AuthenticationActions.saveAuthUser,
    (state, action): AuthenticationState => ({
      ...state,
      authenticatedUser: action.authUser,
    })
  ),
  on(
    AuthenticationActions.clearPreliminaryUser,
    (state, action): AuthenticationState => ({
      ...state,
      preliminaryUser: null,
    })
  ),
  on(
    AuthenticationActions.updateUserOnAdminSetClaims,
    (state, action): AuthenticationState => ({
      ...state,
      authenticatedUser: { ...action.user },
    })
  ),
  on(
    AuthenticationActions.updateTokenOnRegularUserSetClaims,
    (state, action): AuthenticationState => ({
      ...state,
      authenticatedUser: { ...state.authenticatedUser!, token: action.token },
    })
  ),
  on(
    AuthenticationActions.saveFleets,
    (state, action): AuthenticationState => ({
      ...state,
      fleets: [...action.fleets],
    })
  ),
  on(
    AuthenticationActions.changeFleetId,
    (state, action): AuthenticationState => ({
      ...state,
      authenticatedUser: {
        ...state.authenticatedUser!,
        fleetId: action.fleetId,
      },
    })
  ),
  on(
    AuthenticationActions.logout,
    (state, action): AuthenticationState => ({
      ...state,
      authenticatedUser: null,
    })
  ),
  on(
    AuthenticationActions.logoutFromAllTab,
    (state, action): AuthenticationState => ({
      ...state,
      authenticatedUser: null,
    })
  ),
  on(
    AuthenticationActions.saveRefreshedToken,
    (state, action): AuthenticationState => ({
      ...state,
      authenticatedUser: { ...state.authenticatedUser!, token: action.token },
    })
  )
);
