<app-no-data [dataExists]="dataExists$ | async">
  <div class="container">
    <ng-container *ngIf="fleetSeverity$ | async as fleetSeverity">
      <app-widget-title
        routerLink="/private/fleet"
        [widgetTitleType]="'safetyTitle'"
        [title]="'Fleet Safety Score'"
        [avgScore$]="fleetAverageSafetyScore$"
        [showEventsDayFilterTitle$]="showEventsDayFilterTitle$"
        [selectedDateRange$]="selectedDateRange$"
        [startDateAvgSeverityScore$]="startDateAvgSeverityScore$"
        [endDateAvgSeverityScore$]="
          endDateAvgSeverityScore$
        "></app-widget-title>
      <app-risks-overview-chart
        [shipsSeverityByDate]="
          fleetSeverity.shipsSeverityByDate
        "></app-risks-overview-chart>
    </ng-container>
  </div>
</app-no-data>
