import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class NewPassGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const code = route.queryParams['oobCode'];
    const mode = route.queryParams['mode'];
    if (code && mode && mode === 'resetPassword') {
      const email = await this.auth.verifyResetPasswordCode(code);
      if (email) return true;
    }
    this.router.navigateByUrl('/reset-password');
    return false;
  }
}
