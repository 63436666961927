import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { firstValueFrom } from 'rxjs';
import { FeatureFlagService } from 'src/app/core/services/feature-toggle.service';
import {
  MapSettingsOption,
  weatherLayersOptions,
} from '../../../models/map.entity.model';
import { MapLayersMenuService } from '../../../services/map-layers-menu.service';
import { MenuButtonComponent } from '../menu-button/menu-button.component';

@Component({
  selector: 'app-weather-menu',
  templateUrl: './weather-menu.component.html',
  styleUrls: ['./weather-menu.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatSlideToggleModule,
    MatRadioModule,
    MenuButtonComponent,
  ],
})
export class WeatherMenuComponent implements OnInit {
  isEnabledFeature: boolean | null = true;
  weatherLayersOptions = weatherLayersOptions;
  defaultSelectedLayer: MapSettingsOption = weatherLayersOptions[0].key;

  isEnabled: boolean = false;
  selectedLayer: MapSettingsOption = this.defaultSelectedLayer;

  constructor(
    private featureFlag: FeatureFlagService,
    private mapLayersMenuService: MapLayersMenuService
  ) {}

  async ngOnInit(): Promise<void> {
    this.isEnabledFeature = await firstValueFrom(
      this.featureFlag.getFeatureFlag$('weather')
    );
  }
  onOpenMenu() {
    if (!this.isEnabled) {
      this.isEnabled = true;
      this.updateSettings(this.selectedLayer, true);
    }
  }

  updateSettings(layerOption: MapSettingsOption, isChecked: boolean) {
    this.mapLayersMenuService.emitSettingsChanged({ layerOption, isChecked });
  }
  roundedTimeToNearest3Hours() {
    let date = new Date();
    date.setUTCMinutes(0, 0, 0);
    let hours = date.getUTCHours();
    let roundedHours = Math.floor(hours / 3) * 3;
    date.setUTCHours(roundedHours);

    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear().toString().slice(-2);
    const formattedHours = date.getUTCHours().toString().padStart(2, '0');
    const formattedMinutes = date.getUTCMinutes().toString().padStart(2, '0');

    return `${day}/${month}/${year} ${formattedHours}:${formattedMinutes}`;
  }
}
