<div class="container">
  <div class="title-container">
    <div class="title">{{ title }}</div>
    <div
      class="image-container"
      (click)="toggleShowModal()"
      [ngStyle]="{ cursor: !showModal ? 'pointer' : 'default' }">
      <img src="./assets/icons/exclamation_mark.svg" alt="Image" />
    </div>
    <div class="modal-container" *ngIf="showModal">
      <app-explainable-score
        *ngIf="avgScore$ | async as avgScore"
        [titleText]="'Fleet Scoring Factors'"
        [textExplaination]="modalText"
        [score]="avgScore">
      </app-explainable-score>
    </div>
    <div
      class="modal-overlay"
      (click)="toggleShowModal()"
      *ngIf="showModal"></div>
  </div>
  <div [hidden]="widgetTitleType === 'basic'">
    <div class="sub-title">
      <div class="selected-time-display">
        {{ selectedDateRange$ | async }}&nbsp;
      </div>
      <ng-container *ngIf="isTrend; then DeltaRisks"></ng-container>
    </div>
    <div
      [hidden]="widgetTitleType === 'subTitle'"
      class="day-filter"
      [ngClass]="{ hide: (showEventsDayFilterTitle$ | async) === false }">
      <div class="score-container">
        <div class="fleet-score-description-container">
          <div
            class="fleet-score-container"
            *ngIf="avgScore$ | async as avgScore">
            <div class="prog-bar">
              <mat-progress-bar
                mode="determinate"
                [value]="avgScore | toPercentage: 10"></mat-progress-bar>
              <span class="subtitle1">{{ avgScore | scoreToStringLevel }}</span>
            </div>
            <div class="score">
              <h2 class="regular score-font">
                {{ avgScore | number: '1.0-0' }}
              </h2>
              <div class="score-limit">/10</div>
            </div>
          </div>
          <div class="score-description caption" [hidden]="!isTrend">
            {{ isShipAvg ? 'Ship Avg.' : 'Fleet Avg.' }}
          </div>
        </div>
        <div>
          <div class="line"></div>
        </div>
        <div class="orca-score-container" *ngIf="isTrend; else DeltaRisks">
          <div class="score">
            <h2 class="regular">
              {{ secendAvgScore$ | async | number: '1.0-0' }}
            </h2>
            <div class="score-limit">/10</div>
            <img
              *ngIf="widgetTitleType === 'trendWithIconTitle'"
              class="logo"
              src="./assets/icons/logo.svg" />
          </div>
          <div class="score-description caption">
            {{ isShipAvg ? 'Fleet Avg.' : 'Orca AI Avg.' }}
          </div>
        </div>
      </div>
    </div>
    <ng-template #DeltaRisks>
      <app-delta-risks
        style="display: flex"
        [currRisk]="endDateAvgSeverityScore$ | async"
        [oldRisk]="startDateAvgSeverityScore$ | async"></app-delta-risks>
    </ng-template>
  </div>
</div>
