import { ParamMap } from '@angular/router';
import { mixpanelEventPropertiesMap } from 'functions/src/common/models/utm.properties.model';
import {
  DeltaResponse,
  DeltaTypes,
  DeltaTypesToMetricEvents,
} from '../models/metrics.event.filters.model';
import { MetricEvents } from '../models/metrics.model';

import { Range } from '../store/types';
import {
  defaultAppliedEventFilters,
  PresetFilter,
} from '../view-models/filter.view.model';

export function getQueryProperties(
  params: ParamMap
): mixpanelEventPropertiesMap {
  const utmProperties: mixpanelEventPropertiesMap = {
    source: params.get('source'),
  };

  return utmProperties;
}

function formatRange(range?: Range): string {
  const min = range?.min ?? 'undefined';
  const max = range?.max ?? 'undefined';
  return `min: ${min}, max: ${max}`;
}

export function createMetricPayload(
  presetFilter: Partial<PresetFilter | undefined>
) {
  const {
    shipName,
    severityLevels,
    eventType,
    congestionLevel,
    locations,
    ownSog,
    inPort,
    targetSog,
    colregClassifications,
    targetType,
    minDepth,
    minDistance,
    roll,
    pitch,
    sogDiff,
    rot,
  } = presetFilter?.filters || defaultAppliedEventFilters;

  return {
    presetName: presetFilter?.presetName || 'None',
    ships: [shipName?.value],
    severities: severityLevels,
    eventType: [eventType?.value],
    congestionLevel: [congestionLevel?.value],
    locations: locations,
    ownSOG: formatRange(ownSog),
    showPortEvents: inPort === null ? true : false,
    closeEncounterDistance: formatRange(minDistance),
    closeEncounterColregClassification: [colregClassifications?.value],
    closeEncounterTargetSOG: formatRange(targetSog),
    closeEncounterTargetType: [targetType?.value],
    ukcDepth: formatRange(minDepth),
    roll: formatRange(roll),
    pitch: formatRange(pitch),
    speedDropSOG: formatRange(sogDiff),
    highRot: formatRange(rot),
  };
}

export function extractMetricEvent(response: DeltaResponse): MetricEvents {
  const deltaKeys = Object.keys(response.delta);
  const deltaTypeKey = deltaKeys[0] as DeltaTypes;
  return DeltaTypesToMetricEvents[deltaTypeKey];
}
