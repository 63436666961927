import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { filter, map, Observable, switchMap, take } from 'rxjs';
import { CoreState } from '../store/state/core.state';
import { AuthenticationSelectors, ShipsSelectors } from '../store/types';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AdminResolver implements Resolve<Map<number, string>> {
  constructor(private store: Store<CoreState>) {}

  resolve(): Observable<Map<number, string>> {
    return this.store
      .select(AuthenticationSelectors.selectAuthenticatedUser)
      .pipe(
        filter(authUser => !!authUser?.token),
        switchMap(() => this.store.select(ShipsSelectors.selectShips)),
        filter(ships => ships.length > 0),
        map(ships => new Map(ships.map(ship => [ship.shipId, ship.shipName]))),
        take(1),
        untilDestroyed(this)
      );
  }
}
