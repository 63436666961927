import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MapComponent } from './components/map/map.component';
import { MapLayersMenuComponent } from './components/menu/map-layers-menu/map-layers-menu.component';
import { WeatherMenuComponent } from './components/menu/weather-menu/weather-menu.component';
import { ShipProfileTooltipComponent } from './components/tooltip/ship-profile-tooltip/ship-profile-tooltip.component';
import { WeatherLegendComponent } from './components/weather-legend/weather-legend.component';
import { ViewerFactoryService } from './services/viewer-factory.service';

@NgModule({
  declarations: [MapComponent],
  imports: [
    CommonModule,
    FormsModule,
    WeatherMenuComponent,
    MapLayersMenuComponent,
    WeatherLegendComponent,
    ShipProfileTooltipComponent,
  ],
  exports: [MapComponent, WeatherMenuComponent, MapLayersMenuComponent],
  providers: [ViewerFactoryService],
})
export class MapModule {}
