<app-no-data [dataExists]="dataExists$ | async">
  <div class="events">
    <div class="header">
      <div class="title">
        <app-widget-title
          class="title-widget"
          [widgetTitleType]="'basic'"
          [title]="'Events'"></app-widget-title>
        <div class="right-side-title">
          <button mat-menu-item class="events-menu" (click)="onExportClick()">
            <img class="tab-icon" src="/assets/icons/csv.svg" />
            <span>Export</span>
          </button>
        </div>
      </div>
      <div class="events-info">
        <div class="filters-data">
          <span class="events-info-text"
            >{{ eventsCount$ | async }} Events</span
          >
          <span class="current-date-filter">{{
            currentDateFilter$ | async
          }}</span>
        </div>
        <div class="sort-by">
          <span class="body1">Sort By :</span>
          <div>
            <mat-form-field
              appearance="fill"
              class="custom-form-field-selection custom-events-form-field-selection selection">
              <mat-select
                panelClass="custom-select"
                [value]="selectedSortOption$ | async"
                (valueChange)="onSortByChange($event)">
                <mat-option
                  *ngFor="let sortOption of eventsSortList"
                  [value]="sortOption.value">
                  {{ sortOption.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div
      class="events-container"
      #eventsContainer
      appInfiniteScrolling
      (scrolled)="onScroll()">
      <app-events-list
        [events]="events$ | async"
        (eventClicked)="onSelectedEvent($event)"
        (eventExplorationClicked)="onEventExploration($event)"
        [selectedEvent]="selectedEvent$ | async"></app-events-list>
    </div>
  </div>
</app-no-data>
