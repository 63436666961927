import { Component, NgZone, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Model } from 'functions/src/common/public-api';
import { first } from 'rxjs';
import { CoreState } from 'src/app/core/store/state/core.state';
import {
  AuthenticationActions,
  AuthenticationSelectors,
} from '../../../store/types';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm = this.fb.group({
    email: ['', [Validators.required]],
    password: ['', [Validators.required, Validators.minLength(8)]],
  });
  returnUrl: string = '/private/overview';

  constructor(
    private fb: FormBuilder,
    private store: Store<CoreState>,
    private router: Router,
    private route: ActivatedRoute,
    private ngZone: NgZone
  ) {}

  ngOnInit(): void {
    this.returnUrl =
      this.route.snapshot.queryParams['returnUrl'] || '/private/overview';

    this.store
      .select(AuthenticationSelectors.selectIsAuthenticated)
      .pipe(first(isAuthenticated => isAuthenticated === true))
      .subscribe(_ => this.handleRedirect());
  }

  async login() {
    const email = this.loginForm.value.email!.trim();
    const password = this.loginForm.value.password!.trim();

    const loginReq: Model.LoginRequest = {
      password,
      email,
    };

    this.store.dispatch(AuthenticationActions.login({ loginReq }));
  }

  private handleRedirect() {
    this.ngZone.run(() => this.router.navigateByUrl(this.returnUrl));
  }
}
