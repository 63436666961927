<ng-container *ngIf="user$ | async as authenticatedUser">
  <app-side-nav *ngIf="(hideContainer$ | async) === false"></app-side-nav>
  <app-topbar></app-topbar>
  <div class="container">
    <div
      class="sub-container"
      [class.small]="isContainerSmall$ | async"
      [ngClass]="{ 'hide-width': (hideContainer$ | async) }">
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-container>
