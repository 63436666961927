import {
  Component,
  EventEmitter,
  Input,
  Output,
  TrackByFunction,
} from '@angular/core';
import { ColumnVm } from '../../view-models/column.vm';
import { RowVm } from '../../view-models/row.vm';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'orc-data-row',
  templateUrl: './data-row.component.html',
  styleUrls: ['./data-row.component.scss'],
})
export class DataRowComponent {
  @Input()
  row!: RowVm;

  @Output()
  selection = new EventEmitter();

  trackByColId: TrackByFunction<ColumnVm> = (index, col) => col.id;
}
