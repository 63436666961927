import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
  HighDuringLastWeekInsight,
  ShipWithMostEventsInsight,
} from 'src/app/core/models/insights.models';
import { InsightCardComponent } from 'src/app/shared/components/insight-card/insight-card.component';
import { InsightDataDirective } from 'src/app/shared/directives/insight-data.directive';
import { InsightTypeCheckPipe } from 'src/app/shared/pipes/insight-type-check.pipe';
import { InsightCard } from 'src/app/shared/view-models/insight.view.model';

@Component({
  selector: 'app-insight-card-renderer',
  standalone: true,
  imports: [
    CommonModule,
    InsightTypeCheckPipe,
    InsightCardComponent,
    InsightDataDirective,
  ],
  templateUrl: './insight-card-renderer.component.html',
  styleUrls: ['./insight-card-renderer.component.scss'],
})
export class InsightCardRendererComponent {
  @Input() insight!: InsightCard;

  isHighDuringLastWeekInsight = HighDuringLastWeekInsight;
  isShipWithMostEventsInsight = ShipWithMostEventsInsight;
}
