import * as _ from 'lodash';
import {
  MapEntity,
  MapSettingsOption,
  PolygonColor,
  PolygonEntity,
} from 'src/app/map/models/map.entity.model';
import { GeneralEventViewModel } from 'src/app/shared/view-models/general.event.view.model';
import {
  DownloadedEvent,
  Event,
  EventDto,
  EventLocation,
  RtEventDto,
  RtEventImuSampleDto,
  RTEventLimitationsDto,
  RtEventScreenshotDto,
  rtEventTypeToRtEventName,
} from '../models/events.model';
import {
  Events,
  EventType,
  Polygon,
  RtEvent,
  RtEventImuSample,
  RTEventLimitations,
  RTEventScreenshot,
  RtEventToMap,
} from '../view-models/event.view.model';
import { AppliedEventFilters } from '../view-models/filter.view.model';

export function mapEventToEventsType(event: Event): Events {
  const generalEvent: GeneralEventViewModel = {
    shipId: event.shipId,
    fleetId: event.fleetId,
    severity: event.severityLevel,
    timestamp: event.timestamp,
    location: event.subLocation,
    shipName: event.shipName,
    eventId: event.id,
    sog: event.sog,
    cog: event.cog ?? 0,
    lat: event.lat,
    long: event.long,
    keplerUrl: event.keplerUrl,
    videoUrls: [...event.videosUrl],
    isRtEvent: event.isRtEvent,
    aoiComplianceLimitations: event.aoiComplianceLimitations,
    timestampEnd: event.timestampEnd,
    areaOfInterest: event.areaOfInterest,
  };
  switch (event.eventType) {
    case 'Collision risk': {
      return {
        ...generalEvent,
        minDistance: event.aisTargetMinDis!,
        colregClassification: event.colregClassification!,
        targetSog: event.aisTargetSog!,
        targetType: event.aisTargetType!,
        type: 'Close Encounter',
        targetId: event.aisTargetId!,
        costOfExtraDistanceSailedDollar: event.costOfExtraDistanceSailedDollar,
        extraFuelConsumedMt: event.extraFuelConsumedMt,
      };
    }
    case 'Dangerous ROT': {
      return {
        ...generalEvent,
        type: 'High ROT',
        rot: event.rot!,
      };
    }
    case 'Dangerous Speed Drop': {
      return {
        ...generalEvent,
        type: 'Speed Drop',
        eSog: event.sogDiff!,
      };
    }
    case 'Dangerous motion': {
      return {
        ...generalEvent,
        type: 'High Pitch/Roll',
        roll: event.roll!,
        pitch: event.pitch!,
      };
    }
    case 'No Go Zone': {
      return {
        ...generalEvent,
        type: 'No Go Zone',
        roll: event.roll!,
        pitch: event.pitch!,
      };
    }
    case 'Compliance': {
      return {
        ...generalEvent,
        type: 'Compliance',
        roll: event.roll!,
        pitch: event.pitch!,
      };
    }
    case 'Grounding risk': {
      return {
        ...generalEvent,
        type: 'UKC',
        depth: event.depth!,
      };
    }
  }
}

export function mapEventDtoToEvent(eventDto: EventDto): Event {
  return {
    id: eventDto.event_id,
    eventType: eventDto.alert_name,
    congestionLevel: eventDto.congestion_level,
    fleetId: eventDto.fleet_id,
    keplerUrl: eventDto.kepler_url,
    videosUrl: [...eventDto.videos_url_list],
    lat: eventDto.own_lat_start,
    long: eventDto.own_long_start,
    location: eventDto.geo_group,
    subLocation: eventDto.geo_tag_including_client_requests,
    severityLevel: eventDto.severity_level,
    shipName: eventDto.ship_name,
    shipId: eventDto.own_ship_id,
    timestamp: eventDto.timestamp_start,
    aisTargetSog: eventDto.ais_target_sog_start,
    aisTargetId: eventDto.ais_target_id,
    aisTargetMinDis: eventDto.ais_target_distance_min_dis,
    aisTargetType: eventDto.ais_target_type,
    colregClassification: eventDto.colreg_classification,
    depth: eventDto.min_depth,
    pitch: eventDto.max_delta_pitch,
    roll: eventDto.max_delta_roll,
    rot: eventDto.max_rot,
    sogDiff: eventDto.max_delta_sog,
    sog: eventDto.own_sog_start,
    costOfExtraDistanceSailedDollar:
      eventDto.cost_of_extra_distance_sailed_dollar,
    extraFuelConsumedMt: eventDto.extra_fuel_consumed_mt,
    isRtEvent: eventDto.is_rt_event,
    areaOfInterest: eventDto.area_of_interest,
    cog: eventDto.cog_start,
    timestampEnd: eventDto.timestamp_end,
    aoiComplianceLimitations: mapRtEventLimitationsDtoToRtEventLimitations(
      eventDto.aoi_compliance_limitations
    ),
  };
}

export function mapRtEventDtoToRtEvent(eventDto: RtEventDto): RtEvent {
  return {
    shipId: eventDto.ship_id,
    fleetId: eventDto.fleet_id,
    eventId: eventDto.event_id,
    type: rtEventTypeToRtEventName[eventDto.event_type] ?? eventDto.event_type,
    keplerUrl: null,
    location: eventDto.textual_location,
    timestamp: eventDto.timestamp_start,
    videoUrls: [],
    lat: eventDto.lat_start,
    long: eventDto.long_start,
    severity: eventDto.severity_level,
    shipName: eventDto.ship_name,
    start: new Date(eventDto.timestamp_start),
    sog: eventDto.sog_start,
    cog: eventDto.cog_start,
    end: eventDto.timestamp_end ? new Date(eventDto.timestamp_end) : null,
    pitch: eventDto.pitch_start,
    roll: eventDto.roll_start,
    polygonId: eventDto.polygon_uuid,
    areaOfInterest: eventDto.area_of_interest,
    screenshots: eventDto.screenshots.map(screenshot =>
      mapRtEventScreenshotDtoToRtEventScreenshot(screenshot)
    ),
    imuSamples: eventDto.imu_samples.map(sample =>
      mapRtEventImuSampleDtoToRtEventImuSample(sample)
    ),
    aoiComplianceLimitations: mapRtEventLimitationsDtoToRtEventLimitations(
      eventDto.aoi_compliance_limitations
    ),
  };
}

export function mapRtEventScreenshotDtoToRtEventScreenshot(
  screenshot: RtEventScreenshotDto
): RTEventScreenshot {
  return {
    url: screenshot.cdn_url,
    timestamp: screenshot.captured_at,
  };
}

export function mapRtEventImuSampleDtoToRtEventImuSample(
  imuSample: RtEventImuSampleDto
): RtEventImuSample {
  return {
    sog: imuSample.sog,
    roll: imuSample.roll,
    pitch: imuSample.pitch,
    sampledAt: new Date(imuSample.sampled_at),
  };
}

export function mapRtEventLimitationsDtoToRtEventLimitations(
  limitations: RTEventLimitationsDto | undefined
): RTEventLimitations | null {
  return limitations
    ? {
        sog: {
          minAllowed: limitations.min_allowed_sog,
          maxAllowed: limitations.max_allowed_sog,
          ...(limitations.sog_not_in_range
            ? {
                notInRange: {
                  min: limitations.sog_not_in_range.min_value,
                  max: limitations.sog_not_in_range.max_value,
                },
              }
            : {}),
        },
        cog: {
          minAllowed: limitations.min_allowed_cog,
          maxAllowed: limitations.max_allowed_cog,
          ...(limitations.cog_not_in_range
            ? {
                notInRange: {
                  min: limitations.cog_not_in_range.min_value,
                  max: limitations.cog_not_in_range.max_value,
                },
              }
            : {}),
        },
      }
    : null;
}

export function mapEventTODownloadedEvent(event: Event): DownloadedEvent {
  return {
    id: event.id,
    eventType: event.eventType,
    congestionLevel: event.congestionLevel,
    lat: event.lat,
    long: event.long,
    location: event.location,
    subLocation: event.subLocation,
    severityLevel: event.severityLevel,
    shipName: event.shipName,
    timestamp: event.timestamp,
    aisTargetSog: event.aisTargetSog,
    aisTargetId: event.aisTargetId,
    aisTargetMinDis: event.aisTargetMinDis,
    aisTargetType: event.aisTargetType,
    colregClassification: event.colregClassification,
    depth: event.depth,
    pitch: event.pitch,
    roll: event.roll,
    rot: event.rot,
    sogDiff: event.sogDiff,
    sog: event.sog,
  };
}

export function appliedFiltersComparar(
  prevAppliedFilters: AppliedEventFilters,
  currAppliedFilters: AppliedEventFilters
): boolean {
  return _.isEqual(prevAppliedFilters, currAppliedFilters);
}

export function calculateJsonDelta(prevState: any, currentState: any): any {
  const delta: any = {};

  // Iterate over the current state properties
  for (const key in currentState) {
    // Check if the property exists in the previous state
    if (Object.prototype.hasOwnProperty.call(prevState, key)) {
      // Compare values
      if (
        JSON.stringify(prevState[key]) !== JSON.stringify(currentState[key])
      ) {
        // Value has changed, add previous and new value to the delta
        delta[key] = { oldValue: prevState[key], newValue: currentState[key] };
      }
    } else {
      // Property is newly added, add it to the delta with new value
      delta[key] = { oldValue: null, newValue: currentState[key] };
    }
  }

  // Iterate over the previous state properties to find removed properties
  for (const key in prevState) {
    if (!Object.prototype.hasOwnProperty.call(currentState, key)) {
      // Property has been removed, add it to the delta with null new value
      delta[key] = { oldValue: prevState[key], newValue: null };
    }
  }
  return delta;
}

export function formatRTEventLimitations(
  rtEventLimitations: RTEventLimitations
): string {
  const rtEventLimitationsDescriptions: string[] = [];
  if (rtEventLimitations.sog.maxAllowed !== undefined) {
    rtEventLimitationsDescriptions.push(
      `Max Allowed SOG: ${rtEventLimitations.sog.maxAllowed} Kts`
    );
  }
  if (rtEventLimitations.sog.minAllowed !== undefined) {
    rtEventLimitationsDescriptions.push(
      `Min Allowed SOG: ${rtEventLimitations.sog.minAllowed} Kts`
    );
  }
  if (rtEventLimitations.sog.notInRange !== undefined) {
    rtEventLimitationsDescriptions.push(
      `SOG Is Not Within: ${rtEventLimitations.sog.notInRange.min}-${rtEventLimitations.sog.notInRange.max} Kts`
    );
  }
  if (rtEventLimitations.cog.maxAllowed !== undefined) {
    rtEventLimitationsDescriptions.push(
      `Max Allowed COG: ${rtEventLimitations.cog.maxAllowed}°`
    );
  }
  if (rtEventLimitations.cog.minAllowed !== undefined) {
    rtEventLimitationsDescriptions.push(
      `Min Allowed COG: ${rtEventLimitations.cog.minAllowed}°`
    );
  }
  if (rtEventLimitations.cog.notInRange !== undefined) {
    rtEventLimitationsDescriptions.push(
      `COG Is Not Within: ${rtEventLimitations.cog.notInRange.min}°-${rtEventLimitations.cog.notInRange.max}°`
    );
  }
  return rtEventLimitationsDescriptions.join(', ');
}

export function formatPolygonAreaLabel(
  rtEvent: RtEventToMap,
  polygon: Polygon,
  type?: EventType
): string {
  let formatedPolygonAreaLabel = rtEvent.areaOfInterest ?? polygon.polygonArea;
  if (type === 'Compliance' && rtEvent.aoiComplianceLimitations) {
    const formattedLimitations = `Limitation: ${formatRTEventLimitations(
      rtEvent.aoiComplianceLimitations
    )}`;
    formatedPolygonAreaLabel = `${formatedPolygonAreaLabel}\n${formattedLimitations}`;
  }
  return formatedPolygonAreaLabel;
}

const noGoZonePolygonColor: PolygonColor = {
  color: 'rgba(231, 50, 82, 0.20)',
  borderColor: '#E73252',
};
const compliancePolygonColor: PolygonColor = {
  color: 'rgba(156, 156, 156, 0.30)',
  borderColor: '#9C9C9C',
};
const defaultPolygoneColor: PolygonColor = noGoZonePolygonColor;

export function mapEventTypeToPolygonColor(eventType: EventType): PolygonColor {
  switch (eventType) {
    case 'No Go Zone':
      return noGoZonePolygonColor;
    case 'Compliance':
      return compliancePolygonColor;
    default:
      return defaultPolygoneColor;
  }
}
export function mapEventTypeToLabelSource(
  eventType: EventType
): MapSettingsOption {
  switch (eventType) {
    case 'No Go Zone':
      return MapSettingsOption.NoGoAreas;
    case 'Compliance':
      return MapSettingsOption.ComplianceAreas;
    default:
      return MapSettingsOption.NoGoAreas;
  }
}

export function buildPolygonEntity(
  rtEvent: RtEventToMap,
  polygon: Polygon,
  type: EventType
): PolygonEntity {
  const { color, borderColor } = mapEventTypeToPolygonColor(type);
  return {
    id: polygon.polygonId,
    textLabel: formatPolygonAreaLabel(rtEvent, polygon),
    labelType: 'red',
    labelSource: polygon.polygonType,
    coordinates: polygon.edges,
    color,
    borderColor,
  } as PolygonEntity;
}

export function mapEventsToMapEntities(
  events: EventLocation[] | Events[],
  selectedEvent: Events | null
): MapEntity[] {
  return events.map((event): MapEntity => {
    const severityLevel =
      (event as EventLocation).severityLevel || (event as Events).severity;
    const eventId = (event as EventLocation).id || (event as Events).eventId;
    const mapEvent: MapEntity = {
      id: String(eventId),
      image: _.snakeCase(`/assets/map/events/${severityLevel}.png`),
      long: event.long,
      lat: event.lat,
      eyeOffset: [0.0, 0.0, 0.0],
    };

    if (selectedEvent?.eventId === eventId) {
      return {
        ...mapEvent,
        image: _.snakeCase(
          `/assets/map/events/${selectedEvent.type}/${severityLevel}.png`
        ),
        eyeOffset: [0.0, 0.0, -10000.0],
      };
    }
    return mapEvent;
  });
}

export function isWithinFourHours(time: Date | string): boolean {
  if (time) {
    const dateTime = new Date(time);
    const now = new Date();
    const fourHoursInMillis = 4 * 60 * 60 * 1000;
    return now.getTime() - dateTime.getTime() <= fourHoursInMillis;
  }
  return false;
}
