import { Pipe, PipeTransform } from '@angular/core';
import {
  TargetTypeNames,
  targetTypeNamesMap,
} from 'src/app/core/models/events-filters.model';

@Pipe({
  name: 'targetTypeName',
  standalone: true,
})
export class TargetTypeNamePipe implements PipeTransform {
  transform(targetType: string): string {
    if (targetTypeNamesMap.hasOwnProperty(targetType)) {
      return targetTypeNamesMap[targetType as TargetTypeNames];
    } else {
      return targetType;
    }
  }
}
