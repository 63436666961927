import {
  AfterContentInit,
  Component,
  ContentChild,
  ContentChildren,
  Input,
  OnInit,
  QueryList,
} from '@angular/core';
import { DestroyRef } from '../../../services/destroy-ref.service';
import { BehaviorSubject, takeUntil } from 'rxjs';
import { TreeContentComponent } from '../tree-content/tree-content.component';

@Component({
  selector: 'orc-tree-option',
  templateUrl: './tree-option.component.html',
  styleUrls: ['./tree-option.component.scss'],
})
export class TreeOptionComponent implements OnInit, AfterContentInit {
  @Input()
  key!: string;

  constructor(private dr: DestroyRef) {}

  private _options$ = new BehaviorSubject<TreeOptionComponent[]>([]);
  get options$() {
    return this._options$.asObservable();
  }

  @ContentChildren(TreeOptionComponent)
  options!: QueryList<TreeOptionComponent>;

  @ContentChild(TreeContentComponent)
  content: TreeContentComponent | null = null;

  ngOnInit(): void {}

  ngAfterContentInit(): void {
    this._options$.next([...this.options]);
    this.options.changes.pipe(takeUntil(this.dr.destroy$)).subscribe((val) => {
      this._options$.next(val);
    });
  }
}
