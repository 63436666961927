<app-pie-chart
  [dataExists]="(dataExists$ | async)!"
  [title]="'Events By Type'"
  [graphData]="(shipEventCountByAlertNameView$ | async)!"
  [colorScheme]="colorScheme"
  [legendClass]="'event-types'"
  [totalCount]="(totalShipEventCount$ | async)!"
  [countTitle]="'Total Events'"
  [tooltipMapping]="alertNameMap"
  [countByGroup]="(shipEventCountByAlertNameView$ | async)!"></app-pie-chart>
