import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MapLayerChange } from '../models/map.entity.model';

@Injectable({
  providedIn: 'root',
})
export class MapLayersMenuService {
  private settingsChangedSubject = new Subject<MapLayerChange>();
  settingsChanged$ = this.settingsChangedSubject.asObservable();

  emitSettingsChanged(event: any) {
    this.settingsChangedSubject.next(event);
  }
  constructor() {}
}
