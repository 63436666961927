<app-no-data [dataExists]="dataExists$ | async">
  <div class="container">
    <div class="sort-by">
      <app-widget-title
        class="link"
        routerLink="/private/fleet"
        [widgetTitleType]="'subTitle'"
        [showEventsDayFilterTitle$]="showEventsDayFilterTitle$"
        [selectedDateRange$]="selectedDateRange$"
        [title]="'Safety Score Ships'">
      </app-widget-title>
      <div class="sort-container">
        <mat-form-field
          appearance="fill"
          class="custom-form-field-selection selection">
          <mat-select
            panelClass="custom-select"
            [value]="selectedSortOption$ | async"
            (valueChange)="onSortByChange($event)">
            <mat-option
              *ngFor="let sortOption of shipsSortList"
              [value]="sortOption.value">
              {{ sortOption.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="data-container">
      <div
        class="ship-container"
        data-test="risk-item"
        *ngFor="let shipSafetyScore of safteyScoreShips$ | async">
        <span
          class="body1 link"
          [routerLink]="['/private/fleet/ship', shipSafetyScore.shipId]"
          (click)="onShipNameClick(shipSafetyScore)"
          >{{ shipSafetyScore.shipName }}</span
        >
        <div
          class="score"
          [routerLink]="['/private/fleet/ship', shipSafetyScore.shipId]">
          <h2 class="regular">
            {{ shipSafetyScore.avg_score | number: '1.0-0' }}
          </h2>
          <span class="subtitle1">/10</span>
          <div class="separator"></div>
          <app-delta-risks
            [currRisk]="shipSafetyScore.scoreEnd"
            [oldRisk]="shipSafetyScore.scoreStart"></app-delta-risks>
        </div>
      </div>
    </div>
  </div>
</app-no-data>
