import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, filter, firstValueFrom, map, Observable } from 'rxjs';
import {
  CommentsUser,
  IComment,
} from 'src/app/shared/view-models/comments.view.model';
import { inThousands } from '../../../shared/utils/calculators/general';
import { DeleteCommentDialogComponent } from '../../components/dialogs/delete-comment-dialog/delete-comment-dialog.component';
import { FeatureFlagService } from '../../services/feature-toggle.service';
import { RoutingService } from '../../services/routing.service';
import { CoreState } from '../../store/state/core.state';
import {
  AuthenticationSelectors,
  CommentsActions,
  CommentsSelectors,
  EventsActions,
} from '../../store/types';
import {
  buildDayVideos,
  buildThermalVideos,
  buildViewsVideos,
} from '../../utils/videos';
import {
  Events,
  isCloseEncounterEvent,
  isHighPitchRollEvent,
  isHighRot,
  isSpeedDropEvent,
  isUKCEvent,
  SelectedVideo,
  Videos,
  VideosNames,
  ViewsVideo,
  viewsVideoMap,
} from '../../view-models/event.view.model';

@Component({
  selector: 'app-explore-event',
  templateUrl: './explore-event.component.html',
  styleUrls: ['./explore-event.component.scss'],
})
export class ExploreEventComponent implements OnInit, OnDestroy {
  selectedEvent!: Events;
  selectedTabIndex: number = 0;

  isCloseEncounterEvent = isCloseEncounterEvent;
  isHighPitchRollEvent = isHighPitchRollEvent;
  isSpeedDropEvent = isSpeedDropEvent;
  isUKCEvent = isUKCEvent;
  isHighRotEvent = isHighRot;
  prevUrl: string = '';

  showComments$!: Observable<boolean>;
  showEfficiency$!: Observable<boolean>;
  keplerUrl$!: Observable<string>;
  token$!: Observable<string | undefined>;
  viewsVideos$!: Observable<ViewsVideo>;
  dayVideos$!: Observable<Videos>;
  thermalVideos$!: Observable<Videos>;
  combinedVideos$!: Observable<{ type: string; videos: any[] }[]>;

  cameraControl = new FormControl<{ key: string; value: string | null } | null>(
    null
  );
  selectedVideo$!: Observable<SelectedVideo>;

  inThousands = inThousands;

  comments$!: Observable<IComment[]>;
  currentUserId$!: Observable<string>;
  currentUserFullName$!: Observable<string>;
  usersToMention$!: Observable<CommentsUser[]>;

  commentTabLabel$!: Observable<{ text: string; count: number }>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<CoreState>,
    private routingService: RoutingService,
    private featureFlag: FeatureFlagService,
    private fb: FormBuilder,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.initializeComponent();
    this.initializeObservables();

    this.route.queryParams.subscribe(params => {
      this.selectedTabIndex = params['tab'] === 'comments' ? 1 : 0;
    });

    this.prevUrl = this.routingService.getPreviousUrl();

    this.showComments$ = this.featureFlag.getFeatureFlag$('comments');

    this.setDefaultVideo();

    this.commentTabLabel$ = this.comments$.pipe(
      map(comments => {
        const count = comments?.length ?? 0;
        return {
          text: 'Comments',
          count: count,
        };
      })
    );
  }

  ngOnDestroy(): void {
    this.store.dispatch(CommentsActions.emptyCurrentThreadInStore());
  }

  private initializeComponent(): void {
    this.selectedEvent = this.route.snapshot.data['selectedEvent'];
  }

  private initializeObservables(): void {
    this.comments$ = this.store.select(
      CommentsSelectors.selectCurrentThreadComments
    );
    this.token$ = this.store.select(AuthenticationSelectors.selectToken);
    this.keplerUrl$ = this.buildKeplerUrlObservable();
    this.showEfficiency$ = this.featureFlag.getFeatureFlag$('efficiency');
    this.viewsVideos$ = this.buildViewsVideosObservable();
    this.dayVideos$ = this.buildDayVideosObservable();
    this.thermalVideos$ = this.buildThermalVideosObservable();
    this.combinedVideos$ = this.buildCombinedVideosObservable();
    this.currentUserId$ = this.store.select(
      AuthenticationSelectors.selectSelectedUserId
    );
    this.currentUserFullName$ = this.store.select(
      AuthenticationSelectors.selectSelectedUserFullName
    );
    this.usersToMention$ = this.store.select(
      CommentsSelectors.selectUsersToMentionOnThread
    );
  }

  onTabChanged(index: number) {
    const tabName = index === 0 ? 'video' : 'comments';
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { tab: tabName },
      queryParamsHandling: 'merge',
    });
  }

  private buildKeplerUrlObservable(): Observable<string> {
    return this.token$.pipe(
      filter(_ => !!this.selectedEvent.keplerUrl),
      map(token => this.selectedEvent.keplerUrl + `?token=${token}`)
    );
  }

  private buildViewsVideosObservable(): Observable<ViewsVideo> {
    return this.token$.pipe(
      map(token => buildViewsVideos(this.selectedEvent.videoUrls, token!))
    );
  }

  private buildDayVideosObservable(): Observable<Videos> {
    return this.token$.pipe(
      map(token => buildDayVideos(this.selectedEvent.videoUrls, token!))
    );
  }

  private buildThermalVideosObservable(): Observable<Videos> {
    return this.token$.pipe(
      map(token => buildThermalVideos(this.selectedEvent.videoUrls, token!))
    );
  }

  private buildCombinedVideosObservable(): Observable<
    { type: string; videos: any[] }[]
  > {
    return combineLatest({
      viewsVideos: this.viewsVideos$,
      dayVideos: this.dayVideos$,
      thermalVideos: this.thermalVideos$,
    }).pipe(
      map(({ viewsVideos, dayVideos, thermalVideos }) => [
        { type: 'Views', videos: this.getVideosArray(viewsVideos) },
        { type: 'Day Cameras', videos: this.getVideosArray(dayVideos) },
        { type: 'Thermal Cameras', videos: this.getVideosArray(thermalVideos) },
      ])
    );
  }

  onVideoClick(video: { key: string; value: string | null }): void {
    this.cameraControl.setValue({ key: video.key, value: video.value });
  }

  getVideosArray(videos: any): any[] {
    return Object.entries(videos)
      .filter(([key, value]) => value !== null)
      .map(([key, value]) => ({
        key: key as VideosNames,
        value,
      }));
  }

  async downloadKepler(): Promise<void> {
    const keplerUrl = await firstValueFrom(this.keplerUrl$);
    this.store.dispatch(
      EventsActions.downloadKeplerFile({
        url: keplerUrl,
        event: this.selectedEvent,
      })
    );
  }

  navigateBack(): void {
    if (this.prevUrl.includes('/fleet')) {
      this.router.navigateByUrl('/private/fleet');
    } else {
      if (this.prevUrl.includes('/events')) {
        this.router.navigateByUrl('/private/events');
      } else this.router.navigateByUrl('/private/overview');
    }
  }

  onCommentSubmit(commentData: {
    content: string;
    mentionedIds: string[];
  }): void {
    this.store.dispatch(
      CommentsActions.addCommentToThread({
        threadId: this.selectedEvent.eventId,
        content: commentData.content,
        mentions: commentData.mentionedIds,
      })
    );
  }

  onCommentEdit(commentData: { id: string; content: string }): void {
    this.store.dispatch(
      CommentsActions.editCommentOnThread({
        threadId: this.selectedEvent.eventId,
        commentId: commentData.id,
        content: commentData.content,
      })
    );
  }

  onCommentDelete(commentId: string): void {
    const dialogRef = this.dialog.open(DeleteCommentDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(
          CommentsActions.deleteCommentOnThread({
            threadId: this.selectedEvent.eventId,
            commentId: commentId,
          })
        );
      }
    });
  }

  setDefaultVideo(): void {
    this.combinedVideos$.subscribe(categories => {
      categories.forEach(category => {
        category.videos.forEach(video => {
          if (video.key === viewsVideoMap['screen']) {
            this.cameraControl.setValue({ key: video.key, value: video.value });
          }
        });
      });
    });
  }
}
