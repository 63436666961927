import { createSelector } from '@ngrx/store';
import { defaultFleet } from '../../models/fleet.model';
import { AuthenticationState } from '../state/authentication.state';
import { CoreState } from '../state/core.state';

const selectAuthenticationState = (state: CoreState): AuthenticationState =>
  state.authentication;

export const selectIsAuthenticated = createSelector(
  selectAuthenticationState,
  state => (state.authenticatedUser ? true : false)
);
export const selectPreliminaryUser = createSelector(
  selectAuthenticationState,
  state => state.preliminaryUser
);

export const selectUsersCompanyName = createSelector(
  selectPreliminaryUser,
  preliminaryUser => preliminaryUser?.companyName ?? ''
);

export const selectPreliminaryUserId = createSelector(
  selectPreliminaryUser,
  preliminaryUser => preliminaryUser?.id
);

export const selectAuthenticatedUser = createSelector(
  selectAuthenticationState,
  state => state.authenticatedUser
);

export const selectAuthenticatedUserName = createSelector(
  selectAuthenticatedUser,
  user => {
    if (user && user.token != '') {
      return user.firstName + ' ' + user.lastName;
    }
    return '';
  }
);

export const selectIsUserOrcaAdmin = createSelector(
  selectAuthenticatedUser,
  user => {
    if (user && user.token != '') {
      return user.role === 'orca-admin';
    } else {
      return null;
    }
  }
);

export const selectFleets = createSelector(
  selectAuthenticationState,
  state => state.fleets
);

export const selectToken = createSelector(
  selectAuthenticatedUser,
  user => user?.token
);

export const selectFleetName = createSelector(
  selectAuthenticatedUser,
  user => user?.companyName
);

export const selectSelectedFleetId = createSelector(
  selectAuthenticatedUser,
  user => user?.fleetId
);

export const selectSelectedUserId = createSelector(
  selectAuthenticatedUser,
  user => user?.id || ''
);
export const selectSelectedUserFullName = createSelector(
  selectAuthenticatedUser,
  user => user?.firstName + ' ' + user?.lastName || ''
);

export const selectFleetNameOfSelectedFleetId = createSelector(
  selectSelectedFleetId,
  selectFleets,
  (selectedFleetId, fleets) => {
    const selectedFleet = fleets?.find(
      fleet => fleet.fleetId === selectedFleetId
    );
    return selectedFleet ? selectedFleet.companyName : defaultFleet.companyName;
  }
);

export const selectIsUserShipCaptain = createSelector(
  selectAuthenticatedUser,
  user => user?.role === 'ship-captain'
);

export const selectAuthenticatedUserShipId = createSelector(
  selectAuthenticatedUser,
  user => user?.shipId
);
