import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { ShipSafteyScore } from 'src/app/core/models/ship.model';
import { ShipsSortBy } from 'src/app/core/models/ships-filters.model';
import { CoreState } from 'src/app/core/store/state/core.state';
import {
  EventsSelectors,
  FiltersSelectors,
  ShipsActions,
  ShipsSelectors,
} from 'src/app/core/store/types';
import { shipsSortList } from 'src/app/core/view-models/ships.sore.view.model';
import { DateFilterKeys } from 'src/app/shared/view-models/dates.view.model';

@Component({
  selector: 'app-safety-score-ships',
  templateUrl: './safety-score-ships.component.html',
  styleUrls: ['./safety-score-ships.component.scss'],
})
export class HighSafetyScoreComponent implements OnInit {
  safteyScoreShips$!: Observable<ShipSafteyScore[]>;
  selectedSortOption$!: Observable<ShipsSortBy>;
  shipsSortList = shipsSortList;
  selectedDateRange$!: Observable<DateFilterKeys>;
  showEventsDayFilterTitle$!: Observable<boolean>;

  dataExists$!: Observable<boolean>;

  constructor(public store: Store<CoreState>) {}
  ngOnInit(): void {
    this.safteyScoreShips$ = this.store.select(
      ShipsSelectors.selectLimitedSafetyScoreShips
    );

    this.dataExists$ = this.safteyScoreShips$.pipe(
      map(ships => ships.length > 0)
    );
    this.selectedDateRange$ = this.store.select(
      FiltersSelectors.selectFilterdDatesKey
    );
    this.showEventsDayFilterTitle$ = this.store.select(
      EventsSelectors.selectIsEventsFilterByDay
    );
    this.selectedSortOption$ = this.store.select(
      ShipsSelectors.selectSafteyScoreShipsSortBy
    );
  }

  onSortByChange(sortBy: ShipsSortBy): void {
    this.store.dispatch(ShipsActions.saveSortByOptionOnUserChange({ sortBy }));
  }

  onShipNameClick(shipSafetyScore: ShipSafteyScore) {
    this.store.dispatch(
      ShipsActions.shipNameClicked({
        shipId: shipSafetyScore.shipId,
        shipName: shipSafetyScore.shipName,
      })
    );
  }
}
