export interface FleetDto {
  readonly fleet_name: string;
  readonly fleet_id: number;
}

export interface Fleet {
  readonly companyName: string;
  readonly fleetId: number;
}
export const defaultFleet: Fleet = {
  companyName: 'All Fleets',
  fleetId: -1, // seems mat select cant handle null as value
};

export interface ShipDto {
  readonly ship_id: number;
  readonly ship_name: string;
}

export interface Ship {
  readonly shipId: number;
  readonly shipName: string;
}

export interface PolygonDto {
  readonly polygon_id: string;
  readonly polygon_area: string;
  readonly edges: string;
  readonly polygon_type_name: string;
}
export interface ShipLastLocationsDto {
  readonly locations: ShipLastLocationDto[];
}
export interface ShipLastLocationDto {
  readonly latitude: number;
  readonly longitude: number;
  readonly sog: number;
  readonly time: Date;
}

export type polygonType = 'compliance' | 'no_go_zone';
