import { Injectable } from '@angular/core';

import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';

import { Store } from '@ngrx/store';
import {
  catchError,
  distinctUntilChanged,
  filter,
  from,
  map,
  mergeMap,
  of,
  switchMap,
  withLatestFrom,
} from 'rxjs';
import { Position } from 'src/app/map/models/position.model';
import { InsightReadDto, InsightVoteDto } from '../../models/insights.models';
import { defaultOverviewEventsData } from '../../models/overview.models';
import { EventsService } from '../../services/events.service';
import { FleetService } from '../../services/fleet.service';
import { InsightsService } from '../../services/insights.service';
import { ShipsService } from '../../services/ships.service';
import { mapEventToEventsType } from '../../utils/events';
import { mapInsightsDtoToInsights } from '../../utils/insights';
import { defaultAppliedOverviewEventFilters } from '../../view-models/filter.view.model';
import { CoreState } from '../state/core.state';
import {
  AuthenticationActions,
  AuthenticationSelectors,
  FiltersActions,
  FiltersSelectors,
  OverviewActions,
  OverviewSelector,
  ShipsActions,
  ShipsSelectors,
} from '../types';

@Injectable()
export class OverviewEffects {
  constructor(
    private actions$: Actions,
    private eventsService: EventsService,
    private fleetService: FleetService,
    private shipsService: ShipsService,
    private insightsService: InsightsService,
    private store: Store<CoreState>
  ) {}

  resetSelectedShipDataOnShipsChange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShipsActions.saveShipsOnAdminUserAuth),
      map(() => OverviewActions.resetSelectedShipDataOnShipsChange())
    );
  });

  getOverviewEventsLocationOnAuthOrFilterDateOrFleetIdChange$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          AuthenticationActions.updateUserOnAdminSetClaims,
          AuthenticationActions.updateTokenOnRegularUserSetClaims,
          FiltersActions.saveSelectedDatesFilter,
          FiltersActions.resetDateFilterToDefault
        ),
        switchMap(action =>
          of(action).pipe(
            withLatestFrom(
              this.store.select(FiltersSelectors.selectFilteredDates)
            )
          )
        ),
        switchMap(([_, dateFilter]) =>
          from(
            this.eventsService.getEventsLocation$(
              defaultAppliedOverviewEventFilters,
              dateFilter!,
              'date'
            )
          ).pipe(
            map(eventsLocation =>
              OverviewActions.saveOverviewEventsLocationOnChange({
                eventsLocation,
              })
            )
          )
        )
      );
    }
  );

  getOverviewPolygonsonOnAuthOrFilterDateOrFleetIdChange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        AuthenticationActions.updateUserOnAdminSetClaims,
        AuthenticationActions.updateTokenOnRegularUserSetClaims
      ),
      switchMap(() =>
        this.store.select(AuthenticationSelectors.selectToken).pipe(
          filter(token => !!token),
          switchMap(() =>
            from(this.fleetService.getGeoPolygons$()).pipe(
              map(polygons =>
                polygons.filter(
                  (polygon, index, self) =>
                    index ===
                    self.findIndex(p => p.polygonId === polygon.polygonId)
                )
              ),
              map(filteredPolygons =>
                OverviewActions.saveOverviewPolygons({
                  polygons: filteredPolygons,
                })
              ),
              catchError(() =>
                of(OverviewActions.saveOverviewPolygons({ polygons: [] }))
              )
            )
          )
        )
      )
    );
  });

  getSelectedShipEventsDataOnAuthOrFilterDateOrFleetIdChange$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          AuthenticationActions.updateUserOnAdminSetClaims,
          AuthenticationActions.updateTokenOnRegularUserSetClaims,
          FiltersActions.saveSelectedDatesFilter,
          FiltersActions.resetDateFilterToDefault
        ),
        switchMap(action =>
          of(action).pipe(
            withLatestFrom(
              this.store.select(FiltersSelectors.selectFilteredDates),
              this.store.select(OverviewSelector.selectSelectedShip)
            )
          )
        ),
        switchMap(([_, dateFilter, selectedShip]) => {
          if (selectedShip) {
            return from(
              this.eventsService.getEventsAndCount$(
                {
                  ...defaultAppliedOverviewEventFilters,
                  shipName: { value: selectedShip.shipName },
                },
                'date',
                dateFilter!,
                1,
                10
              )
            ).pipe(
              map(selectedShipEventsData =>
                OverviewActions.saveSelectedShipEventsData({
                  selectedShipEventsData,
                })
              )
            );
          } else {
            return of(
              OverviewActions.saveSelectedShipEventsData({
                selectedShipEventsData: defaultOverviewEventsData,
              })
            );
          }
        })
      );
    }
  );

  getSelectedShipEventsDataOnSelectShipInMap$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OverviewActions.setSelectedShip),
      switchMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(FiltersSelectors.selectFilteredDates)
          )
        )
      ),
      switchMap(([action, dateFilter]) =>
        from(
          this.eventsService.getEventsAndCount$(
            {
              ...defaultAppliedOverviewEventFilters,
              shipName: { value: action.selectedShip.shipName },
            },
            'date',
            dateFilter!,
            1,
            10
          )
        ).pipe(
          map(selectedShipEventsData =>
            OverviewActions.saveSelectedShipEventsData({
              selectedShipEventsData,
            })
          )
        )
      )
    );
  });

  getMoreEventsOnScrolling$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OverviewActions.getMoreEventsOnUserScroll),
      switchMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(FiltersSelectors.selectFilteredDates),
            this.store.select(OverviewSelector.selectSelectedShip),
            this.store.select(OverviewSelector.selectSelectedShipEventsData)
          )
        )
      ),
      switchMap(([_, dateFilter, selectedShip, selectedShipEventsData]) => {
        if (
          selectedShipEventsData.totalCount! >
          selectedShipEventsData.events!.length
        ) {
          return from(
            this.eventsService.getEvents$(
              {
                ...defaultAppliedOverviewEventFilters,
                shipName: { value: selectedShip!.shipName },
              },
              'date',
              dateFilter!,
              1,
              10
            )
          ).pipe(
            map(events => {
              if (events.length > 0) {
                return OverviewActions.addMoreEventsAfterScroll({
                  events,
                });
              } else {
                return OverviewActions.reachedEndOfEvents();
              }
            })
          );
        } else {
          return of(OverviewActions.reachedEndOfEvents());
        }
      })
    );
  });

  resetEventsPagination$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OverviewActions.saveSelectedShipEventsData),
      switchMap(_ => of(OverviewActions.resetPagination()))
    );
  });

  resetEventsScroll$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OverviewActions.saveSelectedShipEventsData),
      switchMap(_ => of(OverviewActions.resetScroll()))
    );
  });

  setSelectedShip$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        OverviewActions.selectShipOnShipMapClick,
        OverviewActions.selectShipOnEventMapClick
      ),
      concatLatestFrom(() => this.store.select(ShipsSelectors.selectShips)),
      switchMap(([action, ships]) => {
        const selectedShip = ships.find(
          ship => ship.shipId === Number(action.shipId)
        );
        return selectedShip
          ? of(OverviewActions.setSelectedShip({ selectedShip }))
          : of();
      })
    );
  });

  getShipLastLocationsOnSelectedShip$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OverviewActions.setSelectedShip),
      switchMap(action =>
        from(
          this.fleetService.getShipLastLocations$(
            action.selectedShip.shipId,
            new Date()
          )
        ).pipe(
          map(shipLastLocations =>
            OverviewActions.saveOverviewShipLastLocations({
              selectedShipLastLocations: shipLastLocations,
            })
          )
        )
      )
    );
  });

  getShipSafetyScoresOnShipMapClick$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OverviewActions.setSelectedShip),
      concatLatestFrom(() =>
        this.store.select(FiltersSelectors.selectFilteredDates)
      ),
      switchMap(([action, dates]) => {
        return from(
          this.shipsService.getShipSafetyScores(
            action.selectedShip.shipId,
            dates
          )
        ).pipe(
          map(result =>
            OverviewActions.setShipSafetyScoresOnShipMapClick({
              shipSafetyScores: result,
            })
          )
        );
      })
    );
  });

  updateMapPositionOnSelectShip$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OverviewActions.setSelectedShip),
      switchMap(action => {
        const position: Position = {
          long: action.selectedShip.longitude,
          lat: action.selectedShip.latitude,
        };
        return of(
          OverviewActions.updateMapPositionOnSelectEntity({ position })
        );
      })
    );
  });

  updateMapPositionOnSelectEvent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OverviewActions.setSelectedOverviewEventList),
      switchMap(action => {
        const position: Position = {
          long: action.selectedEvent.long,
          lat: action.selectedEvent.lat,
        };
        return of(
          OverviewActions.updateMapPositionOnSelectEntity({ position })
        );
      })
    );
  });

  updateShipAndEventOnSelectEventMap$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OverviewActions.selectEventOnEventMapClick),
      distinctUntilChanged(),
      switchMap(action =>
        from(this.eventsService.getEventById(action.eventId.toString())).pipe(
          filter(event => event != null),
          map(event => mapEventToEventsType(event!)),
          mergeMap(event => [
            OverviewActions.selectShipOnEventMapClick({
              shipId: Number(event?.shipId),
            }),
            OverviewActions.setSelectedOverviewMapEvent({
              selectedEvent: event,
            }),
          ])
        )
      )
    );
  });

  getOverviewInsights$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        AuthenticationActions.updateUserOnAdminSetClaims,
        AuthenticationActions.updateTokenOnRegularUserSetClaims
      ),
      switchMap(() =>
        from(this.insightsService.getInsights$()).pipe(
          mergeMap(insights => {
            const mappedInsights = mapInsightsDtoToInsights(insights);
            const firstInsight =
              mappedInsights.length > 0 ? mappedInsights[0] : null;

            if (firstInsight) {
              return [
                OverviewActions.saveOverviewInsightsOnChange({
                  insights: mappedInsights,
                }),
                OverviewActions.insightLoad({
                  insight: firstInsight,
                }),
              ];
            } else {
              return [
                OverviewActions.saveOverviewInsightsOnChange({ insights: [] }),
              ];
            }
          }),
          mergeMap(action => of(action))
        )
      )
    );
  });

  updateVoteForInsight$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OverviewActions.setVoteForInsight),
      switchMap(action =>
        from(
          this.insightsService.vote(action.insight.insightId, action.likeVote)
        ).pipe(
          map(response =>
            OverviewActions.updateVoteForInsight({
              insightVote: response as InsightVoteDto,
              insight: action.insight,
            })
          )
        )
      )
    );
  });

  updateMarkAsReadinsight$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OverviewActions.insightLoad, OverviewActions.insightArrowClicked),
      switchMap(action =>
        from(
          this.insightsService.markAsRead(action.insight.insightId, new Date())
        ).pipe(
          map(response =>
            OverviewActions.updateReadStatusForInsight({
              insightRead: response as InsightReadDto,
            })
          )
        )
      )
    );
  });
}
