import { DropdownViewModel } from 'src/app/shared/view-models/dropdown.options.view.model';

export const shipsSortList: DropdownViewModel[] = [
  {
    value: 'low',
    viewValue: 'Lowest',
  },
  {
    value: 'high',
    viewValue: 'Highest',
  },
];
