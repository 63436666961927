<ng-template #defaultSubtitleTemplate let-event>
  {{ event.type }}
</ng-template>

<div class="header">
  <img class="header-image" [src]="eventPathImg" />
  <div class="header-content">
    <div class="ship-details">
      <span class="body1-bold">{{ event.shipName }}</span>
      <div class="ship-profile-link" (click)="navigateToShipProfile()">
        <img src="./assets/icons/ship_link_icon.svg" />
        <div class="ship-profile-link-text">Ship Profile</div>
      </div>
    </div>
    <div class="event-type">
      <div class="severity-{{ event.severity | lowercase }}">
        {{ event.severity | capitalizeFirstLetter }}
      </div>
      <span class="subtitle1">
        <ng-container
          *ngTemplateOutlet="
            subtitleTemplate ? subtitleTemplate : defaultSubtitleTemplate;
            context: { $implicit: event }
          "></ng-container>
      </span>
    </div>
  </div>
</div>
