import { Injectable } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { environment } from 'src/environments/environment';
import { ZoomOption } from '../models/map.view.models';
import { defaultMaxBounds, defaultPosition } from '../models/position.model';

@Injectable()
export class ViewerFactoryService {
  createMap(mapContainer: string, zoom: ZoomOption): mapboxgl.Map {
    var map = new mapboxgl.Map({
      container: mapContainer,
      style: 'mapbox://styles/eladv/clzjjeeir00ki01qy964k4isk',
      accessToken: environment.mapbox.mapProviderAccessToken,
      center: [defaultPosition.long, defaultPosition.lat],
      maxBounds: zoom === 'fit' ? defaultMaxBounds : undefined,
      dragRotate: false,
      touchZoomRotate: false,
    });
    return map;
  }
}
