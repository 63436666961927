<div class="container">
  <app-pie-chart
    [dataExists]="(dataExists$ | async)!"
    [title]="'Ships By Safety'"
    [graphData]="(shipsBySeverityLevelView$ | async)!"
    [colorScheme]="colorScheme"
    [legendClass]="'risk'"
    [totalCount]="shipsBySeverityTotalCount"
    [countTitle]="'Total Ships'"
    [tooltipMapping]="risksMap"
    [countByGroup]="(shipsBySeverityLevelView$ | async)!"
    (labelClick)="onPieComponentClick($event)"
    (sliceClick)="onPieComponentClick($event)"
    (centerTitleClick)="onCenterPieComponentClick()"></app-pie-chart>
</div>
