import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Inject, Injectable, Injector } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, scan, startWith } from 'rxjs/operators';
import { BusyIndicatorComponent } from '../../shared/components/busy-indicator/busy-indicator.component';
import { NotificationsService } from './notifications.service';

@Injectable({
  providedIn: 'root',
})
export class IsBusyService {
  private input$ = new Subject<boolean>();
  private isBusy$: Observable<boolean>;
  private overlayRef!: OverlayRef;
  private overlay!: Overlay;

  constructor(
    @Inject(Injector) private readonly injector: Injector,
    private notificationService: NotificationsService
  ) {
    this.isBusy$ = this.input$.pipe(
      map(val => (val ? 1 : -1)),
      startWith(0),
      scan((acc, val) => acc + val, 0),
      map(val => val > 0),
      distinctUntilChanged()
    );
  }

  init() {
    this.overlay = this.injector.get(Overlay);

    this.overlayRef = this.overlay.create({
      positionStrategy: this.overlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically(),
      hasBackdrop: true,
      backdropClass: 'app-overlay',
    });

    this.isBusy$.subscribe(val => {
      if (val) {
        this.showOverlay();
      } else {
        this.stopOverlay();
      }
    });
  }

  private showOverlay() {
    this.overlayRef.attach(new ComponentPortal(BusyIndicatorComponent));
  }

  private stopOverlay() {
    this.overlayRef.detach();
  }

  add(
    action: () => Promise<any>,
    successMessage?: string,
    errorMessage?: string
  ): Promise<any> {
    this.input$.next(true);
    const promise = action();

    if (successMessage !== undefined) {
      promise.then(() => this.notificationService.success(successMessage));
    }

    if (errorMessage !== undefined) {
      promise.catch(_ => this.notificationService.error(errorMessage));
    }

    promise.finally(() => this.input$.next(false));
    return promise;
  }
}
