<div class="container">
  <p class="title">{{ insight.title }}</p>
  <div class="content">
    <p class="text">
      <ng-container
        *ngTemplateOutlet="
          insightData;
          context: { $implicit: insight }
        "></ng-container>
    </p>
    <p class="date">
      {{ insight.dateUpdated | date: 'dd MMM yyyy HH:mm' }}
    </p>
  </div>
  <div class="footer">
    <div class="link" routerLink="{{ insight.link.linkTo }}">
      {{ insight.link.text }}
    </div>
    <app-like-unlike
      [isLikeActive]="insight.likeVote"
      (statusLikeChanged)="updateStatusLike($event)"></app-like-unlike>
  </div>
</div>
