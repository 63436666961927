import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as shape from 'd3-shape';
import { map, Observable } from 'rxjs';
import { CoreState } from 'src/app/core/store/state/core.state';
import {
  EventsSelectors,
  FiltersSelectors,
  ShipsSelectors,
} from 'src/app/core/store/types';
import { SafetyScoreByDate } from 'src/app/core/view-models/safety.score.view.model';
import { tranformDateToDayMonth } from 'src/app/shared/utils/date-transforms/dateToDayMonth';
import { customXTicks } from 'src/app/shared/utils/graphs/general';
import { DateFilterKeys } from 'src/app/shared/view-models/dates.view.model';

@Component({
  selector: 'app-fleet-safety-graph',
  templateUrl: './fleet-safety-graph.component.html',
  styleUrls: ['./fleet-safety-graph.component.scss'],
})
export class FleetSafetyGraphComponent implements OnInit {
  safetyScoreByDate$!: Observable<SafetyScoreByDate[]>;
  safetyScoreByDateView$: any;

  yScaleMax = 10;
  yScaleMin = 0;
  linearCurve = shape.curveLinear;
  xAxisTicks$!: Observable<Date[]>;

  endDateAvgSeverityScore$!: Observable<number>;
  startDateAvgSeverityScore$!: Observable<number>;
  selectedDateRange$!: Observable<DateFilterKeys>;
  dataExists$!: Observable<boolean>;
  showEventsDayFilterTitle$!: Observable<boolean>;
  fleetAverageSafetyScore$!: Observable<number>;
  orcaAverageSafetyScore$!: Observable<number>;
  showModal: boolean = false;
  modalText: string =
    'This is your fleet average score, considering all ships within your fleet, and their safety score.';

  constructor(public store: Store<CoreState>) {}

  toggleShowModal() {
    this.showModal = !this.showModal;
  }

  ngOnInit(): void {
    this.safetyScoreByDate$ = this.store.select(
      ShipsSelectors.selectSafetyScoreByDateWithoutNA
    );

    this.safetyScoreByDateView$ = this.safetyScoreByDate$.pipe(
      map(safetyScoreByDate =>
        safetyScoreByDate.map(safetyScore => {
          const customSafetyScore = {
            ...safetyScore,
            name: safetyScore.date,
            value: safetyScore.safetyScore,
          };
          return customSafetyScore;
        })
      ),
      map(safetyScoreView => {
        return [
          {
            name: 'Safety Score By Date',
            series: safetyScoreView,
          },
        ];
      })
    );

    this.xAxisTicks$ = this.safetyScoreByDate$.pipe(
      map(safetyScoreByDate =>
        safetyScoreByDate.map(safetyScore => safetyScore.date)
      ),
      map(dates => customXTicks(dates))
    );

    this.dataExists$ = this.safetyScoreByDate$.pipe(
      map(safetyScore => safetyScore.length > 0)
    );

    this.showEventsDayFilterTitle$ = this.store.select(
      EventsSelectors.selectIsEventsFilterByDay
    );

    this.selectedDateRange$ = this.store.select(
      FiltersSelectors.selectFilterdDatesKey
    );

    this.fleetAverageSafetyScore$ = this.store.select(
      ShipsSelectors.selectAverageFleetSafteyScore
    );

    this.orcaAverageSafetyScore$ = this.store.select(
      ShipsSelectors.selectAverageOrcaSafteyScore
    );

    this.endDateAvgSeverityScore$ = this.store.select(
      ShipsSelectors.selectEndDateAvgSeverityScore
    );

    this.startDateAvgSeverityScore$ = this.store.select(
      ShipsSelectors.selectStartDateAvgSeverityScore
    );
  }

  formatXLabel(date: Date): string {
    return tranformDateToDayMonth(date);
  }
}
