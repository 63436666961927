<div class="container">
  <app-range-slider
    [customSliderOptions]="(sliderTargetDistance$ | async)!"
    [title]="'Minimum Distance'"
    (rangeChanged)="
      onEventFiltersChange($event, 'minDistance')
    "></app-range-slider>

  <app-selection-dropdown
    [title]="'COLREG Classification'"
    [options]="(colregList$ | async)!"
    (valueChange)="onEventFiltersChange($event, 'colregClassifications')"
    [isMultipleChoice]="true"
    [selectedValue]="
      (appliedColregClassification$ | async)!
    "></app-selection-dropdown>

  <app-range-slider
    [customSliderOptions]="(sliderTargetSog$ | async)!"
    (rangeChanged)="onEventFiltersChange($event, 'targetSog')"
    [title]="'Target SOG'"></app-range-slider>

  <app-selection-dropdown
    [title]="'Target Type'"
    [options]="(targetTypeList$ | async)!"
    (valueChange)="onEventFiltersChange($event, 'targetType')"
    [isMultipleChoice]="true"
    [selectedValue]="(appliedTargetType$ | async)!"></app-selection-dropdown>
</div>
