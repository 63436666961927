import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';
import {
  CommentDto,
  CommentsUser,
  IComment,
} from 'src/app/shared/view-models/comments.view.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CommentsService {
  constructor(private http: HttpClient) {}

  getUsersByThreadId(threadId: string): Observable<CommentsUser[]> {
    return this.http.get<CommentsUser[]>(
      `${environment.api.users}/${threadId}`
    );
  }
  getCommentsByThreadId(threadId: string): Observable<IComment[]> {
    return this.http.get<IComment[]>(`${environment.api.comments}/${threadId}`);
  }

  addComment(
    threadId: string,
    commentDto: CommentDto
  ): Observable<IComment | null> {
    return this.http
      .post<IComment>(`${environment.api.comments}/${threadId}`, commentDto)
      .pipe(
        catchError(() => {
          return of(null);
        })
      );
  }

  updateComment(
    threadId: string,
    commentId: string,
    updateCommentDto: CommentDto
  ): Observable<IComment> {
    return this.http.patch<IComment>(
      `${environment.api.comments}/${threadId}/${commentId}`,
      updateCommentDto
    );
  }

  deleteComment(threadId: string, commentId: string): Observable<IComment> {
    return this.http.delete<IComment>(
      `${environment.api.comments}/${threadId}/${commentId}`
    );
  }
}
