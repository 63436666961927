/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { VectorTile } from '@mapbox/vector-tile';
import * as mapboxgl from 'mapbox-gl';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  CloudsLegendColors,
  CurrentsLegendColors,
  WaveLegendColors,
  WindLegendColors,
} from '../map-data/weather';

const WAVE_SWELL_SOURCE_ID = 'wave-swell-vector-tile-source';
const WAVE_SWELL_HEIGHT_FILL_LAYER_ID = 'wave-swell-height-fill';
const WAVE_SWELL_HEIGHT_LINE_LAYER_ID = 'wave-swell-height-line';

const WIND_SOURCE_ID = 'wind-vector-tile-source';
const WIND_SPEED_FILL_LAYER_ID = 'wind-speed-fill';
const WIND_ARROWS_LAYER_ID = 'wind-arrows';

const WAVE_SIGNIFICANT_SOURCE_ID = 'wave-significant-vector-tile-source';
const WAVE_SIGNIFICANT_HEIGHT_FILL_LAYER_ID = 'wave-significant-height-fill';
const WAVE_SIGNIFICANT_HEIGHT_LINE_LAYER_ID = 'wave-significant-height-line';

const CURRENTS_SOURCE_ID = 'currents-vector-tile-source';
const CURRENTS_FILL_LAYER_ID = 'currents-height-fill';
const CURRENTS_LINE_LAYER_ID = 'currents-height-line';

const HUMIDITY_SOURCE_ID = 'humidity-tile-source';
const HUMIDITY_LAYER_ID = 'humidity-png';

const TEMPERATURE_SOURCE_ID = 'temperature-tile-source';
const TEMPERATURE_LAYER_ID = 'temperature-png';

const RASTER_CLOUDS_SOURCE_ID = 'clouds-raster-tile-source';
const RASTER_CLOUDS_LAYER_ID = 'clouds-png';
const VECTOR_CLOUDS_SOURCE_ID = 'clouds-vector-tile-source';
const VECTOR_CLOUDS_FILL_LAYER_ID = 'clouds-height-fill';

@Injectable({
  providedIn: 'root',
})
export class MapTilesService {
  private tileSubject = new BehaviorSubject<VectorTile[]>([]);
  tiles$ = this.tileSubject.asObservable();

  time = this.roundedTimeToNearest3Hours();
  private map!: mapboxgl.Map;

  constructor() {}

  init(map: mapboxgl.Map): void {
    this.map = map;
  }

  private getWaveSwellUrl() {
    return `${environment.meteoblue.tilesUrl}/vector/MFWAM/${this.time}/waveColortable~109~sfc~hourly~none~contourSteps~-0.1~0.3~0.5~1.0~1.5~2.0~2.5~3.0~4.0~5.0~6.0~7.0~8.0~9.0~10.0~11.0_arrowsswell~102~sfc~hourly~none~windArrows/{z}/{x}/{y}?temperatureUnit=C&velocityUnit=km%2Fh&lengthUnit=metric&energyUnit=watts&internal=true&apikey=${environment.meteoblue.apiKey}`;
  }

  private getWindUrl() {
    return `${environment.meteoblue.tilesUrl}/vector/ICONAUTO/${this.time}/windsoftRainbowColortable~32~10%20m%20above%20gnd~hourly~none~contourSteps~0.0~0.5~1.0~1.5~2.0~3.0~4.0~5.0~6.0~7.0~8.0~10.0~12.0~14.0~16.0~18.0~20.0~25.0~30.0~35.0~45.0~55.0~65.0~75.0~90.0~105.0_arrowshourly~31~10%20m%20above%20gnd~hourly~none~windArrows/{z}/{x}/{y}?temperatureUnit=C&velocityUnit=kn&lengthUnit=metric&energyUnit=watts&internal=true&apikey=${environment.meteoblue.apiKey}`;
  }

  private getWaveSignificantUrl() {
    return `${environment.meteoblue.tilesUrl}/vector/MFWAM/${this.time}/waveColortable~100~sfc~hourly~none~contourSteps~-0.1~0.3~0.5~1.0~1.5~2.0~2.5~3.0~4.0~5.0~6.0~7.0~8.0~9.0~10.0~11.0_arrowssignificant~105~sfc~hourly~none~windArrows/{z}/{x}/{y}?temperatureUnit=C&velocityUnit=kn&lengthUnit=metric&energyUnit=watts&internal=true&apikey=${environment.meteoblue.apiKey}`;
  }

  private getCurrentsUrl() {
    return `${environment.meteoblue.tilesUrl}/vector/RTOFS/${this.time}/oceancurrentColortable~2000~sfc~3hourly~none~contourSteps~-0.05~0.19~0.38~0.54~0.7~0.86~1.08~1.35~1.62~1.89~2.16~2.43~2.7~3.24~3.78_arrows3hourly~2001~sfc~3hourly~none~windArrows/{z}/{x}/{y}?temperatureUnit=C&velocityUnit=kn&lengthUnit=metric&energyUnit=watts&internal=true&apikey=${environment.meteoblue.apiKey}`;
  }

  private getHumidityTileUrl() {
    return `${environment.meteoblue.tilesUrl}/raster/NEMSAUTO/${this.time}/52~2%20m%20above%20gnd~hourly~none~contourSteps~-0.1~rgba(251,251,242,1.0)~5.0~rgba(236,244,211,1.0)~10.0~rgba(219,241,189,1.0)~15.0~rgba(202,240,177,1.0)~20.0~rgba(174,237,161,1.0)~25.0~rgba(150,235,156,1.0)~30.0~rgba(142,236,159,1.0)~35.0~rgba(132,240,170,1.0)~40.0~rgba(121,237,185,1.0)~45.0~rgba(110,229,200,1.0)~50.0~rgba(98,226,215,1.0)~55.0~rgba(90,224,224,1.0)~60.0~rgba(84,204,221,1.0)~65.0~rgba(73,178,216,1.0)~70.0~rgba(63,159,214,1.0)~75.0~rgba(58,138,224,1.0)~80.0~rgba(60,122,214,1.0)~85.0~rgba(62,106,198,1.0)~90.0~rgba(70,96,178,1.0)~95.0~rgba(75,96,165,1.0)~100.0~rgba(75,90,145,1.0)/{z}/{x}/{y}?temperatureUnit=C&velocityUnit=km%2Fh&lengthUnit=metric&energyUnit=watts&internal=true&apikey=${environment.meteoblue.apiKey}`;
  }

  private getTemperatureTileUrl() {
    return `${environment.meteoblue.tilesUrl}/raster/ICONAUTO/${this.time}/11~2%20m%20above%20gnd~hourly~none~contourSteps~-100.0~rgba(0,255,255,1.0)~-80.0~rgba(0,255,255,1.0)~-75.0~rgba(6,230,237,1.0)~-70.0~rgba(11,205,219,1.0)~-65.0~rgba(16,181,202,1.0)~-60.0~rgba(21,158,185,1.0)~-55.0~rgba(27,134,168,1.0)~-50.0~rgba(32,111,151,1.0)~-45.0~rgba(37,89,135,1.0)~-40.0~rgba(42,66,118,1.0)~-35.0~rgba(47,42,101,1.0)~-32.0~rgba(54,10,78,1.0)~-30.0~rgba(86,11,105,1.0)~-28.0~rgba(123,12,136,1.0)~-26.0~rgba(163,12,168,1.0)~-24.0~rgba(206,11,220,1.0)~-22.0~rgba(175,5,227,1.0)~-20.0~rgba(137,4,225,1.0)~-18.0~rgba(93,4,216,1.0)~-16.0~rgba(28,13,207,1.0)~-14.0~rgba(27,52,215,1.0)~-12.0~rgba(36,96,226,1.0)~-10.0~rgba(52,140,237,1.0)~-8.0~rgba(68,177,246,1.0)~-6.0~rgba(81,203,250,1.0)~-4.0~rgba(128,224,247,1.0)~-2.0~rgba(160,234,247,1.0)~0.0~rgba(0,239,124,1.0)~2.0~rgba(0,228,82,1.0)~4.0~rgba(0,200,72,1.0)~6.0~rgba(16,184,122,1.0)~8.0~rgba(41,123,93,1.0)~10.0~rgba(0,114,41,1.0)~12.0~rgba(60,161,44,1.0)~14.0~rgba(121,208,48,1.0)~16.0~rgba(181,255,51,1.0)~18.0~rgba(216,247,161,1.0)~20.0~rgba(255,246,0,1.0)~22.0~rgba(248,223,11,1.0)~24.0~rgba(253,202,12,1.0)~26.0~rgba(252,172,5,1.0)~28.0~rgba(248,141,0,1.0)~30.0~rgba(255,102,0,1.0)~32.0~rgba(252,79,0,1.0)~34.0~rgba(255,1,0,1.0)~36.0~rgba(243,26,0,1.0)~38.0~rgba(243,24,97,1.0)~40.0~rgba(243,22,194,1.0)~42.0~rgba(212,15,140,1.0)~44.0~rgba(180,9,87,1.0)~46.0~rgba(149,2,33,1.0)/{z}/{x}/{y}?temperatureUnit=C&velocityUnit=km%2Fh&lengthUnit=metric&energyUnit=watts&internal=true&apikey=${environment.meteoblue.apiKey}`;
  }

  private getRasterCloudsTileUrl() {
    return `${environment.meteoblue.tilesUrl}/raster/ICONAUTO/${this.time}/75~high%20cld%20lay~hourly~none~contourSteps~0.0~rgba(255,255,255,0.0)~20.0~rgba(255,255,255,0.09803922)~40.0~rgba(255,255,255,0.2)~60.0~rgba(255,255,255,0.29803923)~80.0~rgba(255,255,255,0.4)~100.0~rgba(255,255,255,0.49803922)_2006~sfc~hourly~none~contourSteps~0.0~rgba(255,255,255,0.0)~20.0~rgba(255,255,255,0.1764706)~40.0~rgba(255,255,255,0.29803923)~60.0~rgba(255,255,255,0.54901963)~80.0~rgba(255,255,255,0.8)~95.0~rgba(255,255,255,0.8980392)_61~sfc~hourly~none~contourSteps~0.1~rgba(133,247,244,0.49803922)~0.25~rgba(133,247,244,1.0)~0.5~rgba(105,148,252,1.0)~1.0~rgba(90,123,248,1.0)~1.5~rgba(1,124,254,1.0)~2.0~rgba(2,104,213,1.0)~3.0~rgba(3,151,135,1.0)~5.0~rgba(2,198,33,1.0)~7.0~rgba(174,255,3,1.0)~10.0~rgba(218,255,53,1.0)~15.0~rgba(255,173,2,1.0)~20.0~rgba(255,97,1,1.0)~25.0~rgba(252,60,3,1.0)~30.0~rgba(251,20,3,1.0)/{z}/{x}/{y}?temperatureUnit=C&velocityUnit=km%2Fh&lengthUnit=metric&energyUnit=watts&internal=true&apikey=${environment.meteoblue.apiKey}`;
  }

  private getVectorCloudsTileUrl() {
    return `${environment.meteoblue.tilesUrl}/vector/ICONAUTO/${this.time}/layerSnowInternal~679~sfc~hourly~none~contourSteps~0.2/{z}/{x}/{y}?temperatureUnit=C&velocityUnit=km%2Fh&lengthUnit=metric&energyUnit=watts&internal=true&apikey=${environment.meteoblue.apiKey}`;
  }

  private removeLayerIfExists(layerId: string): void {
    if (this.map && this.map.getLayer(layerId)) {
      this.map.removeLayer(layerId);
    }
  }

  private removeSourceIfExists(sourceId: string): void {
    if (this.map && this.map.getSource(sourceId)) {
      this.map.removeSource(sourceId);
    }
  }

  removeWaveSwellLayers(): void {
    if (!this.map) {
      console.error('Map is not initialized. Call init() first.');
      return;
    }

    this.removeLayerIfExists(WAVE_SWELL_HEIGHT_LINE_LAYER_ID);
    this.removeLayerIfExists(WAVE_SWELL_HEIGHT_FILL_LAYER_ID);
    this.removeSourceIfExists(WAVE_SWELL_SOURCE_ID);
  }

  removeWindLayers(): void {
    if (!this.map) {
      console.error('Map is not initialized. Call init() first.');
      return;
    }

    this.removeLayerIfExists(WIND_SPEED_FILL_LAYER_ID);
    this.removeLayerIfExists(WIND_ARROWS_LAYER_ID);
    this.removeSourceIfExists(WIND_SOURCE_ID);
  }

  removeWaveSignificantLayers(): void {
    if (!this.map) {
      console.error('Map is not initialized. Call init() first.');
      return;
    }

    this.removeLayerIfExists(WAVE_SIGNIFICANT_HEIGHT_FILL_LAYER_ID);
    this.removeLayerIfExists(WAVE_SIGNIFICANT_HEIGHT_LINE_LAYER_ID);
    this.removeSourceIfExists(WAVE_SIGNIFICANT_SOURCE_ID);
  }

  removeCurrentsLayers(): void {
    if (!this.map) {
      console.error('Map is not initialized. Call init() first.');
      return;
    }

    this.removeLayerIfExists(CURRENTS_FILL_LAYER_ID);
    this.removeLayerIfExists(CURRENTS_LINE_LAYER_ID);
    this.removeSourceIfExists(CURRENTS_SOURCE_ID);
  }

  addWaveSwellLayers(): void {
    if (!this.map) {
      console.error('Map is not initialized. Call init() first.');
      return;
    }

    this.map.addSource(WAVE_SWELL_SOURCE_ID, {
      type: 'vector',
      tiles: [this.getWaveSwellUrl()],
    });

    this.map.addLayer({
      id: WAVE_SWELL_HEIGHT_FILL_LAYER_ID,
      source: WAVE_SWELL_SOURCE_ID,
      'source-layer': 'waveColortable',
      type: 'fill',
      paint: {
        'fill-antialias': false,
        'fill-opacity': 0.75,
        'fill-color': [
          'interpolate',
          ['linear'],
          ['get', 'minValue'],
          ...WaveLegendColors.flatMap(item => [item.value, item.color]),
        ],
      },
      filter: ['all', ['==', '$type', 'Polygon']],
    });

    this.map.addLayer({
      id: WAVE_SWELL_HEIGHT_LINE_LAYER_ID,
      type: 'line',
      source: WAVE_SWELL_SOURCE_ID,
      'source-layer': 'arrowsswell',
      paint: {
        'line-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          'black',
          '#848884',
        ],
        'line-width': {
          stops: [
            [0, 1.5],
            [22, 2.5],
          ],
        },
        'line-opacity': 0.8,
      },
      filter: ['all', ['==', '$type', 'LineString']],
    });
  }

  addWindLayers(): void {
    if (!this.map) {
      console.error('Map is not initialized. Call init() first.');
      return;
    }

    this.map.addSource(WIND_SOURCE_ID, {
      type: 'vector',
      tiles: [this.getWindUrl()],
    });

    this.map.addLayer({
      id: WIND_SPEED_FILL_LAYER_ID,
      source: WIND_SOURCE_ID,
      'source-layer': 'windsoftRainbowColortable',
      type: 'fill',
      paint: {
        'fill-antialias': false,
        'fill-opacity': 0.75,
        'fill-color': [
          'interpolate',
          ['linear'],
          ['get', 'minValue'],
          ...WindLegendColors.flatMap(item => [item.value, item.color]),
        ],
      },
      filter: ['all', ['==', '$type', 'Polygon']],
    });

    this.map.addLayer({
      id: WIND_ARROWS_LAYER_ID,
      type: 'line',
      source: WIND_SOURCE_ID,
      'source-layer': 'arrowshourly',
      paint: {
        'line-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          'black',
          '#848884',
        ],
        'line-width': {
          stops: [
            [0, 1.5],
            [22, 2.5],
          ],
        },
        'line-opacity': 0.8,
      },
      filter: ['all', ['==', '$type', 'LineString']],
    });
  }

  addWaveSignificantLayers(): void {
    if (!this.map) {
      console.error('Map is not initialized. Call init() first.');
      return;
    }

    this.map.addSource(WAVE_SIGNIFICANT_SOURCE_ID, {
      type: 'vector',
      tiles: [this.getWaveSignificantUrl()],
    });

    this.map.addLayer({
      id: WAVE_SIGNIFICANT_HEIGHT_FILL_LAYER_ID,
      source: WAVE_SIGNIFICANT_SOURCE_ID,
      'source-layer': 'waveColortable',
      type: 'fill',
      paint: {
        'fill-antialias': false,
        'fill-opacity': 0.75,
        'fill-color': [
          'interpolate',
          ['linear'],
          ['get', 'minValue'],
          ...WaveLegendColors.flatMap(item => [item.value, item.color]),
        ],
      },
      filter: ['all', ['==', '$type', 'Polygon']],
    });

    this.map.addLayer({
      id: WAVE_SIGNIFICANT_HEIGHT_LINE_LAYER_ID,
      type: 'line',
      source: WAVE_SIGNIFICANT_SOURCE_ID,
      'source-layer': 'arrowssignificant',
      paint: {
        'line-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          'black',
          '#848884',
        ],
        'line-width': {
          stops: [
            [0, 1.5],
            [22, 2.5],
          ],
        },
        'line-opacity': 0.8,
      },
      filter: ['all', ['==', '$type', 'LineString']],
    });
  }

  addCurrentsLayers(): void {
    if (!this.map) {
      console.error('Map is not initialized. Call init() first.');
      return;
    }

    this.map.addSource(CURRENTS_SOURCE_ID, {
      type: 'vector',
      tiles: [this.getCurrentsUrl()],
    });

    this.map.addLayer({
      id: CURRENTS_FILL_LAYER_ID,
      source: CURRENTS_SOURCE_ID,
      'source-layer': 'oceancurrentColortable',
      type: 'fill',
      paint: {
        'fill-antialias': false,
        'fill-opacity': 0.75,
        'fill-color': [
          'interpolate',
          ['linear'],
          ['get', 'minValue'],
          ...CurrentsLegendColors.flatMap(item => [item.value, item.color]),
        ],
      },
      filter: ['all', ['==', '$type', 'Polygon']],
    });

    this.map.addLayer({
      id: CURRENTS_LINE_LAYER_ID,
      type: 'line',
      source: CURRENTS_SOURCE_ID,
      'source-layer': 'arrows3hourly',
      paint: {
        'line-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          'black',
          '#848884',
        ],
        'line-width': {
          stops: [
            [0, 1.5],
            [22, 2.5],
          ],
        },
        'line-opacity': 0.8,
      },
      filter: ['all', ['==', '$type', 'LineString']],
    });
  }

  addHumidityLayer(): void {
    if (!this.map) {
      console.error('Map is not initialized. Call init() first.');
      return;
    }

    this.map.addSource(HUMIDITY_SOURCE_ID, {
      type: 'raster',
      tiles: [this.getHumidityTileUrl()],
      tileSize: 256,
    });

    this.map.addLayer({
      id: HUMIDITY_LAYER_ID,
      type: 'raster',
      source: HUMIDITY_SOURCE_ID,
      paint: {
        'raster-opacity': 0.7,
      },
    });
  }

  removeHumidityLayer(): void {
    if (!this.map) {
      console.error('Map is not initialized. Call init() first.');
      return;
    }

    this.removeLayerIfExists(HUMIDITY_LAYER_ID);
    this.removeSourceIfExists(HUMIDITY_SOURCE_ID);
  }

  addTemperatureLayer(): void {
    if (!this.map) {
      console.error('Map is not initialized. Call init() first.');
      return;
    }

    this.map.addSource(TEMPERATURE_SOURCE_ID, {
      type: 'raster',
      tiles: [this.getTemperatureTileUrl()],
      tileSize: 256,
    });

    this.map.addLayer({
      id: TEMPERATURE_LAYER_ID,
      type: 'raster',
      source: TEMPERATURE_SOURCE_ID,
      paint: {
        'raster-opacity': 0.5,
      },
    });
  }

  removeTemperatureLayer(): void {
    if (!this.map) {
      console.error('Map is not initialized. Call init() first.');
      return;
    }

    this.removeLayerIfExists(TEMPERATURE_LAYER_ID);
    this.removeSourceIfExists(TEMPERATURE_SOURCE_ID);
  }

  addCloudsLayer(): void {
    if (!this.map) {
      console.error('Map is not initialized. Call init() first.');
      return;
    }

    this.map.addSource(VECTOR_CLOUDS_SOURCE_ID, {
      type: 'vector',
      tiles: [this.getVectorCloudsTileUrl()],
    });

    this.map.addLayer({
      id: VECTOR_CLOUDS_FILL_LAYER_ID,
      source: VECTOR_CLOUDS_SOURCE_ID,
      'source-layer': 'layerSnowInternal',
      type: 'fill',
      paint: {
        'fill-antialias': false,
        'fill-opacity': 0.75,
        'fill-color': [
          'interpolate',
          ['linear'],
          ['get', 'minValue'],
          ...CloudsLegendColors.flatMap(item => [item.value, item.color]),
        ],
      },
      filter: ['all', ['==', '$type', 'Polygon']],
    });

    this.map.addSource(RASTER_CLOUDS_SOURCE_ID, {
      type: 'raster',
      tiles: [this.getRasterCloudsTileUrl()],
      tileSize: 256,
    });

    this.map.addLayer({
      id: RASTER_CLOUDS_LAYER_ID,
      type: 'raster',
      source: RASTER_CLOUDS_SOURCE_ID,
      paint: {
        'raster-opacity': 0.7,
      },
    });
  }

  removeCloudsLayer(): void {
    if (!this.map) {
      console.error('Map is not initialized. Call init() first.');
      return;
    }

    this.removeLayerIfExists(RASTER_CLOUDS_LAYER_ID);
    this.removeSourceIfExists(RASTER_CLOUDS_SOURCE_ID);

    this.removeLayerIfExists(VECTOR_CLOUDS_FILL_LAYER_ID);
    this.removeSourceIfExists(VECTOR_CLOUDS_SOURCE_ID);
  }

  roundedTimeToNearest3Hours() {
    let date = new Date();
    date.setUTCMinutes(0, 0, 0);
    let hours = date.getUTCHours();
    let roundedHours = Math.floor(hours / 3) * 3;
    date.setUTCHours(roundedHours);
    return date.toISOString().substring(0, 13) + ':00Z';
  }

  removeAllWeatherLayers(): void {
    if (!this.map) {
      console.error('Map is not initialized. Call init() first.');
      return;
    }

    this.removeWindLayers();
    this.removeWaveSwellLayers();
    this.removeWaveSignificantLayers();
    this.removeCurrentsLayers();
    this.removeHumidityLayer();
    this.removeTemperatureLayer();
    this.removeCloudsLayer();
  }
}
