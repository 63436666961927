import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as shape from 'd3-shape';
import { GraphTooltipMapping } from 'src/app/shared/models/graph-data-model';
import { tranformDateToDayMonth } from 'src/app/shared/utils/date-transforms/dateToDayMonth';
import { customXTicks } from 'src/app/shared/utils/graphs/general';
import { ShipSeverityScore } from '../../../models/ship.model';
import { ChartAverageShipData } from '../../../view-models/ship.view-model';

@Component({
  selector: 'app-risks-overview-chart',
  templateUrl: './risks-overview-chart.component.html',
  styleUrls: ['./risks-overview-chart.component.scss'],
})
export class RisksOverviewChartComponent implements OnChanges {
  @Input()
  shipsSeverityByDate!: ShipSeverityScore[] | null;
  customAvgShipsData!: ChartAverageShipData[];
  dataset = [{}];
  riskClass: string = 'risk';
  risksMap: GraphTooltipMapping = {
    low: {
      divClass: 'low',
      text: 'Low',
    },
    moderate: {
      divClass: 'moderate',
      text: 'Moderate',
    },
    good: {
      divClass: 'good',
      text: 'Good',
    },
    excellent: {
      divClass: 'excellent',
      text: 'Excellent',
    },
  };
  constructor() {}

  linearCurve = shape.curveLinear;

  xAxisTicks: Date[] = [];

  ngOnChanges(changes: SimpleChanges) {
    this.setDataSet();
  }

  setDataSet(): void {
    this.customAvgShipsData = this.shipsSeverityByDate!.map(shipsDataPerDay => {
      const newConvertedShipData = {
        ...shipsDataPerDay,
        name: shipsDataPerDay.date,
        value: shipsDataPerDay.avgScore,
      };
      return newConvertedShipData;
    });

    this.dataset = [
      {
        name: 'Fleet Safety Score',
        series: this.customAvgShipsData,
      },
    ];
    this.customXTicks();
  }

  customXTicks(): void {
    this.xAxisTicks = customXTicks(
      this.customAvgShipsData.map(shipData => shipData.name)
    );
  }

  formatXLabel(date: Date): string {
    return tranformDateToDayMonth(date);
  }
}
