import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, of, switchMap, withLatestFrom } from 'rxjs';
import { dateFilters } from 'src/app/shared/view-models/dates.view.model';
import { RoutingService } from '../../services/routing.service';
import { CoreState } from '../state/core.state';
import { FiltersActions, FiltersSelectors } from '../types';

@Injectable()
export class FiltersEffects {
  constructor(
    private routerService: RoutingService,
    private actions$: Actions,
    private store: Store<CoreState>
  ) {}

  onNavigationToEventExploration$ = createEffect(() => {
    return this.routerService.getCurrentPage$().pipe(
      map(currPage => {
        if (currPage === 'Event Exploration') {
          return FiltersActions.setDatesFilterState({ isDisabled: true });
        } else {
          return FiltersActions.setDatesFilterState({ isDisabled: false });
        }
      })
    );
  });

  disableCustomDateFilterOptionOnNavigationToFleetOrShipPage$ = createEffect(
    () => {
      return this.routerService.getCurrentPage$().pipe(
        map(currPage => {
          if (currPage === 'Fleet' || currPage === 'Ship') {
            return FiltersActions.setCustomDateFilterState({
              isDisabled: true,
            });
          } else {
            return FiltersActions.setCustomDateFilterState({
              isDisabled: false,
            });
          }
        })
      );
    }
  );

  resetDateFilterOnDisabledCustomDateOption$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FiltersActions.setCustomDateFilterState),
      filter(action => action.isDisabled),
      switchMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(FiltersSelectors.selectFilterdDatesKey)
          )
        )
      ),
      filter(
        ([_, currentDateFilter]) => currentDateFilter === 'Custom dates average'
      ),
      switchMap(([_, __]) =>
        of(
          FiltersActions.resetDateFilterToDefault({
            dateFilter: { ['Last 30 days']: dateFilters['Last 30 days'] },
          })
        )
      )
    );
  });
}
