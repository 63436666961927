import {
  defaultShipEventCount,
  defaultShipLiveStream,
  defaultShipsScoreData,
  defaultShipsSeverityByDate,
  LiveShipData,
  ScoringFactorsStats,
  Ship,
  ShipEventCount,
  ShipLiveStream,
  ShipSafetyScore,
  ShipSafteyScoreBySorting,
  ShipSailData,
  ShipsSeverityByDate,
} from '../../models/ship.model';
import { ActiveRTEvent } from '../../view-models/event.view.model';
import { Screenshot } from '../../view-models/gallery.view.model';

export interface ShipsState {
  readonly shipsSeverityScoreByDate: ShipsSeverityByDate;
  readonly shipsSafetyScoreData: ShipSafteyScoreBySorting;
  readonly ships: Ship[];
  readonly selectedShipId: number | null;
  readonly selectedShipEventCountData: ShipEventCount;
  readonly selectedShipSafetyScores: ShipSafetyScore[];
  readonly shipsScoreExplaination: ScoringFactorsStats | null;
  readonly selectedShipSailData: ShipSailData[];
  readonly shipLiveStream: ShipLiveStream;
  readonly screenshots: Screenshot[];
  readonly rtEvents: { [shipId: number]: ActiveRTEvent };
  readonly shipRealTimeData: Ship | null;
  readonly shipPitchRoll: any[];
  readonly liveShipData: LiveShipData | null;
}

export const initialShipsState: ShipsState = {
  shipsSeverityScoreByDate: defaultShipsSeverityByDate,
  shipsSafetyScoreData: defaultShipsScoreData,
  ships: [],
  selectedShipId: null,
  shipsScoreExplaination: null,
  selectedShipEventCountData: defaultShipEventCount,
  selectedShipSafetyScores: [],
  shipLiveStream: defaultShipLiveStream,
  selectedShipSailData: [],
  screenshots: [],
  rtEvents: {},
  shipRealTimeData: null,
  shipPitchRoll: [],
  liveShipData: null,
};
