import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateToTimeAgo',
})
export class DateToTimeAgoPipe implements PipeTransform {
  transform(inputDate: string | null | undefined): string {
    if (inputDate === null || inputDate === undefined || !inputDate.length)
      return '';

    const utcDate = new Date(inputDate + 'Z');
    const currentDate = new Date();
    const diffInMilliseconds = currentDate.getTime() - utcDate.getTime();

    const diffInMinutesTotal = Math.floor(diffInMilliseconds / (1000 * 60));
    const diffInHoursTotal = Math.floor(diffInMinutesTotal / 60);
    const diffInDaysTotal = Math.floor(diffInHoursTotal / 24);

    if (diffInMinutesTotal < 60) {
      return `${diffInMinutesTotal} minutes ago`;
    } else if (diffInHoursTotal < 24) {
      return `${diffInHoursTotal} hours ago`;
    } else if (diffInDaysTotal === 1) {
      return '1 day ago';
    } else {
      return `${diffInDaysTotal} days ago`;
    }
  }
}
