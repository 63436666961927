import { Component, ContentChild, Input } from '@angular/core';
import { TableCellDirective } from '../../directives/table-cell.directive';
import { TableHeaderDirective } from '../../directives/table-header.directive';
import { TableStore } from '../../store.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'orc-column',
  templateUrl: './column.component.html',
  styleUrls: ['./column.component.scss'],
})
export class ColumnComponent {
  @Input()
  id: string = '';

  @ContentChild(TableCellDirective)
  set tableCellDirective(value: TableCellDirective | null) {
    const template = value?.template ?? null;
    this.store.setCellTemplate(this.id, template);
  }

  @ContentChild(TableHeaderDirective)
  set TableHeaderDirective(value: TableHeaderDirective | null) {
    const template = value?.template ?? null;
    this.store.setHeaderTemplate(this.id, template);
  }

  constructor(private store: TableStore) {}
}
