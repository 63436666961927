import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Dates } from 'src/app/shared/view-models/dates.view.model';
import { environment } from 'src/environments/environment';
import {
  defaultKpisData,
  InefficientEventDtoResponse,
  InefficientEventResponse,
  Resolution,
  SailCostDto,
  SailFuelExpenditureGraphData,
  ShipEfficiencyDto,
  ShipsEmissionDtoResponse,
  ShipsEmissionResponse,
  ShipsSailCostDtoResponse,
  TimeFilter,
} from '../models/efficiency.models';
import {
  getAccumulatedSailCosts,
  getDateAlmostFiveWeeksAgo,
  getThreeMonthsAgoDate,
  mapKpiDataDtoToKpiData,
  mapSailCostDtoToSailCost,
  mapShipEfficiencyDtoToShipEfficiency,
} from '../utils/efficiency';
import { IsBusyService } from './is-busy.service';

@Injectable({
  providedIn: 'root',
})
export class EfficiencyService {
  constructor(private http: HttpClient, private isBusy: IsBusyService) {}

  getShipsEmission$(dates: Dates): Promise<ShipsEmissionResponse> {
    const request$ = this.http.get<ShipsEmissionDtoResponse>(
      environment.api.shipsEmission,
      {
        params: {
          start_date: dates.startDate.toISOString(),
          end_date: dates.endDate.toISOString(),
        },
      }
    );

    return this.isBusy.add(() =>
      firstValueFrom(
        request$.pipe(
          switchMap((response: ShipsEmissionDtoResponse) => {
            const shipsEfficiency = response.ships_emission_data.map(
              (shipEfficiencyDto: ShipEfficiencyDto) =>
                mapShipEfficiencyDtoToShipEfficiency(shipEfficiencyDto)
            );
            const kpisData = mapKpiDataDtoToKpiData(response);
            return of({
              kpisData: kpisData,
              shipsEfficiency: shipsEfficiency,
            });
          }),
          catchError(_ =>
            of({ kpisData: defaultKpisData, shipsEfficiency: [] })
          )
        )
      )
    );
  }

  getShipsCost$(
    timeFilter: TimeFilter = TimeFilter.YEAR_AND_THIS_MONTH
  ): Promise<SailFuelExpenditureGraphData[]> {
    let request$: Observable<ShipsSailCostDtoResponse>;
    if (timeFilter === TimeFilter.YEAR_AND_THIS_MONTH) {
      const today = new Date();
      const oneYearAgo = new Date(today.getFullYear() - 1, today.getMonth(), 1);
      request$ = this.http.get<ShipsSailCostDtoResponse>(
        environment.api.sailCost,
        {
          params: {
            start_date: oneYearAgo.toISOString(),
            end_date: new Date().toISOString(),
            resolution: Resolution.MONTH,
          },
        }
      );
    } else {
      if (timeFilter === TimeFilter.THREE_MONTHS_AND_THIS_MONTH) {
        request$ = this.http.get<ShipsSailCostDtoResponse>(
          environment.api.sailCost,
          {
            params: {
              start_date: getThreeMonthsAgoDate(),
              end_date: new Date().toISOString(),
              resolution: Resolution.MONTH,
            },
          }
        );
      } else {
        request$ = this.http.get<ShipsSailCostDtoResponse>(
          environment.api.sailCost,
          {
            params: {
              start_date: getDateAlmostFiveWeeksAgo(),
              end_date: new Date().toISOString(),
              resolution: Resolution.WEEK,
            },
          }
        );
      }
    }

    return this.isBusy.add(() =>
      firstValueFrom(
        request$.pipe(
          switchMap((response: ShipsSailCostDtoResponse) => {
            const sailCosts$ = response.data.map((sailCostDto: SailCostDto) =>
              mapSailCostDtoToSailCost(sailCostDto)
            );
            return firstValueFrom(
              of(sailCosts$).pipe(
                map(sailCosts => getAccumulatedSailCosts(sailCosts, timeFilter))
              )
            );
          }),
          catchError(_ => of([]))
        )
      )
    );
  }

  getIneficientEvent$(dates: Dates): Promise<InefficientEventResponse> {
    const request$ = this.http.get<InefficientEventDtoResponse>(
      environment.api.ineficientEvent,
      {
        params: {
          start_date: dates.startDate.toISOString(),
          end_date: dates.endDate.toISOString(),
        },
      }
    );

    return this.isBusy.add(() =>
      firstValueFrom(
        request$.pipe(
          switchMap((response: InefficientEventDtoResponse) => {
            return of({ inefficientEventPerShip: response.event_per_ship });
          }),
          catchError(_ => of({}))
        )
      )
    );
  }
}
