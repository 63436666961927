import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { LiveStreamDialogComponent } from 'src/app/core/components/dialogs/live-stream-dialog/live-stream-dialog.component';

@Component({
  selector: 'app-live-stream-button',
  templateUrl: './live-stream-button.component.html',
  styleUrls: ['./live-stream-button.component.scss'],
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule],
})
export class LiveStreamButtonComponent {
  @Input()
  shouldOpenDialog: boolean = true;

  @Input()
  text: string = 'Live';

  @Input()
  isDisabled: boolean = false;

  constructor(public dialog: MatDialog) {}

  async onClick() {
    if (this.shouldOpenDialog) {
      const dialogRef = this.dialog.open(LiveStreamDialogComponent, {
        disableClose: true,
      });
    }
  }
}
