import { Model } from 'common';

export type UsersGroupedByCompany = {
  [companyName: string]: Model.PreliminaryUser[];
};

export function groupBy(array: Model.PreliminaryUser[]): UsersGroupedByCompany {
  return array.reduce(
    (grouped, element) => ({
      ...grouped,
      [element.companyName]: [
        ...((grouped as UsersGroupedByCompany)[element.companyName] || []),
        element,
      ],
    }),
    {}
  );
}
