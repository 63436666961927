import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Color, LegendPosition, ScaleType } from '@swimlane/ngx-charts';
import * as shape from 'd3-shape';
import { map, Observable } from 'rxjs';
import { Ship } from 'src/app/core/models/fleet.model';
import { PitchAndRollData } from 'src/app/core/models/ship.model'; // Ensure this model is correctly defined
import { CoreState } from 'src/app/core/store/state/core.state';
import { ShipsActions, ShipsSelectors } from 'src/app/core/store/types';
import {
  GraphDataView,
  GraphTooltipMapping,
} from 'src/app/shared/models/graph-data-model'; // Ensure this model supports the structure used below
import { transformDateToHourMinute } from 'src/app/shared/utils/date-transforms/dateToDayMonth';
import { customXTicks } from 'src/app/shared/utils/graphs/general';

@Component({
  selector: 'app-ship-imu-samples-graph',
  templateUrl: './ship-imu-samples-graph.component.html',
  styleUrls: ['./ship-imu-samples-graph.component.scss'],
})
export class ShipImuSamplesGraphComponent implements OnInit {
  @Input() ship!: Ship;
  shipPitchRoll$!: Observable<PitchAndRollData[]>;
  dataExists$!: Observable<boolean>;
  yScaleMax = 10;
  yScaleMin = 0;
  curveBumpX = shape.curveBumpX;
  xAxisTicks$!: Observable<Date[]>;
  latestTimestamp$!: Observable<string>;
  pitchRollGraphDataView$!: Observable<GraphDataView[]>;
  legendPosition = LegendPosition.Right;
  sampleClass = 'sample';
  latestReceivedDate!: string;

  tooltipViewMapping: GraphTooltipMapping = {
    Pitch: {
      divClass: 'pitch',
    },
    Roll: {
      divClass: 'roll',
    },
  };

  constructor(private store: Store<CoreState>) {}

  ngOnInit(): void {
    this.store.dispatch(
      ShipsActions.fetchLatestShipImu({ shipId: this.ship.shipId })
    );

    this.shipPitchRoll$ = this.store.select(ShipsSelectors.selectShipPitchRoll);

    this.pitchRollGraphDataView$ = this.shipPitchRoll$.pipe(
      map(pitchRollData => [
        {
          name: 'Roll',
          series: pitchRollData.map(data => ({
            name: new Date(data.date),
            value: data.roll.toFixed(1),
          })),
        },
        {
          name: 'Pitch',
          series: pitchRollData.map(data => ({
            name: new Date(data.date),
            value: data.pitch.toFixed(1),
          })),
        },
      ])
    );

    this.xAxisTicks$ = this.shipPitchRoll$.pipe(
      map(pitchRollByDate =>
        pitchRollByDate.map(pitchRoll => new Date(pitchRoll.date))
      ),
      map(dates => customXTicks(dates))
    );

    this.shipPitchRoll$.subscribe(shipPitchRoll => {
      if (shipPitchRoll && shipPitchRoll.length > 0) {
        this.latestReceivedDate = shipPitchRoll[0].date;
      }
    });

    this.dataExists$ = this.shipPitchRoll$.pipe(map(array => array.length > 0));
  }

  pitchRollColorScheme: Color = {
    name: 'pitchRoll',
    selectable: true,
    group: ScaleType.Linear,
    domain: ['var(--dv-purple)', 'var(--dv-dark-blue)'],
  };

  formatXLabel(date: Date): string {
    return transformDateToHourMinute(date);
  }

  formatPitchRollYLabel(degrees: number): string {
    return `${degrees}°`;
  }
}
