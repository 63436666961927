export type MetricEvents =
  | 'SessionStart'
  | 'SessionEnd'
  | 'InAppNavigation'
  | 'LoggedIn'
  | 'LoggedOut'
  | 'TimeframeFilterChanged'
  | 'EventsListSelectedEvent'
  | 'MapSelectedEvent'
  | 'EventExploration'
  | 'UpdateEventsDayFilter'
  | 'ResetEventsDayFilter'
  | 'ResetEventsSelectedMapEvent'
  | 'SortByEvents'
  | 'ResetToDefaultAppliedFilters'
  | 'Signup'
  | 'PasswordRecovery'
  | 'DownloadKepler'
  | 'ShipListSelectedShip'
  | 'MapSelectedShip'
  | 'ShipListViewedShip'
  | 'PresetCreated'
  | 'PresetSelected'
  | 'SailedPeriodSelected'
  | 'LiveStreamClicked'
  | 'LiveStreamCameraSelected'
  | 'ShipFilterSelected'
  | 'SeverityFilterSelected'
  | 'EventTypeFilterSelected'
  | 'CongestionLevelFilterSelected'
  | 'LocationFilterSelected'
  | 'OwnSogFilterSelected'
  | 'ShowEventsInPortsFilterSelected'
  | 'CloseEncounterMinDistFilterSelected'
  | 'ColregClassFilterSelected'
  | 'TargetSogFilterSelected'
  | 'TargetTypeFilterSelected'
  | 'UKCFilterSelected'
  | 'RollFilterSelected'
  | 'PitchFilterSelected'
  | 'SpeedDropSOGDiffFilterSelected'
  | 'HighROTFilterSelected'
  | 'SavingsGraphTimeSelected'
  | 'HighlightSelected'
  | 'ExtraCostSavingsSorted'
  | 'ExtraFuelSorted'
  | 'ExtraSailedSorted'
  | 'ExtraEmissionSorted'
  | 'CommentAdded'
  | 'CommentEdited'
  | 'CommentDeleted'
  | 'InsightRated'
  | 'EventsExploredFromInsights'
  | 'InsightSwitched';

export type SortColunmName =
  | 'extra-cost'
  | 'extra-fuel'
  | 'extra-sailed'
  | 'extra-emission';
export type SortColunmToMetricEventsMap = {
  [key in SortColunmName]: MetricEvents;
};

export const SortColunmNameToMetricEvents: SortColunmToMetricEventsMap = {
  'extra-cost': 'ExtraCostSavingsSorted',
  'extra-fuel': 'ExtraFuelSorted',
  'extra-sailed': 'ExtraSailedSorted',
  'extra-emission': 'ExtraEmissionSorted',
};
