import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { CommentsUser } from 'src/app/shared/view-models/comments.view.model';

@Component({
  selector: 'app-comment-form',
  templateUrl: './comment-form.component.html',
  styleUrls: ['./comment-form.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, SharedModule, TextFieldModule],
})
export class CommentFormComponent {
  @ViewChild('commentInput') commentInput!: ElementRef<HTMLTextAreaElement>;
  @Input() currentUserFullName: string = '';
  @Input() usersToMention: CommentsUser[] = [];
  @Output() submitComment = new EventEmitter<string>();
  @Output() cancelComment = new EventEmitter<void>();

  commentForm: FormGroup;
  commentInputFocused = false;
  showMentionsList = false;
  filteredUsers: CommentsUser[] = [];

  constructor(private fb: FormBuilder) {
    this.commentForm = this.fb.group({
      comment: ['', Validators.required],
    });
  }

  onCommentSubmit(): void {
    if (this.commentForm.valid) {
      this.submitComment.emit(this.commentForm.get('comment')?.value);
      this.commentForm.reset();
    }
  }

  onCommentCancel(): void {
    this.cancelComment.emit();
    this.commentForm.reset();
    this.commentInputFocused = false;
  }

  adjustTextareaHeight(element: HTMLTextAreaElement): void {
    element.style.height = 'auto';
    element.style.height = `${element.scrollHeight}px`;
    const maxHeight = 200;
    if (element.scrollHeight > maxHeight) {
      element.style.height = `${maxHeight}px`;
      element.style.overflowY = 'auto';
    } else {
      element.style.overflowY = 'hidden';
    }
  }

  onCommentInput(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    this.checkForMentionTrigger(textarea);
  }

  onCommentKeydown(event: KeyboardEvent): void {
    if (event.key === '@') {
      this.showMentionSuggestions();
    }
  }

  checkForMentionTrigger(textarea: HTMLTextAreaElement): void {
    const cursorPosition = textarea.selectionStart;
    const textBeforeCursor = textarea.value.substring(0, cursorPosition);
    const lastAtSymbol = textBeforeCursor.lastIndexOf('@');

    if (
      lastAtSymbol !== -1 &&
      !textBeforeCursor.slice(lastAtSymbol + 1).includes(' ')
    ) {
      const searchTerm = textBeforeCursor.slice(lastAtSymbol + 1);
      this.filterMentionSuggestions(searchTerm);
    } else {
      this.hideMentionSuggestions();
    }
  }

  filterMentionSuggestions(searchTerm: string): void {
    this.filteredUsers = this.usersToMention.filter(user =>
      `${user.firstName} ${user.lastName}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    this.showMentionsList = this.filteredUsers.length > 0;
  }

  showMentionSuggestions(): void {
    this.showMentionsList = true;
  }

  hideMentionSuggestions(): void {
    this.showMentionsList = false;
  }

  insertMention(user: CommentsUser): void {
    const textarea = this.commentInput.nativeElement;
    const cursorPosition = textarea.selectionStart;
    const textBeforeCursor = textarea.value.substring(0, cursorPosition);
    const textAfterCursor = textarea.value.substring(cursorPosition);
    const lastAtSymbol = textBeforeCursor.lastIndexOf('@');

    const newText =
      textBeforeCursor.substring(0, lastAtSymbol) +
      `@${user.firstName} ${user.lastName} ` +
      textAfterCursor;

    this.commentForm.get('comment')?.setValue(newText);
    textarea.focus();
    const newCursorPosition =
      lastAtSymbol + user.firstName.length + user.lastName.length + 3;
    textarea.setSelectionRange(newCursorPosition, newCursorPosition);

    this.hideMentionSuggestions();
  }
}
