import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from '@angular/core';

@Directive({
  selector: '[appGraphTooltipItem]',
})
export class ElementMappingDirective implements OnChanges {
  @Input()
  value!: number;

  @Input()
  name!: string;

  @Input()
  scheme!: any;

  @Input()
  class!: string;

  @Input()
  colorClass!: string;

  @Input()
  text: string | undefined;

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    this.createElementContent();
  }

  createElementContent() {
    while (this.elementRef.nativeElement.firstChild) {
      this.elementRef.nativeElement.removeChild(
        this.elementRef.nativeElement.lastChild
      );
    }

    const div = this.renderer.createElement('div');
    div.classList.add(this.class);

    const spanDot = this.renderer.createElement('span');
    spanDot.classList.add('dot');
    spanDot.classList.add(this.colorClass);

    const spanVal = this.renderer.createElement('span');
    spanVal.classList.add('caption');
    spanVal.innerText =
      (this.name || '') +
      (' ' + this.value + ' ' || '') +
      (this, this.text || '');

    this.renderer.setAttribute(spanDot, 'data-test', `dot-${this.colorClass}`);
    this.renderer.setAttribute(spanVal, 'data-test', `text`);

    div.appendChild(spanDot);
    div.appendChild(spanVal);
    this.renderer.appendChild(this.elementRef.nativeElement, div);
  }
}
