import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateToHoursMinutesAgo',
  standalone: true,
})
export class DateToHoursMinutesAgoPipe implements PipeTransform {
  transform(date: Date | null): string | null {
    if (!date) return null;

    const dateWithUtcSign = new Date(Date.parse(date + 'Z'));

    const currentDate = new Date();
    const diffInMilliseconds =
      currentDate.getTime() - new Date(dateWithUtcSign).getTime();
    const diffInMinutesTotal = Math.floor(diffInMilliseconds / (1000 * 60));
    const diffInHours = Math.floor(diffInMinutesTotal / 60);
    const diffInMinutes = diffInMinutesTotal % 60;

    let result = '';

    if (diffInHours > 0) {
      result += `${diffInHours} ${diffInHours === 1 ? 'hr' : 'hrs'}`;
    }

    if (diffInMinutes > 0) {
      result += result
        ? `, ${diffInMinutes} ${diffInMinutes === 1 ? 'min' : 'mins'}`
        : `${diffInMinutes} ${diffInMinutes === 1 ? 'min' : 'mins'}`;
    }

    if (!result) {
      return 'Just now';
    }

    return `${result} ago`;
  }
}
