import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appendCamera',
})
export class AppendCameraPipe implements PipeTransform {
  transform(value: string | null): string {
    if (value === null || value?.toLowerCase().endsWith('view')) {
      // Avoid adding 'Camera' if value ends with 'View' or 'view'
      return value || '';
    } else {
      return `${value} Camera`;
    }
  }
}
