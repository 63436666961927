<div
  class="container"
  [class.open]="isOpen$ | async"
  (mouseenter)="isContainerHovered = true"
  (mouseleave)="isContainerHovered = false">
  <div class="head">
    <img class="header-icon" src="./assets/icons/logo.svg" />
    <h3 class="header-name">ORCA AI</h3>
  </div>
  <div class="body">
    <ng-container *ngFor="let tab of tabs; let tabIndex = index">
      <div
        *ngIf="tab.show"
        class="tab selected"
        [ngClass]="{ selected: (tabName$ | async) === tab.navName }"
        routerLink="{{ tab.navName }}">
        <img class="tab-icon" src="./assets/icons/{{ tab.iconName }}.svg" />
        <app-new-badge
          class="close-badge"
          *ngIf="
            tab.new && (isOpen$ | async) === false && !isContainerHovered
          "></app-new-badge>
        <span class="body1 tab-name">
          <div>{{ tab.navName | titlecase }}</div>
          <app-new-badge
            *ngIf="tab.new && ((isOpen$ | async) || isContainerHovered)"
            class="new-badge"></app-new-badge>
        </span>
      </div>
    </ng-container>
  </div>
  <div class="footer">
    <div class="name" [matMenuTriggerFor]="menu">
      {{ userName$ | async | nameInitials }}
    </div>
    <mat-menu #menu="matMenu" yPosition="above">
      <button mat-menu-item (click)="logout()">
        <mat-icon class="footer-icon">exit_to_app</mat-icon>
        <span class="footer-name">Logout</span>
      </button>
      <button mat-menu-item routerLink="admin" *ngIf="isUserAdmin$ | async">
        <mat-icon class="footer-icon">account_box</mat-icon>
        <span class="footer-name">Admin Settings</span>
      </button>
    </mat-menu>
    <div class="select-container">
      <span class="button-text time"
        >Good {{ date$ | async | dateToDayTime | titlecase }}</span
      >
      <span class="caption">{{ userName$ | async }}</span>
      <span class="body1" *ngIf="isUserAdmin$ | async">{{ version }}</span>
    </div>
  </div>
</div>
