<div class="comments-section">
  <ng-container *ngIf="comments.length > 0; else noComments">
    <div *ngFor="let comment of comments" class="comment">
      <div class="comment-header">
        <div
          class="user-avatar"
          [style.background-color]="getAvatarColor(comment.authorName)">
          {{ comment.authorName | nameInitials }}
        </div>
        <span class="user-name">{{ comment.authorName }}</span>
        <span class="comment-time">{{
          isCreatedWithinFourHours(comment.createdAt)
            ? (comment.createdAt | dateToHoursMinutesAgo)
            : (comment.createdAt | date: 'dd MMM yy, HH:mm':'UTC')
        }}</span>
      </div>
      <ng-container *ngIf="editingCommentId !== comment.id; else editMode">
        <div class="comment-body">
          <p [innerHTML]="parseCommentContent(comment.content) | async"></p>
        </div>
      </ng-container>
      <ng-template #editMode>
        <div class="comment-body">
          <textarea
            #editInput
            [value]="comment.content"
            class="subtitle1 comment-input edit-input"
            (keyup.enter)="onCommentEditSubmit(comment.id, editInput.value)"
            (input)="adjustTextareaHeight(editInput)"></textarea>
          <div class="edit-actions">
            <button
              class="submit-button"
              (click)="onCommentEditSubmit(comment.id, editInput.value)">
              Save
            </button>
            <button class="cancel-button" (click)="cancelEdit()">Cancel</button>
          </div>
        </div>
      </ng-template>
      <div
        *ngIf="
          comment.authorId === currentUserId && editingCommentId !== comment.id
        "
        class="comment-actions">
        <button
          class="action-btn button-text"
          (click)="onCommentEdit(comment.id)">
          Edit
        </button>
        <button
          class="action-btn button-text"
          (click)="onCommentDelete(comment.id)">
          Delete
        </button>
      </div>
    </div>
  </ng-container>
  <ng-template #noComments>
    <div class="no-comments-container">
      <img class="icon" src="./assets/icons/no_comments.svg" />
    </div>
  </ng-template>
  <app-comment-form
    [usersToMention]="usersToMention"
    [currentUserFullName]="currentUserFullName"
    (submitComment)="onCommentSubmit($event)"
    (cancelComment)="onCommentCancel()">
  </app-comment-form>
</div>
