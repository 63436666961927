import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, filter, fromEvent, map, Observable } from 'rxjs';

@UntilDestroy()
@Directive({
  selector: '[appInfiniteScrolling]',
})
export class InfiniteScrollingDirective {
  @Input()
  onScrollThreashold: number = 0.8;
  onScroll$: Observable<number>;
  @Output()
  scrolled = new EventEmitter<void>();

  constructor(private el: ElementRef) {
    this.onScroll$ = fromEvent(this.el.nativeElement, 'scroll').pipe(
      debounceTime(300),
      map((event: any) => {
        return (
          (event.srcElement.scrollTop + event.srcElement.clientHeight) /
          event.srcElement.scrollHeight
        );
      }),
      filter(amountScrolled => amountScrolled > this.onScrollThreashold),
      untilDestroyed(this)
    );

    this.onScroll$.subscribe(v => this.scrolled.emit());
  }
}
