import { createSelector } from '@ngrx/store';

import { CoreState } from '../state/core.state';

const commentsState = (state: CoreState) => state.comments;

export const selectCurrentThreadComments = createSelector(
  commentsState,
  state => {
    if (!state.comments || !Array.isArray(state.comments)) {
      return state.comments || [];
    }
    return [...state.comments].sort((a, b) => {
      const dateA = new Date(a.createdAt).getTime();
      const dateB = new Date(b.createdAt).getTime();
      return dateA - dateB;
    });
  }
);

export const selectUsersToMentionOnThread = createSelector(
  commentsState,
  state => state.commentsUsers || []
);
