import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
  CloudsLegendColors,
  CurrentsLegendColors,
  HumidityLegendColors,
  TemperatureLegendColors,
  WaveLegendColors,
  WindLegendColors,
} from '../../map-data/weather';
import { LegendColors, MapSettingsOption } from '../../models/map.entity.model';

@Component({
  selector: 'app-weather-legend',
  templateUrl: './weather-legend.component.html',
  styleUrls: ['./weather-legend.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class WeatherLegendComponent {
  @Input() selectedOption: MapSettingsOption | null = null;

  get legendTitle(): string {
    switch (this.selectedOption) {
      case MapSettingsOption.Wind:
      case MapSettingsOption.Currents:
        return 'kts';
      case MapSettingsOption.Humidity:
        return '%';
      case MapSettingsOption.Temperature:
        return 'C';
      case MapSettingsOption.Clouds:
        return 'mm';
      case MapSettingsOption.WaveSignificant:
      case MapSettingsOption.WaveSwell:
      default:
        return 'm';
    }
  }

  get legendItems(): LegendColors {
    let legendsItems = WindLegendColors;
    switch (this.selectedOption) {
      case MapSettingsOption.Wind:
        legendsItems = WindLegendColors;
        break;
      case MapSettingsOption.WaveSignificant:
      case MapSettingsOption.WaveSwell:
        legendsItems = WaveLegendColors;
        break;
      case MapSettingsOption.Currents:
        legendsItems = CurrentsLegendColors;
        break;
      case MapSettingsOption.Humidity:
        legendsItems = HumidityLegendColors;
        break;
      case MapSettingsOption.Temperature:
        legendsItems = TemperatureLegendColors;
        break;
      case MapSettingsOption.Clouds:
        legendsItems = CloudsLegendColors;
        break;
    }
    return [...legendsItems];
  }
}
