import { createSelector } from '@ngrx/store';
import { CoreState } from '../state/core.state';
import { EfficiencyState } from '../state/efficiency.state';

const selectEfficiencyState = (state: CoreState): EfficiencyState =>
  state.efficiency;

export const selectKpisData = createSelector(
  selectEfficiencyState,
  state => state.kpisData
);

export const selectTotalExtraCostDollar = createSelector(
  selectKpisData,
  state => state.totalExtraCostDollar
);

export const selectSailFuelExpenditures = createSelector(
  selectEfficiencyState,
  state => state.sailFuelExpenditures
);

export const selectSailFuelExpendituresTimeFilter = createSelector(
  selectEfficiencyState,
  state => state.sailFuelExpendituresTimeFilter
);
export const selectTotalExtraFuelMt = createSelector(
  selectKpisData,
  state => state.totalExtraFuelMt
);

export const selectTotalExtraEmissionMt = createSelector(
  selectKpisData,
  state => state.totalExtraEmissionMt
);

export const selectTotalNumOfEarlyActions = createSelector(
  selectKpisData,
  state => state.totalNumOfEarlyActions
);

export const selectTotalNumOfLateActions = createSelector(
  selectKpisData,
  state => state.totalNumOfLateActions
);

export const selectShipsEfficiency = createSelector(
  selectEfficiencyState,
  state => state.shipsEfficiency
);

export const selectShipIds = createSelector(
  selectShipsEfficiency,
  shipsEfficiency => shipsEfficiency.map(ship => ship.shipId)
);

export const selectInefficientEventPerShip = createSelector(
  selectEfficiencyState,
  state => state.inefficientEventPerShip
);

export const selectIsEfficiencyPageActive = createSelector(
  selectEfficiencyState,
  state => state.isEfficiencyPageActive
);
