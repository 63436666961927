import { ChangeContext } from '@angular-slider/ngx-slider';
import { Component, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { Range } from 'src/app/shared/view-models/range.view.model';
import { CustomSliderOptions } from '../../view-models/slider.options.model';

@Component({
  selector: 'app-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.scss'],
})
export class RangeSliderComponent {
  @Input()
  title!: string;

  @Input()
  customSliderOptions!: CustomSliderOptions;

  @Output()
  rangeChanged = new Subject<Range>();

  constructor() {}

  clear(): void {
    this.customSliderOptions = {
      ...this.customSliderOptions,
      highRangeValue:
        this.customSliderOptions.ngxSliderOptions.ceil! + Math.random() / 1000,
      lowRangeValue:
        this.customSliderOptions.ngxSliderOptions.floor! + Math.random() / 1000,
    };
    this.rangeChanged.next({
      min: this.customSliderOptions.lowRangeValue,
      max: this.customSliderOptions.highRangeValue,
    });
  }

  async onRangeChange(event: ChangeContext) {
    this.rangeChanged.next({ min: event.value, max: event.highValue! });
  }
}
