import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { firstValueFrom, Observable } from 'rxjs';
import { CoreState } from 'src/app/core/store/state/core.state';
import { EventsActions, EventsSelectors } from 'src/app/core/store/types';
import { AppliedEventFilters } from 'src/app/core/view-models/filter.view.model';
import { CustomSliderOptions } from 'src/app/shared/view-models/slider.options.model';
@Component({
  selector: 'app-high-pitch-roll-filter',
  templateUrl: './high-pitch-roll-filter.component.html',
  styleUrls: ['./high-pitch-roll-filter.component.scss'],
})
export class HighPitchRollFilterComponent implements OnInit {
  sliderRoll$!: Observable<CustomSliderOptions>;
  sliderPitch$!: Observable<CustomSliderOptions>;
  constructor(private store: Store<CoreState>) {}

  ngOnInit(): void {
    this.sliderPitch$ = this.store.select(EventsSelectors.selectSliderPitch);
    this.sliderRoll$ = this.store.select(EventsSelectors.selectSliderRoll);
  }

  async onEventFiltersChange(value: any, key: string): Promise<void> {
    const appliedFilters = await firstValueFrom(
      this.store.select(EventsSelectors.selectAppliedEventFilters)
    );
    const updatedAppliedFilters: AppliedEventFilters = {
      ...appliedFilters,
      [key]: value,
    };
    this.store.dispatch(
      EventsActions.onEventFilterChange({ updatedAppliedFilters })
    );
  }
}
