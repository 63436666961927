<div class="delta">
  <img
    *ngIf="getRoundedDelta() > 0"
    class="tab-icon"
    src="./assets/icons/green_arrow_up.svg" />
  <img
    *ngIf="getRoundedDelta() < 0"
    class="tab-icon"
    src="./assets/icons/red_arrow_down.svg" />
  <span data-test="delta-color" class="body1" [class]="getDeltaColorClass()">
    {{ getDeltaAbs() | number: '1.0-0' }}%
  </span>
</div>
