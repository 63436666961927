import { Injectable } from '@angular/core';
import { interval, map, Observable, share, startWith } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  time$: Observable<Date>;

  constructor() {
    this.time$ = interval(50000).pipe(
      map(_ => new Date()),
      startWith(new Date()),
      share()
    );
  }
}
