import * as _ from 'lodash';
import { TableState } from '../state/table-state';
import { TableVm } from '../view-models/table.vm';

export function areStatesEqual(s1: TableState, s2: TableState): boolean {
  return (
    s1.defaultCellTemplate === s2.defaultCellTemplate &&
    s1.defaultHeaderTemplate === s2.defaultHeaderTemplate &&
    _.isEqual(s1.columns, s2.columns) &&
    _.isEqual(s1.data, s2.data) &&
    _.isEqual(s1.selectedItemId, s2.selectedItemId) &&
    _.isEqual(s1.sortColumn, s2.sortColumn) &&
    _.isEqual(s1.sortDirection, s2.sortDirection) &&
    s1.trackBy === s2.trackBy
  );
}

export function areVmsEquals(s1: TableVm, s2: TableVm): boolean {
  return _.isEqual(s1, s2);
}
