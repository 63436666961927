<app-no-data [dataExists]="dataExists$ | async">
  <div class="container">
    <div class="filters">
      <app-filters></app-filters>
    </div>
    <app-filtered-events></app-filtered-events>
    <div class="map-container">
      <div class="score-by-date"><app-events-graph></app-events-graph></div>
      <div class="map">
        <app-map
          [events]="mapEvents$ | async"
          [position]="(selectedEventPosition$ | async)!"
          (selectedMapEvent)="onSelectedMapEvent($event)"></app-map>
      </div>
    </div>
  </div>
</app-no-data>
