export function getTimeDiff(endDate: Date, startDate: Date): string {
  const rawSeconds = Math.floor(
    (endDate.getTime() - startDate.getTime()) / 1000
  );
  const rawMinutes = Math.floor(rawSeconds / 60);
  const rawHours = Math.floor(rawMinutes / 60);

  const hours = rawHours;
  const minutes = rawMinutes - hours * 60;

  return `${formatTimeUnit(hours)}:${formatTimeUnit(minutes)}`;
}

export function getTimeDiffWithSeconds(endDate: Date, startDate: Date): string {
  const rawSeconds = Math.floor(
    (endDate.getTime() - startDate.getTime()) / 1000
  );
  const rawMinutes = Math.floor(rawSeconds / 60);
  const rawHours = Math.floor(rawMinutes / 60);

  const hours = rawHours;
  const minutes = rawMinutes - hours * 60;
  const seconds = rawSeconds - rawMinutes * 60;

  return `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

export function formatTimeUnit(timeUnit: number): string {
  return timeUnit >= 0 && timeUnit <= 9 ? `0${timeUnit}` : `${timeUnit}`;
}

export function getNumOfDaysInMonth(year: number, month: number): number {
  return new Date(year, month + 1, 0).getDate();
}
