import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { DestroyRef } from 'projects/orca-lib-main/projects/orca-lib/src/lib/services/destroy-ref.service';
import {
  distinctUntilChanged,
  fromEvent,
  map,
  Observable,
  startWith,
  takeUntil,
  withLatestFrom,
} from 'rxjs';
import { Ship } from 'src/app/core/models/ship.model';
import { FeatureFlagService } from 'src/app/core/services/feature-toggle.service';
import { CoreState } from 'src/app/core/store/state/core.state';
import {
  AuthenticationSelectors,
  OverviewActions,
  OverviewSelector,
} from 'src/app/core/store/types';
import {
  Events,
  ShipLastLocation,
} from 'src/app/core/view-models/event.view.model';
import { Screenshot } from 'src/app/core/view-models/gallery.view.model';
import {
  MapEntity,
  MapLabel,
  MapSettingsOption,
  MapTooltip,
  PolygonEntity,
} from 'src/app/map/models/map.entity.model';
import { Position } from 'src/app/map/models/position.model';

@Component({
  selector: 'app-overview-map',
  templateUrl: './overview-map.component.html',
  styleUrls: ['./overview-map.component.scss'],
  providers: [DestroyRef],
})
export class OverviewMapComponent
  implements OnInit, AfterViewInit, AfterViewChecked
{
  eventOfSelectedShip$!: Observable<Events[]>;
  mapShipEntities$!: Observable<MapEntity[]>;
  mapEventEntities$!: Observable<MapEntity[]>;
  polygons$!: Observable<PolygonEntity[]>;
  mapTooltip$!: Observable<MapTooltip>;
  mapPosition$!: Observable<Position>;
  selectedEvent$!: Observable<Events | null>;
  shipNamelabels$!: Observable<MapLabel[] | null>;
  rtEventlabels$!: Observable<MapLabel[] | null>;
  startDateAvgShipSafetyScore$!: Observable<number>;
  endDateAvgShipSafetyScore$!: Observable<number>;
  screenshot$!: Observable<Screenshot | null>;
  showFlag$!: Observable<boolean>;
  selectedShip$!: Observable<Ship | null>;
  shipLastLocations$!: Observable<ShipLastLocation[]>;
  isShipCaptain$!: Observable<boolean>;
  offsetLongPositionDestination: number = 2.5;
  initialEnabledLayers = [
    MapSettingsOption.Events,
    MapSettingsOption.LiveEvents,
    MapSettingsOption.NoGoAreas,
  ];

  @ViewChild('eventsContainer') eventsContainer!: ElementRef;

  constructor(
    private store: Store<CoreState>,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private featureFlag: FeatureFlagService,
    private dr: DestroyRef
  ) {}

  ngOnInit(): void {
    this.selectedShip$ = this.store.select(OverviewSelector.selectSelectedShip);

    this.polygons$ = this.store.select(OverviewSelector.selectMapPolygons);

    this.shipLastLocations$ = this.store.select(
      OverviewSelector.selectSelectedShipLastLocations
    );
    this.endDateAvgShipSafetyScore$ = this.store.select(
      OverviewSelector.selectEndDateAvgShipSafetyScore
    );

    this.startDateAvgShipSafetyScore$ = this.store.select(
      OverviewSelector.selectStartDateAvgShipSafetyScore
    );

    this.screenshot$ = this.store.select(
      OverviewSelector.selectEnhancedScreenshotByShip
    );

    this.showFlag$ = this.featureFlag.getFeatureFlag$('LiveView');

    this.eventOfSelectedShip$ = this.store.select(
      OverviewSelector.selectEventsOfSelectedShip
    );

    this.mapShipEntities$ = this.store.select(
      OverviewSelector.selectMapShipEntities
    );

    this.mapEventEntities$ = this.store.select(
      OverviewSelector.selectMapEventEntities
    );

    this.selectedEvent$ = this.store.select(
      OverviewSelector.selectSelectedEvent
    );

    this.shipNamelabels$ = this.store.select(
      OverviewSelector.selectMapShipNameLabels
    );

    this.rtEventlabels$ = this.store.select(
      OverviewSelector.selectMapRtEventLabels
    );

    this.mapPosition$ = this.store
      .select(OverviewSelector.selectMapPositionDefaultByLastEvent)
      .pipe(distinctUntilChanged());

    this.subscribeToEventChanges();

    this.showFlag$ = this.featureFlag.getFeatureFlag$('LiveView');

    this.mapTooltip$ = this.store
      .select(OverviewSelector.selectMapTooltip)
      .pipe(
        distinctUntilChanged(),
        withLatestFrom(this.showFlag$.pipe(startWith(null))),
        map(([tooltip, showFlag]) => ({
          ...tooltip,
          isVisible: true,
          showLiveStreamFlag: showFlag,
        }))
      );

    this.isShipCaptain$ = this.store.select(
      AuthenticationSelectors.selectIsUserShipCaptain
    );
  }

  ngAfterViewInit(): void {
    this.checkAndSubscribeToScroll();
  }

  ngAfterViewChecked(): void {
    this.checkAndSubscribeToScroll();
  }

  checkAndSubscribeToScroll(): void {
    if (
      this.eventsContainer &&
      !this.eventsContainer.nativeElement.hasAttribute('data-subscribed')
    ) {
      this.updateScrollToTopOnUserScroll();
      this.eventsContainer.nativeElement.setAttribute(
        'data-subscribed',
        'true'
      );
    }
  }

  updateScrollToTopOnUserScroll(): void {
    fromEvent(this.eventsContainer.nativeElement, 'scroll')
      .pipe(takeUntil(this.dr.destroy$))
      .subscribe(() => {
        this.store.dispatch(OverviewActions.updateScrollToTopByUser());
      });
  }

  subscribeToEventChanges(): void {
    this.eventOfSelectedShip$
      .pipe(
        takeUntil(this.dr.destroy$),
        withLatestFrom(
          this.store.select(
            OverviewSelector.selectScrollTopOfSelectedShipEventData
          )
        )
      )
      .subscribe(([_, scrollToTop]) => {
        if (scrollToTop) this.resetScroll();
      });
  }

  async onSelectedMapEntity(entityParams: string[]): Promise<void> {
    const entityId = entityParams[0];
    const entityCategory = entityParams[1];
    if (entityCategory == 'ship')
      this.store.dispatch(
        OverviewActions.selectShipOnShipMapClick({ shipId: Number(entityId) })
      );
    else
      this.store.dispatch(
        OverviewActions.selectEventOnEventMapClick({ eventId: entityId })
      );
  }

  onSelectedEvent(selectedEvent: Events): void {
    this.store.dispatch(
      OverviewActions.setSelectedOverviewEventList({ selectedEvent })
    );
  }

  onCloseTooltip(): void {
    this.store.dispatch(OverviewActions.resetSelectedShipIdOnCloseTooltip());
  }

  onEventListScrollToEnd(): void {
    this.store.dispatch(OverviewActions.getMoreEventsOnUserScroll());
  }

  shipTooltipButtonClicked(navigate: string) {
    this.router.navigateByUrl(navigate);
    let shipId!: number;
    this.selectedShip$.subscribe(ship => {
      shipId = ship!.shipId;
    });
    this.store.dispatch(
      OverviewActions.setSelectedShipIdOnShipProfileClickOnMap({
        shipId: shipId,
      })
    );
  }

  resetScroll(): void {
    if (this.eventsContainer && this.eventsContainer.nativeElement) {
      this.eventsContainer.nativeElement.scrollTo({
        top: 0,
        behavior: 'auto',
      });
    }
  }

  onEventExploration(event: Events): void {
    if (event.isRtEvent) {
      this.router.navigate([`rt-event-exploration`, event.eventId], {
        relativeTo: this.activatedRoute,
      });
    } else {
      this.router.navigate([`event-exploration`, event.eventId], {
        relativeTo: this.activatedRoute,
      });
    }
  }
}
