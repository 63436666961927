import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UserRecoveryGuard implements CanActivate {
  constructor(private router: Router) {}
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const code = route.queryParams['oobCode'];
    const mode = route.queryParams['mode'];
    if (code && mode) {
      switch (mode) {
        case 'resetPassword':
          this.router.navigate(['/new-password'], {
            queryParams: route.queryParams,
          });
          return true;
        case 'verifyEmail':
          this.router.navigate(['/email-verification'], {
            queryParams: route.queryParams,
          });
          return true;
        default:
          this.router.navigateByUrl('/login');
      }
    }
    return false;
  }
}
