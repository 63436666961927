import { ShipSeverityScore } from '../models/ship.model';

export interface ChartAverageShipData extends ShipSeverityScore {
  readonly name: Date;
  readonly value: number;
}

export interface ShipsBySeverity {
  readonly countOfExcellentSeverityShips: number;
  readonly countOfGoodSeverityShips: number;
  readonly countOfModerateSeverityShips: number;
  readonly countOfLowSeverityShips: number;
  readonly countOfNASeverityShips: number;
  readonly totalCount: number;
}

export const defaultShipsBySeverity: ShipsBySeverity = {
  countOfExcellentSeverityShips: 0,
  countOfGoodSeverityShips: 0,
  countOfLowSeverityShips: 0,
  countOfNASeverityShips: 0,
  countOfModerateSeverityShips: 0,
  totalCount: 0,
};

export interface ShipSailDataChange {
  readonly month: number;
  readonly year: number;
  readonly changeInEvents: number;
}
