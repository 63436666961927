import { Component, Input, OnInit } from '@angular/core';
import { LegendPosition } from '@swimlane/ngx-charts';
import * as shape from 'd3-shape';
import {
  GraphDataView,
  GraphTooltipMapping,
} from 'src/app/shared/models/graph-data-model';
import { tranformDateToTimeComponents } from 'src/app/shared/utils/date-transforms/time-component';

@Component({
  selector: 'app-imu-samples-graph',
  templateUrl: './imu-samples-graph.component.html',
  styleUrls: ['./imu-samples-graph.component.scss'],
})
export class ImuSamplesGraphComponent implements OnInit {
  @Input()
  allGraphDataView!: GraphDataView[];

  @Input()
  yLabel!: any;

  @Input()
  colorScheme!: any;

  dataExists!: boolean;

  curveBumpX = shape.curveBumpX;

  legendPosition = LegendPosition.Right;
  sampleClass = 'sample';

  @Input()
  tooltipViewMapping!: GraphTooltipMapping;

  constructor() {}

  ngOnInit(): void {
    this.dataExists = this.allGraphDataView.length > 0;
  }

  formatXLabel(date: Date): string {
    return tranformDateToTimeComponents(date);
  }
}
