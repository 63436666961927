import { Screenshot } from 'src/app/core/view-models/gallery.view.model';
import { LabelSource, LabelType } from './map.view.models';

export interface MapEntity {
  readonly id: string;
  readonly image?: string;
  readonly lat: number;
  readonly long: number;
  readonly eyeOffset: [number, number, number];
  readonly textLabel?: string;
  readonly labelType?: LabelType;
}

export interface MapLabel {
  readonly iconSrc?: string;
  readonly lat: number;
  readonly long: number;
  readonly textLabel: string;
  readonly labelType: LabelType;
  readonly offset: number;
}

export interface MapTooltip {
  readonly shipData?: any | null;
  readonly endDateAvgShipSafetyScore?: number | null;
  readonly startDateAvgShipSafetyScore?: number | null;
  readonly showLiveStreamFlag?: boolean | null;
  readonly isLiveStreamOpen?: boolean;
  readonly screenshot?: Screenshot;
  readonly isVisible?: boolean;
  readonly visibleByLoading?: boolean;
}

export interface PolygonEntity {
  readonly id: string;
  readonly textLabel: string;
  readonly labelType: LabelType;
  readonly coordinates: number[][];
  readonly color: string;
  readonly borderColor: string;
  readonly labelSource: string;
}

export type PolygonColor = {
  color: string;
  borderColor: string;
};

export enum EntityCategory {
  Ship = 'ship',
  Event = 'event',
  Unknown = 'unknown',
}

export enum MapSettingsOption {
  Events = 'Events',
  LiveEvents = 'Live Events',
  NoGoAreas = 'No go Areas',
  ComplianceAreas = 'Compliance Areas',
  Wind = 'Wind',
  WaveSwell = 'Waves (Swell)',
  WaveSignificant = 'Waves (Significant)',
  Currents = 'Ocean Currents',
  Humidity = 'Humidity',
  Temperature = 'Temperature',
  Clouds = 'Clouds and Precipitation',
}

export type LayerOption = { key: MapSettingsOption; label: string };

export const eventLayersOptions: LayerOption[] = [
  { key: MapSettingsOption.Events, label: 'Events' },
  { key: MapSettingsOption.LiveEvents, label: 'Live Events' },
];

export const areaLayersOptions: LayerOption[] = [
  { key: MapSettingsOption.NoGoAreas, label: 'No go Areas' },
  { key: MapSettingsOption.ComplianceAreas, label: 'Compliance Areas' },
];

export const weatherLayersOptions: LayerOption[] = [
  { key: MapSettingsOption.WaveSwell, label: 'Waves (Swell)' },
  { key: MapSettingsOption.WaveSignificant, label: 'Waves (Significant)' },
  { key: MapSettingsOption.Wind, label: 'Wind' },
  { key: MapSettingsOption.Currents, label: 'Ocean Currents' },
  { key: MapSettingsOption.Humidity, label: 'Humidity' },
  { key: MapSettingsOption.Temperature, label: 'Temperature' },
  { key: MapSettingsOption.Clouds, label: 'Clouds and Precipitation' },
];

export type MapLayerChange = {
  layerOption: MapSettingsOption;
  isChecked: boolean;
};

export type LegendColors = { value: number; color: string }[];

export interface MapBounds {
  north: number;
  south: number;
  east: number;
  west: number;
  zoom: number;
}

export interface TileCoordinate {
  z: number;
  x: number;
  y: number;
}

export interface PolyLinePoint {
  latitude: number;
  longitude: number;
  sog: number;
  time: string;
  textLabel: string;
  labelType: LabelType;
}

export interface FeatureLabel {
  iconSrc?: string;
  textLabel: string;
  labelType: LabelType;
  labelSource?: LabelSource;
}
