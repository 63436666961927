import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, take } from 'rxjs';
import { InsightCardRendererComponent } from 'src/app/core/components/insights/insight-card-renderer/insight-card-renderer.component';
import { TitleComponent } from 'src/app/core/components/insights/title/title.component';
import { CoreState } from 'src/app/core/store/state/core.state';
import { OverviewActions, OverviewSelector } from 'src/app/core/store/types';
import { CarouselComponent } from 'src/app/shared/components/carousel/carousel.component';
import { InsightCard } from 'src/app/shared/view-models/insight.view.model';

@Component({
  selector: 'app-insights-widget',
  standalone: true,
  imports: [
    CommonModule,
    CarouselComponent,
    TitleComponent,
    InsightCardRendererComponent,
  ],
  templateUrl: './insights-widget.component.html',
  styleUrls: ['./insights-widget.component.scss'],
})
export class InsightsWidgetComponent implements OnInit {
  insights$!: Observable<InsightCard[]>;

  constructor(private store: Store<CoreState>) {}

  ngOnInit(): void {
    this.insights$ = this.store.select(OverviewSelector.selectInsights);
  }

  onArrowClicked(carouselIndex: number) {
    this.insights$.pipe(take(1)).subscribe((insights: InsightCard[]) => {
      const currentInsight = insights[carouselIndex];
      this.store.dispatch(
        OverviewActions.insightLoad({
          insightId: currentInsight.insightId,
        })
      );
    });
  }
}
