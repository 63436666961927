import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { DestroyRef } from 'projects/orca-lib-main/projects/orca-lib/src/lib/services/destroy-ref.service';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  interval,
  map,
  Observable,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs';
import { CoreState } from 'src/app/core/store/state/core.state';
import { ShipsActions, ShipsSelectors } from 'src/app/core/store/types';
import {
  DayNamesDto,
  StreamingState,
  StreamVideo,
  ThermalNamesDto,
  VideosNames,
  ViewsStreamNames,
  ViewStreamNamesDto,
} from 'src/app/core/view-models/event.view.model';

@Component({
  selector: 'app-live-stream',
  templateUrl: './live-stream.component.html',
  styleUrls: ['./live-stream.component.scss'],
  providers: [DestroyRef],
})
export class LiveStreamComponent implements OnInit, OnDestroy {
  @Input() isDialogChild: boolean = false;
  @Input() fleetId!: number;
  @Input() shipId!: number;
  isStreamMaster$!: Observable<boolean>;
  streamUrl$!: Observable<string>;
  liveCameraId$!: Observable<
    DayNamesDto | ThermalNamesDto | ViewStreamNamesDto | null
  >;
  viewCamera$!: Observable<StreamVideo[]>;
  dayCameras$!: Observable<StreamVideo[]>;
  thermalCameras$!: Observable<StreamVideo[]>;
  cameraControl = new FormControl<
    DayNamesDto | ThermalNamesDto | ViewStreamNamesDto | null
  >(null);
  selectedCameraName$!: Observable<VideosNames | ViewsStreamNames | null>;
  streamingStartTimer$!: Observable<number>;
  streamingState$!: Observable<StreamingState>;
  connectivityStatus$ = this.store.select(
    ShipsSelectors.selectConnectivityStatusOnLoadingStage
  );

  screenshotUrl$ = new BehaviorSubject<string>('');

  constructor(
    private store: Store<CoreState>,
    private dr: DestroyRef,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.isStreamMaster$ = this.store.select(
      ShipsSelectors.selectIsShipStreamMaster
    );

    this.store.dispatch(
      ShipsActions.getShipStartStream({
        fleetId: this.fleetId,
        shipId: this.shipId,
      })
    );

    this.store
      .select(ShipsSelectors.selectShipStreamingId)
      .pipe(
        filter(id => !!id),
        takeUntil(this.dr.destroy$)
      )
      .subscribe(() => {
        this.store.dispatch(ShipsActions.getShipStreamMaster());
      });

    this.streamingStartTimer$ = interval(1000).pipe(
      startWith(0),
      takeUntil(this.dr.destroy$)
    );

    this.liveCameraId$ = this.store.select(
      ShipsSelectors.selectOnlineShipCameraId
    );

    this.streamUrl$ = this.store.select(ShipsSelectors.selectStreamUrl);

    this.store
      .select(ShipsSelectors.selectShipStreamingId)
      .pipe(
        takeUntil(this.dr.destroy$),
        filter(id => id !== null),
        switchMap(id => {
          return this.store.select(
            ShipsSelectors.selectEnhancedScreenshotByShipId(id)
          );
        })
      )
      .subscribe(screenshot => {
        if (screenshot) {
          this.screenshotUrl$.next(screenshot.cdnUrl);
        } else {
          this.screenshotUrl$.next('');
        }
      });

    this.viewCamera$ = this.store.select(ShipsSelectors.selectShipViewsCameras);

    this.dayCameras$ = this.store.select(ShipsSelectors.selectShipDayCameras);

    this.thermalCameras$ = this.store.select(
      ShipsSelectors.selectShipThermalCameras
    );

    this.cameraControl.valueChanges
      .pipe(
        takeUntil(this.dr.destroy$),
        distinctUntilChanged(),
        filter(cameraId => cameraId !== null)
      )
      .subscribe(cameraId => {
        this.store.dispatch(
          ShipsActions.getShipStream({ camera_id: cameraId! })
        );
      });

    this.liveCameraId$
      .pipe(takeUntil(this.dr.destroy$))
      .subscribe(liveCameraId => {
        this.cameraControl.setValue(liveCameraId);
      });

    this.selectedCameraName$ = this.store.select(
      ShipsSelectors.selectSelectedShipCameraName
    );

    this.streamingState$ = combineLatest([
      this.streamingStartTimer$,
      this.streamUrl$.pipe(startWith('')),
    ]).pipe(
      map(([time, streamUrl]): StreamingState => {
        if (streamUrl != '') {
          return {
            state: 'Ok',
            streamUrl,
          };
        } else if (time >= 45 && streamUrl === '') {
          return { state: 'Timeout', streamUrl: '' };
        }
        return {
          state: 'WaitingForStream',
          streamUrl: '',
        };
      })
    );
  }

  getIsCamerasEmpty(cameras: StreamVideo[]): boolean {
    return Object.keys(cameras).length === 0;
  }

  get backgroundImageStyle(): SafeStyle {
    const url = this.screenshotUrl$.getValue();
    return this.sanitizer.bypassSecurityTrustStyle(`url(${url})`);
  }

  get hasBackgroundImage(): boolean {
    const url = this.screenshotUrl$.getValue();
    return !!url;
  }

  ngOnDestroy(): void {
    this.store.dispatch(
      ShipsActions.closePollingLiveStream({
        shipDialogDef: { streamUrl: '', isStreamMaster: false },
      })
    );
  }
}
