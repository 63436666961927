import { createSelector } from '@ngrx/store';
import { DateFilterKeys } from 'src/app/shared/view-models/dates.view.model';
import { CoreState } from '../state/core.state';
import { FiltersState } from '../state/filters.state';

const selectFiltersState = (state: CoreState): FiltersState => state.filters;

export const selectFilterdDatesKey = createSelector(
  selectFiltersState,
  state => {
    const filter = state.dateFilter;
    const dateFilterKey = Object.keys(filter)[0] as unknown as DateFilterKeys;
    return dateFilterKey;
  }
);

export const selectFilteredDates = createSelector(selectFiltersState, state => {
  const filter = state.dateFilter;

  const dateFilterKey = Object.keys(filter)[0] as unknown as DateFilterKeys;
  const dates = state.dateFilter[dateFilterKey]!;
  return {
    ...dates,
    startDate: new Date(dates.startDate.setUTCHours(0, 0, 0, 0)),
  };
});

export const selectIsDatesFilterDisabled = createSelector(
  selectFiltersState,
  state => state.isDateFilterDisabled
);

export const selectIsCustomDateOptionDisabled = createSelector(
  selectFiltersState,
  state => state.isCustomDateOptionDisabled
);
