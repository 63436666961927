export interface KpisData {
  readonly totalExtraEmissionMt: number | null;
  readonly totalExtraCostDollar: number | null;
  readonly totalExtraFuelMt: number | null;
  readonly totalNumOfLateActions: number | null;
  readonly totalNumOfEarlyActions: number | null;
}
export interface KpisDataDto {
  readonly total_extra_emission_mt: number;
  readonly total_extra_cost_dollar: number;
  readonly total_extra_fuel_mt: number;
  readonly total_num_of_late_actions: number;
  readonly total_num_of_early_actions: number;
}

export const defaultKpisData: KpisData = {
  totalExtraEmissionMt: null,
  totalExtraCostDollar: null,
  totalExtraFuelMt: null,
  totalNumOfLateActions: null,
  totalNumOfEarlyActions: null,
};
export interface ShipEfficiency {
  readonly shipId: number;
  readonly shipName: string;
  readonly fleetName: string;
  readonly extraCostDistanceSailed: number;
  readonly numLateActions: number;
  readonly numEarlyActions: number;
  readonly extraFuelConsumedMt: number;
  readonly extraDistanceSailedNm: number;
  readonly nmSailedInOpenWaters: number;
  readonly extraEmissionMt: number;
}
export interface SailCost {
  readonly startDate: string;
  readonly totalSpentDollar: number;
  readonly extraSpentDollar: number;
}
export interface SailFuelExpenditureGraphData {
  readonly name: string;
  readonly series: {
    name: string;
    value: number;
  }[];
}
export interface ShipEfficiencyDto {
  readonly ship_id: number;
  readonly ship_name: string;
  readonly fleet_name: string;
  readonly nm_sailed_in_open_waters: number;
  readonly cost_of_nm_sailed_dollar: number;
  readonly extra_distance_sailed_nm: number;
  readonly extra_fuel_consumed_mt: number;
  readonly cost_of_extra_distance_sailed_dollar: number;
  readonly num_of_late_actions: number;
  readonly num_of_early_actions: number;
  readonly num_of_events: number;
  readonly extra_emission_mt: number;
}

export interface SailCostDto {
  readonly start_date: string;
  readonly total_spent_dollar: number;
  readonly extra_spent_dollar: number;
}

export interface ShipsEmissionDtoResponse {
  readonly ships_emission_data: ShipEfficiencyDto[];
  readonly total_extra_emission_mt: number;
  readonly total_extra_cost_dollar: number;
  readonly total_extra_fuel_mt: number;
  readonly total_num_of_late_actions: number;
  readonly total_num_of_early_actions: number;
}
export interface ShipsSailCostDtoResponse {
  readonly data: SailCostDto[];
}

export interface ShipsEmissionResponse {
  readonly shipsEfficiency: ShipEfficiency[];
  readonly kpisData: KpisData;
}
export interface ShipsSailCostResponse {
  readonly sailCost: SailCost[];
}
export enum TimeFilter {
  YEAR_AND_THIS_MONTH = '1 Year',
  THREE_MONTHS_AND_THIS_MONTH = '3 Months',
  FOUR_WEEKS_AND_THIS_WEEK = '4 Weeks',
}

export enum Resolution {
  WEEK = 'WEEK',
  MONTH = 'MONTH',
}
export interface InefficientEventDtoResponse {
  readonly event_per_ship: { [key: number]: string };
}
export interface InefficientEventResponse {
  readonly inefficientEventPerShip: { [key: number]: string };
}
