import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { AlertNames } from 'src/app/core/models/events-filters.model';
import {
  CrewShifts,
  EventCountByGroup,
  EventSeverity,
} from 'src/app/core/models/events.model';
import { CoreState } from 'src/app/core/store/state/core.state';
import { ShipsSelectors } from 'src/app/core/store/types';
import { GraphTooltipMapping } from 'src/app/shared/models/graph-data-model';

@Component({
  selector: 'app-ship-events-by-severity',
  templateUrl: './ship-events-by-severity.component.html',
  styleUrls: ['./ship-events-by-severity.component.scss'],
})
export class ShipEventsBySeverityComponent implements OnInit {
  colorScheme = [
    { name: 'high', value: 'var(--graph-red)' },
    { name: 'medium', value: 'var(--graph-dark-orange)' },
    { name: 'low', value: 'var(--graph-light-orange)' },
  ];

  dataExists$!: Observable<boolean>;
  shipEventCountBySeverity$!: Observable<EventCountByGroup[]>;
  totalShipEventCount$!: Observable<number>;
  shipEventCountBySeverityView$!: Observable<
    {
      name: EventSeverity | AlertNames | CrewShifts;
      value: number;
    }[]
  >;

  risksMap: GraphTooltipMapping = {
    high: {
      divClass: 'high',
      text: 'High',
    },
    medium: {
      divClass: 'medium',
      text: 'Medium',
    },
    low: {
      divClass: 'low',
      text: 'Low',
    },
  };

  constructor(private store: Store<CoreState>) {}

  ngOnInit(): void {
    this.totalShipEventCount$ = this.store.select(
      ShipsSelectors.selectSelectedShipTotalEventCount
    );
    this.shipEventCountBySeverity$ = this.store.select(
      ShipsSelectors.selectSelectedShipEventCountBySeverity
    );

    this.shipEventCountBySeverityView$ = this.shipEventCountBySeverity$.pipe(
      map(eventCountList =>
        eventCountList.map(eventCountObject => ({
          name: eventCountObject.group_title,
          value: eventCountObject.count,
        }))
      )
    );

    this.dataExists$ = this.totalShipEventCount$.pipe(map(count => count > 0));
  }
}
