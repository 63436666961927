import { createAction, props } from '@ngrx/store';
import {
  LiveShipData,
  PitchAndRollData,
  ScoringFactorsStats,
  Ship,
  ShipCamera,
  ShipEventCount,
  ShipLiveStream,
  ShipSafetyScores,
  ShipSafteyScore,
  ShipSailData,
  ShipsSeverityByDate,
} from '../../models/ship.model';
import { ShipsSortBy } from '../../models/ships-filters.model';
import { Screenshot } from '../../view-models/gallery.view.model';

export const saveShipsSeverityOnAuth = createAction(
  '[Ships Effects] Save ships severity on authentication',
  props<{ ships: ShipsSeverityByDate }>()
);

export const updateShipsSeverityOnRangeChange = createAction(
  '[Ships Effects] Update ships severity on range change',
  props<{ ships: ShipsSeverityByDate }>()
);

export const updateShipsSeverityOnFleetChange = createAction(
  '[Ships Effect] Update ships severity on fleet change',
  props<{ ships: ShipsSeverityByDate }>()
);

export const saveHighestSafteyScoreShipsOnAuth = createAction(
  '[Ships Effects] Save ships with saftey score on authentication',
  props<{ ships: ShipSafteyScore[] }>()
);

export const updateHighestSafteyScoreShipsOnRangeChange = createAction(
  '[Ships Effects] Update ships with highest saftey score on range change',
  props<{ ships: ShipSafteyScore[] }>()
);

export const updateHighestSafteyScoreShipsOnFleetChange = createAction(
  '[Ships Effect] Update ships with highest safety score on fleet change',
  props<{ ships: ShipSafteyScore[] }>()
);

export const saveSortByOptionOnUserChange = createAction(
  '[Overview Page] Save sort by option on user change',
  props<{ sortBy: ShipsSortBy }>()
);

export const saveSortedShipsScoreOnUserChange = createAction(
  '[Ships Effects] Save ships with saftey score',
  props<{ ships: ShipSafteyScore[] }>()
);

export const saveShipsOnRegularUserAuth = createAction(
  '[Ships Effects] Save ships on auth',
  props<{ ships: Ship[] }>()
);

export const saveShipsOnAdminUserAuth = createAction(
  '[Ships Effects] Save ships on auth',
  props<{ ships: Ship[] }>()
);

export const setShipSafetyScoresOnShipListClick = createAction(
  '[Fleet page] Get ship safety scores on ship list click',
  props<{ shipSafetyScores: ShipSafetyScores }>()
);

export const setShipSafetyScoresOnDateRangeChanged = createAction(
  '[Fleet page] Get ship safety scores on date range changed',
  props<{ shipSafetyScores: ShipSafetyScores }>()
);

export const setSelectedShipIdOnShipListClick = createAction(
  '[Fleet page] Save ship id on ship list click',
  props<{ shipId: number | null }>()
);

export const setSelectedShipIdOnShipMapClick = createAction(
  '[Fleet page] Save ship id on ship map click',
  props<{ shipId: number }>()
);

export const setSelectedShipIdOnViewShipProfile = createAction(
  '[Fleet page] Save ship id on view ship click',
  props<{ shipId: number }>()
);
export const setSelectedShipIdOnTooltipShipProfile = createAction(
  '[ship tooltip] Save ship id on ship tooltip click',
  props<{ shipId: number }>()
);

export const setSelectedShipIdOnViewRtEvent = createAction(
  '[RT Event page] Save ship id on view ship click',
  props<{ shipId: number }>()
);

export const updateShipsOnDateRangeChange = createAction(
  '[Ships Effects] Update ships on date filter change',
  props<{ ships: Ship[] }>()
);

export const setShipConnectionStatusOnPageCreation = createAction(
  '[Ships Effects] Set ship connection status on creation',
  props<{ shipId: number; fleetId: number }>()
);

export const updateShipConnectionStatus = createAction(
  '[Ships Effects] update ship connection status',
  props<{ shipConnection: Partial<ShipLiveStream> }>()
);

export const closeShipConnectionStatus = createAction(
  '[Ships Effects] close ship connection status'
);

export const updateShipCameras = createAction(
  '[Ships Effects] update ship cameras',
  props<{ cameras: ShipCamera[] }>()
);

export const getShipStreamMaster = createAction(
  '[Ships Effects] get ship stream master'
);

export const patchShipStreamMaster = createAction(
  '[Ships Effects] patch ship stream master'
);

export const closePollingLiveStream = createAction(
  '[Live Stream Dialog] close polling live stream data',
  props<{ shipDialogDef: Partial<ShipLiveStream> }>()
);

export const updateIsShipStreamMaster = createAction(
  '[Ships Effects] update is ship stream master',
  props<{ isStreamMaster: boolean }>()
);

export const getShipStream = createAction(
  '[Ships Effects] get ship stream',
  props<{ camera_id: string }>()
);

export const getShipStartStream = createAction(
  '[Ships Effects] get ship start stream',
  props<{ fleetId: number; shipId: number }>()
);

export const updateShipStream = createAction(
  '[Ships Effects] update ship stream',
  props<{ shipStream: Partial<ShipLiveStream> }>()
);

export const resetSelectedShipIdOnFleetChange = createAction(
  '[Ships Effects] Reset Selected Ship Id On Fleet Change'
);

export const getShipSailData = createAction(
  '[Ship Page] Get ship sail data',
  props<{ shipId: number; periodInDays: number }>()
);

export const updateSelectedShipSailData = createAction(
  '[Ship Effects] Update selected ship sailing data',
  props<{ sailData: ShipSailData[] }>()
);

export const updateSelectedShipSailDataOnNavigation = createAction(
  '[Ship Resolver] Update selected ship sailing data on ship page navigation',
  props<{ sailData: ShipSailData[] }>()
);

export const getSelectedShipEventCountDataOnNavigation = createAction(
  '[Ship Resolver] Get selected ship event count data on ship page navigation',
  props<{ shipId: number }>()
);

export const updateSelectedShipEventCountData = createAction(
  '[Ship Resolver] Update selected ship event count data',
  props<{ shipEventCountData: ShipEventCount }>()
);

export const updateShipsScoringFactorsStatistics = createAction(
  '[Ship Resolver] Update fleet scoring factor statistics',
  props<{ shipsScoreStats: ScoringFactorsStats }>()
);

export const shipNameClicked = createAction(
  '[Safety Score Ships] Ship Name Clicked',
  props<{ shipId: number; shipName: string }>()
);

export const fetchScreenshotsInitiated = createAction(
  '[Live page] Fetch Screenshots Initiated'
);

export const updateScreenshots = createAction(
  '[Live page] Update Screenshots',
  props<{ screenshots: Screenshot[] }>()
);

export const fetchShipDetails = createAction(
  '[Ship Profile - live page] Fetch ships data',
  props<{ shipId: number }>()
);

export const updateShipRealTimeData = createAction(
  '[Ship Profile - live page] Update ships real time data',
  props<{ shipRealTimeData: Ship | null }>()
);

export const fetchLatestShipImu = createAction(
  '[Pitch Roll] Fetch Latest Ship Pitch Roll',
  props<{ shipId: number }>()
);

export const updateLatestShipImu = createAction(
  '[Pitch Roll] Update Latest Ship Pitch Roll',
  props<{ shipPitchRoll: PitchAndRollData[] }>()
);

export const fetchShipLiveData = createAction(
  '[RT event page - live page] Fetch ship live data',
  props<{ shipId: number }>()
);

export const updateShipLiveData = createAction(
  '[RT event page - live page] Update ship live data',
  props<{ liveShipData: LiveShipData | null }>()
);

export const updateConnectivityStatusOnLoadingStage = createAction(
  '[Ships Effects] update connectivity status on loading stage',
  props<{ status: string }>()
);
