import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Model } from 'common';
import { AuthenticationActions } from '../../../store/types';

@Component({
  selector: 'app-reset-pass',
  templateUrl: './reset-pass.component.html',
  styleUrls: ['./reset-pass.component.scss'],
})
export class ResetPassComponent {
  resetPasswordForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
  });
  constructor(private fb: FormBuilder, private store: Store) {}

  resetPassword(): void {
    const resetPasswordReq: Model.ResetPasswordRequest = {
      email: this.resetPasswordForm.value.email!,
    };
    this.store.dispatch(
      AuthenticationActions.requestResetPassword({ resetPasswordReq })
    );
  }
}
