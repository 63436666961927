<div>
  <button
    class="menu-button"
    [class.menu-open]="menuOpen"
    (click)="onToggleMenu()"
    [disabled]="!isEnabledFeature">
    <img [src]="iconSrc" alt="icon-menu" />
  </button>
  <div *ngIf="menuOpen" class="menu">
    <div class="menu-title">
      <ng-container *ngTemplateOutlet="menuTitle"></ng-container>
    </div>

    <div class="menu-content">
      <ng-container *ngTemplateOutlet="menuContent"></ng-container>
    </div>
  </div>
</div>
