import { Model } from 'common';
import { Fleet } from '../../models/fleet.model';

export interface AuthenticationState {
  readonly authenticatedUser: Model.User | null;
  readonly preliminaryUser: Model.PreliminaryUser | null;
  readonly fleets: Fleet[] | null;
}

export const initialAuthenticationState: AuthenticationState = {
  authenticatedUser: null,
  preliminaryUser: null,
  fleets: null,
};
