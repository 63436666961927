export const allEntityImagePath: string[] = [
  '/assets/map/events/close_encounter/high.png',
  '/assets/map/events/close_encounter/low.png',
  '/assets/map/events/close_encounter/medium.png',
  '/assets/map/events/compliance/high.png',
  '/assets/map/events/high_pitch/roll/high.png',
  '/assets/map/events/high_pitch/roll/low.png',
  '/assets/map/events/high_pitch/roll/medium.png',
  '/assets/map/events/high_rot/high.png',
  '/assets/map/events/high_rot/low.png',
  '/assets/map/events/high_rot/medium.png',
  '/assets/map/events/high.png',
  '/assets/map/events/low.png',
  '/assets/map/events/medium.png',
  '/assets/map/events/no_go_zone/high.png',
  '/assets/map/events/speed_drop/high.png',
  '/assets/map/events/speed_drop/low.png',
  '/assets/map/events/speed_drop/medium.png',
  '/assets/map/events/ukc/high.png',
  '/assets/map/events/ukc/low.png',
  '/assets/map/events/ukc/medium.png',
  '/assets/map/ships/bulk_carrier/faded_ship_icon.png',
  '/assets/map/ships/bulk_carrier/ship_icon.png',
  '/assets/map/ships/container_ship/faded_ship_icon.png',
  '/assets/map/ships/container_ship/ship_icon.png',
  '/assets/map/ships/crude_oil_tanker/faded_ship_icon.png',
  '/assets/map/ships/crude_oil_tanker/ship_icon.png',
  '/assets/map/ships/default.png',
  '/assets/map/ships/demo/faded_ship_icon.png',
  '/assets/map/ships/demo/ship_icon.png',
  '/assets/map/ships/faded_default.png',
  '/assets/map/ships/lng_tanker/faded_ship_icon.png',
  '/assets/map/ships/lng_tanker/ship_icon.png',
  '/assets/map/ships/lpg_tanker/faded_ship_icon.png',
  '/assets/map/ships/lpg_tanker/ship_icon.png',
  '/assets/map/ships/roro_vehicle_carrier/faded_ship_icon.png',
  '/assets/map/ships/roro_vehicle_carrier/ship_icon.png',
  '/assets/map/ships/tug/faded_ship_icon.png',
  '/assets/map/ships/tug/ship_icon.png',
  '/assets/map/ships/vehicles_carrier/faded_ship_icon.png',
  '/assets/map/ships/vehicles_carrier/ship_icon.png',
];
